import React from 'react';
import { Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';

type Props = FlexProps & CssTypeFix;

/*
 *  This component is needed to easily enable scrolling containers after the safari fix in index.css
 */
export const ScrollBox = (props: Props) => (
  <Flex
    {...props}
    overflow="auto"
    sx={{
      flexShrink: 1,
      ...(props.sx || {}),
    }}
  />
);
