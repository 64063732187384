import { createSelector } from "reselect";
import { getCases } from "selectors/cases/getCases";
import { GlobalState } from "reducers";

export const getCasesForPatient = createSelector(
  getCases,
  (_: GlobalState, { patientId }: { patientId: number | string }) => patientId,
  (cases, patientId) => Object.values(cases).filter(
    therapyCase => String(therapyCase.patientId) === String(patientId)
  ),
);
