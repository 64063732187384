import { createAction } from "redux-actions";
import { PAYMENT_FAILURE, PAYMENT_START, PAYMENT_SUCCESS } from "modules/payment/actionTypes";
import { PaymentIntent } from "@stripe/stripe-js";

export interface PaymentPayload {
  paymentIntentSecret: string;
  paymentMethodId: string;
}
export const makePayment = createAction<PaymentPayload>(PAYMENT_START);

export interface PaymentSuccessPayload {
  paymentIntent: PaymentIntent;
}
export const makePaymentSuccess = createAction<PaymentSuccessPayload>(PAYMENT_SUCCESS);

export interface PaymentFailurePayload {
  name: string;
  message?: string;
}
export const makePaymentFailure = createAction<PaymentFailurePayload>(PAYMENT_FAILURE);
