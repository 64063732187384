import { Appointment } from "types/types";
import { appointmentMoment } from "utils/appointment/appointmentMoment";

/**
 * Sorts a list of appointments chronologically
 * @param appointments List of appointments
 */
export const sortAppointmentsByTime = (appointments: Appointment[]): Appointment[] =>
  appointments.sort(
    (a, b) => {
      const momentA = appointmentMoment(a);
      const momentB = appointmentMoment(b);
      if (momentA.isAfter(momentB)) {
        return 1;
      } else if (momentA.isBefore(momentB)) {
        return -1;
      } else {
        return 0;
      }
    }
  );
