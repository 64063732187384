import { createAction } from 'redux-actions';
import {
  BOOK_APPOINTMENT,
  BOOK_APPOINTMENT_FAILURE,
  BOOK_APPOINTMENT_SUCCESS,
  END_SESSION,
  END_SESSION_FAILURE,
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILURE,
  FETCH_APPOINTMENTS_SUCCESS,
  HOME_HEALTH_APPOINTMENT_CANCEL_FAILURE,
  HOME_HEALTH_APPOINTMENT_CANCEL_SUCCESS,
  SET_LAST_APPOINTMENT,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_SUCCESS,
} from './actionTypes';
import {
  Appointment,
  AppointmentService,
  AppointmentStatus,
  AppointmentStatusName,
} from 'types/types';
import { CancellationReason } from 'modules/appointments/constants';

export interface FetchAppointmentsActionPayload {
  caseId?: string;
  status?: AppointmentStatus;
  includeCancelled?: boolean;
}

export interface HomeHealthAppointmentCancel {
  appointmentId?: string;
}
export const homeHealthCancelSuccess = createAction(
  HOME_HEALTH_APPOINTMENT_CANCEL_SUCCESS
);
export const homeHealthCancelFailure = createAction(
  HOME_HEALTH_APPOINTMENT_CANCEL_FAILURE
);

// untested, ensure this is translated properly
export const fetchAppointments = createAction<FetchAppointmentsActionPayload>(
  FETCH_APPOINTMENTS
);
export const fetchFutureAppointments = ({ caseId }: { caseId: string }) =>
  fetchAppointments({
    caseId,
    status: AppointmentStatus.Pending,
  });

export type FetchAppointmentsSuccessActionPayload = Appointment[];
export const fetchAppointmentsSuccess = createAction(
  FETCH_APPOINTMENTS_SUCCESS
);

export const fetchAppointmentsFailure = createAction(
  FETCH_APPOINTMENTS_FAILURE
);

export interface BookAppointmentActionPayload {
  // Will accept:
  //   YYYY-MM-DDTHH:MM
  //   YYYY-MM-DDTHH:MM:SS
  //   YYYY-MM-DDTHH:MM:SS[+-]HH:MM
  dateTime: string;
  type: AppointmentService;
  therapistId?: number;
  patientId: number;
  duration: number;
  caseId?: number; // fetched by epic if none provided
}
export const bookAppointment = createAction<BookAppointmentActionPayload>(
  BOOK_APPOINTMENT
);

export type BookAppointmentSuccessActionPayload = Appointment;
export const bookAppointmentSuccess = createAction<
  BookAppointmentSuccessActionPayload
>(BOOK_APPOINTMENT_SUCCESS);

export const bookAppointmentFailure = createAction(BOOK_APPOINTMENT_FAILURE);

export type UpdateAppointmentActionPayload = Partial<Appointment> & {
  id: number;
  missingDocs?: string[];
};
export const updateAppointment = createAction<UpdateAppointmentActionPayload>(
  UPDATE_APPOINTMENT
);

export type UpdateAppointmentSuccessActionPayload = Appointment;
// TODO: RESTORE
// export const updateAppointmentSuccess = createAction<Appointment>(UPDATE_APPOINTMENT_SUCCESS);

// TODO: REMOVE, this is for testing
export const rawUpdateAppointmentSuccess = createAction<Appointment>(
  UPDATE_APPOINTMENT_SUCCESS
);
export const updateAppointmentSuccess = (appointment: Appointment) => {
  return rawUpdateAppointmentSuccess(appointment);
};
// remove up to here

export const updateAppointmentFailure = createAction(
  UPDATE_APPOINTMENT_FAILURE
);

export const endSessionFailure = createAction(END_SESSION_FAILURE);

export interface StartSessionActionPayload {
  id: number; // appointment id
}
export const startSession = ({ id }: StartSessionActionPayload) =>
  updateAppointment({
    id,
    appStatus: AppointmentStatusName.Arrived,
  });

export interface EndSessionActionPayload {
  id: number;
  missingDocs?: string[];
}

export const endSession = createAction<EndSessionActionPayload>(END_SESSION);

export interface CancelAppointmentPayload {
  id: number;
  reason: CancellationReason;
}
export const cancelAppointment = ({ id, reason }: CancelAppointmentPayload) =>
  updateAppointment({
    id,
    appStatus: reason,
  });

export interface SetAppointmentAsPaidPayload {
  id: number;
  amountPaid?: string;
}
export const setAppointmentAsFinalized = ({
  id,
  amountPaid,
}: SetAppointmentAsPaidPayload) =>
  updateAppointment({
    id,
    amountPaid,
    appStatus: AppointmentStatusName.Finalized,
  });

export interface SetLastAppointmentPayload {
  id: number;
}
export const setLastAppointment = createAction<SetLastAppointmentPayload>(
  SET_LAST_APPOINTMENT
);

export interface RescheduleAppointmentPayload {
  id: number;
  newTime: string; // "2020-07-07T07:15:00.000Z"
  reason: string;
}
export const rescheduleAppointment = ({
  id,
  newTime,
  reason,
}: RescheduleAppointmentPayload) =>
  updateAppointment({
    id,
    appDatetime: newTime,
    cancellationReason: reason, // find a better field to put this, or ask for one
  });
