import { CustomEpic } from 'types/types';
import {
  GetTherapistCasesActionPayload,
  fetchCasesSuccess,
  fetchCasesFailure,
} from '../actions';
import { ofType } from 'redux-observable';
import { FETCH_THERAPIST_CASES } from '../actionTypes';
import { switchMap } from 'rxjs/operators';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { fetchCasesCallTranslator } from 'modules/cases/translators/translateFetchCases';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { getId } from 'selectors/user/getId';
import { getFetchTherapistCasesUrl } from 'modules/cases/utils/getFetchTherapistCasesUrl';

export const fetchTherapistCases$: CustomEpic<GetTherapistCasesActionPayload> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(FETCH_THERAPIST_CASES),
    switchMap(({ payload: { therapistId = getId(state$.value) } }) =>
      getApi$({
        endpoint: getFetchTherapistCasesUrl({ therapistId }),
        payload: {},
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(fetchCasesSuccess, fetchCasesCallTranslator),
        handleAjaxError(fetchCasesFailure)
      )
    )
  );
