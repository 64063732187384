import { Appointment, Case } from "types/types";
import { getAppointmentCaseProgression } from "utils/case/caseProgression/getAppointmentCaseProgression";

interface Props {
  therapyCase: Case;
  appointment: Appointment;
}

export const getAppointmentCaseProgressionFraction = ({ therapyCase, appointment }: Props): string => {
  const { casesDone, casesLeft } = getAppointmentCaseProgression({ therapyCase, appointment });
  return `${casesDone}/${casesLeft}`;
};
