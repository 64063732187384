import React from 'react';
import { Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';

type Props = {
  children?: React.ReactNode;
} & FlexProps &
  CssTypeFix;

export const ShadowCard = ({ children, ...rest }: Props) => (
  <Flex
    {...rest}
    css={{
      boxShadow: '0 0 12pt 0 rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
    }}
  >
    {children}
  </Flex>
);
