import React, { useCallback, useRef } from 'react';
import { Button, Flex, Text } from 'rebass';
import { CloseIcon } from 'components/icons/CloseIcon';
import { WhiteBoard } from 'components/Whiteboard/WhiteBoard';
import { FormikProps } from 'formik';
import { FormValues } from 'components/forms/SignatureForm/DisclaimerSignatureForm';

export interface OwnProps {
  terms?: React.ReactNode;
  children?: React.ReactNode;
}

export type Props = FormikProps<FormValues> & OwnProps;

export const SignatureFormFields = ({
  submitForm,
  isValid,
  setFieldValue,
  terms = '',
  children = null,
}: Props) => {
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const setCanvas = useCallback(
    (canvasElement: HTMLCanvasElement) => (canvas.current = canvasElement),
    [canvas]
  );
  const clearCanvas = useCallback(() => {
    const canvasElement = canvas.current;
    if (canvasElement) {
      const ctx = canvasElement.getContext('2d');
      ctx?.clearRect(0, 0, canvasElement.width, canvasElement.height);
      ctx?.beginPath();
    }
  }, [canvas]);
  const submitFields = useCallback(() => {
    const canvasElement = canvas.current;
    if (canvasElement) {
      const signature = canvasElement.toDataURL();
      setFieldValue('signature', signature);
      submitForm();
    } else {
      throw new Error('Canvas not initialized');
    }
  }, [submitForm, canvas, setFieldValue]);

  return (
    <Flex flex={1} width="100%" flexDirection="column">
      <Flex
        flex={1}
        width="100%"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          position: 'relative',
        }}
      >
        <Flex
          width="90%"
          padding={1}
          sx={{
            position: 'absolute',
            borderBottom: '1px black solid',
          }}
        >
          <CloseIcon size={35} />
        </Flex>
        <Flex
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={{
            textAlign: 'center',
            position: 'absolute',
          }}
        >
          <Flex
            flexDirection="column"
            color="grey"
            fontSize="22pt"
            opacity={0.6}
          >
            <Text>Please sign here</Text>
            <Text>(If Customer is Present)</Text>
          </Flex>
        </Flex>

        <WhiteBoard
          getCanvas={setCanvas}
          sx={{
            zIndex: 1,
          }}
        />
      </Flex>
      <Flex alignItems="center" flexDirection="column" color="grey">
        <Text textAlign="center" fontSize="8pt" marginTop={2} marginBottom={2}>
          {terms}
        </Text>
        {children}
        <Flex marginTop={3} width="90%">
          <Flex flex={1}>
            <Button
              variant="secondary"
              width="100%"
              marginRight={2}
              type="button"
              onClick={clearCanvas}
            >
              Clear Signature
            </Button>
          </Flex>
          <Flex flex={1}>
            <Button
              width="100%"
              height="100%"
              marginLeft={2}
              type="button"
              disabled={!isValid}
              onClick={submitFields}
            >
              Done Signing
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
