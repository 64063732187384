import { catchError } from "rxjs/operators";
import { ActionFunction1 } from "redux-actions";

export const handleError = (failActionCreator: ActionFunction1<any, any>) =>
  catchError((error: Error) => {
    console.error(error);
    return [
      failActionCreator({
        message: error?.message,
        name: error?.name,
      }),
    ]
  });
