import { useSelector } from 'react-redux';
import { getIsOfferModelEnabled } from 'selectors/features/getIsOfferModelEnabled';
import { OFFER_NAVBAR, REGULAR_NAVBAR } from 'components/MainNav/constants';

export const useNavItems = () => {
  const isOfferModelEnabled = useSelector(getIsOfferModelEnabled);
  if (isOfferModelEnabled) {
    return OFFER_NAVBAR;
  }
  return REGULAR_NAVBAR;
};
