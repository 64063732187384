import moment from "moment";

const timeFormat = 'HH:mm';

export const getPossibleTimes = ({
  minTime,
  maxTime,
  interval,
  duration,
}: {
  minTime: string,  // 'hh:mm" in 24hr time
  maxTime: string,
  interval: number, // in minutes
  duration: number,
}): string[] => {
  const min = moment(minTime, timeFormat);
  const max = moment(maxTime, timeFormat);

  const result = [];
  let time = min.clone();
  while(
    time
      .clone()
      .add(duration, 'minutes')
      .isSameOrBefore(max) &&
    time
      .clone()
      .add(duration, 'minutes')
      .isSameOrBefore(time.clone().endOf('day'))
  ) {
    result.push(time);
    time = time.clone().add(interval, "minutes");
  }
  return result.map(resultTime => resultTime.format(timeFormat));
};
