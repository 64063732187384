import { DARK_BLUE, GREY, ORANGE, PINK, PURPLE } from 'theme/colors';
import {
  BACKGROUND_GRADIENT,
  COLOR_CODE_DARK_BLUE,
  COLOR_CODE_DISCHARGE_KEY,
  COLOR_CODE_EVALUATION_KEY,
  COLOR_CODE_GREY,
  COLOR_CODE_NONE_KEY,
  COLOR_CODE_ORANGE,
  COLOR_CODE_PATIENT_KEY,
  COLOR_CODE_PINK,
  COLOR_CODE_PURPLE,
  COLOR_CODE_TREATMENT_KEY,
} from 'theme/constants';

export const theme = {
  colors: {
    primary: PINK,
    background: 'white',
    muted: 'rgb(0, 0, 0, 0.1)',
    faded: 'rgb(0, 0, 0, 0.05)',
    [COLOR_CODE_DISCHARGE_KEY]: DARK_BLUE,
    [COLOR_CODE_TREATMENT_KEY]: ORANGE,
    [COLOR_CODE_PATIENT_KEY]: PINK,
    [COLOR_CODE_EVALUATION_KEY]: PURPLE,
    [COLOR_CODE_NONE_KEY]: GREY,
    [COLOR_CODE_DARK_BLUE]: DARK_BLUE,
    [COLOR_CODE_GREY]: GREY,
    [COLOR_CODE_ORANGE]: ORANGE,
    [COLOR_CODE_PINK]: PINK,
    [COLOR_CODE_PURPLE]: PURPLE,
  },
  buttons: {
    primary: {
      background: BACKGROUND_GRADIENT,
      height: '44px',
      '&:disabled': {
        background: 'grey',
      },
    },
    secondary: {
      border: '2px solid',
      borderColor: 'primary',
      color: 'primary',
      backgroundColor: 'white',
      '&:disabled': {
        borderColor: 'grey',
        color: 'grey',
      },
    },
    link: {
      border: 'none',
      color: 'primary',
      backgroundColor: 'white',
      '&:disabled': {
        backgroundColor: 'grey',
        color: 'black',
      },
    },
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'system-ui, sans-serif',
  },
  borders: {
    border: '1px solid',
  },
  radii: {
    borderRadius: '5px',
  },
  text: {
    link: {
      color: 'primary',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    error: {
      color: 'red',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    reschedulePage: {
      primarySectionHeader: {
        backgroundColor: COLOR_CODE_DARK_BLUE,
        color: 'white',
      },
      secondarySectionHeader: {
        backgroundColor: 'muted',
        color: COLOR_CODE_DARK_BLUE,
      },
    },
  },
};
