import {
  DEFAULT_SIGNATURE_TYPE,
  AppFileType,
  SignatureFormConfig,
  SignatureType,
  signatureTypes,
  signatureFormConfigMapping,
} from 'utils/file/constants';

interface Props {
  appFileType: AppFileType;
}

export const getSignatureFormConfigMapping = ({
  appFileType,
}: Props): SignatureFormConfig => {
  if (signatureTypes.includes(appFileType as SignatureType)) {
    const signatureType = appFileType as SignatureType;
    return signatureFormConfigMapping[signatureType];
  }
  return signatureFormConfigMapping[DEFAULT_SIGNATURE_TYPE];
};
