import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Moment } from 'moment';
import { getAppointmentsForDay } from 'selectors/schedule/getAppointmentsForDay';
import { AppointmentListItem } from 'components/AppointmentListItem/AppointmentListItem';
import { Appointment, Case, Patient } from 'types/types';
import { getPatients } from 'selectors/patients/getPatients';
import { getCases } from 'selectors/cases/getCases';

interface OwnProps {
  date: Moment;
}

interface StateProps {
  appointments: Appointment[];
  patients: Record<number, Patient>;
  cases: Record<string, Case>;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  ownProps: OwnProps
): StateProps => ({
  appointments: getAppointmentsForDay(state, {
    day: ownProps.date,
  }),
  patients: getPatients(state),
  cases: getCases(state),
});

const DaysAppointmentsComponent = ({
  appointments,
  patients,
  cases,
}: Props) => (
  <React.Fragment>
    {appointments.map(appointment => (
      <AppointmentListItem
        key={appointment.id}
        appointment={appointment}
        patient={patients[appointment.patientId]}
        therapyCase={cases[appointment.caseId]}
      />
    ))}
  </React.Fragment>
);

export const DaysAppointments = connect(mapStateToProps)(
  DaysAppointmentsComponent
);
