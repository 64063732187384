import { ClaimOfferSuccessPayload } from 'modules/offers/actions';

export const FETCH_OFFERS_URL = 'offers';

export const claimedOfferPropertyWhitelist: Array<keyof ClaimOfferSuccessPayload> = [
  'id',
  'claimedAt',
  'unclaimedAt',
  'acceptedAt',
  'postedAt',
  'createdAt',
];
