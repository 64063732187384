import React, { SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import moment, { Moment } from 'moment';
import { ProviderSessionInfo } from 'components/ProviderSessionInfo/ProviderSessionInfo';
import { FormikProps } from 'formik';
import { FormValues } from './Booking';
import { Separator } from 'components/Separator';
import { getAppointmentsForDay } from 'selectors/schedule/getAppointmentsForDay';
import { Appointment } from 'types/types';
import { timeBlockToString } from './utils/timeBlockToString';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { getValidAppointmentSlots } from 'utils/appointment/timeSlots/getValidAppointmentsSlots';
import { AppointmentSlotPicker } from 'components/AppointmentSlotPicker/AppointmentSlotPicker';

// TODO: FIX THIS, this should be a real value
const DEFAULT_DURATION = 0;

type OwnProps = {
  onBackClick: (e: SyntheticEvent) => void;
  onSubmit: (date: Moment) => void;
} & FormikProps<FormValues>;

interface StateProps {
  appointments: Appointment[];
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  ownProps: OwnProps
): StateProps => ({
  appointments: getAppointmentsForDay(state, {
    day: ownProps?.values?.appointmentDate || moment(),
  }),
});

const mapDispatchToProps: DispatchProps = {};

const BookingTimePickerPageComponent = ({
  appointments,
  onBackClick,
  onSubmit,
  values: { appointmentDate, patient, appointmentServiceType },
}: Props) => {
  const validAppointmentSlots = getValidAppointmentSlots({
    duration: DEFAULT_DURATION,
  }).map(timeBlockToString);

  const [currentAppointmentValue, setAppointmentValue] = useState(
    validAppointmentSlots[0]
  );

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Select Time"
        left={<ArrowBackIcon size={20} onClick={onBackClick} />}
      />
      {patient && (
        <React.Fragment>
          <Flex paddingTop={3} paddingLeft={3} paddingRight={3} width="100%">
            {appointmentServiceType && (
              <ProviderSessionInfo
                patient={patient}
                appointmentServiceType={appointmentServiceType}
              />
            )}
          </Flex>
          <Separator />
        </React.Fragment>
      )}
      <Flex
        flexDirection="column"
        width="100%"
        sx={{
          textAlign: 'center',
        }}
      >
        <Text fontWeight="bold">
          {appointmentServiceType ? appointmentServiceType?.name : 'Unknown'}
        </Text>
        <Text paddingTop={2} color="grey">
          {appointmentDate && appointmentDate.format('dddd, MMM DD')}
        </Text>
      </Flex>
      <Flex flexDirection="column" width="100%" padding={3} flex={1}>
        <AppointmentSlotPicker
          appointments={appointments}
          value={currentAppointmentValue}
          onChange={setAppointmentValue}
          setInitialValue
        />
      </Flex>
      <FooterButton
        onClick={React.useCallback(
          () => onSubmit(moment(currentAppointmentValue, 'hh:mm a')),
          [onSubmit, currentAppointmentValue]
        )}
        label="Select Time"
      />
    </Flex>
  );
};

export const BookingTimePickerPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingTimePickerPageComponent);
