import {GlobalState} from "../../reducers";

export const getId = (state: GlobalState): string => {
  const id = state?.user?.user?.id;

  // Because Ted Steben's ID is actually 1 not 3 in other endpoints
  if (id === '3') {
    return '1';
  }
  return id;
};
