import { combineEpics } from "redux-observable";
import { fetchCurrentPatients$ } from "modules/patients/epics/fetchCurrentPatients$";
import { fetchPatient$ } from "modules/patients/epics/fetchPatient$";
import { updatePatient$ } from "modules/patients/epics/updatePatient$";
import { fetchAllPatients$ } from "modules/patients/epics/fetchAllPatients$";

export default combineEpics(
  fetchCurrentPatients$,
  fetchPatient$,
  updatePatient$,
  fetchAllPatients$,
);
