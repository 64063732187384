import React, { useCallback } from 'react';
import { CompositeImageInput } from '../CompositeImageInput/CompositeImageInput';
import { ModalButton, RenderButtonProps } from '../ModalButton/ModalButton';

interface Props {
  onSubmit: (result: string) => void;
  renderButton: (props: RenderButtonProps) => React.ReactNode;
  accept?: string; // file types accepted by the uploader
}

export const CompositeImageInputModal = ({
  renderButton,
  onSubmit,
  accept,
}: Props) => {
  const renderChildren = useCallback(
    ({ closeModal }) => {
      const submitAndClose = (file: string) => {
        closeModal();
        onSubmit(file);
      };
      return (
        <CompositeImageInput
          onSubmit={submitAndClose}
          onCancel={closeModal}
          accept={accept}
        />
      );
    },
    [onSubmit, accept]
  );

  return (
    <ModalButton renderChildren={renderChildren} renderButton={renderButton} />
  );
};
