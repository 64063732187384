import React, { useCallback, useEffect, useState } from 'react';
import { useGoBack } from 'utils/hooks/useGoBack';
import { Button, Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { CloseIcon } from 'components/icons/CloseIcon';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { useGoToAppointment } from 'utils/hooks/useGoToAppointment';
import { useRouteMatch } from 'react-router-dom';
import { CollapsibleSection } from 'components/CollapsibleSection/CollapsibleSection';
import { SectionHeader, SectionHeaderVariant } from 'components/SectionHeader';
import {
  SmartDatePickerBar,
  RenderProps,
} from 'containers/SmartDatePickerBar/SmartDatePickerBar';
import { useFormikContext, Field } from 'formik';
import { DatePickerBarField } from 'containers/forms/DatePickerBarField';
import { DaysAppointments } from 'containers/DaysAppointments/DaysAppointments';
import { ConnectedDetailedPatientInfo } from 'containers/ConnectedDetailedPatientInfo/ConnectedDetailedPatientInfo';
import { Separator } from 'components/Separator';
import { DateAndTimeFields } from 'pages/appointment/AppointmentReschedulePage/RescheduleTimePage/DateAndTimeFields';
import { fetchAppointmentServiceTypes } from 'modules/appointmentServiceTypes/actions';
import { connect } from 'react-redux';
import { Footer } from 'components/Footer';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { getCancellationPath } from 'routing/routeFunctions';
import { AppointmentReschedulePathParams } from 'routing/routePaths';

interface OwnProps {
  onSubmit: () => void;
}

interface DispatchProps {
  getAppointmentServiceTypes: typeof fetchAppointmentServiceTypes;
}

type Props = OwnProps & DispatchProps;

const mapDispatchToProps: DispatchProps = {
  getAppointmentServiceTypes: fetchAppointmentServiceTypes,
};

const RescheduleTimePageComponent = ({
  onSubmit,
  getAppointmentServiceTypes,
}: Props) => {
  const {
    params: { appointmentId },
  } = useRouteMatch<AppointmentReschedulePathParams>();
  const goBack = useGoBack();
  const goToAppointment = useGoToAppointment({
    appointmentId,
  });
  const {
    values: { date },
  } = useFormikContext<any>();
  const [isAppointmentsOpen, setIsAppointmentsOpen] = useState(true);
  const toggleAppointmentsOpen = useCallback(
    () => setIsAppointmentsOpen(!isAppointmentsOpen),
    [isAppointmentsOpen, setIsAppointmentsOpen]
  );

  useEffect(() => {
    getAppointmentServiceTypes();
  }, [getAppointmentServiceTypes]);

  const DatePicker = useCallback(
    ({ onRangeShift }: RenderProps): React.ReactNode => (
      <Field
        name="date"
        component={DatePickerBarField}
        onRangeShift={onRangeShift}
      />
    ),
    []
  );

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Reschedule Appointment"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
        right={<CloseIcon size={20} onClick={goToAppointment} />}
      />
      <SmartDatePickerBar render={DatePicker} />
      <ScrollBox flexDirection="column" flex={1}>
        <CollapsibleSection
          title="Booked appointment(s)"
          isOpen={isAppointmentsOpen}
          onClick={toggleAppointmentsOpen}
        >
          <DaysAppointments date={date} />
        </CollapsibleSection>
        <SectionHeader variant={SectionHeaderVariant.Primary}>
          Selected Patient for Rescheduling
        </SectionHeader>
        <ConnectedDetailedPatientInfo appointmentId={appointmentId} />
        <Flex flexDirection="column" padding={3} paddingTop={0}>
          <Separator marginTop={0} />
          <DateAndTimeFields appointmentId={appointmentId} date={date} />
        </Flex>
      </ScrollBox>
      <Footer padding={3} flexDirection="column" alignItems="center">
        <Text color="grey" fontSize={12} textAlign="center" marginBottom={3}>
          If you cannot find the date or time to reschedule your appointment
          please “Cancel Appointment”
        </Text>
        <Button width="80%" marginBottom={2} onClick={onSubmit}>
          Reschedule Appointment
        </Button>
        <CustomTextLink
          margin={2}
          textProps={{
            fontWeight: 400,
          }}
          to={getCancellationPath({ appointmentId })}
        >
          Cancel Appointment
        </CustomTextLink>
      </Footer>
    </Flex>
  );
};

export const RescheduleTimePage = connect(
  null,
  mapDispatchToProps
)(RescheduleTimePageComponent);
