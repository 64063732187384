import { ORANGE, PINK, PURPLE } from 'theme/colors';

export const COLOR_CODE_DARK_BLUE = 'customDarkBlue';
export const COLOR_CODE_GREY = 'customGrey';
export const COLOR_CODE_ORANGE = 'customOrange';
export const COLOR_CODE_PINK = 'customPink';
export const COLOR_CODE_PURPLE = 'customPurple';
export const BUTTON_LEFT_COLOR = PURPLE;
export const BUTTON_MIDDLE_COLOR = PINK;
export const BUTTON_RIGHT_COLOR = ORANGE;
export const COLOR_CODE_DISCHARGE_KEY = 'colorCodeDischarge';
export const COLOR_CODE_TREATMENT_KEY = 'colorCodeTreatment';
export const COLOR_CODE_PATIENT_KEY = 'colorCodePatient';
export const COLOR_CODE_EVALUATION_KEY = 'colorCodeEvaluation';
export const COLOR_CODE_NONE_KEY = 'colorCodeNone';
export const BACKGROUND_GRADIENT = `linear-gradient(90deg, ${BUTTON_LEFT_COLOR}, ${BUTTON_MIDDLE_COLOR}, ${BUTTON_RIGHT_COLOR})`;
