import {Moment} from "moment";
import {createSelector} from "reselect";
import {getSchedule} from "selectors/schedule/getSchedule";
import {parseAppointmentDate} from "modules/schedule/utils/parseAppointmentDate";
import {Appointment} from "types/types";
import {GlobalState} from "reducers";

export const getAppointmentsForDay = createSelector(
  getSchedule,
  (_: GlobalState, { day }: { day: Moment }) => day,
  (appointments, day) => {
    const targetYear = day.year();
    const targetMonth = day.month() + 1;
    const targetDate = day.date();

    const result: Appointment[] = [];
    for(let i = 0; i < appointments.length; i++) {
      const appointment = appointments[i];

      const { year, month, day } = parseAppointmentDate({ appointment });

      if(
        year === targetYear &&
        month === targetMonth &&
        day === targetDate
      ) {
        result.push(appointment);
      }
    }

    return result;
  },
);
