import React, { useCallback } from 'react';
import { CaseAppointmentsList } from 'components/CaseAppointmentsList/CaseAppointmentsList';
import { useSelectorWithProps } from 'utils/hooks/useSelectorWithProps';
import { getCase } from 'selectors/cases/getCase';
import { Text } from 'rebass';
import { FutureAppointments } from 'containers/FutureAppointments/FutureAppointments';

interface Props {
  caseId: string;
}

export const FutureCaseAppointments = ({ caseId }: Props) => {
  const therapyCase = useSelectorWithProps(getCase, { caseId: caseId });

  const renderAppointmentsList = useCallback(
    props => {
      if (therapyCase) {
        return <CaseAppointmentsList therapyCase={therapyCase} {...props} />;
      }
      return null;
    },
    [therapyCase]
  );

  if (therapyCase) {
    return (
      <FutureAppointments
        render={renderAppointmentsList}
        caseId={String(therapyCase.id)}
      />
    );
  }

  return <Text alignSelf="center">Case not found</Text>;
};
