import React from 'react';
import { FormikProps } from 'formik';
import { FormValues } from 'pages/addressInput/AddressInputPage';
import { Flex } from 'rebass';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { AddressInputFields } from 'pages/addressInput/AddressInputFields';

type Props = FormikProps<FormValues>;

export const AddressInputForm = ({ handleSubmit, isValid }: Props) => (
  <Flex
    height="100%"
    flexDirection="column"
    as="form"
    // @ts-ignore
    onSubmit={handleSubmit}
  >
    <Flex padding={3}>
      <AddressInputFields />
    </Flex>
    <Flex flex={1} />
    <FooterButton label="Save Address" isSubmit disabled={!isValid} />
  </Flex>
);
