import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

export interface RenderParams {
  files?: FileList;
  handleClick: () => void;
}

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  render: (params: RenderParams) => React.ReactNode;
}

export const FileInputWrapper = ({ render, onChange, ...rest }: Props) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const handleClick = useCallback(() => fileInput.current?.click(), [
    fileInput,
  ]);
  const [currentFiles, setCurrentFiles] = useState<FileList | undefined>();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    const newFiles = fileInput.current?.files;
    if (newFiles) {
      setCurrentFiles(newFiles);
    }
  };

  return (
    <>
      <input
        style={{ display: 'none' }}
        {...rest}
        type="file"
        ref={fileInput}
        onChange={handleChange}
      />
      {render({
        files: currentFiles,
        handleClick,
      })}
    </>
  );
};
