import { createAction } from "redux-actions";
import { FETCH_INSURANCE_FAILURE, FETCH_INSURANCE_START, FETCH_INSURANCE_SUCCESS } from "modules/insurance/actionTypes";
import { Insurance } from "types/types";

interface FetchById {
  insuranceId: string;
  insuranceName?: undefined;
}
interface FetchByName {
  insuranceId?: undefined;
  insuranceName: string;
}
export type FetchInsuranceActionPayload = FetchById | FetchByName;

export const fetchInsurance = createAction<FetchInsuranceActionPayload>(FETCH_INSURANCE_START);

export type FetchInsuranceSuccessActionPayload = Insurance;
export const fetchInsuranceSuccess = createAction<FetchInsuranceActionPayload>(FETCH_INSURANCE_SUCCESS);
export const fetchInsuranceFailure = createAction(FETCH_INSURANCE_FAILURE);
