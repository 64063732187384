import React, { createRef, Fragment, useEffect } from 'react';
import { Appointment, AppointmentService, Case, Patient } from 'types/types';
import _ from 'lodash';
import { Box } from 'rebass';
import { BlockedAppointmentCard } from 'components/AppointmentCard/BlockedAppointmentCard';
import { AppointmentCardLink } from 'components/AppointmentCard/AppointmentCardLink';
import { SmartDateSpacer } from 'pages/schedule/SmartDateSpacer';
import { appointmentToMoment } from 'modules/schedule/utils/appointmentToMoment';
import { dateFormat } from 'appConstants';

interface Props {
  appointments: Appointment[];
  patients: Record<string, Patient>;
  cases: Record<string, Case>;
  currentlySelectedDate?: string;
  initialAppointmentId?: number;
}

export const BucketedAppointmentList = ({
  appointments,
  patients,
  cases,
  currentlySelectedDate,
  initialAppointmentId,
}: Props) => {
  let currentDate: string;
  const appointmentsWithDateSpacers = _.uniq(
    _.flatten(
      appointments.map(appointment => {
        const parsedAppointmentTime = appointmentToMoment(appointment).format(
          dateFormat
        );
        if (currentDate !== parsedAppointmentTime) {
          currentDate = parsedAppointmentTime;
          return [currentDate, appointment];
        }
        return appointment;
      })
    )
  );

  const initialAppointmentRef = createRef<HTMLElement>();
  useEffect(() => {
    if (initialAppointmentRef.current) {
      initialAppointmentRef.current.scrollIntoView(true);
    }
  }, [initialAppointmentRef.current]); // eslint-disable-line

  return (
    <Fragment>
      {appointmentsWithDateSpacers.map(dateOrAppointment => {
        if (!dateOrAppointment) {
          return null;
        }
        if (typeof dateOrAppointment === 'string') {
          return (
            <SmartDateSpacer
              key={dateOrAppointment}
              date={dateOrAppointment}
              currentlySelectedDate={currentlySelectedDate}
            />
          );
        }
        return (
          <Box
            key={dateOrAppointment.id}
            paddingBottom={2}
            marginLeft={2}
            marginRight={2}
            ref={
              initialAppointmentId === dateOrAppointment.id
                ? initialAppointmentRef
                : null
            }
          >
            {dateOrAppointment.service === AppointmentService.Blocked ? (
              <BlockedAppointmentCard appointment={dateOrAppointment} />
            ) : (
              <AppointmentCardLink
                appointment={dateOrAppointment}
                patient={patients[dateOrAppointment.patientId] || {}}
                therapyCase={cases[dateOrAppointment.caseId] || {}}
              />
            )}
          </Box>
        );
      })}
    </Fragment>
  );
};
