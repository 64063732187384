import { useDispatch, useSelector } from "react-redux";
import { useFetchStatusHooks } from "utils/hooks/useFetchStatusHooks";
import { useCallback } from "react";
import { FetchStatus } from "types/types";
import { GlobalState } from "reducers";

export type BaseActionCreator = (...args: any[]) => any;
export type FetchStatusSelector = (state: GlobalState) => FetchStatus;
export interface UseCallExternalProps {
  onFetching?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
}
export interface ActionSpecificProps<ActionCreator extends BaseActionCreator> {
  actionCreator: ActionCreator;
  fetchStatusSelector: FetchStatusSelector;
}
type Props<ActionCreator extends BaseActionCreator> =
  UseCallExternalProps &
  ActionSpecificProps<ActionCreator>

export interface UseCallOutput<ActionCreator extends BaseActionCreator> {
  call: (...p: Parameters<ActionCreator>) => void;
  fetchStatus: FetchStatus;
}

/**
 * useCall - API to easily dispatch actions and follow up on the results of those
 * actions
 * @param actionCreator - the creator of the action you're calling
 * @param fetchStatusSelector - a selector to return the fetchstatus of your call
 * @param onFetching - called if the action is called and starts fetching
 * @param onSuccess - called if the action is called and succeeds
 * @param onFailure - called if the action is called and fails
 * @return call - the action to call, type safe
 * @return fetchStatus - the fetch status of the call, non-FetchStatus.None only
 *   if "call" has been called
 */
export const useCall = <ActionCreator extends BaseActionCreator>({
  actionCreator,
  fetchStatusSelector,
  onFetching,
  onSuccess,
  onFailure,
}: Props<ActionCreator>): UseCallOutput<ActionCreator> => {
  const fetchStatus = useSelector(fetchStatusSelector);
  const { armHook, isArmed } = useFetchStatusHooks({
    fetchStatus,
    onFetching,
    onSuccess,
    onFailure,
  });
  const dispatch = useDispatch();
  const call = useCallback(
    (...p) => {
      dispatch(actionCreator(...p));
      armHook();
    },
    [
      dispatch,
      actionCreator,
      armHook,
    ],
  );
  return {
    call,
    fetchStatus: isArmed ? fetchStatus : FetchStatus.None,
  }
};