import React, { useCallback } from 'react';
import { useDispatchFunction } from 'utils/hooks/useDispatchFunction';
import { login } from 'modules/auth/actions';
import { Button } from 'rebass';

export const DevLoginButton = () => {
  const loginDevAction = useDispatchFunction(login);

  const loginDev = useCallback(() => {
    const {
      REACT_APP_USERNAME: username,
      REACT_APP_PASSWORD: password,
    } = process.env;
    if (!username || !password) {
      throw new Error(
        'Attempting to login as developer but missing ' +
          'REACT_APP_USERNAME or REACT_APP_PASSWORD environment variables'
      );
    }
    loginDevAction({
      username,
      password,
    });
  }, [loginDevAction]);

  return <Button onClick={loginDev}>Developer Login</Button>;
};
