import React from 'react';
import { Proc } from 'types/types';
import { Flex, Text } from 'rebass';

interface Props {
  proc: Proc;
}

const Annotation = ({ children }: { children: React.ReactNode }) => (
  <Text fontSize={12} marginBottom={2}>
    {children}
  </Text>
);

export const ProcInfo = ({ proc: { name, minutes, note } }: Props) => (
  <Flex flexDirection="column">
    <Flex justifyContent="space-between">
      <Annotation>{name}</Annotation>
      <Annotation>{minutes} minutes</Annotation>
    </Flex>
    <Annotation>{note}</Annotation>
  </Flex>
);
