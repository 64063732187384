import { CustomEpic } from 'types/types';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import {
  fetchOfferFailure,
  FetchOfferPayload,
  fetchOfferSuccess,
} from 'modules/offers/actions';
import { FETCH_OFFER_START } from 'modules/offers/actionsTypes';
import { getFetchOfferUrl } from 'modules/offers/utils/getFetchOfferUrl';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { translateDetailedOffer } from 'modules/offers/utils/translateDetailedOffer';
import { handleAjaxError } from 'utils/epics/handleAjaxError';

export const fetchOffer$: CustomEpic<FetchOfferPayload> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(FETCH_OFFER_START),
    switchMap(({ payload: { offerId } }) =>
      getApi$({
        endpoint: getFetchOfferUrl({ offerId }),
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(fetchOfferSuccess, translateDetailedOffer),
        handleAjaxError(fetchOfferFailure)
      )
    )
  );
