import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { GetScheduleSuccessActionPayload } from 'modules/schedule/actions';
import { GET_SCHEDULE_SUCCESS } from 'modules/schedule/actionTypes';
import { Doctor } from 'types/types';

export interface DoctorState {
  normalized: Record<string, Doctor>;
}

export const normalized = handleActions<
  DoctorState['normalized'],
  GetScheduleSuccessActionPayload
>(
  {
    [GET_SCHEDULE_SUCCESS]: (state, action) => ({
      ...state,
      ...action.payload.doctors,
    }),
  },
  {}
);

export default combineReducers<DoctorState>({
  normalized,
});
