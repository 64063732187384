import { Moment } from 'moment';

// Combine separate moments into a single value
interface Props {
  date?: Moment;
  time?: Moment;
}

export const combineTimes = ({ date, time }: Props): Moment | undefined =>
  date &&
  time &&
  date
    .clone()
    .hour(time.hour())
    .minute(time.minute())
    .second(time.second());
