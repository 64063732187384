import {
  END_SESSION,
  END_SESSION_FAILURE,
  END_SESSION_SUCCESS,
} from 'modules/appointments/actionTypes';
import { handleActions } from 'redux-actions';

export const END_EVAL_SESSION_WITH_ERRORS = 'END_EVAL_SESSION_WITH_ERRORS';
export const SKIP_EVAL_SESSION_WITH_ERRORS = 'SKIP_EVAL_SESSION_WITH_ERRORS';
export const CLEAR_EVAL_SESSION_STATE = 'CLEAR_EVAL_SESSION_STATE';
export const START_EVAL_SESSION_WITH_ERRORS = 'START_EVAL_SESSION_WITH_ERRORS';
export const SKIP_START_EVAL_SESSION_WITH_ERRORS =
  'SKIP_START_EVAL_SESSION_WITH_ERRORS';

export type EvalSessionState = {
  isLoadingSession: boolean;
  isStartingSessionWithErrors: boolean;
  isSkippingStartErrors: boolean;
  isEndingSessionWithErrors: boolean;
  isSkippingEndErrors: boolean;
  skipStartNotes: string;
  skipEndNotes: string;
};

export const evalSessionReducer = handleActions<EvalSessionState, any>(
  {
    [START_EVAL_SESSION_WITH_ERRORS]: state => ({
      ...state,
      isStartingSessionWithErrors: true,
    }),
    [END_EVAL_SESSION_WITH_ERRORS]: state => ({
      ...state,
      isEndingSessionWithErrors: true,
    }),
    [SKIP_START_EVAL_SESSION_WITH_ERRORS]: (state, { payload }) => ({
      ...state,
      isSkippingStartErrors: true,
      skipStartNotes: payload.notes,
    }),
    [SKIP_EVAL_SESSION_WITH_ERRORS]: (state, { payload }) => ({
      ...state,
      isSkippingEndErrors: true,
      skipEndNotes: payload.notes,
    }),
    [CLEAR_EVAL_SESSION_STATE]: () => ({
      isLoadingSession: false,
      isStartingSessionWithErrors: false,
      isSkippingStartErrors: false,
      isEndingSessionWithErrors: false,
      isSkippingEndErrors: false,
      skipStartNotes: '',
      skipEndNotes: '',
    }),
    [END_SESSION]: state => ({
      ...state,
      isLoadingSession: true,
    }),
    [END_SESSION_SUCCESS]: state => ({
      ...state,
      isLoadingSession: false,
    }),
    [END_SESSION_FAILURE]: state => ({
      ...state,
      isLoadingSession: false,
    }),
  },
  {
    isLoadingSession: false,
    isStartingSessionWithErrors: false,
    isSkippingStartErrors: false,
    isEndingSessionWithErrors: false,
    isSkippingEndErrors: false,
    skipStartNotes: '',
    skipEndNotes: '',
  }
);

export default evalSessionReducer;

export const getEvalSessionState = (state: any) => state.evalSession;

export const getEvalSessionSkipNotes = (state: any) => ({
  startSkipNotes: state.evalSession.skipStartNotes,
  endSkipNotes: state.evalSession.skipEndNotes,
});
