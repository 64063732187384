import React from 'react';
import { CustomLink, OnClick, To } from 'components/CustomLink/CustomLink';
import {
  FloatingCard,
  Props as FloatingCardProps,
} from 'components/FloatingCard/FloatingCard';

type Props = FloatingCardProps & {
  onClick?: OnClick;
  to?: To;
};

export const FloatingCardLink = ({ to, onClick, children, ...rest }: Props) => {
  return (
    <CustomLink
      to={to}
      onClick={onClick}
      sx={{
        display: 'flex',
      }}
      flexGrow={1}
    >
      <FloatingCard {...rest}>{children}</FloatingCard>
    </CustomLink>
  );
};
