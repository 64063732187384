import { Appointment } from "types/types";
import { formatCurrency } from "utils/formatCurrency";

export const getTotalAppointmentCost = (appointment: Appointment): string => {
  const patientResponsibility = parseFloat(appointment.patientResponsibility) || 0;
  const techFee = parseFloat(appointment.techFee) || 0;
  return formatCurrency({
    amount: patientResponsibility + techFee,
    omitCurrencySymbol: true,
  });
};
