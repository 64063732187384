import { CustomEpic } from 'types/types';
import {
  claimOfferFailure,
  ClaimOfferPayload,
  claimOfferSuccess,
} from 'modules/offers/actions';
import { ofType } from 'redux-observable';
import { CLAIM_OFFER_START } from 'modules/offers/actionsTypes';
import { switchMap } from 'rxjs/operators';
import { getClaimOfferUrl } from 'modules/offers/utils/getClaimOfferUrl';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { translateClaimOffer } from 'modules/offers/utils/translateClaimOffer';
import { getUser } from 'selectors/user/getUser';

export const claimOffer$: CustomEpic<ClaimOfferPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(CLAIM_OFFER_START),
    switchMap(({ payload: { offerId } }) =>
      postApi$({
        endpoint: getClaimOfferUrl({ offerId }),
        headers: getAuthHeaders(state$.value),
        payload: {
          therapist_id: getUser(state$.value).id,
        },
      }).pipe(
        handleAjaxSuccess(claimOfferSuccess, translateClaimOffer),
        handleAjaxError(claimOfferFailure)
      )
    )
  );
