import { Appointment } from "types/types";
import { appointmentMoment } from "utils/appointment/appointmentMoment";

interface Props {
  appointment: Appointment;
}

// to parse the values out of values in dateParamFormat
export const parseAppointmentDate = ({ appointment }: Props) => {
  const parsedAppointment = appointmentMoment(appointment);
  return {
    day: parsedAppointment.date(),
    month: parsedAppointment.month() + 1,
    year: parsedAppointment.year(),
  };
};