import React from 'react';
import { Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';

type Props = {
  children: React.ReactNode;
  innerProps?: FlexProps & CssTypeFix;
} & FlexProps &
  CssTypeFix;

export const FramedCard = ({ children, innerProps = {}, ...rest }: Props) => (
  <Flex width="100%" padding={3} backgroundColor="muted" {...rest}>
    <Flex
      width="100%"
      padding={3}
      backgroundColor="white"
      sx={{
        borderRadius: '10px',
      }}
      {...innerProps}
    >
      {children}
    </Flex>
  </Flex>
);
