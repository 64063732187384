import React, { SyntheticEvent, useCallback } from 'react';
import { Appointment, Case, Patient } from 'types/types';
import { Flex } from 'rebass';
import { MapPinIcon } from 'components/icons/MapPinIcon';
import { getAppointmentCardStyles } from 'components/AppointmentCard/utils/getAppointmentCardStyles';
import { PatientInfo } from 'components/PatientInfo/PatientInfo';
import { AppointmentDurationLocationInfo } from 'components/AppointmentDurationLocationInfo/AppointmentDurationLocationInfo';
import { AppointmentInsuranceAndSessions } from 'components/InsuranceAndSessions/AppointmentInsuranceAndSessions';
import { PatientAddress } from 'components/PatientAddress/PatientAddress';
import { getGoogleMapsDirectionsUrl } from 'utils/getGoogleMapsDirectionsUrl';
import { navigateToUrl } from 'utils/navigateToUrl';
import { CallButton } from 'components/CallButton/CallButton';

interface Props {
  appointment: Appointment;
  patient: Patient;
  therapyCase: Case;
}

export const AppointmentCard = ({
  patient,
  appointment,
  appointment: { service },
  therapyCase,
}: Props) => {
  const navigateToDirections = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      navigateToUrl(
        getGoogleMapsDirectionsUrl({
          lat: patient?.lat,
          lng: patient?.lng,
        })
      );
    },
    [patient]
  );

  const callPatient = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    navigateToUrl(`tel:${4243935420}`);
  }, []);

  return (
    <Flex flexDirection="column" sx={getAppointmentCardStyles(service)}>
      <Flex flexDirection="column" padding={2}>
        <Flex justifyContent="space-between" marginBottom={2}>
          <PatientInfo
            firstName={patient.firstName}
            lastName={patient.lastName}
            service={service}
          />
          <AppointmentDurationLocationInfo appointment={appointment} />
        </Flex>

        <AppointmentInsuranceAndSessions
          patient={patient}
          therapyCase={therapyCase}
          appointment={appointment}
        />
      </Flex>
      <Flex
        sx={{
          borderTop: '1px solid',
          borderColor: 'muted',
        }}
      >
        <Flex
          flex={1}
          paddingTop={2}
          paddingBottom={2}
          onClick={navigateToDirections}
        >
          <MapPinIcon size={40} />
          <PatientAddress patient={patient} />
        </Flex>
        <Flex
          onClick={callPatient}
          padding={2}
          sx={{
            borderLeft: '1px solid',
            borderColor: 'muted',
          }}
        >
          <CallButton number={patient.phone} size={40} />
        </Flex>
      </Flex>
    </Flex>
  );
};
