export const FETCH_OFFERS_START = 'FETCH_OFFERS_START';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';
export const FETCH_OFFER_START = 'FETCH_OFFER_START';
export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const FETCH_OFFER_FAILURE = 'FETCH_OFFER_FAILURE';
export const CLAIM_OFFER_START = 'CLAIM_OFFER_START';
export const CLAIM_OFFER_SUCCESS = 'CLAIM_OFFER_SUCCESS';
export const CLAIM_OFFER_FAILURE = 'CLAIM_OFFER_FAILURE';
export const UNCLAIM_OFFER_START = 'UNCLAIM_OFFER_START';
export const UNCLAIM_OFFER_SUCCESS = 'UNCLAIM_OFFER_SUCCESS';
export const UNCLAIM_OFFER_FAILURE = 'UNCLAIM_OFFER_FAILURE';
