import { combineReducers } from 'redux';
import { Action, handleActions } from 'redux-actions';
import {
  DetailedOffer,
  FetchStatus,
  ListOffer,
  OfferStatus,
} from 'types/types';
import {
  CLAIM_OFFER_FAILURE,
  CLAIM_OFFER_START,
  CLAIM_OFFER_SUCCESS,
  FETCH_OFFER_FAILURE,
  FETCH_OFFER_START,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFERS_FAILURE,
  FETCH_OFFERS_START,
  FETCH_OFFERS_SUCCESS,
  UNCLAIM_OFFER_FAILURE,
  UNCLAIM_OFFER_START,
  UNCLAIM_OFFER_SUCCESS,
} from 'modules/offers/actionsTypes';
import {
  ClaimOfferSuccessPayload,
  FetchOffersSuccessPayload,
  FetchOfferSuccessPayload,
} from 'modules/offers/actions';
import _ from 'lodash';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';

export type OfferMap = Record<string, ListOffer>;
export interface OffersState {
  normalized: OfferMap;
  fetchStatus: FetchStatus;
  offerPage: DetailedOffer | null;
  claimFetchStatus: FetchStatus;
}

const initialNormalizedState: OfferMap = {};

const normalized = handleActions<OfferMap, any>(
  {
    [FETCH_OFFERS_SUCCESS]: (
      state,
      action: Action<FetchOffersSuccessPayload>
    ) => ({
      ...state,
      ..._.keyBy(action.payload, 'id'),
    }),
    [CLAIM_OFFER_SUCCESS]: (
      state,
      action: Action<ClaimOfferSuccessPayload>
    ) => {
      const existingObject = state[action.payload.id];
      return {
        ...state,
        [action.payload.id]: {
          ...(existingObject || {}),
          status: OfferStatus.Claimed,
          ...action.payload,
        },
      };
    },
    [UNCLAIM_OFFER_SUCCESS]: (
      state,
      action: Action<ClaimOfferSuccessPayload>
    ) => {
      const existingObject = state[action.payload.id];
      return {
        ...state,
        [action.payload.id]: {
          ...(existingObject || {}),
          status: OfferStatus.Unclaimed,
          ...action.payload,
        },
      };
    },
  },
  initialNormalizedState
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [FETCH_OFFERS_START, FETCH_OFFER_START],
  successActions: [FETCH_OFFERS_SUCCESS, FETCH_OFFER_SUCCESS],
  failureActions: [FETCH_OFFERS_FAILURE, FETCH_OFFER_FAILURE],
});

const claimFetchStatus = createFetchStatusReducer({
  fetchingActions: [CLAIM_OFFER_START, UNCLAIM_OFFER_START],
  successActions: [CLAIM_OFFER_SUCCESS, UNCLAIM_OFFER_SUCCESS],
  failureActions: [CLAIM_OFFER_FAILURE, UNCLAIM_OFFER_FAILURE],
});

const initialOfferPageState = null;
const offerPage = handleActions<DetailedOffer | null, any>(
  {
    [FETCH_OFFER_SUCCESS]: (state, action: Action<FetchOfferSuccessPayload>) =>
      action.payload,
    [CLAIM_OFFER_SUCCESS]: (
      state,
      action: Action<ClaimOfferSuccessPayload>
    ) => {
      if (action.payload.id === state?.id) {
        return {
          ...state,
          status: OfferStatus.Claimed,
          ...action.payload,
        };
      }
      return state;
    },
    [UNCLAIM_OFFER_SUCCESS]: (
      state,
      action: Action<ClaimOfferSuccessPayload>
    ) => {
      if (action.payload.id === state?.id) {
        return {
          ...state,
          status: OfferStatus.Unclaimed,
          ...action.payload,
        };
      }
      return state;
    },
  },
  initialOfferPageState
);

export default combineReducers<OffersState>({
  normalized,
  fetchStatus,
  claimFetchStatus,
  offerPage,
});
