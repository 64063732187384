import { CustomEpic } from 'types/types';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import toast from 'react-hot-toast';
import {
  fetchAppFiles,
  requestFormCancel,
  requestFormFailure,
  requestFormSuccess,
} from '../actions';
import { REQUEST_FORM } from '../actionTypes';
import { getAuthHeaders } from 'api/getAuthHeaders';
import HelloSign from 'hellosign-embedded';
import { currentConnectionStatus } from 'utils/connectionStatus';

const formTypeToMissingDoc: Record<string, string> = {
  intake: 'Intake Form',
  abn: 'ABN Form',
  lien: 'Lien Form',
};

export const requestForm$: CustomEpic<{
  client: HelloSign;
  caseId: string;
  leadId: string;
  form_type: string;
  embed: boolean;
}> = (action$, state$, { postApi$ }) =>
  action$.pipe(
    ofType(REQUEST_FORM),
    switchMap(({ payload: { caseId, leadId, ...rest } }) => {
      const toastId = toast.loading('Requesting form');
      return postApi$({
        endpoint: `/cases/${caseId}/send_form`,
        headers: getAuthHeaders(state$.value),
        payload: { ...rest, resend: true },
      }).pipe(
        switchMap((result: any) => {
          toast.dismiss(toastId);
          const promise = new Promise<void>((resolve: any, reject) => {
            const helloSign = new HelloSign();

            const resolvePromise = (promiseResolution: () => void) => () => {
              helloSign.off('close');
              helloSign.off('cancel');
              helloSign.off('finish');
              helloSign.off('sign');
              helloSign.off('error');
              promiseResolution();
            };

            const clear = resolvePromise(() => resolve(requestFormCancel()));
            const success = resolvePromise(() =>
              resolve(
                requestFormSuccess({
                  leadId,
                  missingDoc: formTypeToMissingDoc[rest.form_type],
                })
              )
            );
            const failure = resolvePromise(() => reject(requestFormFailure()));

            helloSign.on('close', clear);
            helloSign.on('cancel', clear);
            helloSign.on('finish', clear);
            helloSign.on('sign', (...args) => {
              console.log('sign', ...args);
              success();
            });
            helloSign.on('error', failure);

            helloSign.open(result.data.sign_url, {
              clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
              skipDomainVerification: true,
            });
          });

          return from(promise);
        }),
        switchMap(() => of(fetchAppFiles({ caseId }))),
        catchError(err => {
          console.log(err);
          toastId && toast.dismiss(toastId);
          if (currentConnectionStatus === 'offline') {
            toast.success(
              'Form request will be sent when connection is back.',
              {
                icon: '✖️',
              }
            );
          }
          if (
            err.code !== 'ERR_CANCELED' &&
            err.response &&
            err?.response?.status !== 401
          ) {
            toast.error('Something happened requesting form');
          }
          return [requestFormFailure()];
        })
      );
    })
  );
