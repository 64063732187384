import { combineReducers } from 'redux';
import { Action, handleActions } from 'redux-actions';
import { GetScheduleSuccessActionPayload } from 'modules/schedule/actions';
import { GET_SCHEDULE_SUCCESS } from 'modules/schedule/actionTypes';
import { FetchStatus, Insurance } from 'types/types';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';
import {
  FETCH_INSURANCE_FAILURE,
  FETCH_INSURANCE_START,
  FETCH_INSURANCE_SUCCESS,
} from 'modules/insurance/actionTypes';
import { FetchInsuranceSuccessActionPayload } from 'modules/insurance/actions';

export interface InsuranceState {
  normalized: Record<string, Insurance>;
  fetchStatus: FetchStatus;
}

export const normalized = handleActions<InsuranceState['normalized'], any>(
  {
    [GET_SCHEDULE_SUCCESS]: (
      state,
      action: Action<GetScheduleSuccessActionPayload>
    ) => ({
      ...state,
      ...action.payload.insurances,
    }),
    [FETCH_INSURANCE_SUCCESS]: (
      state,
      action: Action<FetchInsuranceSuccessActionPayload>
    ) => ({
      ...state,
      [action.payload.id]: action.payload,
    }),
  },
  {}
);

export const fetchStatus = createFetchStatusReducer({
  fetchingActions: [FETCH_INSURANCE_START],
  successActions: [FETCH_INSURANCE_SUCCESS],
  failureActions: [FETCH_INSURANCE_FAILURE],
});

export default combineReducers<InsuranceState>({
  normalized,
  fetchStatus,
});
