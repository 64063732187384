import React from 'react';
import moment, { Moment } from 'moment';
import { Box, Flex } from 'rebass';

interface Props {
  date: Moment;
}

export const SmartDate = ({ date }: Props) => {
  const isToday = moment()
    .startOf('day')
    .isSame(date.startOf('day'));
  return (
    <Flex>
      {isToday && (
        <Box
          marginRight={1}
          sx={{
            fontWeight: 'bold',
          }}
        >
          Today -
        </Box>
      )}
      {date.format('dddd, MMM. DD')}
    </Flex>
  );
};
