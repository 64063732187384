import React, { ReactChildren } from 'react';
import { connect } from 'react-redux';

import { Route, Redirect } from 'react-router-dom';
import { RouteProps } from 'react-router';

import { GlobalState } from 'reducers';
import { LOGIN_PATH } from './routePaths';
import { getIsAuthenticated } from 'selectors/auth/getIsAuthenticated';
import { getBasicToken } from 'selectors/auth/getBasicToken';

interface StateProps {
  isAuthenticated: boolean;
  basicToken: string;
}

interface OwnProps {
  children: ReactChildren;
  [key: string]: any;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (state: GlobalState) => ({
  isAuthenticated: getIsAuthenticated(state),
  basicToken: getBasicToken(state),
});

export const PrivateRouteComponent = ({
  isAuthenticated,
  basicToken,
  dispatch,
  children,
  ...rest
}: Props) => {
  const renderRoute = ({ location }: RouteProps) =>
    isAuthenticated ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: LOGIN_PATH,
          state: { from: location },
        }}
      />
    );
  return <Route {...rest} render={renderRoute} />;
};

export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);
