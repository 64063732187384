import React from 'react';
import { SectionHeader, SectionHeaderVariant } from 'components/SectionHeader';
import { Box, Flex } from 'rebass';
import { ArrowDownIcon } from 'components/icons/ArrowDownIcon';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';

interface Props {
  children: React.ReactNode;
  title?: React.ReactNode;
  onClick?: () => void;
  isOpen?: boolean;
}

export const CollapsibleSection = ({
  title = '',
  onClick = () => {},
  isOpen = true,
  children,
}: Props) => {
  return (
    <Flex flexDirection="column">
      <Box onClick={onClick}>
        <SectionHeader variant={SectionHeaderVariant.Secondary}>
          <Flex justifyContent="space-between" width="100%">
            <Box>{title}</Box>
            {isOpen ? <ArrowDownIcon /> : <ArrowBackIcon />}
          </Flex>
        </SectionHeader>
      </Box>
      {isOpen && children}
    </Flex>
  );
};
