import React from 'react';
import { Box, BoxProps } from 'rebass';
import { CssTypeFix } from 'types/types';

export type Props = BoxProps &
  CssTypeFix & {
    children: React.ReactNode;
    color: string;
  };

export const Label = ({ children, color, ...restProps }: Props) => {
  return (
    <Box
      backgroundColor={color}
      paddingLeft={2}
      paddingRight={2}
      paddingTop={1}
      paddingBottom={1}
      sx={{
        borderRadius: 10,
      }}
      {...restProps}
    >
      {children}
    </Box>
  );
};
