import React from 'react';
import { FullTimeTherapistStatsCard } from 'containers/TherapistStatsCard/FullTimeTherapistStatsCard';
import { PartTimeTherapistStatsCard } from 'containers/TherapistStatsCard/PartTimeTherapistStatsCard';

export const TherapistStatsCard = () => (
  <>
    <FullTimeTherapistStatsCard />
    <PartTimeTherapistStatsCard />
  </>
);
