import { post$ } from './post';

export interface PostApiParams {
  endpoint: string;
  payload?: Record<string, any>;
  headers?: Record<string, any>;
}

export const postApi$ = ({ endpoint, payload, headers }: PostApiParams) =>
  post$({
    endpoint,
    payload,
    headers,
  });
