import {AppointmentServiceType} from "types/types";
import {createAction} from "redux-actions";
import {
  FETCH_APPOINTMENT_SERVICE_TYPES,
  FETCH_APPOINTMENT_SERVICE_TYPES_FAILURE,
  FETCH_APPOINTMENT_SERVICE_TYPES_SUCCESS
} from "./actionTypes";

export const fetchAppointmentServiceTypes = createAction(
  FETCH_APPOINTMENT_SERVICE_TYPES,
);

export type GetAppointmentServiceTypesSuccessActionPayload = Record<string, AppointmentServiceType>;

export const fetchAppointmentServiceTypesSuccess = createAction<GetAppointmentServiceTypesSuccessActionPayload>(
  FETCH_APPOINTMENT_SERVICE_TYPES_SUCCESS,
);

export const fetchAppointmentServiceTypesFailure = createAction(FETCH_APPOINTMENT_SERVICE_TYPES_FAILURE);