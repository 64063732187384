import React from 'react';
import { Box, Flex } from 'rebass';
import { AppointmentServiceType, Patient } from 'types/types';

interface Props {
  patient: Patient;
  appointmentServiceType: AppointmentServiceType;
}

export const ProviderSessionInfo = ({
  appointmentServiceType,
  patient,
}: Props) => (
  <Flex justifyContent="space-between" width="100%">
    <Box
      sx={{
        fontWeight: 'bold',
      }}
    >
      {patient?.insurance}
    </Box>
    <Box>
      Session allow <br />
      {appointmentServiceType.duration} mins
    </Box>
  </Flex>
);
