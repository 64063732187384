import { Appointment } from "types/types";
import moment, { Moment } from "moment";
import { appointmentDateTimeFormat } from "appConstants";

export const appointmentMoment = (appointment: Appointment): Moment => {
  const { appDatetime } = appointment;
  if (!appDatetime) {
    throw new Error(`Appointment datetime missing for id: ${appointment.id}`);
  }
  return moment(appDatetime, appointmentDateTimeFormat);
};
