import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import { Radio as CheckboxComponent } from '@rebass/forms';

interface Props<V> {
  field: FieldProps<V>;
  checkedValue: any;
  disabled?: boolean;
}

// @ts-ignore
export const Radio = ({ checkedValue, field, disabled = false }: Props) => {
  const { value: currentValue, ...fieldProps } = field;
  // set current field value to this component's value
  const onChange = useCallback(() => field.onChange(field.name)(checkedValue), [
    field,
    checkedValue,
  ]);
  return (
    <CheckboxComponent
      {...fieldProps}
      checked={checkedValue === currentValue}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
