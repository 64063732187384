import { useHistory } from "react-router-dom";
import { useCallback } from "react";

export const useGoBack = () => {
  const history = useHistory();
  return useCallback(
    () => history.goBack(),
    [history],
  );
};
