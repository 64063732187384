import { AppointmentStatusName, CustomEpic } from 'types/types';
import { UpdateAppointmentActionPayload } from 'modules/appointments/actions';
import { ofType } from 'redux-observable';
import { UPDATE_APPOINTMENT } from 'modules/appointments/actionTypes';
import { switchMap } from 'rxjs/operators';
import { iif } from 'rxjs';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { getAppointment } from '../../../selectors/appointments/getAppointment';
import { EVENT_HOOK_URL } from '../constants';
import { eventHookFailure, eventHookSuccess } from '../actions';
import { convertSnakeToCamelCase } from '../../../utils/convertSnakeToCamelCase';
import { getIsEvalAppointment } from '../../appointments/utils/getIsEvalAppointment';

export const finishEvalAppointment$: CustomEpic<UpdateAppointmentActionPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(UPDATE_APPOINTMENT),
    switchMap(({ payload: { id, ...rest } }) => {
      const appointment = getAppointment(state$.value, {
        appointmentId: String(id),
      });
      return iif(
        () => {
          return Boolean(
            appointment &&
              rest.appStatus === AppointmentStatusName.Finished &&
              getIsEvalAppointment(appointment)
          );
        },
        postApi$({
          endpoint: EVENT_HOOK_URL,
          headers: getAuthHeaders(state$.value),
          payload: {
            event_type: 'eval_completed',
            case_id: appointment?.caseId,
            // For testing:
            // to_email: 'your email here',
          },
        }).pipe(
          handleAjaxSuccess(
            () =>
              eventHookSuccess({
                eventType: 'eval_completed',
                caseId: Number(appointment?.caseId),
              }),
            convertSnakeToCamelCase
          ),
          handleAjaxError(eventHookFailure)
        )
      );
    })
  );
