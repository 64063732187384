import React, { useCallback } from 'react';
import { FormikProps } from "formik";
import { Route, Switch, useRouteMatch, useHistory } from "react-router-dom";
import { BookingFormPage } from "pages/booking/BookingFormPage";
import { BookingTimePickerPage } from "pages/booking/BookingTimePickerPage";
import { BookingDatePickerPage } from "pages/booking/BookingDatePickerPage";
import { FormValues } from "pages/booking/Booking";
import { useGoBack } from "utils/hooks/useGoBack";
import { datePickerRoute, timePickerRoute } from "pages/booking/constants";

type Props = FormikProps<FormValues>;

export const BookingRouter = (formProps: Props) => {
  const {
    setFieldValue,
  } = formProps;

  const goBack = useGoBack();
  const { push } = useHistory();
  const { path } = useRouteMatch();

  const timePickerUrl = `${path}/${timePickerRoute}`;
  const datePickerUrl = `${path}/${datePickerRoute}`;

  const goToDatePicker = useCallback(
    () => push(datePickerUrl),
    [push, datePickerUrl],
  );

  const submitTime = useCallback(
    (time) => {
      setFieldValue('appointmentTime', time);
      push(path);
    },
    [setFieldValue, push, path],
  );

  const submitDate = useCallback(
    date => {
      setFieldValue('appointmentDate', date);
      push(timePickerUrl);
    },
    [setFieldValue, push, timePickerUrl],
  );

  return (
    <Switch>
      <Route
        exact
        path={timePickerUrl}
      >
        <BookingTimePickerPage
          {...formProps}
          onBackClick={goBack}
          onSubmit={submitTime}
        />
      </Route>
      <Route
        exact
        path={datePickerUrl}
      >
        <BookingDatePickerPage
          onBackClick={goBack}
          onSelectDate={submitDate}
        />
      </Route>
      <Route path={path}>
        <BookingFormPage
          {...formProps}
          onDatePickerClick={goToDatePicker}
        />
      </Route>
    </Switch>
  );
};
