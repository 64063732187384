// More generic type
import React from 'react';
import { SignatureFormType } from 'components/forms/SignatureForm/constants';
import _ from 'lodash';

export interface SimpleFile {
  id: string;
  size: number;
  name: string;
  path: string;
}

export enum AppFileType {
  InsuranceFront = 'InsuranceFront',
  InsuranceBack = 'InsuranceBack',
  Insurance2Front = 'Insurance2Front',
  Insurance2Back = 'Insurance2Back',
  Rx = 'Rx',
  Payment = 'Payment',
  FormSignature = 'FormSignature',
  Initials = 'Initials',
  AbnForm = 'AbnForm',
  LienForm = 'LienForm',
  IntakeForm = 'Intake',
  Other = 'Other',
}

export const signatureTypes = [
  AppFileType.FormSignature,
  AppFileType.Initials,
] as const;
export type SignatureType = typeof signatureTypes[number];
export const DEFAULT_SIGNATURE_TYPE: SignatureType = AppFileType.Initials;

export interface SignatureFormConfig {
  signatureFormType: SignatureFormType;
  terms?: string;
}

const FORM_SIGNATURE_TERMS =
  'You are indicating that you have read and you understand and agree to the terms set forth.  By your signature, you further agree to final and binding arbitration for any and all claims, disputes, or controversies arising between you and Kulagy Physical Therapy, PC whether contractual, statuatory or common law.';

export const signatureFormConfigMapping: Record<
  SignatureType,
  SignatureFormConfig
> = {
  [AppFileType.FormSignature]: {
    signatureFormType: SignatureFormType.Disclaimer,
    terms: FORM_SIGNATURE_TERMS,
  },
  [AppFileType.Initials]: {
    signatureFormType: SignatureFormType.Default,
  },
};

// These do not include file extensions
export const fileNameMapping: Record<AppFileType, string> = {
  [AppFileType.InsuranceFront]: 'insuranceFront',
  [AppFileType.InsuranceBack]: 'insuranceBack',
  [AppFileType.Insurance2Front]: 'insurance2Front',
  [AppFileType.Insurance2Back]: 'insurance2Back',
  [AppFileType.Rx]: 'rx',
  [AppFileType.Payment]: 'payment',
  [AppFileType.FormSignature]: 'signature',
  [AppFileType.IntakeForm]: 'intake',
  [AppFileType.Initials]: 'formInitials',
  [AppFileType.AbnForm]: 'AbnForm',
  [AppFileType.LienForm]: 'LienForm',
  [AppFileType.Other]: 'Other',
};

export const fileTypeMapping = _.invert(fileNameMapping) as Record<
  typeof fileNameMapping[AppFileType],
  AppFileType
>;

export const acceptedFileTypesEnvVar: Record<
  AppFileType,
  string | undefined
> = {
  [AppFileType.InsuranceFront]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.InsuranceBack]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.Insurance2Front]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.Insurance2Back]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.Rx]: 'REACT_APP_ACCEPTED_RX_FILE_TYPES',
  [AppFileType.Payment]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.AbnForm]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.LienForm]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.Other]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.IntakeForm]: 'REACT_APP_ACCEPTED_INTAKE_FILE_TYPES',
  // Unused since these are not user imported images, will update types later
  [AppFileType.FormSignature]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
  [AppFileType.Initials]: 'REACT_APP_ACCEPTED_INSURANCE_FILE_TYPES',
};

export enum AppFileInputType {
  Upload = 'Upload', // regular image upload
  FormSignature = 'FormSignature',
  Composite = 'Composite', // two images combined together
  Form = 'Form', // HelloSign request to backend
  AbnForm = 'AbnForm', // HelloSign request to backend with previous modal
}

export interface UploaderRenderParams {
  files?: FileList;
  handleClick: () => void;
}

export type UploaderRenderer = (
  params: UploaderRenderParams
) => React.ReactNode;
