import React from 'react';
import { OfferIcon } from 'components/icons/OfferIcon';
import { Flex, Text } from 'rebass';
import { FloatingCardLink } from 'components/FloatingCard/FloatingCardLink';
import { OFFERS_PATH } from 'routing/routePaths';

export const OffersCard = () => {
  return (
    <FloatingCardLink to={OFFERS_PATH}>
      <OfferIcon />
      <Flex flexDirection="column" marginLeft={3}>
        <Text fontWeight="bold">Offers</Text>
        <Text marginTop={1}>New patient updates??</Text>
      </Flex>
    </FloatingCardLink>
  );
};
