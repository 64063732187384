import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Flex } from 'rebass';
import { getSchedule as getScheduleAction } from 'modules/schedule/actions';
import { getSchedule } from 'selectors/schedule/getSchedule';
import { Appointment, Case, Patient } from 'types/types';
import { HeaderBar } from 'components/HeaderBar';
import { InfoIcon } from 'components/icons/InfoIcon';
import { FooterBar } from 'components/FooterBar';
import { Moment } from 'moment';
import { getPatients } from 'selectors/patients/getPatients';
import { getCases } from 'selectors/cases/getCases';
import { dateFormat } from 'appConstants';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { SmartAppointmentList } from 'pages/schedule/SmartAppointmentList';
import { SmartDatePickerBar } from 'containers/SmartDatePickerBar/SmartDatePickerBar';
import {
  DatePickerBar,
  Props as DatePickerBarProps,
} from 'components/DatePickerBar/DatePickerBar';

interface StateProps {
  appointments: Appointment[];
  patients: Record<string, Patient>;
  cases: Record<string, Case>;
}

interface DispatchProps {
  getSchedule: typeof getScheduleAction;
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: GlobalState): StateProps => ({
  appointments: getSchedule(state),
  patients: getPatients(state),
  cases: getCases(state),
});

const mapDispatchToProps = {
  getSchedule: getScheduleAction,
};

const ScheduleComponent = ({
  getSchedule,
  appointments,
  patients,
  cases,
}: Props) => {
  const [currentlySelectedDate, setCurrentlySelectedDate] = useState<string>();
  const formatAndSelectDate = useCallback(
    (date: Moment) => setCurrentlySelectedDate(date.format(dateFormat)),
    [setCurrentlySelectedDate]
  );

  const DatePicker = useCallback(
    ({ onRangeShift }: DatePickerBarProps) => (
      <DatePickerBar
        onRangeShift={onRangeShift}
        onDateClick={formatAndSelectDate}
      />
    ),
    [formatAndSelectDate]
  );

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar title="My Schedule" right={<InfoIcon size="20px" />} />
      <Flex
        width="100%"
        sx={{
          borderBottom: 'border',
          borderColor: 'muted',
        }}
      >
        <SmartDatePickerBar render={DatePicker} />
      </Flex>
      <ScrollBox flexDirection="column" flex={1}>
        <SmartAppointmentList
          appointments={appointments}
          patients={patients}
          cases={cases}
          currentlySelectedDate={currentlySelectedDate}
        />
      </ScrollBox>
      <FooterBar />
    </Flex>
  );
};

export const Schedule = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleComponent);
