import React, { useEffect, useReducer } from 'react';
import { Flex } from 'rebass';
import { SxStyleProp } from 'theme-ui';
import { clearTimeout, setTimeout } from 'timers';

type Props = {
  render: (args: {
    createHideHandler: (handler?: () => void) => () => void;
  }) => React.ReactNode;
  onClickOverlay?: () => void;
  sx?: SxStyleProp;
};

type AnimatedState = {
  overlayColor?: string;
  contentTransform?: string;
};

type AnimatedAction = {
  type: string;
};

export const RisePopup = ({ render, sx, onClickOverlay }: Props) => {
  const [animatedValues, dispatch] = useReducer(
    (state: AnimatedState, action: AnimatedAction) => {
      switch (action.type) {
        case 'mounted':
          return {
            ...state,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            contentTransform: 'translateY(0)',
          };
        case 'unmounted':
          return {
            ...state,
            overlayColor: 'rgba(0, 0, 0, 0)',
            contentTransform: 'translateY(100%)',
          };
        case 'animated':
          return {
            ...state,
            contentTransform: undefined,
          };
        default:
          return state;
      }
    },
    {
      overlayColor: 'rgba(0, 0, 0, 0)',
      contentTransform: 'translateY(100%)',
    }
  );

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];
    timeouts.push(
      setTimeout(() => {
        dispatch({ type: 'mounted' });
        setTimeout(() => {
          dispatch({ type: 'animated' });
        }, 180);
      }, 60)
    );
    return () => {
      timeouts.forEach(t => clearTimeout(t));
    };
  }, []);

  const createHideHandler = (handler?: () => void) => {
    return () => {
      // If not mounted and unmounted won't animate
      dispatch({ type: 'mounted' });
      dispatch({ type: 'unmounted' });
      if (handler) {
        setTimeout(handler, 180);
      }
    };
  };

  return (
    <Flex
      sx={{
        alignItems: 'flex-end',
        backgroundColor: animatedValues.overlayColor,
        height: 'calc(100vh - 100%)',
        position: 'absolute',
        bottom: '100%',
        width: '100%',
        transition: 'background-color 180ms ease',
        flexDirection: 'column-reverse',
        ...sx,
      }}
      onClick={e => {
        if (e.target !== e.currentTarget) return;
        createHideHandler(onClickOverlay)();
      }}
    >
      <Flex
        flexDirection="column"
        backgroundColor="white"
        padding={4}
        paddingBottom={5}
        sx={{
          width: '100%',
          maxHeight: 'calc(100% - 180px)',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          transform: animatedValues.contentTransform,
          transition: 'transform 180ms ease',
        }}
      >
        {render({ createHideHandler })}
      </Flex>
    </Flex>
  );
};
