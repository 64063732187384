import { CustomEpic } from 'types/types';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { FETCH_CASE_APP_TYPES } from '../actionTypes';
import { fetchCaseAppTypesFailure, fetchCaseAppTypesSuccess } from '../actions';
import { translateFetchCaseAppointmentServiceTypes } from '../utils/translateFetchAppointmentServiceTypes';

export const fetchCaseAppTypes$: CustomEpic<number> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(FETCH_CASE_APP_TYPES),
    switchMap(action =>
      getApi$({
        endpoint: `cases/${action.payload}/apptypes`,
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(
          fetchCaseAppTypesSuccess,
          translateFetchCaseAppointmentServiceTypes(action.payload)
        ),
        handleAjaxError(fetchCaseAppTypesFailure)
      )
    )
  );
