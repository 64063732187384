
export const FETCH_CURRENT_PATIENTS_START = 'FETCH_CURRENT_PATIENTS_START';
export const FETCH_CURRENT_PATIENTS_SUCCESS = 'FETCH_CURRENT_PATIENTS_SUCCESS';
export const FETCH_CURRENT_PATIENTS_FAILURE = 'FETCH_CURRENT_PATIENTS_FAILURE';

export const FETCH_PATIENT_START = 'FETCH_PATIENT_START';
export const FETCH_PATIENT_SUCCESS = 'FETCH_PATIENT_SUCCESS';
export const FETCH_PATIENT_FAILURE = 'FETCH_PATIENT_FAILURE';

export const UPDATE_PATIENT_START = 'UPDATE_PATIENT_START';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_FAILURE = 'UPDATE_PATIENT_FAILURE';

export const FETCH_ALL_PATIENTS_START = 'FETCH_ALL_PATIENTS_START';
export const FETCH_ALL_PATIENTS_SUCCESS = 'FETCH_ALL_PATIENTS_SUCCESS';
export const FETCH_ALL_PATIENTS_FAILURE = 'FETCH_ALL_PATIENTS_FAILURE';
