import React from 'react';
import { Appointment } from 'types/types';
import { Flex } from 'rebass';
import { getAppointmentCardStyles } from 'components/AppointmentCard/utils/getAppointmentCardStyles';
import { formatAppointmentTime } from 'utils/appointment/formatAppointmentTime';

interface Props {
  appointment: Appointment;
}

export const BlockedAppointmentCard = ({ appointment }: Props) => {
  const { service } = appointment;
  return (
    <Flex flexDirection="column" sx={getAppointmentCardStyles(service)}>
      <Flex flexDirection="column" padding={2}>
        <Flex justifyContent="flex-end">
          <Flex minWidth={105} fontSize="12pt">
            {formatAppointmentTime({
              appointment,
            })}
          </Flex>
        </Flex>
        <Flex justifyContent="center" marginTop={1} marginBottom={3}>
          {service}
        </Flex>
      </Flex>
    </Flex>
  );
};
