import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import {
  DatePickerBar,
  Props as DatePickerBarProps,
} from 'components/DatePickerBar/DatePickerBar';
import { Moment } from 'moment';

export type Props = FieldProps & DatePickerBarProps;

export const DatePickerBarField = ({
  onRangeShift,
  field: { name, value },
  form: { setFieldValue },
}: Props) => {
  const onDateClick = useCallback((date: Moment) => setFieldValue(name, date), [
    name,
    setFieldValue,
  ]);

  return (
    <DatePickerBar
      onDateClick={onDateClick}
      onRangeShift={onRangeShift}
      selectedDate={value}
    />
  );
};
