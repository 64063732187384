import { createAction } from 'redux-actions';
import {
  FETCH_APP_FILES_FAILURE,
  FETCH_APP_FILES_START,
  FETCH_APP_FILES_SUCCESS,
  FETCH_APP_FILE_FAILURE,
  FETCH_APP_FILE_START,
  FETCH_APP_FILE_SUCCESS,
  UPLOAD_APP_FILE_FAILURE,
  UPLOAD_APP_FILE_START,
  UPLOAD_APP_FILE_SUCCESS,
  REQUEST_FORM_SUCCESS,
  REQUEST_FORM_FAILURE,
  REQUEST_FORM_CANCEL,
} from './actionTypes';
import { AppFile } from '../../types/types';
import { getFileExtension } from '../../utils/file/getFileExtension';
import { AppFileType } from 'utils/file/constants';

// Upload file

export interface UploadAppFileActionPayload {
  caseId: string;
  leadId?: string;
  filename: string;
  file: File;
  upload_type: AppFileType;
}
export const uploadAppFile = createAction(
  UPLOAD_APP_FILE_START,
  (payload: UploadAppFileActionPayload) => {
    const fileExtension = getFileExtension(payload.file.name);
    payload.filename = `${payload.filename}.${fileExtension}`;
    return payload;
  }
);
export interface UploadAppFileSuccessPayload {
  result: 'success' | 'fail';
  fileId: string;
  error?: string;
  filename?: string;
  caseId?: string;
  leadId?: string;
  uploadType?: AppFileType;
}
export const uploadAppFileSuccess = createAction<UploadAppFileSuccessPayload>(
  UPLOAD_APP_FILE_SUCCESS
);
export const uploadAppFileFailure = createAction(UPLOAD_APP_FILE_FAILURE);

// Fetch file

export interface FetchAppFileActionPayload {
  caseId: string;
  fileName: string;
}
export const fetchAppFile = createAction<FetchAppFileActionPayload>(
  FETCH_APP_FILE_START
);
export interface FetchAppFileSuccessPayload {
  caseId: string;
  fileName: string;
  fileUrl: string;
  uploadType: string;
}
export const fetchAppFileSuccess = createAction<FetchAppFileSuccessPayload>(
  FETCH_APP_FILE_SUCCESS
);
export const fetchAppFileFailure = createAction(FETCH_APP_FILE_FAILURE);

// Request forms

export const requestFormSuccess = createAction(REQUEST_FORM_SUCCESS);
export const requestFormFailure = createAction(REQUEST_FORM_FAILURE);
export const requestFormCancel = createAction(REQUEST_FORM_CANCEL);

// Fetch files

export interface FetchAppFilesActionPayload {
  caseId: string;
}
export const fetchAppFiles = createAction<FetchAppFilesActionPayload>(
  FETCH_APP_FILES_START
);
export interface FetchAppFilesSuccessPayload {
  caseId: string;
  files: AppFile[];
}
export const fetchAppFilesSuccess = createAction<FetchAppFilesSuccessPayload>(
  FETCH_APP_FILES_SUCCESS
);
export const fetchAppFilesFailure = createAction(FETCH_APP_FILES_FAILURE);
