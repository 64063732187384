import { createSelector } from "reselect";
import { getInsurances } from "selectors/insurances/getInsurances";
import { Insurance } from "types/types";
import { GlobalState } from "reducers";

export const getInsuranceByName = createSelector(
  getInsurances,
  (_: GlobalState, { name }: { name: string }): string => name,
  (insurances, name): Insurance | undefined =>
    Object.values(insurances).find(insurance => insurance.name === name),
);
