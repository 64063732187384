import React, { useEffect } from 'react';
import { Flex } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { useGoBack } from 'utils/hooks/useGoBack';
import { useOfferId } from 'pages/offers/OfferPage/hooks/useOfferId';
import { useSelectorWithProps } from 'utils/hooks/useSelectorWithProps';
import { getOffer } from 'selectors/offers/getDetailedOffer';
import { OfferDetails } from 'components/OfferDetails/OfferDetails';
import { Loader } from 'components/Loader/Loader';
import { OfferStatusLabel } from 'components/OfferStatusLabel/OfferStatusLabel';
import { OfferActionButton } from 'containers/OfferActionButton/OfferActionButton';
import { ACTION_HOOKS, CallAction } from 'utils/hooks/useCall/config';
import { FetchStatus } from 'types/types';

export const OfferPage = () => {
  const goBack = useGoBack();
  const offerId = useOfferId();
  const offer = useSelectorWithProps(getOffer, { offerId });

  const { call: fetchOffer, fetchStatus } = ACTION_HOOKS[CallAction.FetchOffer](
    {}
  );

  const isReady = Boolean(offer && fetchStatus === FetchStatus.Success);

  useEffect(() => {
    fetchOffer({ offerId });
  }, [fetchOffer, offerId]);

  return (
    <Flex width="100%" flexDirection="column">
      <HeaderBar
        title="Offer Details"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
        right={isReady && <OfferStatusLabel offerStatus={offer!.status} />}
      />
      <ScrollBox flexDirection="column" margin={3}>
        <Loader isLoading={!isReady}>
          <OfferDetails offer={offer} />
        </Loader>
      </ScrollBox>
      <Flex flex={1} />
      {isReady && (
        <OfferActionButton offerStatus={offer!.status} offerId={offer!.id} />
      )}
    </Flex>
  );
};
