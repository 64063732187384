import React from 'react';

import './MissingDocsWrapper.css';

export const MissingDocsWrapper = ({
  isDocMissing,
  children,
}: {
  isDocMissing: boolean;
  children: React.ReactElement;
}) => {
  return isDocMissing ? (
    <div className="missing-docs">
      <div className="missing-docs__title">Required document</div>
      {children}
    </div>
  ) : (
    children
  );
};
