import {
  FetchAppFilesSuccessPayload,
  FetchAppFileSuccessPayload,
} from './actions';
import { convertSnakeToCamelCase } from '../../utils/convertSnakeToCamelCase';

export const getTranslateFetchAppFile = ({
  fileName,
  caseId,
}: {
  fileName: string;
  caseId: string;
}) => (appFileSuccessPayload: Object): FetchAppFileSuccessPayload => {
  const camelCased = convertSnakeToCamelCase(appFileSuccessPayload);
  return {
    caseId,
    fileName,
    ...camelCased,
  };
};

export const getTranslateFetchAppFiles = ({ caseId }: { caseId: string }) => (
  appFilesSuccessPayload: Object
): FetchAppFilesSuccessPayload => {
  const camelCased = convertSnakeToCamelCase(appFilesSuccessPayload);
  return {
    caseId,
    files: camelCased,
  };
};
