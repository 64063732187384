import { Action } from 'redux-actions';

export interface DebugState {
  lastCall?: Action<any>;
  olderCall?: Action<any>;
}

export default (state: DebugState | undefined, action: Action<any>) => ({
  lastCall: action,
  olderCall: state?.lastCall,
});
