import React from 'react';
import { Flex, Text } from 'rebass';
import { FormValues } from 'pages/payment/PaymentMethod';
import { Field, FormikProps } from 'formik';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { PaymentMethod } from 'pages/payment/types';
import { Separator } from 'components/Separator';
import { LabelledRadio } from 'components/forms/LabelledRadio';
import { FormikTextField } from 'components/forms/FormikTextField';
import { AppFileType } from 'utils/file/constants';
import { Appointment, Case, Patient } from 'types/types';
import { CostBreakdown } from 'pages/payment/CostBreakdown';
import { CurrentOrNewCard } from 'pages/payment/PaymentForm/CurrentOrNewCard';
import { CaseFileControls } from '../../../containers/Files/CaseFileControls';

// cash or check
// https://app.zeplin.io/project/5e0510850d97c818513015b3/screen/5e169813a1eeb8521b35c2f3

interface Props extends FormikProps<FormValues> {
  patient: Patient;
  appointment: Appointment;
  therapyCase: Case;
}

export const PaymentForm = ({
  handleSubmit,
  isValid,
  values,
  patient,
  therapyCase,
  appointment,
}: Props) => (
  <Flex
    height="100%"
    flexDirection="column"
    as="form"
    // @ts-ignore
    onSubmit={handleSubmit}
  >
    <Flex padding={3} flexDirection="column">
      <Text fontWeight="bold" marginBottom={3}>
        Choose payment method your co-pay charges:
      </Text>
      <Flex marginBottom={2} justifyContent="space-between">
        <Field
          name="paymentMethod"
          component={LabelledRadio}
          checkedValue={PaymentMethod.CreditOrDebit}
          label="Credit or Debit Card"
        />
        <Field
          name="paymentMethod"
          component={LabelledRadio}
          checkedValue={PaymentMethod.CashOrCheck}
          label="Cash or Check"
        />
      </Flex>
      <Text color="grey" fontSize="10pt" marginBottom={2}>
        * we will confirm your insurance coverage with you before charging your
        card.
      </Text>
      {values.paymentMethod === PaymentMethod.CreditOrDebit && (
        <CurrentOrNewCard appointment={appointment} patient={patient} />
      )}
      {values.paymentMethod === PaymentMethod.CashOrCheck && (
        <Flex justifyContent="space-between">
          <Flex flex={1}>
            <CostBreakdown appointment={appointment} />
          </Flex>
          <Flex alignItems="center" flex={1}>
            <Text fontSize="16pt" marginRight={2} fontWeight="bold">
              $
            </Text>
            <Field name="cashCheckValue" component={FormikTextField} />
          </Flex>
        </Flex>
      )}
      <Separator />
      <Text fontWeight="bold" marginBottom={3}>
        Your total cost is reflected when you use insurance.
      </Text>
      <Flex justifyContent="flex-end" marginBottom={3}>
        <Flex flexDirection="column">
          <Flex>
            <Text marginRight={1}>Insurance front:</Text>
            <CaseFileControls
              appFileType={AppFileType.InsuranceFront}
              caseId={String(therapyCase.id)}
            />
          </Flex>
          <Flex>
            <Text marginRight={1}>Insurance back:</Text>
            <CaseFileControls
              appFileType={AppFileType.InsuranceBack}
              caseId={String(therapyCase.id)}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
    <Flex flex={1} />
    <FooterButton label="Confirm Payment" isSubmit disabled={!isValid} />
    <Flex
      justifyContent="center"
      alignItems="center"
      padding={3}
      paddingTop={0}
      sx={{
        color: 'grey',
      }}
    >
      <Flex marginRight={1}>Issue? Call us now! -</Flex>
      {process.env.REACT_APP_HELP_NUMBER}
    </Flex>
  </Flex>
);
