import React from 'react';
import { Flex } from 'rebass';

export interface ButtonSpec {
  value: any;
  label: React.ReactNode;
  onClick?: () => void;
  key?: string;
}

interface Props {
  buttons: ButtonSpec[];
  onSelect?: (value: any) => void;
}

export const ButtonList = ({ buttons, onSelect = () => {} }: Props) => (
  <Flex flexDirection="column">
    {buttons.map(({ label, value, onClick, key }, i) => (
      <Flex
        key={key || String(label)}
        onClick={onClick || (() => onSelect(value))}
        alignItems="center"
        height="75px"
        padding={3}
        sx={{
          ...(i % 2 === 0 ? { backgroundColor: 'faded' } : {}),
          fontSize: '14pt',
          borderBottom: 'border',
          borderColor: 'muted',
        }}
      >
        {label}
      </Flex>
    ))}
  </Flex>
);
