import { FetchStatus } from "types/types";
import { useState } from "react";

export interface Props {
  fetchStatus: FetchStatus;
  error?: string | null;
  initialArmedState?: boolean;
}

export interface Output {
  finalError?: string | null;
  armHook: () => void;
}

/*
  Returns the error only if the fetch status is error after having been triggered.
  This is to avoid having a component display an unrelated error on first render.
*/
export const useSmartError = ({
  fetchStatus,
  error,
  initialArmedState = false,
}: Props): Output => {
  const [isArmed, setHasArmed] = useState(initialArmedState);
  const output: Output = {
    armHook: () => setHasArmed(true),
  };
  if (isArmed && fetchStatus === FetchStatus.Failure) {
    output.finalError = error;
  }
  return output;
};
