import React from 'react';
import { Therapist } from 'types/types';
import { Flex } from 'rebass';
import { TherapistAddress } from 'components/TherapistAddress/TherapistAddress';
import { TherapistPhoneNumber } from 'components/TherapistPhoneNumber/TherapistPhoneNumber';
import { TherapistInfoDisplayer } from 'components/TherapistInfo/TherapistInfoDisplayer';

interface Props {
  therapist: Therapist;
  children?: React.ReactNode;
}

// Omitting specialization and notes as per Jerry's wishes
export const TherapistInfo = ({ therapist, children }: Props) => (
  <Flex flexDirection="column" paddingLeft={3} paddingRight={3}>
    <TherapistInfoDisplayer label="Home Address:">
      <TherapistAddress therapist={therapist} />
    </TherapistInfoDisplayer>
    <TherapistInfoDisplayer label="Phone number:">
      <TherapistPhoneNumber therapist={therapist} />
    </TherapistInfoDisplayer>
    {children}
  </Flex>
);
