import React from 'react';
import { Text, TextProps } from 'rebass';
import {
  CustomLink,
  ParentProps as CustomLinkProps,
} from 'components/CustomLink/CustomLink';
import { CssTypeFix } from 'types/types';

export type ParentProps = CustomLinkProps & {
  isInline?: boolean;
  textProps?: TextProps & CssTypeFix;
};

export const CustomTextLink = ({
  isInline = false,
  children,
  textProps = {},
  ...props
}: ParentProps) => (
  <CustomLink
    {...props}
    {...(isInline
      ? {
          display: 'inline',
        }
      : {})}
  >
    <Text
      variant="link"
      {...(isInline
        ? {
            display: 'inline',
          }
        : {})}
      {...textProps}
    >
      {children}
    </Text>
  </CustomLink>
);
