import React, { useCallback } from 'react';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { ACTION_HOOKS, CallAction } from 'utils/hooks/useCall/config';
import { isFetching } from 'utils/fetchStatus';
import { Box, Flex, Text } from 'rebass';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';
import { useDeferred } from 'utils/hooks/useDeferred';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';

interface Props {
  offerId: number;
  label: React.ReactNode;
}

export const UnclaimButton = ({ label, offerId }: Props) => {
  const { call, fetchStatus } = ACTION_HOOKS[CallAction.UnclaimOffer]({
    onSuccess: () => console.log('success'),
  });

  const makeCall = useCallback(() => {
    call({ offerId: String(offerId) });
  }, [call, offerId]);

  const { isConfirmationVisible, confirm, reject, areYouSure } = useDeferred({
    onConfirm: makeCall,
  });

  const isLoading = isFetching(fetchStatus);

  const finalLabel = (
    <Flex justifyContent="center" alignItems="center">
      <Text>{label}</Text>
      {isLoading && (
        <Box marginLeft={1}>
          <LoadingSpinner size={20} spinnerColor="white" />
        </Box>
      )}
    </Flex>
  );

  return (
    <>
      {isConfirmationVisible && (
        <ConfirmationModal
          onAccept={confirm}
          onCancel={reject}
          onClose={reject}
        >
          Are you sure???
        </ConfirmationModal>
      )}
      <FooterButton
        label={finalLabel}
        disabled={isLoading}
        onClick={areYouSure}
      />
    </>
  );
};
