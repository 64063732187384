import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Case, Patient } from 'types/types';
import { getPatient } from 'selectors/patients/getPatient';
import { getCase } from 'selectors/cases/getCase';
import { Box, Flex, Text } from 'rebass';
import { ReactComponent as SuccessImage } from 'assets/Successful _icon@1x.svg';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { ShadowCard } from 'components/ShadowCard/ShadowCard';
import { FieldDisplayer } from 'components/FieldDisplayer/FieldDisplayer';
import { PatientAddress } from 'components/PatientAddress/PatientAddress';
import { Separator } from 'components/Separator';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { FutureCaseAppointments } from 'containers/FutureCaseAppointments/FutureCaseAppointments';
// import { CustomTextLink } from "common/components/CustomLink/CustomTextLink";
// import { getAddressUpdatePath } from "routing/routeFunctions";

// Assumes case and patient exist in state

interface OwnProps {
  therapyCaseId: number | string;
  patientId: number | string;
  children?: React.ReactNode;
  footerLabel?: React.ReactNode;
  onFooterClick: () => void;
}

interface StateProps {
  patient?: Patient;
  therapyCase?: Case;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  { therapyCaseId, patientId }: OwnProps
): StateProps => ({
  patient: getPatient(state, { patientId }),
  therapyCase: getCase(state, { caseId: String(therapyCaseId) }),
});

const SuccessPageTemplateComponent = ({
  patient,
  therapyCase,
  children,
  footerLabel = 'Done',
  onFooterClick,
}: Props) => {
  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <Box marginTop={4}>
        <SuccessImage height={80} width={80} />
      </Box>
      {children}
      <ScrollBox width="100%" padding={3}>
        <ShadowCard
          width="100%"
          flexDirection="column"
          alignItems="center"
          overflow="auto"
        >
          <Flex
            width="100%"
            padding={3}
            flexDirection="column"
            alignItems="center"
          >
            <Text>Upcoming Schedule</Text>
            <Text fontWeight="bold" marginTop={2}>
              {patient?.lastName}, {patient?.firstName}
            </Text>
            {patient && (
              <Flex marginTop={2} justifyContent="space-between" width="100%">
                <FieldDisplayer
                  title="Place of Service"
                  value={<PatientAddress patient={patient} />}
                />
                {/*
                // TODO restore this when feature is working
                <CustomTextLink
                  to={getAddressUpdatePath({ patientId: patient.id })}
                >
                  Change
                </CustomTextLink>
                */}
              </Flex>
            )}
          </Flex>
          <Separator />
          {therapyCase && (
            <FutureCaseAppointments caseId={String(therapyCase.id)} />
          )}
        </ShadowCard>
      </ScrollBox>
      <Flex flex={1} />
      <FooterButton label={footerLabel} onClick={onFooterClick} />
    </Flex>
  );
};

export const SuccessPageTemplate = connect(mapStateToProps)(
  SuccessPageTemplateComponent
);
