import { CustomEpic } from 'types/types';
import { switchMap } from 'rxjs/operators';
// import { FILE_UPLOAD_HOOK_URL, fileTypeToUploadType } from '../constants';
// import { convertCamelToSnakeCase } from '../../../utils/convertCamelToSnakeCase';
// import { parseFilePath } from '../../../utils/file/parseFilePath';
// import { getUser } from '../../../selectors/user/getUser';
// import { getUserName } from '../../../selectors/user/getUserName';
// import { getAuthHeaders } from '../../../api/getAuthHeaders';
// import { handleAjaxSuccess } from '../../../utils/epics/handleAjaxSuccess';
import { fileUploadHookFailure } from '../actions';
// import { fileUploadHookFailure, fileUploadHookSuccess } from '../actions';
// import { handleAjaxError } from '../../../utils/epics/handleAjaxError';
import { handleError } from '../../../utils/epics/handleError';
import { ofType } from 'redux-observable';
import { getIsFileUploadHookEnabled } from '../../../selectors/features/getIsFileUploadHookEnabled';
import { UploadAppFileSuccessPayload } from '../../files/actions';
// import { UPLOAD_APP_FILE_SUCCESS } from '../../files/actionTypes';

export const newUploadedFile$: CustomEpic<UploadAppFileSuccessPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(''), // TODO: ask how the hook should be called with the new file API
    // there's no path anymore, so the files can't be passed in
    // ofType(UPLOAD_APP_FILE_SUCCESS),
    switchMap(({ payload: { fileId } }) => {
      if (!getIsFileUploadHookEnabled(state$.value)) {
        return [];
      }
      return [];
      /*
      const parsedFilePath = parseFilePath(path_display!);
      if (!parsedFilePath)
        throw new Error('File path cannot be parsed: ' + path_display);
      const { firstName, lastName, id, fileType } = parsedFilePath!;
      const therapist = getUser(state$.value);
      return postApi$({
        endpoint: FILE_UPLOAD_HOOK_URL,
        payload: convertCamelToSnakeCase({
          files: [path_display],
          patientId: id,
          patientFirstName: firstName,
          patientLastName: lastName,
          therapistId: therapist.id,
          therapistName: getUserName(state$.value),
          bodyPart: null, // TBD when feature available
          ...(fileType
            ? {
                upload_type: fileTypeToUploadType[fileType],
              }
            : {}),
        }),
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(fileUploadHookSuccess),
        handleAjaxError(fileUploadHookFailure)
      );
       */
    }),
    handleError(fileUploadHookFailure)
  );
