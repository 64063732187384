import { applyMiddleware } from 'redux';
import * as Sentry from '@sentry/react';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { getApi$ } from 'api/getApi';
import { postApi$ } from 'api/postApi';
import { PayloadAction } from 'types/types';
import { GlobalState } from 'reducers';

export interface EpicDependencies {
  getApi$: typeof getApi$;
  postApi$: typeof postApi$;
}

export const epicMiddleware = createEpicMiddleware<
  PayloadAction<any>, // Input
  any, // Output
  GlobalState, // State
  EpicDependencies // Dependencies
>({
  dependencies: {
    getApi$: getApi$,
    postApi$: postApi$,
  },
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const middleware = composeWithDevTools(
  // to enable redux devtools browser extension
  applyMiddleware(epicMiddleware),
  sentryReduxEnhancer
);
