import { Action } from 'redux-actions';
import { FetchStatus } from 'types/types';

/**
 * Create a fetch status reducer field
 * @param fetchingActions actions to set the status to fetching
 * @param successActions actions to set the status to success
 * @param failureActions actions to set the status to failure
 * @param initialState initial fetch status
 */
export const createFetchStatusReducer = ({
  fetchingActions,
  successActions,
  failureActions,
  unauthorizedActions,
  initialState = FetchStatus.None,
}: {
  fetchingActions: string[];
  successActions: string[];
  failureActions: string[];
  unauthorizedActions?: string[];
  initialState?: FetchStatus;
}) => (state: FetchStatus = initialState, action: Action<any>): FetchStatus => {
  if (!state) {
    return FetchStatus.None;
  }
  if (fetchingActions.indexOf(action.type) !== -1) {
    return FetchStatus.Fetching;
  }
  if (successActions.indexOf(action.type) !== -1) {
    return FetchStatus.Success;
  }
  if (failureActions.indexOf(action.type) !== -1) {
    return FetchStatus.Failure;
  }
  if (unauthorizedActions && unauthorizedActions.indexOf(action.type) !== -1) {
    return FetchStatus.Unauthorized;
  }
  return state;
};
