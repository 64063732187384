import { CustomEpic } from "types/types";
import {
  UpdateAppointmentActionPayload,
  updateAppointmentFailure,
  updateAppointmentSuccess
} from "modules/appointments/actions";
import { ofType } from "redux-observable";
import { UPDATE_APPOINTMENT } from "modules/appointments/actionTypes";
import { switchMap } from "rxjs/operators";
import { getUpdateAppointmentUrl } from "modules/appointments/utils/getUpdateAppointmentUrl";
import { denormalizeAppointment } from "modules/appointments/utils/normalizeAppointment";
import { handleAjaxSuccess } from "utils/epics/handleAjaxSuccess";
import { updateAppointmentCallTranslator } from "modules/appointments/translator";
import { handleAjaxError } from "utils/epics/handleAjaxError";
import { getAuthHeaders } from "api/getAuthHeaders";

export const updateAppointment$: CustomEpic<UpdateAppointmentActionPayload> = (
  action$,
  state$,
  { postApi$ },
) => action$.pipe(
  ofType(UPDATE_APPOINTMENT),
  switchMap(
    ({
      payload: {
        id,
        ...rest
      }
    }) => postApi$({
      endpoint: getUpdateAppointmentUrl({ appointmentId: id }),
      headers: getAuthHeaders(state$.value),
      payload: denormalizeAppointment(rest),
    })
      .pipe(
        handleAjaxSuccess(updateAppointmentSuccess, updateAppointmentCallTranslator),
        handleAjaxError(updateAppointmentFailure),
      ),
  )
);