import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Appointment } from 'types/types';
import {
  AppointmentData,
  getAppointmentData,
} from 'selectors/appointments/getAppointmentData';
import { AppointmentInfo } from 'components/AppointmentInfo/AppointmentInfo';
import { Text } from 'rebass';

interface OwnProps {
  appointment: Appointment;
}

interface StateProps {
  appointmentData: AppointmentData;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  { appointment }: OwnProps
): StateProps => ({
  appointmentData: getAppointmentData(state, { appointment }),
});

const ErrorMessage = ({ children }: { children: React.ReactNode }) => (
  <Text textAlign="center" color="red" margin={2}>
    {children}
  </Text>
);

const ConnectedAppointmentInfoComponent = ({
  appointment,
  appointmentData: { patient, therapyCase, doctor, lead },
}: Props) => {
  if (!appointment) {
    return <ErrorMessage>Appointment not found</ErrorMessage>;
  }
  if (!patient) {
    return <ErrorMessage>Patient not found</ErrorMessage>;
  }
  if (!therapyCase) {
    return <ErrorMessage>Case not found</ErrorMessage>;
  }
  if (!lead) {
    return <ErrorMessage>Lead not found</ErrorMessage>;
  }
  return (
    <AppointmentInfo
      appointment={appointment}
      patient={patient}
      therapyCase={therapyCase}
      doctor={doctor}
      lead={lead}
    />
  );
};

export const ConnectedAppointmentInfo = connect(mapStateToProps)(
  ConnectedAppointmentInfoComponent
);
