import { GlobalState } from "reducers";
import { getPatient } from "selectors/patients/getPatient";
import { Insurance } from "types/types";
import { getInsuranceByName } from "selectors/insurances/getInsuranceByName";

export const getPatientInsurance = (state: GlobalState, { patientId }: { patientId: number }): Insurance | undefined => {
  const patient = getPatient(state, { patientId });
  if (!patient) {
    return undefined;
  }
  const insuranceName = patient.insurance;
  if (!insuranceName) {
    return undefined;
  }
  return getInsuranceByName(state, { name: insuranceName });
};
