import { CustomEpic } from 'types/types';
import { ofType } from 'redux-observable';
import { FETCH_OFFERS_START } from 'modules/offers/actionsTypes';
import { switchMap } from 'rxjs/operators';
import { FETCH_OFFERS_URL } from 'modules/offers/constants';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { fetchOffersFailure, fetchOffersSuccess } from 'modules/offers/actions';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { translateListOffers } from 'modules/offers/utils/translateListOffers';

export const fetchOffers$: CustomEpic<{}> = (action$, state$, { getApi$ }) =>
  action$.pipe(
    ofType(FETCH_OFFERS_START),
    switchMap(() =>
      getApi$({
        endpoint: FETCH_OFFERS_URL,
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(fetchOffersSuccess, translateListOffers),
        handleAjaxError(fetchOffersFailure)
      )
    )
  );
