import { Appointment } from "types/types";
import { formatSessionTime } from "utils/formatSessionTime";
import { appointmentToMoment } from "modules/schedule/utils/appointmentToMoment";

interface Props {
  appointment: Appointment;
}

export const formatAppointmentTime = ({ appointment }: Props): string =>
  formatSessionTime({
    startTime: appointmentToMoment(appointment),
    duration: appointment.duration,
  });
