import React, { useEffect, useRef, useState } from 'react';

interface Props {
  clockSpeed: number; // of milliseconds
  render: () => React.ReactNode;
}

export const ClockRenderer = ({ clockSpeed, render }: Props) => {
  const [, setTime] = useState<number>();

  const interval = useRef(0);

  // start interval
  useEffect(() => {
    // TODO: TS error here: Timeout is not settable to number
    interval.current = window.setInterval(() => {
      return setTime(Date.now());
    }, clockSpeed);

    // clear interval upon dismount
    return () => clearInterval(interval.current);
  });

  return <>{render()}</>;
};
