import { createAction } from 'redux-actions';
import {
  GET_SCHEDULE,
  GET_SCHEDULE_FAILURE,
  GET_SCHEDULE_SUCCESS,
} from './actionTypes';
import {
  Appointment,
  Case,
  Doctor,
  Insurance,
  Lead,
  Patient,
} from 'types/types';

export interface GetScheduleActionPayload {
  period?: string;
  since?: string; // 2020-03-01
  until?: string;
  includeCancelledAppointments?: boolean;
}

export const getSchedule = createAction<GetScheduleActionPayload>(GET_SCHEDULE);

export interface GetScheduleSuccessActionPayload {
  appointmentList: number[];
  appointments: Record<string, Appointment>;
  patients: Record<string, Patient>;
  insurances: Record<string, Insurance>;
  doctors: Record<string, Doctor>;
  cases: Record<string, Case>;
  leads: Record<string, Lead>;
}

export const getScheduleSuccess = createAction<GetScheduleSuccessActionPayload>(
  GET_SCHEDULE_SUCCESS
);

export const getScheduleFailure = createAction(GET_SCHEDULE_FAILURE);
