import React, { useCallback } from 'react';
import { FieldProps } from 'formik';
import {
  AppointmentSlotPicker,
  Props as AppointmentSlotPickerProps,
} from 'components/AppointmentSlotPicker/AppointmentSlotPicker';

type Props = FieldProps<string> &
  Omit<AppointmentSlotPickerProps, 'value' | 'onChange'>;

export const FormikAppointmentSlotPickerField = ({
  field: { name, value },
  form: { setFieldValue },
  appointments,
  setInitialValue,
  timeToOption,
  duration,
}: Props) => {
  const onChange = useCallback(newValue => setFieldValue(name, newValue), [
    setFieldValue,
    name,
  ]);

  return (
    <AppointmentSlotPicker
      appointments={appointments}
      value={value}
      onChange={onChange}
      setInitialValue={setInitialValue}
      timeToOption={timeToOption}
      duration={duration}
    />
  );
};
