import moment, { Moment } from "moment";
import { Appointment } from "types/types";
import { appointmentMoment } from "utils/appointment/appointmentMoment";

export const getNextAppointment = ({
  nextAfterTime = moment(),
  appointments,
}: {
  nextAfterTime?: Moment;
  appointments: Appointment[]; // assuming they're sorted
}) => appointments.find(appointment => appointmentMoment(appointment).isAfter(nextAfterTime));
