import { get$ } from './get';
import { convertCamelToSnakeCase } from 'utils/convertCamelToSnakeCase';

export interface GetAPIParams {
  endpoint: string;
  payload?: Object;
  headers?: Object;
}

export const getApi$ = ({ payload = {}, headers, endpoint }: GetAPIParams) =>
  get$({
    headers,
    endpoint,
    payload: payload ? convertCamelToSnakeCase(payload) : {},
  });
