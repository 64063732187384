import React from 'react';
import { FieldInputProps } from 'formik';
import { Checkbox } from '@rebass/forms';
import { LabelledComponent } from 'components/forms/LabelledComponent';

export interface Props {
  field: FieldInputProps<any>;
  label: React.ReactNode;
}

export const LabelledCheckbox = ({ field, label }: Props) => (
  <LabelledComponent label={label} input={<Checkbox {...field} />} />
);
