import React, { useCallback } from 'react';
import { Moment } from 'moment';
import { Flex } from 'rebass';
import { Days } from 'components/DatePicker/Days';
import { daysOfWeek } from 'appConstants';
import { GREY } from 'theme/colors';

interface Props {
  weeksToShow?: number;
  activeDate: Moment;
  renderDay?: (date: Moment) => React.ReactNode;
  renderWeekDay?: (weekday: string) => React.ReactNode;
  startDate?: Moment;
  onDateClick?: (date: Moment) => void;
  isDateDisabled?: (date: Moment) => boolean;
}

const defaultRenderWeekday = (s: string) => s;
const defaultRenderDay = (m: Moment) => m.format('D');

export const DatePicker = ({
  activeDate,
  renderWeekDay = defaultRenderWeekday,
  renderDay = defaultRenderDay,
  startDate,
  weeksToShow = 1,
  onDateClick = () => {},
  isDateDisabled = () => false,
}: Props) => {
  const renderDayComponent = useCallback(
    (momentDate: Moment) => {
      const isDisabled = isDateDisabled(momentDate);
      const onClick = () => {
        if (!isDisabled) {
          onDateClick(momentDate);
        }
      };
      return (
        <Flex
          key={momentDate.unix()}
          width={`${(1 / 7) * 100}%`}
          justifyContent="center"
          onClick={onClick}
          color={isDisabled ? GREY : undefined}
        >
          {renderDay(momentDate)}
        </Flex>
      );
    },
    [onDateClick, renderDay, isDateDisabled]
  );

  return (
    <Flex width="100%" flexDirection="column">
      <Flex>
        {daysOfWeek.map(weekday => (
          <Flex
            key={weekday}
            width={`${(1 / 7) * 100}%`}
            justifyContent="center"
          >
            {renderWeekDay(weekday)}
          </Flex>
        ))}
      </Flex>
      <Flex flexWrap="wrap" width="100%" marginTop={2}>
        <Days
          startDate={startDate || activeDate}
          daysToShow={weeksToShow * 7}
          renderDay={renderDayComponent}
        />
      </Flex>
    </Flex>
  );
};
