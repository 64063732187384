import { AppointmentServiceType, FetchStatus } from 'types/types';
import { Action, handleActions } from 'redux-actions';
import {
  FETCH_CASE_APP_TYPES,
  FETCH_CASE_APP_TYPES_SUCCESS,
  FETCH_CASE_APP_TYPES_FAILURE,
} from 'modules/cases/actionTypes';
import { FetchCaseAppTypesSuccessActionPayload } from 'modules/cases/actions';
import { combineReducers } from 'redux';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';

export interface AppointmentServiceTypeMap {
  [key: string]: AppointmentServiceType[];
}
export interface CasesAppointmentTypesState {
  normalized: AppointmentServiceTypeMap;
  fetchStatus: FetchStatus;
}

const initialNormalizedState: AppointmentServiceTypeMap = {};

const normalized = handleActions<AppointmentServiceTypeMap, any>(
  {
    [FETCH_CASE_APP_TYPES_SUCCESS]: (
      state,
      action: Action<FetchCaseAppTypesSuccessActionPayload>
    ) => ({
      ...state,
      ...(action.payload.caseId
        ? {
            [action.payload.caseId]: action.payload.appointmentTypes,
          }
        : {}),
    }),
  },
  initialNormalizedState
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [FETCH_CASE_APP_TYPES],
  successActions: [FETCH_CASE_APP_TYPES_SUCCESS],
  failureActions: [FETCH_CASE_APP_TYPES_FAILURE],
});

export default combineReducers<CasesAppointmentTypesState>({
  normalized,
  fetchStatus,
});
