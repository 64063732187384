import React, { useCallback, useState } from 'react';
import { Text } from 'rebass';
import { ImageViewer } from 'components/ImageViewer/ImageViewer';
import { ClosableFullOverlay } from 'components/ClosableFullOverlay/ClosableFullOverlay';
import { fetchS3Image } from '../../utils/file/fetchS3Image';
import { useSelectorWithProps } from '../../utils/hooks/useSelectorWithProps';
import { getCaseFile } from '../../selectors/files/getCaseFile';
import { getAppFileType } from '../../utils/file/getAppFileType';
import { useSelector } from 'react-redux';
import { getAuthHeaders } from '../../api/getAuthHeaders';
import { fetchAppFile } from '../../modules/files/promises/fetchAppFile';

const img_types = ['png', 'jpg', 'jpeg', 'gif', 'tiff'];

interface Props {
  fileName: string;
  caseId: string;
  children: React.ReactNode;
}

export const LazyS3ImageViewLink = ({ caseId, fileName, children }: Props) => {
  const [imageSrc, setImageSrc] = useState<string | undefined>();
  const appFileType = getAppFileType(fileName);

  const file = useSelectorWithProps(getCaseFile, { caseId, appFileType });

  const authHeaders = useSelector(getAuthHeaders);

  const fetchImageAndOpenModal = useCallback(
    async (fileUrl: string) => {
      // handle not file URL
      if (!fileUrl) {
        return;
      }
      const base64Image = await fetchS3Image({ fileUrl });
      setImageSrc(base64Image);
    },
    [setImageSrc]
  );

  /**
   * Ideally we'd be using the ACTION_HOOKS from useCall/config
   * but there's a bug in the state based callback util that causes onSuccess
   * to get called each time this component is rerendered, breaking the app
   */
  const fetchFileUrl = useCallback(async () => {
    if (!file) {
      return;
    }
    setImageSrc(undefined);
    const fileUrl = await fetchAppFile({
      caseId,
      fileId: file?.fileId,
      headers: authHeaders,
    });

    if (!fileUrl) {
      return;
    }
    let nameSplit = file?.fileId.split('.');
    if (img_types.includes(nameSplit[nameSplit.length - 1])) {
      fetchImageAndOpenModal(fileUrl);
    } else {
      window.open(fileUrl);
    }
  }, [fetchImageAndOpenModal, authHeaders, caseId, file]);

  return (
    <>
      <Text as="a" variant="link" onClick={fetchFileUrl} download>
        {children}
      </Text>
      {imageSrc && (
        <ClosableFullOverlay>
          <ImageViewer src={imageSrc} />
        </ClosableFullOverlay>
      )}
    </>
  );
};
