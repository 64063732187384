import { CustomEpic } from 'types/types';
import {
  GetScheduleActionPayload,
  getScheduleFailure,
  getScheduleSuccess,
} from '../actions';
import { ofType } from 'redux-observable';
import { GET_SCHEDULE } from '../actionTypes';
import { switchMap } from 'rxjs/operators';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { getScheduleEndpoint } from '../utils/getScheduleEndpoint';
import { getId } from 'selectors/user/getId';
import { getScheduleCallTranslator } from '../translator';
import { getIsOnHoldScheduleFilterEnabled } from 'selectors/features/getIsOnHoldScheduleFilterEnabled';

export const getSchedule$: CustomEpic<GetScheduleActionPayload> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(GET_SCHEDULE),
    switchMap(
      ({ payload: { period, since, until, includeCancelledAppointments } }) =>
        getApi$({
          endpoint: getScheduleEndpoint({ therapistId: getId(state$.value) }),
          payload: {
            period,
            since,
            until,
            cx: includeCancelledAppointments || false,
          },
          headers: getAuthHeaders(state$.value),
        }).pipe(
          handleAjaxSuccess(
            getScheduleSuccess,
            getScheduleCallTranslator(
              getIsOnHoldScheduleFilterEnabled(state$.value)
            )
          ),
          handleAjaxError(getScheduleFailure)
        )
    )
  );
