import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Box, Flex } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import moment, { Moment } from 'moment';
import { getSchedule } from 'selectors/schedule/getSchedule';
import { Appointment } from 'types/types';
import { ConnectedDatePicker } from 'containers/ConnectedDatePicker/ConnectedDatePicker';
import { getActiveMonths } from 'utils/getActiveMonths';
import { ArrowForwardIcon } from 'components/icons/ArrowForwardIcon';
import { getSchedule as getScheduleAction } from 'modules/schedule/actions';
import { DaysAppointments } from 'containers/DaysAppointments/DaysAppointments';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { dateFormat } from 'appConstants';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { getIsFutureAppointmentsOnlyEnabled } from 'selectors/features/getIsFutureAppointmentsOnlyEnabled';

interface OwnProps {
  onBackClick: (e: SyntheticEvent) => void;
  onSelectDate: (date: Moment) => void;
}

interface StateProps {
  appointments: Appointment[];
}

interface DispatchProps {
  getSchedule: typeof getScheduleAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: GlobalState): StateProps => ({
  appointments: getSchedule(state),
});

const mapDispatchToProps: DispatchProps = {
  getSchedule: getScheduleAction,
};

const today = moment();
const weeksToShow = 4;

const BookingDatePickerPageComponent = ({
  getSchedule,
  onBackClick,
  onSelectDate,
}: Props) => {
  const [monthsInFuture, setMonthsInFuture] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Moment | undefined>(
    undefined
  );
  const startDate = today.clone().add(4 * monthsInFuture, 'weeks');
  const canDecrementMonth = monthsInFuture > 0;
  const operativeDate = selectedDate || today;

  const isFutureAppointmentsOnlyEnabled = useSelector(
    getIsFutureAppointmentsOnlyEnabled
  );
  const blockPastDates = useCallback(
    (date: Moment) =>
      isFutureAppointmentsOnlyEnabled && date.endOf('day').isBefore(),
    [isFutureAppointmentsOnlyEnabled]
  );

  useEffect(
    () => {
      getSchedule({
        since: startDate.startOf('week').format(dateFormat),
        until: startDate
          .clone()
          .add(weeksToShow, 'weeks')
          .endOf('week')
          .format(dateFormat),
      });
    },
    // linting disabled since startDate is unique for each render
    [monthsInFuture, getSchedule] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Select Date"
        left={<ArrowBackIcon size={20} onClick={onBackClick} />}
      />
      <Flex flexDirection="column" padding={3}>
        <Flex
          justifyContent="center"
          paddingBottom={2}
          sx={{
            fontWeight: 'bold',
          }}
        >
          {getActiveMonths({
            activeDay: startDate,
            weeksToShow,
          })}
          <Flex
            width="100%"
            justifyContent="space-around"
            sx={{
              position: 'absolute',
              fontWeight: 'bold',
            }}
          >
            <Box
              onClick={() =>
                canDecrementMonth && setMonthsInFuture(monthsInFuture - 1)
              }
              sx={
                canDecrementMonth
                  ? {}
                  : {
                      visibility: 'hidden',
                    }
              }
            >
              <ArrowBackIcon />
            </Box>
            <Box />
            <Box onClick={() => setMonthsInFuture(monthsInFuture + 1)}>
              <ArrowForwardIcon />
            </Box>
          </Flex>
        </Flex>
        <ConnectedDatePicker
          startDate={startDate}
          weeksToShow={weeksToShow}
          onDateClick={setSelectedDate}
          selectedDate={selectedDate}
          isDateDisabled={blockPastDates}
        />
      </Flex>
      <ScrollBox flexDirection="column" flex={1}>
        <DaysAppointments date={operativeDate} />
      </ScrollBox>
      <FooterButton
        onClick={() => onSelectDate(operativeDate)}
        label="Select Date"
      />
    </Flex>
  );
};

export const BookingDatePickerPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingDatePickerPageComponent);
