import { ActionSpecificProps, BaseActionCreator, useCall, UseCallExternalProps } from "utils/hooks/useCall/useCall";

export const createActionHook = <ActionCreator extends BaseActionCreator>({
  actionCreator,
  fetchStatusSelector,
}: ActionSpecificProps<ActionCreator>) =>
  (props: UseCallExternalProps) => useCall({
    ...props,
    actionCreator,
    fetchStatusSelector,
  });
