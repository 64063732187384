import React from 'react';
import { FormikProps } from 'formik';
import { FormValues } from 'pages/session/SessionPage/EndSession';
import { FooterButton } from 'components/FooterButton/FooterButton';
import _ from 'lodash';
import { formatCurrency } from 'utils/formatCurrency';
import { MissingDocumentsPopup } from 'components/MissingDocumentsPopup';
import { Appointment, Case, Lead, Patient } from 'types/types';
import { Text } from 'rebass';
import { useSelector } from 'react-redux';
import { getIsLoadingSession } from 'selectors/session/getIsLoadingSession';

export type Props = {
  appointment?: Appointment;
  lead?: Lead;
  therapyCase?: Case;
  patient?: Patient;
  isEndSessionDisabled?: boolean;
  isSkippingErrors?: boolean;
  missingDocumentsIgnoredFiles?: string[];
  isFetchingSessionData?: boolean;
  onCloseErrorsPopup: () => void;
  onSkipWithErrors: (notes: string) => void;
} & FormikProps<FormValues>;

export const EndSessionForm = ({
  handleSubmit,
  isEndSessionDisabled,
  isSkippingErrors,
  isFetchingSessionData,
  missingDocumentsIgnoredFiles,
  onCloseErrorsPopup,
  onSkipWithErrors,
  appointment,
  lead,
  patient,
  therapyCase,
}: Props) => {
  const fee = appointment?.appFee;
  const isLoadingSession = useSelector(getIsLoadingSession);

  return (
    <form style={{ position: 'relative' }} onSubmit={handleSubmit}>
      {isEndSessionDisabled ? (
        <MissingDocumentsPopup
          lead={lead}
          appointment={appointment}
          patient={patient}
          therapyCase={therapyCase}
          onClickOverlay={onCloseErrorsPopup}
          onSkipWithErrors={onSkipWithErrors}
          ignoredDocuments={missingDocumentsIgnoredFiles}
        ></MissingDocumentsPopup>
      ) : isSkippingErrors ? (
        <Text
          padding={2}
          textAlign={'center'}
          backgroundColor="#444"
          color="white"
          fontSize={1}
        >
          You're ending the session with documents missing.
        </Text>
      ) : null}
      <FooterButton
        label={`End Session${
          !_.isNil(fee) ? `: ${formatCurrency({ amount: fee })}` : ''
        }`}
        disabled={isEndSessionDisabled || isLoadingSession}
        loading={isFetchingSessionData || isLoadingSession}
        isSubmit
        variant="secondary"
      ></FooterButton>
    </form>
  );
};
