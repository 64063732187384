import React from 'react';
import { Flex, Heading } from 'rebass';
import { useGoBack } from 'utils/hooks/useGoBack';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';

export const PageNotFound = () => {
  const goBack = useGoBack();
  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Heading>404</Heading>
      <Heading>Page not found</Heading>
      <Flex height="20%" />
      <Heading fontSize={18}>
        <CustomTextLink onClick={goBack}>Go Back</CustomTextLink>
      </Heading>
    </Flex>
  );
};
