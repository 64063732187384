import { UpdateCaseActionPayload, updateCaseFailure, updateCaseSuccess } from "modules/cases/actions";
import { CustomEpic } from "types/types";
import { ofType } from "redux-observable";
import { UPDATE_CASE } from "modules/cases/actionTypes";
import { switchMap } from "rxjs/operators";
import { getCaseUrl } from "modules/cases/utils/getCaseUrl";
import { getAuthHeaders } from "api/getAuthHeaders";
import { handleAjaxSuccess } from "utils/epics/handleAjaxSuccess";
import { reverseTranslateCase, translateCase } from "modules/cases/translators/translateCase";
import { handleAjaxError } from "utils/epics/handleAjaxError";

export const updateCase$: CustomEpic<UpdateCaseActionPayload> = (
  action$,
  state$,
  { postApi$ },
) => action$.pipe(
  ofType(UPDATE_CASE),
  switchMap(
    ({
      payload: {
        id,
        ...payload
      },
    }) => postApi$({
      endpoint: getCaseUrl({ caseId: String(id) }),
      payload: reverseTranslateCase(payload),
      headers: getAuthHeaders(state$.value),
    })
      .pipe(
        handleAjaxSuccess(updateCaseSuccess, translateCase),
        handleAjaxError(updateCaseFailure),
      ),
  )
);