import React, { SyntheticEvent, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import { Box, Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { PatientAutoComplete } from 'containers/PatientAutoComplete/PatientAutoComplete';
import { fetchTherapistCases as getCasesAction } from 'modules/cases/actions';
import { getPatientFromName } from 'utils/patient/getPatientFromName';
import { GlobalState } from 'reducers';
import { fetchAppointmentServiceTypes as fetchAppointmentServiceTypesAction } from 'modules/appointmentServiceTypes/actions';
import { FormValues } from './Booking';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { getPatientsList } from 'selectors/patients/getPatientsList';
import { fetchAllPatients as fetchAllPatientsAction } from 'modules/patients/actions';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { getScheduleFetchStatus } from 'selectors/schedule/getScheduleFetchStatus';
import { FetchStatus, Insurance } from 'types/types';
import { getScheduleError } from 'selectors/schedule/getScheduleError';
import { useSmartError } from 'utils/hooks/useSmartError';
import { useGoBack } from 'utils/hooks/useGoBack';
import { getAppointmentServiceTypeFromPatient } from 'utils/booking/getAppointmentServiceTypeFromPatient';
import { getInsurancesList } from 'selectors/insurances/getInsurancesList';
import { fetchInsurance as fetchInsuranceAction } from 'modules/insurance/actions';
import { PatientSubform } from './PatientSubform';

interface OwnProps {
  onDatePickerClick: (e: SyntheticEvent) => void;
}

interface StateProps {
  patients: ReturnType<typeof getPatientsList>;
  bookingFetchStatus: FetchStatus;
  bookingError: string | null;
  insurances: Insurance[];
}

interface DispatchProps {
  getCases: typeof getCasesAction;
  fetchAppointmentServiceTypes: typeof fetchAppointmentServiceTypesAction;
  fetchAllPatients: typeof fetchAllPatientsAction;
  fetchInsurance: typeof fetchInsuranceAction;
}

type Props = OwnProps & StateProps & DispatchProps & FormikProps<FormValues>;

const mapStateToProps = (state: GlobalState): StateProps => ({
  patients: getPatientsList(state),
  bookingFetchStatus: getScheduleFetchStatus(state),
  bookingError: getScheduleError(state),
  insurances: getInsurancesList(state),
});

const mapDispatchToProps: DispatchProps = {
  getCases: getCasesAction,
  fetchAppointmentServiceTypes: fetchAppointmentServiceTypesAction,
  fetchAllPatients: fetchAllPatientsAction,
  fetchInsurance: fetchInsuranceAction,
};

const BookingFormPageComponent = ({
  fetchAppointmentServiceTypes,
  getCases,
  isValid,
  onDatePickerClick,
  patients,
  setFieldValue,
  handleSubmit,
  fetchAllPatients,
  values: {
    appointmentServiceType,
    patientName,
    patient,
    appointmentTime,
    appointmentDate,
  },
  bookingFetchStatus,
  bookingError,
  insurances,
  fetchInsurance,
}: Props) => {
  useEffect(() => {
    getCases({});
  }, [getCases]);

  useEffect(() => {
    fetchAllPatients({});
  }, [fetchAllPatients]);

  useEffect(() => {
    fetchAppointmentServiceTypes();
  }, [fetchAppointmentServiceTypes]);

  const { armHook, finalError } = useSmartError({
    fetchStatus: bookingFetchStatus,
    error: bookingError,
  });

  const setPatient = useCallback(
    newValue => {
      setFieldValue('patientName', newValue);
      // set patient ID if name contains both first and last name
      if (newValue.match(',')) {
        const selectedPatient = getPatientFromName({
          patients,
          name: newValue,
        });

        setFieldValue('patient', selectedPatient);

        if (selectedPatient) {
          setFieldValue('patientId', selectedPatient?.id);
        }
      }
    },
    [patients, setFieldValue]
  );

  const goBack = useGoBack();

  const { insuranceType, error } = getAppointmentServiceTypeFromPatient({
    patient,
    insurances,
  });

  // Fetch insurance if none exists
  useEffect(() => {
    if (patient?.insurance && !insuranceType) {
      fetchInsurance({
        insuranceName: patient.insurance,
      });
    }
  }, [insuranceType, patient, fetchInsurance]);

  const disabledError = finalError || error;

  return (
    <Flex
      flexDirection="column"
      width="100%"
      as="form"
      // @ts-ignore
      onSubmit={handleSubmit}
    >
      <HeaderBar
        title="Booking Schedule"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
      />
      <ScrollBox flexDirection="column" flex={1} paddingTop={3}>
        <Flex flexDirection="column" paddingLeft={3} paddingRight={3}>
          <Box marginBottom={1}>Which patient are we scheduling for:</Box>
          <PatientAutoComplete
            value={patientName || ''}
            onChange={setPatient}
          />
        </Flex>
        {patient && insuranceType && (
          <PatientSubform
            onDatePickerClick={onDatePickerClick}
            appointmentServiceType={appointmentServiceType}
            appointmentTime={appointmentTime}
            appointmentDate={appointmentDate}
            patient={patient}
          />
        )}
      </ScrollBox>
      {disabledError && (
        <Text color="red" textAlign="center" margin={1}>
          {disabledError}
        </Text>
      )}
      <FooterButton
        isSubmit
        label="Book Session"
        disabled={!isValid}
        onClick={armHook}
      />
    </Flex>
  );
};

export const BookingFormPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingFormPageComponent);
