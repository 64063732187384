import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { getDurationFromAppointment } from 'selectors/appointmentServiceTypes/getDurationFromAppointment';
import { Appointment } from 'types/types';
import { Moment } from 'moment';
import { getAppointmentsForDay } from 'selectors/schedule/getAppointmentsForDay';
import { Field } from 'formik';
import { FormikAppointmentSlotPickerField } from 'components/forms/FormikAppointmentSlotPickerField';
import { TimeBlock } from 'utils/appointment/timeSlots/constants';
import { Option } from 'components/AppointmentSlotPicker/AppointmentSlotPicker';
import moment from 'moment';

interface OwnProps {
  appointmentId: string;
  date: Moment;
}

interface StateProps {
  duration?: number;
  appointments: Appointment[];
}

type Props = OwnProps & StateProps;

const renderTimeOption = ({ hr, min, duration }: TimeBlock): Option => {
  const parsedTime = moment(`${hr}:${min}`, 'hh:mm');

  return {
    label: `${parsedTime.format('h:mm')} - ${parsedTime
      .clone()
      .add(duration, 'minutes')
      .format('h:mm A')}`,
    value: parsedTime.format('h:mm A'),
  };
};

const mapStateToProps = (
  state: GlobalState,
  { appointmentId, date }: OwnProps
): StateProps => ({
  duration: getDurationFromAppointment(state, { appointmentId }),
  appointments: getAppointmentsForDay(state, {
    day: date,
  }),
});

const LabelText = ({ children }: { children: React.ReactNode }) => (
  <Text color="grey" fontSize={14}>
    {children}
  </Text>
);

export const DateAndTimeFieldsComponent = ({
  duration = 0,
  appointments,
  date,
}: Props) => {
  return (
    <Flex flexDirection="column">
      <LabelText>Selected date:</LabelText>
      <Box
        padding={2}
        marginBottom={2}
        sx={{
          borderColor: 'black',
          border: '1px solid',
        }}
      >
        {date.format('MMMM DD, YYYY')}
      </Box>
      <LabelText>Selected time:</LabelText>
      <Field
        name="time"
        component={FormikAppointmentSlotPickerField}
        appointments={appointments}
        setInitialValue
        timeToOption={renderTimeOption}
        duration={duration}
      />
    </Flex>
  );
};

export const DateAndTimeFields = connect(mapStateToProps)(
  DateAndTimeFieldsComponent
);
