import {createAction} from "redux-actions";
import {
  FETCH_THERAPIST,
  FETCH_THERAPIST_FAIL,
  FETCH_THERAPIST_SUCCESS,
  UPDATE_THERAPIST, UPDATE_THERAPIST_FAIL,
  UPDATE_THERAPIST_SUCCESS
} from "./actionTypes";
import {Therapist} from "types/types";

export interface FetchTherapistActionPayload {
  therapistId?: string;
}

export const fetchTherapist = createAction<FetchTherapistActionPayload>(FETCH_THERAPIST);

export type FetchTherapistSuccessActionPayload = Therapist;
export const fetchTherapistSuccess = createAction<FetchTherapistSuccessActionPayload>(FETCH_THERAPIST_SUCCESS);

export const fetchTherapistFailure = createAction(FETCH_THERAPIST_FAIL);

export interface UpdateTherapistActionPayload {
  therapist: Partial<Therapist>;
}
export const updateTherapist = createAction<UpdateTherapistActionPayload>(UPDATE_THERAPIST);

export type UpdateTherapistSuccessActionPayload = Therapist;
export const updateTherapistSuccess = createAction<UpdateTherapistSuccessActionPayload>(UPDATE_THERAPIST_SUCCESS);

export const updateTherapistFailure = createAction(UPDATE_THERAPIST_FAIL);
