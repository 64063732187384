import { CardNumberElement } from "@stripe/react-stripe-js";
import { PaymentMethod, PaymentMethodCreateParams, Stripe, StripeElements, StripeError } from "@stripe/stripe-js";

export interface Props {
  stripe: Stripe | null
  elements: StripeElements | null;
  paymentRequestParams?: PaymentMethodCreateParams;
  onError?: (error: StripeError | null) => void;
  onSuccess?: (payload: PaymentMethod) => void;
}

const toStripeErrorObject = (errorMessage: string): StripeError => ({
  type: 'api_error',
  message: errorMessage,
});

// Convert to promise based rather than callback based return type
// Ex: returns Promise<PaymentMethod | undefined>
export const createPaymentMethod = async ({
  stripe,
  elements,
  onError = () => {},
  onSuccess = () => {},
  paymentRequestParams = {},
}: Props) => {
  onError(null);
  if (!stripe || !elements) {
    // Stripe.js has not loaded yet
    onError(toStripeErrorObject('Error: Stripe not loaded'));
    return;
  }

  const cardElement = elements.getElement(CardNumberElement);

  if (!cardElement) {
    onError(toStripeErrorObject('Error: Card element not found'));
    return;
  }

  const payload = await stripe.createPaymentMethod({
    ...paymentRequestParams,
    type: 'card',
    card: cardElement,
  });

  if (payload.error) {
    const {error} = payload;
    onError(error);
  } else if(payload.paymentMethod) {
    onSuccess(payload.paymentMethod);
  } else {
    onError({
      type: 'api_error',
      message: 'Unknown error, try again',
    });
  }
};
