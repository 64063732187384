import { FetchStatus } from "types/types";
import { useCallback, useEffect, useState } from "react";

export interface FetchStatusHooks {
  setHasArmed: (newValue: boolean) => void;
  armHook: () => void;
  isArmed: boolean;
}

/**
 * Converts state based fetch status into events corresponding to changing state.
 * Useful to create success callbacks for state changing from fetching to success.
 *
 * @param fetchStatus current fetch status
 * @param onFetching called if fetchStatus transitions into fetching
 * @param onSuccess called if fetchStatus transitions into success
 * @param onFailure called if fetchStatus transitions info failure
 * @param initialTriggeredState
 * @returns setHasArmed function to set triggered state.  Callbacks will only
 *   fire if the hook has been triggered.
 * @returns armHook arm the hook to start triggering callbacks on state change. This
 */
export const useFetchStatusHooks = ({
  fetchStatus,
  onFetching = () => {},
  onSuccess = () => {},
  onFailure = () => {},
  initialArmedState = false,
}: {
  fetchStatus: FetchStatus;
  onFetching?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
  initialArmedState?: boolean;
}): FetchStatusHooks => {
  const [isArmed, setHasArmed] = useState(initialArmedState);
  useEffect(
    () => {
      if (isArmed) {
        if (fetchStatus === FetchStatus.Fetching) {
          onFetching();
        }
        if (fetchStatus === FetchStatus.Success) {
          onSuccess();
        }
        if (fetchStatus === FetchStatus.Failure) {
          onFailure();
        }
      }
    },
    [fetchStatus, onFetching, onSuccess, onFailure, isArmed],
  );
  return {
    setHasArmed: setHasArmed,
    armHook: useCallback(() => setHasArmed(true), [setHasArmed]),
    isArmed,
  };
};
