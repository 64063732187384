import { Patient } from "types/types";

export const normalizePatient = (rawPatient: Object): Patient => {
  const result: {[key: string]: any} = {...rawPatient};
  if (result.lat && result.lon) {
    result.lat = parseFloat(result.lat);
    result.lng = parseFloat(result.lon);
  }
  return result as Patient;
};
