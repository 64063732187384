import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';
import {
  PAYMENT_FAILURE,
  PAYMENT_START,
  PAYMENT_SUCCESS,
} from 'modules/payment/actionTypes';
import { combineReducers } from 'redux';
import { FetchStatus } from 'types/types';
import { Action, handleActions } from 'redux-actions';
import { PaymentFailurePayload } from 'modules/payment/actions';

export interface PaymentState {
  fetchStatus: FetchStatus;
  errorMessage?: string;
}

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [PAYMENT_START],
  successActions: [PAYMENT_SUCCESS],
  failureActions: [PAYMENT_FAILURE],
});

const errorMessage = handleActions<string | undefined, any>(
  {
    [PAYMENT_START]: () => undefined,
    [PAYMENT_SUCCESS]: () => undefined,
    [PAYMENT_FAILURE]: (state, action: Action<PaymentFailurePayload>) =>
      action.payload.message,
  },
  ''
);

export default combineReducers<PaymentState>({
  errorMessage,
  fetchStatus,
});
