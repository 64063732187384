import React from 'react';
import { Box, BoxProps } from 'rebass';
import { CssTypeFix } from 'types/types';

type Props = BoxProps & CssTypeFix;

export const Separator = (props: Props) => (
  <Box
    width="100%"
    marginTop={2}
    marginBottom={2}
    sx={{
      borderTop: '1px solid',
      borderColor: 'muted',
    }}
    {...props}
  />
);
