import React from 'react';
import { Flex, Text } from 'rebass';
import { GREY } from 'theme/colors';
import { OfferStatus } from 'types/types';
import { offerStatusMessages } from 'appConstants';

interface Props {
  offerStatus: OfferStatus;
}

export const OfferMessage = ({ offerStatus }: Props) => {
  const messageStyle = offerStatusMessages[offerStatus];

  if (!messageStyle) {
    return null;
  }

  return (
    <Flex
      padding={2}
      backgroundColor={messageStyle.color}
      marginBottom={2}
      sx={{
        borderRadius: 5,
      }}
    >
      <Text color={GREY}>{messageStyle.text}</Text>
    </Flex>
  );
};
