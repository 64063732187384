import React from 'react';
import { FramedCard } from 'components/FramedCard/FramedCard';
import { PatientFiles } from 'components/PatientFiles/PatientFiles';
import { ValueTextArea } from 'components/ValueTextArea/ValueTextArea';
import { Appointment, Case, Doctor, Lead, Patient } from 'types/types';
import { Flex, Text } from 'rebass';
import { ProcsInfo } from 'components/ProcsInfo';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import { ClickToCopy } from 'components/ClickToCopy/ClickToCopy';
import { getInjury } from 'utils/lead/getInjury';

interface Props {
  appointment: Appointment;
  patient: Patient;
  therapyCase: Case;
  doctor?: Doctor;
  lead: Lead;
}

export const AppointmentInfo = ({
  appointment,
  patient,
  therapyCase,
  doctor,
  lead,
}: Props) => {
  const phoneNumber = formatPhoneNumber(patient.phone);
  return (
    <>
      <ClickToCopy text={phoneNumber}>
        <FramedCard paddingBottom={0}>
          <Flex flexDirection="column" width="100%">
            <Text marginBottom={1} color="grey">
              Phone number (click to copy):
            </Text>
            <Text textAlign="center">{phoneNumber}</Text>
          </Flex>
        </FramedCard>
      </ClickToCopy>
      <FramedCard
        paddingBottom={0}
        innerProps={{
          flexDirection: 'column',
        }}
      >
        <Text paddingBottom={1}>Access Notes:</Text>
        <Text color="grey">{patient.accessNotes}</Text>
      </FramedCard>
      <FramedCard paddingBottom={0}>
        <PatientFiles
          appointment={appointment}
          patient={patient}
          therapyCase={therapyCase}
          doctor={doctor}
          lead={lead}
        />
      </FramedCard>
      <FramedCard
        innerProps={{
          flexDirection: 'column',
        }}
      >
        <ValueTextArea label="Injury/Affected:">
          {getInjury({ lead })}
        </ValueTextArea>
      </FramedCard>
      {therapyCase.procs && (
        <FramedCard
          innerProps={{
            flexDirection: 'column',
          }}
          paddingTop={0}
        >
          <ValueTextArea label="Patient's Note:">
            <ProcsInfo therapyCase={therapyCase} />
          </ValueTextArea>
        </FramedCard>
      )}
    </>
  );
};
