import React from 'react';
import { ListOffer, OfferStatus } from 'types/types';
import { Flex } from 'rebass';
import { OfferListHeader } from 'components/OfferListSection/OfferListHeader';
import { OfferListItem } from 'components/OfferListItem/OfferListItem';

interface Props {
  offerStatus: OfferStatus;
  offers: ListOffer[];
}

export const OfferListSection = ({ offerStatus, offers }: Props) => {
  if (offers.length === 0) {
    return null;
  }

  return (
    <Flex flexDirection="column" paddingBottom={2}>
      <OfferListHeader offerStatus={offerStatus} />
      <Flex flexDirection="column" paddingLeft={2} paddingRight={2}>
        {offers.map(offer => (
          <OfferListItem key={offer.id} offer={offer} />
        ))}
      </Flex>
    </Flex>
  );
};
