import React from 'react';
import { CompositeImageInput } from '../../components/CompositeImageInput/CompositeImageInput';

export const TestPage = () => {
  return (
    <CompositeImageInput
      onSubmit={console.log}
      onCancel={() => console.log('cancelled')}
    />
  );
};
