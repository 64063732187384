import React from 'react';
import { Flex, Text } from 'rebass';

interface Props {
  title: React.ReactNode;
  value: React.ReactNode;
}

export const FieldDisplayer = ({ title, value }: Props) => (
  <Flex flexDirection="column">
    <Text color="grey" fontSize={13} paddingBottom={1}>
      {title}
    </Text>
    <Text>{value}</Text>
  </Flex>
);
