import React from 'react';
import { Flex } from 'rebass';
import { Case, Patient } from 'types/types';
import { getCaseProgressionFraction } from 'utils/case/caseProgression/getCaseProgressionFraction';

interface Props {
  therapyCase: Case;
  patient: Patient;
}

export const InsuranceAndSessions = ({
  patient: { insurance },
  therapyCase,
}: Props) => (
  <Flex justifyContent="space-between" marginBottom={2} width="100%">
    <Flex flexDirection="column">
      {insurance && (
        <>
          <Flex marginBottom={1}>Insurance</Flex>
          <Flex
            sx={{
              color: 'grey',
            }}
          >
            {insurance}
          </Flex>
        </>
      )}
    </Flex>
    <Flex flexDirection="column" alignItems="flex-end">
      <Flex marginBottom={1}>Session</Flex>
      <Flex
        sx={{
          color: 'grey',
        }}
      >
        {getCaseProgressionFraction({ therapyCase })}
      </Flex>
    </Flex>
  </Flex>
);
