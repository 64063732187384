import { CustomEpic } from "types/types";
import { FetchPatientActionPayload, fetchPatientFailure, fetchPatientSuccess } from "modules/patients/actions";
import { ofType } from "redux-observable";
import { FETCH_PATIENT_START } from "modules/patients/actionTypes";
import { switchMap } from "rxjs/operators";
import { getPatientUrl } from "modules/patients/utils/getPatientUrl";
import { getAuthHeaders } from "api/getAuthHeaders";
import { handleAjaxSuccess } from "utils/epics/handleAjaxSuccess";
import { translatePatient } from "modules/patients/utils/translatePatient";
import { handleAjaxError } from "utils/epics/handleAjaxError";

export const fetchPatient$: CustomEpic<FetchPatientActionPayload> = (
  action$,
  state$,
  { getApi$ },
) => action$.pipe(
  ofType(FETCH_PATIENT_START),
  switchMap(
    ({
      payload: {
        patientId,
      },
    }) => getApi$({
      endpoint: getPatientUrl({ patientId }),
      headers: getAuthHeaders(state$.value),
    })
      .pipe(
        handleAjaxSuccess(fetchPatientSuccess, translatePatient),
        handleAjaxError(fetchPatientFailure),
      )
  )
);
