import React from 'react';
import { Patient } from 'types/types';
import { Box, Flex } from 'rebass';

interface Props {
  patient: Patient;
}

export const PatientAddress = ({
  patient: { address, city, state, zipCode },
}: Props) => {
  if (!address) {
    return <Flex alignItems="center">No address</Flex>;
  }

  return (
    <Box>
      {address}
      <br />
      {city}, {state} {zipCode}
    </Box>
  );
};
