import React from 'react';
import { Box, Flex, Text } from 'rebass';

interface Props {
  label: React.ReactNode;
  children: React.ReactNode;
}

export const ValueTextArea = ({ label, children }: Props) => (
  <Flex flexDirection="column">
    <Box>{label}</Box>
    <Text marginTop={1} color="grey">
      {children}
    </Text>
  </Flex>
);
