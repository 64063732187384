import { Moment } from 'moment';

interface Props {
  startTime: Moment;
  duration: number; // in minutes
}

export const formatSessionTime = ({ startTime, duration }: Props): string => {
  const end = startTime.clone().add(duration, 'minutes');
  return `${startTime.format('hh:mm')}-${end.format('hh:mm A')}`;
};
