import React, { useCallback, useEffect } from 'react';
import { Select } from '@rebass/forms';
import { getValidAppointmentSlots } from 'utils/appointment/timeSlots/getValidAppointmentsSlots';
import { timeBlockToString } from 'pages/booking/utils/timeBlockToString';
import { Appointment } from 'types/types';
import { TimeBlock } from 'utils/appointment/timeSlots/constants';

const DEFAULT_DURATION = 30;

export interface Option {
  label: React.ReactNode;
  value: string;
}

export interface Props {
  appointments: Appointment[];
  value: string;
  onChange: (newValue: string) => void;
  setInitialValue?: boolean; // initially set value to first item in list with onChange
  timeToOption?: (time: TimeBlock) => Option;
  duration?: number;
}

const defaultRenderTimeBlock = (time: TimeBlock): Option => {
  const timeString = timeBlockToString(time);
  return {
    label: timeString,
    value: timeString,
  };
};

export const AppointmentSlotPicker = ({
  appointments,
  value,
  onChange,
  setInitialValue = false,
  timeToOption = defaultRenderTimeBlock,
  duration = DEFAULT_DURATION,
}: Props) => {
  const validAppointmentSlots = getValidAppointmentSlots({ duration }).map(
    timeToOption
  );

  useEffect(() => {
    if (setInitialValue) {
      onChange(validAppointmentSlots[0].value);
    }
    // disabling since we only want this triggering once
  }, []); // eslint-disable-line

  const finalOnChange = useCallback(e => onChange(e.target.value), [onChange]);
  return (
    <Select width="100%" value={value} onChange={finalOnChange}>
      {validAppointmentSlots.map(({ label, value }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );
};
