import { CustomEpic } from "types/types";
import { makePaymentFailure, makePaymentSuccess, PaymentPayload } from "modules/payment/actions";
import { ofType } from "redux-observable";
import { PAYMENT_START } from "modules/payment/actionTypes";
import { pipe } from "rxjs/internal-compatibility";
import { mergeMap, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { stripePromise } from "utils/stripePromise";
import { handleError } from "utils/epics/handleError";

export const makePayment$: CustomEpic<PaymentPayload> = (
  action$,
) => action$.pipe(
  ofType(PAYMENT_START),
  // Create an inner observable to protect the epic from errors
  switchMap( (params) => of(params)
    .pipe(
      switchMap(
        async ({
                 payload,
               }) => {
          const stripe = await stripePromise;
          if (!stripe) {
            throw new Error("Stripe not found");
          }
          return {
            payload,
            stripe,
          };
        },
      ),
      mergeMap(
        ({
           payload: {
             paymentIntentSecret,
             paymentMethodId,
           },
           stripe,
         }) => from(stripe.confirmCardPayment(paymentIntentSecret, {
          payment_method: paymentMethodId,
          // Allows us to re-use the card with this customer
          // https://stripe.com/docs/payments/save-during-payment?platform=web#web-submit-payment
          setup_future_usage: 'off_session',
        }))
      ),
      mergeMap(({
                  paymentIntent,
                  error
                }) => {
        if (paymentIntent) {
          return of(makePaymentSuccess({ paymentIntent }));
        }
        return of(makePaymentFailure({
          name: error?.code || 'Payment failed',
          message: error?.message,
        }));
      }),
      pipe(
        handleError(makePaymentFailure),
      ),
    )
  ),

);
