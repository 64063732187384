import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from 'utils/stripePromise';

interface Props {
  children: React.ReactNode;
}

export const StripeProvider = ({ children }: Props) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);
