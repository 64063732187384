import React, { useCallback, useState } from 'react';
import { Flex } from 'rebass';
import moment, { Moment } from 'moment';
import { ExpandedCalendarIcon } from 'components/icons/ExpandedCalendarIcon';
import { ContractedCalendarIcon } from 'components/icons/ContractedCalendarIcon';
import {
  ConnectedDatePicker,
  OwnProps as DatePickerProps,
} from 'containers/ConnectedDatePicker/ConnectedDatePicker';
import { DatePickerBarTitle } from 'components/DatePickerBar/DatePickerBarTitle';

export interface Props {
  onDateClick?: DatePickerProps['onDateClick'];
  onRangeShift?: (date: Moment) => void;
  selectedDate?: Moment;
}

const getWeeksToShow = (isExpanded: boolean) => (isExpanded ? 4 : 1);

const DatePickerBarComponent = ({
  onDateClick,
  onRangeShift = () => {},
  selectedDate,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);
  const weeksToShow = getWeeksToShow(isExpanded);
  const [activeDay, setActiveDay] = useState<Moment>(moment());

  const onPreviousClick = useCallback(() => {
    const newActiveDay = activeDay.clone().subtract(weeksToShow, 'week');
    setActiveDay(newActiveDay);
    onRangeShift(newActiveDay);
  }, [weeksToShow, activeDay, onRangeShift]);
  const onNextClick = useCallback(() => {
    const newActiveDay = activeDay.clone().add(weeksToShow, 'week');
    setActiveDay(newActiveDay);
    onRangeShift(newActiveDay);
  }, [weeksToShow, activeDay, onRangeShift]);

  return (
    <Flex width="100%" flexDirection="column" padding={2}>
      <Flex justifyContent="space-between">
        <DatePickerBarTitle
          activeDay={activeDay}
          weeksToShow={weeksToShow}
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
        />
        <Flex onClick={toggleExpanded}>
          {isExpanded ? (
            <ExpandedCalendarIcon size={25} />
          ) : (
            <ContractedCalendarIcon size={25} />
          )}
        </Flex>
      </Flex>
      <Flex>
        <ConnectedDatePicker
          weeksToShow={weeksToShow}
          onDateClick={onDateClick}
          startDate={activeDay}
          selectedDate={selectedDate}
        />
      </Flex>
    </Flex>
  );
};

export const DatePickerBar = DatePickerBarComponent;
