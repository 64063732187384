import { CustomEpic } from 'types/types';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { getAuthHeaders } from '../../../api/getAuthHeaders';
import { handleAjaxSuccess } from '../../../utils/epics/handleAjaxSuccess';
import { handleAjaxError } from '../../../utils/epics/handleAjaxError';
import { getFetchAppFilesEndpoint } from '../constants';
import {
  FetchAppFilesActionPayload,
  fetchAppFilesFailure,
  fetchAppFilesSuccess,
} from '../actions';
import { FETCH_APP_FILES_START } from '../actionTypes';
import { getTranslateFetchAppFiles } from '../translator';

export const fetchAppFiles$: CustomEpic<FetchAppFilesActionPayload> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(FETCH_APP_FILES_START),
    switchMap(({ payload: { caseId } }) =>
      getApi$({
        endpoint: getFetchAppFilesEndpoint({ caseId }),
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(
          fetchAppFilesSuccess,
          getTranslateFetchAppFiles({ caseId })
        ),
        handleAjaxError(fetchAppFilesFailure)
      )
    )
  );
