import { combineReducers } from 'redux';
import { Action, handleActions } from 'redux-actions';
import { Appointment, AppointmentStatusName, FetchStatus } from 'types/types';
import { GetScheduleSuccessActionPayload } from 'modules/schedule/actions';
import {
  GET_SCHEDULE,
  GET_SCHEDULE_FAILURE,
  GET_SCHEDULE_SUCCESS,
} from 'modules/schedule/actionTypes';
import {
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILURE,
  FETCH_APPOINTMENTS_SUCCESS,
  HOME_HEALTH_APPOINTMENT_CANCEL_SUCCESS,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_SUCCESS,
} from 'modules/appointments/actionTypes';
import {
  FetchAppointmentsSuccessActionPayload,
  UpdateAppointmentSuccessActionPayload,
} from 'modules/appointments/actions';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';

export interface AppointmentState {
  normalized: Record<string, Appointment>;
  fetchStatus: FetchStatus;
}

export const normalized = handleActions<AppointmentState['normalized'], any>(
  {
    [GET_SCHEDULE_SUCCESS]: (
      state,
      action: Action<GetScheduleSuccessActionPayload>
    ) => ({
      ...state,
      ...action.payload.appointments,
    }),
    // FOR DEVELOPMENT
    INIT_CURRENT_SESSION: (state, action: Action<Record<string, any>>) => {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          appStatus: AppointmentStatusName.Scheduled,
          faked: AppointmentStatusName.Scheduled,
        },
      };
    },
    START_CURRENT_SESSION: (state, action: Action<Record<string, any>>) => {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          appStatus: AppointmentStatusName.Arrived,
          faked: AppointmentStatusName.Arrived,
        },
      };
    },
    [HOME_HEALTH_APPOINTMENT_CANCEL_SUCCESS]: (state, action) => {
      const newState = { ...state };
      delete newState[action.payload.appointmentId];
      return newState;
    },
    // END FOR DEVELOPMENT
    [UPDATE_APPOINTMENT_SUCCESS]: (
      state,
      action: Action<UpdateAppointmentSuccessActionPayload>
    ) => ({
      ...state,
      [action.payload.id]: action.payload,
    }),
    [FETCH_APPOINTMENTS_SUCCESS]: (
      state,
      action: Action<FetchAppointmentsSuccessActionPayload>
    ) => ({
      ...state,
      ...action.payload.reduce((currentResult, appointment) => {
        currentResult[String(appointment.id)] = appointment;
        return currentResult;
      }, {} as Record<string, Appointment>),
    }),
  },
  {}
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [GET_SCHEDULE, UPDATE_APPOINTMENT, FETCH_APPOINTMENTS],
  successActions: [
    GET_SCHEDULE_SUCCESS,
    UPDATE_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENTS_SUCCESS,
  ],
  failureActions: [
    GET_SCHEDULE_FAILURE,
    UPDATE_APPOINTMENT_FAILURE,
    FETCH_APPOINTMENTS_FAILURE,
  ],
});

export default combineReducers<AppointmentState>({
  normalized,
  fetchStatus,
});
