import { Insurance, Patient } from "types/types";

interface Props {
  insurances: Insurance[];
  patient?: Patient;
}

interface Output {
  insuranceType?: string;
  error?: string;
}

export const getAppointmentServiceTypeFromPatient = ({
  insurances,
  patient,
}: Props): Output => {
  if (!patient) {
    return { insuranceType: undefined };
  }
  const insuranceName = patient.insurance;

  if (!insuranceName) {
    return {
      insuranceType: undefined,
      error: `Couldn't find insurance name`,
    };
  }
  const insurance = insurances.find(insurance => insurance.name === insuranceName);

  if (!insurance) {
    return {
      insuranceType: undefined,
      error: `Insurance for ${insuranceName} not found`,
    };
  }
  const insuranceType = insurance.insType;
  return { insuranceType };
};
