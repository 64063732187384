import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Flex, Text } from 'rebass';
import { FooterBar } from 'components/FooterBar';
import { TherapistSummaryCard } from 'containers/TherapistSummaryCard/TherapistSummaryCard';
import { ButtonList } from 'components/ButtonList/ButtonList';
import { logout as logoutAction } from 'modules/auth/actions';
import { TherapistStatsCard } from 'containers/TherapistStatsCard/TherapistStatsCard';
import { ArrowForwardIcon } from 'components/icons/ArrowForwardIcon';
import { useGoTo } from 'utils/hooks/useGoTo';
import { PATIENT_REFERRAL_PATH } from 'routing/routePaths';
import { getIsPatientReferralEnabled } from 'selectors/features/getIsPatientReferralEnabled';

interface OwnProps {}

interface StateProps {}

interface DispatchProps {
  logout: typeof logoutAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: GlobalState): StateProps => ({});

const mapDispatchToProps: DispatchProps = {
  logout: logoutAction,
};

const MorePageComponent = ({ logout }: Props) => {
  const goToPatientReferral = useGoTo(PATIENT_REFERRAL_PATH);
  const isPatientReferralEnabled = useSelector(getIsPatientReferralEnabled);

  const buttons = useMemo(() => {
    const defaultButtonList = [
      {
        key: 'logout',
        value: null,
        label: (
          <Text color="orange" fontWeight="bold">
            Log Out
          </Text>
        ),
        onClick: logout,
      },
    ];
    if (isPatientReferralEnabled) {
      return [
        {
          key: 'referral',
          value: null,
          label: (
            <Flex
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text>Patient Referral</Text>
              <ArrowForwardIcon size={30} />
            </Flex>
          ),
          onClick: goToPatientReferral,
        },
        ...defaultButtonList,
      ];
    }
    return defaultButtonList;
  }, [logout, goToPatientReferral, isPatientReferralEnabled]);
  return (
    <Flex width="100%" flexDirection="column">
      <TherapistSummaryCard />
      <TherapistStatsCard />
      <ButtonList buttons={buttons} />
      <Flex flex={1} />
      <FooterBar />
    </Flex>
  );
};

export const MorePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MorePageComponent);
