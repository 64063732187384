import { CustomEpic } from "types/types";
import {
  FetchCurrentPatientsActionPayload,
  fetchCurrentPatientsFailure,
  fetchCurrentPatientsSuccess
} from "modules/patients/actions";
import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { getFetchCurrentPatientsUrl } from "modules/patients/utils/getFetchCurrentPatientsUrl";
import { getId } from "selectors/user/getId";
import { getAuthHeaders } from "api/getAuthHeaders";
import { handleAjaxSuccess } from "utils/epics/handleAjaxSuccess";
import { translatePatientList } from "modules/patients/utils/translatePatientList";
import { handleAjaxError } from "utils/epics/handleAjaxError";
import { FETCH_CURRENT_PATIENTS_START } from "modules/patients/actionTypes";

export const fetchCurrentPatients$: CustomEpic<FetchCurrentPatientsActionPayload> = (
  action$,
  state$,
  { getApi$ },
) => action$.pipe(
  ofType(FETCH_CURRENT_PATIENTS_START),
  switchMap(
    ({
      payload: {
        therapistId,
      },
    }) => getApi$({
      endpoint: getFetchCurrentPatientsUrl({ therapistId: therapistId || getId(state$.value) }),
      headers: getAuthHeaders(state$.value),
    })
      .pipe(
        handleAjaxSuccess(fetchCurrentPatientsSuccess, translatePatientList),
        handleAjaxError(fetchCurrentPatientsFailure),
      )
  )
);
