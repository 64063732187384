import React from 'react';
import { CardBrand } from 'types/types';
import { IconProps } from 'components/icons/types';
import { cardIcons } from 'appConstants';

interface Props extends IconProps {
  cardBrand?: CardBrand;
}

export const CreditCardIcon = ({
  cardBrand = CardBrand.Unknown,
  ...rest
}: Props) => {
  const CardIcon = cardIcons[cardBrand] || cardIcons[CardBrand.Unknown];
  return <CardIcon {...rest} />;
};
