import React from 'react';
import { Button, Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';

type Props = FlexProps &
  CssTypeFix & {
    onAccept: () => void;
    acceptLabel?: string;
    onCancel: () => void;
    cancelLabel?: string;
    children?: React.ReactNode;
  };

export const Confirmation = ({
  onAccept,
  acceptLabel = 'Confirm',
  onCancel,
  cancelLabel = 'Cancel',
  children = null,
  ...restProps
}: Props) => {
  return (
    <Flex flexDirection="column" padding={3} {...restProps}>
      {children}
      <Flex width="100%" marginTop={3}>
        <Button variant="secondary" marginRight={2} onClick={onCancel} flex={1}>
          {cancelLabel}
        </Button>
        <Button variant="primary" marginLeft={2} onClick={onAccept} flex={1}>
          {acceptLabel}
        </Button>
      </Flex>
    </Flex>
  );
};
