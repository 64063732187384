import React from 'react';
import { FloatingCardLink } from 'components/FloatingCard/FloatingCardLink';
import { Flex, Text } from 'rebass';
import { DefaultUserAvatar } from 'components/icons/DefaultUserAvatar';
import { Avatar } from 'components/Avatar/Avatar';
import { getListOfferPatientName } from 'utils/offer/getListOfferPatientName';
import { DARK_BLUE } from 'theme/colors';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { getOfferPath } from 'routing/routeFunctions';
import { ListOffer } from 'types/types';

interface Props {
  offer: ListOffer;
}

export const OfferListItem = ({ offer }: Props) => {
  return (
    <FloatingCardLink to={getOfferPath({ offerId: String(offer.id) })}>
      <Flex margin={1}>
        <Avatar size={50}>
          <DefaultUserAvatar size={45} />
        </Avatar>
      </Flex>
      <Flex flex={1} flexDirection="column" marginLeft={2}>
        <Text fontWeight="bold" color={DARK_BLUE}>
          {getListOfferPatientName({ offer })}
        </Text>
        <Flex flex={1} />
        <Flex justifyContent="flex-end">
          <CustomTextLink>View Details</CustomTextLink>
        </Flex>
      </Flex>
    </FloatingCardLink>
  );
};
