import React, { useCallback } from 'react';
import { Box, Flex, Text } from 'rebass';
import { Appointment, Case, FetchStatus } from 'types/types';
import { Separator } from 'components/Separator';
import _ from 'lodash';
import { appointmentMoment } from 'utils/appointment/appointmentMoment';
import { getAppointmentCaseProgression } from 'utils/case/caseProgression/getAppointmentCaseProgression';

interface Props {
  therapyCase: Case;
  fetchStatus: FetchStatus;
  appointments: Appointment[];
}

const PaddedBox = ({ children }: { children: React.ReactNode }) => (
  <Flex padding={3}>{children}</Flex>
);

export const CaseAppointmentsList = ({ appointments, therapyCase }: Props) => {
  const renderListItem = useCallback(
    (appointment: Appointment) => {
      const { casesDone, casesLeft } = getAppointmentCaseProgression({
        therapyCase,
        appointment,
      });
      const parsedAppointment = appointmentMoment(appointment);
      return [
        <Box key={appointment.id} paddingLeft={3} paddingRight={3}>
          <Flex justifyContent="space-between">
            {parsedAppointment.format('ddd, MMM D, YYYY')}
            <Flex
              flexDirection="column"
              sx={{
                minWidth: 120,
              }}
            >
              <Text fontWeight="bold">
                {Number(casesDone)} out of {casesLeft}
              </Text>
              <Text color="grey">
                {`${parsedAppointment.format('h:mm')}-${parsedAppointment
                  .clone()
                  .add(appointment.duration, 'minutes')
                  .format('h:mm A')}`}
              </Text>
            </Flex>
          </Flex>
        </Box>,
        <Separator key={`separator-${appointment.id}`} />,
      ];
    },
    [therapyCase]
  );

  return (
    <Flex width="100%" flexDirection="column">
      <PaddedBox>
        <Text fontWeight="bold" marginRight={1}>
          {appointments.length}
        </Text>
        Scheduled Sessions
      </PaddedBox>
      <Box paddingBottom={2}>
        {_.flatten(appointments.map(renderListItem)).slice(0, -1)}
      </Box>
    </Flex>
  );
};
