import React from 'react';
import { Box, Text } from 'rebass';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { useGoBack } from 'utils/hooks/useGoBack';

export const AppointmentNotFoundPage = () => {
  const goBack = useGoBack();
  return (
    <Text>
      Appointment not found.
      <Box
        paddingLeft={1}
        paddingRight={1}
        sx={{
          display: 'inline',
        }}
      >
        <CustomTextLink onClick={goBack} isInline>
          Go back
        </CustomTextLink>
      </Box>
      and try again
    </Text>
  );
};
