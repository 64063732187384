import React from 'react';
import { NavBar } from 'components/NavBar/NavBar';
import { useRouteMatch } from 'react-router-dom';
import { useNavItems } from 'components/MainNav/hooks/useNavItems';

export const MainNav = () => {
  const match = useRouteMatch();
  const items = useNavItems();

  return (
    <NavBar
      items={items.map(item => ({
        ...item,
        ...(match.path === item.route ? { isSelected: true } : {}),
      }))}
    />
  );
};
