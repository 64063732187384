import React from 'react';
import { Case, Proc } from 'types/types';
import { ProcInfo } from 'components/ProcInfo';

interface Props {
  therapyCase: Case;
}

const renderProc = (proc: Proc) => <ProcInfo key={proc.name} proc={proc} />;

export const ProcsInfo = ({ therapyCase: { procs } }: Props) => {
  if (!procs) {
    return null;
  }
  return <>{procs.map(renderProc)}</>;
};
