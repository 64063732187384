import React from 'react';
import { Therapist } from 'types/types';
import { Text } from 'rebass';

interface Props {
  therapist: Therapist;
}

export const TherapistName = ({
  therapist: { firstName, lastName, ptPta },
}: Props) => (
  <Text fontWeight="bold">
    {firstName} {lastName}, {ptPta}
  </Text>
);
