import { CustomEpic } from 'types/types';
import {
  FetchTherapistActionPayload,
  fetchTherapistFailure,
  fetchTherapistSuccess,
} from '../actions';
import { ofType } from 'redux-observable';
import { FETCH_THERAPIST } from '../actionTypes';
import * as Sentry from '@sentry/react';
import { switchMap } from 'rxjs/operators';
import { getTherapistEndpoint } from '../utils/getTherapistEndpoint';
import { getId } from 'selectors/user/getId';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { translateTherapist } from 'modules/therapist/utils/translateTherapist';

export const fetchTherapist$: CustomEpic<FetchTherapistActionPayload> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(FETCH_THERAPIST),
    switchMap(({ payload: { therapistId } }) =>
      getApi$({
        endpoint: getTherapistEndpoint({
          therapistId: therapistId || getId(state$.value),
        }),
        payload: {},
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(fetchTherapistSuccess, therapist => {
          Sentry.setUser({
            id: therapist.id,
            name: `${therapist.first_name} ${therapist.last_name}`,
            employment_type: therapist.employment_type,
            email: therapist.email || therapist.company_email,
          });
          return translateTherapist(therapist);
        }),
        handleAjaxError(fetchTherapistFailure)
      )
    )
  );
