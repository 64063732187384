import React from 'react';
import { Flex } from 'rebass';
import { AppointmentService } from 'types/types';
import { PatientInfoFrame } from 'components/PatientInfoFrame/PatientInfoFrame';

interface Props {
  firstName?: string;
  lastName?: string;
  service?: AppointmentService;
}

export const PatientInfo = ({ firstName, lastName, service }: Props) => (
  <PatientInfoFrame firstName={firstName} lastName={lastName}>
    <Flex
      sx={{
        color: 'grey',
      }}
    >
      {service}
    </Flex>
  </PatientInfoFrame>
);
