import { createAction } from 'redux-actions';
import { Patient } from 'types/types';
import {
  FETCH_ALL_PATIENTS_FAILURE,
  FETCH_ALL_PATIENTS_START,
  FETCH_ALL_PATIENTS_SUCCESS,
  FETCH_CURRENT_PATIENTS_FAILURE,
  FETCH_CURRENT_PATIENTS_START,
  FETCH_CURRENT_PATIENTS_SUCCESS,
  FETCH_PATIENT_FAILURE,
  FETCH_PATIENT_START,
  FETCH_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAILURE,
  UPDATE_PATIENT_START,
  UPDATE_PATIENT_SUCCESS,
} from 'modules/patients/actionTypes';

export interface FetchCurrentPatientsActionPayload {
  therapistId?: string;
}
export const fetchCurrentPatients = createAction<
  FetchCurrentPatientsActionPayload
>(FETCH_CURRENT_PATIENTS_START);

export type FetchCurrentPatientsSuccessPayload = Patient[];
export const fetchCurrentPatientsSuccess = createAction<
  FetchCurrentPatientsSuccessPayload
>(FETCH_CURRENT_PATIENTS_SUCCESS);

export const fetchCurrentPatientsFailure = createAction(
  FETCH_CURRENT_PATIENTS_FAILURE
);

export interface FetchPatientActionPayload {
  patientId: string;
}
export const fetchPatient = createAction<FetchPatientActionPayload>(
  FETCH_PATIENT_START
);

export type FetchPatientSuccessPayload = Patient;
export const fetchPatientSuccess = createAction<FetchPatientSuccessPayload>(
  FETCH_PATIENT_SUCCESS
);

export const fetchPatientFailure = createAction(FETCH_PATIENT_FAILURE);

export interface UpdatePatientActionPayload {
  patientId: string;
  cardBrand?: string;
  cardLast4?: string;
  paymentMethodId?: string; // patient.card_id is set only on the backend, so we set this value instead
}
export const updatePatient = createAction<UpdatePatientActionPayload>(
  UPDATE_PATIENT_START
);

export interface UpdatePatientPaymentMethodPayload {
  patientId: string;
  paymentMethod: {
    id: string;
    card?: {
      brand: string;
      last4: string;
    };
  };
}
export const updatePatientPaymentMethod = ({
  patientId,
  paymentMethod,
}: UpdatePatientPaymentMethodPayload) =>
  updatePatient({
    patientId,
    paymentMethodId: paymentMethod.id,
    cardBrand: paymentMethod.card?.brand,
    cardLast4: paymentMethod.card?.last4,
  });

export type UpdatePatientSuccessPayload = Patient;
export const updatePatientSuccess = createAction<UpdatePatientSuccessPayload>(
  UPDATE_PATIENT_SUCCESS
);

export const updatePatientFailure = createAction(UPDATE_PATIENT_FAILURE);

export interface FetchAllPatientsActionPayload {
  activeCasesOnly?: boolean;
}

export const fetchAllPatients = createAction<FetchAllPatientsActionPayload>(
  FETCH_ALL_PATIENTS_START
);
export type FetchAllPatientsSuccessPayload = Patient[];
export const fetchAllPatientsSuccess = createAction<
  FetchAllPatientsSuccessPayload
>(FETCH_ALL_PATIENTS_SUCCESS);
export const fetchAllPatientsFailure = createAction(FETCH_ALL_PATIENTS_FAILURE);
