import {createSelector} from "reselect";
import {getPatients} from "./getPatients";
import {GlobalState} from "reducers";
import { Patient } from "types/types";

export const getPatient = createSelector(
  getPatients,
  (_: GlobalState, { patientId }: {patientId: number | string}) => patientId,
  (patients, patientId): Patient | undefined => patients[patientId],
);
