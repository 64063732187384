import { AppFileType } from 'utils/file/constants';
import { GlobalState } from '../../reducers';

export const createGetIsFileTypePendingApproval = (
  caseId: string,
  appFileType: AppFileType
) => (state: GlobalState) => {
  const fileTypesPendingApproval = state.files.pendingApprovals[caseId] || [];
  return fileTypesPendingApproval.some(fileType => fileType === appFileType);
};
