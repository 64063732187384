import React from 'react';
import { Label } from '@rebass/forms';
import { Box } from 'rebass';

interface Props {
  label: React.ReactNode;
  input: React.ReactNode;
}

export const LabelledComponent = ({ input, label }: Props) => (
  <Label alignItems="center">
    <Box
      // necessary for proper spacing on narrower screens
      minWidth={28}
      maxWidth={28}
    >
      {input}
    </Box>
    <Box
      sx={{
        flexShrink: 1, // for safari
      }}
    >
      {label}
    </Box>
  </Label>
);
