import React, { MouseEventHandler, SyntheticEvent } from 'react';
import { LocationDescriptorObject } from 'history';
import { Box, BoxProps } from 'rebass';
import { CssTypeFix } from 'types/types';
import { RouteComponentProps, withRouter } from 'react-router';

export type OnClick = (e: SyntheticEvent) => void;
export type To = LocationDescriptorObject<any> | string;
export type ParentProps = {
  children: React.ReactNode;
  // must define at least one of these
  onClick?: OnClick;
  to?: To;
} & BoxProps &
  CssTypeFix;
export type Props = ParentProps & RouteComponentProps;
const CustomLinkComponent = ({
  children,
  history,
  onClick,
  to,
  ...restProps
}: Props) => {
  const actionProps: { onClick?: MouseEventHandler } = {};
  if (onClick) {
    actionProps.onClick = onClick;
  }
  if (to) {
    // history.push incorrectly doesn't accept strings
    actionProps.onClick = () => history.push(to as LocationDescriptorObject);
  }
  return (
    <Box {...restProps} {...actionProps}>
      {children}
    </Box>
  );
};

export const CustomLink = withRouter<Props, typeof CustomLinkComponent>(
  CustomLinkComponent
);
