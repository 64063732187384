import { getPossibleTimes } from "utils/appointment/timeSlots/getPossibleTimes";
import { APPOINTMENT_TIME_INTERVAL, MAX_APPOINTMENT_TIME, MIN_APPOINTMENT_TIME } from "pages/booking/constants";
import { parseTime } from "pages/booking/utils/parseTime";
import { TimeBlock } from "utils/appointment/timeSlots/constants";

interface Props {
  duration: number;
}


export const getValidAppointmentSlots = ({ duration }: Props): TimeBlock[] => {
  const allPossibleAppointmentTimes = getPossibleTimes({
    minTime: MIN_APPOINTMENT_TIME,
    maxTime: MAX_APPOINTMENT_TIME,
    interval: APPOINTMENT_TIME_INTERVAL,
    duration: duration,
  });

  return allPossibleAppointmentTimes.map(time => parseTime(time, duration)).filter(value => !!value);
}
