import { flatMap } from 'rxjs/operators';
import { ActionFunction1 } from 'redux-actions';
import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';

export const handleAjaxSuccess = (
  successActionCreator: ActionFunction1<any, any>,
  translator?: (arg0: any) => any,
  toastIdToReuseOrDismiss?: string,
  toastMessage?: string
) =>
  flatMap((response: AxiosResponse) => {
    const result = translator ? translator(response?.data) : response?.data;
    if (toastMessage) {
      toast.success(toastMessage, { id: toastIdToReuseOrDismiss });
    } else if (toastIdToReuseOrDismiss) {
      toast.dismiss(toastIdToReuseOrDismiss);
    }
    if (result.result === 'fail') {
      throw new Error(result.message);
    }
    return [successActionCreator(result)];
  });
