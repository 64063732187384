import React from 'react';
import { Flex } from 'rebass';

export enum SectionHeaderVariant {
  Primary = 'styles.reschedulePage.primarySectionHeader',
  Secondary = 'styles.reschedulePage.secondarySectionHeader',
}

interface Props {
  children?: React.ReactNode;
  variant?: SectionHeaderVariant;
}

export const SectionHeader = ({
  children,
  variant = SectionHeaderVariant.Primary,
}: Props) => (
  <Flex
    height={36}
    minHeight={36}
    paddingLeft={3}
    paddingRight={3}
    alignItems="center"
    variant={variant}
  >
    {children}
  </Flex>
);
