import React from 'react';
import { Flex } from 'rebass';
import { FooterBar } from 'components/FooterBar';
import { HeaderBar } from 'components/HeaderBar';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { OffersCard } from 'pages/communication/OffersCard';

export const CommunicationPage = () => {
  return (
    <Flex width="100%" flexDirection="column">
      <HeaderBar title="Communication" />
      <ScrollBox marginTop={2}>
        <OffersCard />
      </ScrollBox>
      <Flex flex={1} />
      <FooterBar />
    </Flex>
  );
};
