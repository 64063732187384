import React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from 'rebass';
import { getUser } from 'selectors/user/getUser';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { USER_DETAILS_PATH } from 'routing/routePaths';
import { DefaultTherapistAvatar } from 'components/DefaultTherapistAvatar/DefaultTherapistAvatar';
import { TherapistName } from 'components/TherapistName/TherapistName';
import { TherapistEmail } from 'components/TherapistName/TherapistEmail';
import { BoldDisplayer } from 'components/BoldDisplayer/BoldDisplayer';
import { TherapistEmploymentType } from 'components/TherapistEmploymentType/TherapistEmploymentType';

export const TherapistSummaryCard = () => {
  const user = useSelector(getUser);
  const { thisWeeksGoal, cxlRate, thisMonthsVisits, thisWeeksVisits } = user;
  return (
    <Flex
      padding={3}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'muted',
      }}
    >
      <DefaultTherapistAvatar />
      <Flex flexDirection="column" flex={1} paddingLeft={2}>
        <TherapistName therapist={user} />
        <TherapistEmail therapist={user}></TherapistEmail>
        {thisWeeksVisits != null && (
          <BoldDisplayer
            boldText={thisWeeksVisits}
            postText="Sessions this week"
          />
        )}
        {thisMonthsVisits != null && (
          <BoldDisplayer
            boldText={thisMonthsVisits}
            postText="Sessions this month"
          />
        )}
        {cxlRate != null && (
          <BoldDisplayer
            boldText={`${cxlRate}%`}
            postText="Therapist cancel rate"
          />
        )}
        <BoldDisplayer
          preText="Goal this week is"
          boldText={thisWeeksGoal}
          postText="sessions"
        />
        <BoldDisplayer
          preText="Employment type:"
          boldText={
            <TherapistEmploymentType employmentType={user.employmentType} />
          }
        />
      </Flex>
      <CustomTextLink to={USER_DETAILS_PATH}>View</CustomTextLink>
    </Flex>
  );
};
