import React from 'react';
import { FormikProps } from 'formik';
import { Flex } from 'rebass';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { FormValues } from 'pages/appointment/AppointmentCancellationPage/AppointmentCancellationPage';
import { AppointmentStatusName } from 'types/types';
import { LabelledRadioFieldGroup } from 'components/forms/LabelledRadioFieldGroup';

const reasons = [
  {
    label: 'Cancelled with notice',
    value: AppointmentStatusName.CancelledWithNotice,
  },
  {
    label: 'Cancelled without notice',
    value: AppointmentStatusName.CancelledWithoutNotice,
  },
  {
    label: 'Cancelled fee waived',
    value: AppointmentStatusName.CancelledFeeWaived,
  },
  {
    label: 'Cancelled by PT',
    value: AppointmentStatusName.CancelledByPT,
  },
  {
    label: 'No Show',
    value: AppointmentStatusName.NoShow,
  },
];

interface Props extends FormikProps<FormValues> {
  disabled?: boolean;
}

export const CancellationForm = ({
  handleSubmit,
  isValid,
  disabled = false,
}: Props) => {
  return (
    <Flex
      flexDirection="column"
      as="form"
      // @ts-ignore
      onSubmit={handleSubmit}
      flex={1}
    >
      <LabelledRadioFieldGroup
        headerText="Please select the reason(s):"
        options={reasons}
        fieldName="reason"
      />
      <Flex flex={1} />
      <FooterButton
        isSubmit
        label="Cancel Appointment"
        disabled={!isValid || disabled}
      />
    </Flex>
  );
};
