// https://app.zeplin.io/project/5e0510850d97c818513015b3/screen/5e0c0df797449919cc99aada
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Flex } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { CloseIcon } from 'components/icons/CloseIcon';
import { Formik, FormikProps } from 'formik';
import { PaymentMethod } from 'pages/payment/types';
import { PaymentForm } from 'pages/payment/PaymentForm/PaymentForm';
import * as Yup from 'yup';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { getPatient } from 'selectors/patients/getPatient';
import { Appointment, Case, Patient } from 'types/types';
import { useHistory } from 'react-router-dom';
import { getTotalAppointmentCost } from 'utils/appointment/getTotalAppointmentCost';
import { AppointmentNotFoundPage } from 'pages/appointmentNotFound/AppointmentNotFoundPage';
import { getPatientCardInfo } from 'utils/patient/getPatientCardInfo';
import { getCase } from '../../selectors/cases/getCase';

export interface FormValues {
  paymentMethod: PaymentMethod | null;
  cashCheckValue: string;
  isCreditCardValid: boolean;
}

interface OwnProps {
  appointmentId: string;
  onSubmit: (values: FormValues) => void;
}

interface StateProps {
  patient?: Patient;
  appointment?: Appointment;
  therapyCase?: Case;
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const validationSchema = Yup.object().shape({
  paymentMethod: Yup.mixed()
    .required()
    .oneOf(Object.values(PaymentMethod)),
  cashCheckValue: Yup.string().when('paymentMethod', {
    is: PaymentMethod.CashOrCheck,
    then: Yup.string()
      .required()
      .matches(/^\d+(\.\d\d)?$/, 'Field incorrectly formatted'),
  }),
  isCreditCardValid: Yup.boolean().when('paymentMethod', {
    is: PaymentMethod.CreditOrDebit,
    then: Yup.boolean().oneOf([true]),
  }),
});

const defaultInitialValues: FormValues = {
  paymentMethod: PaymentMethod.CreditOrDebit,
  cashCheckValue: '',
  isCreditCardValid: false,
};

const mapStateToProps = (
  state: GlobalState,
  { appointmentId }: OwnProps
): StateProps => {
  const appointment = getAppointment(state, { appointmentId });
  return {
    appointment,
    patient:
      appointment && getPatient(state, { patientId: appointment.patientId }),
    therapyCase:
      appointment && getCase(state, { caseId: String(appointment?.caseId) }),
  };
};

const mapDispatchToProps: DispatchProps = {};

const PaymentMethodComponent = ({
  therapyCase,
  patient,
  onSubmit,
  appointment,
}: Props) => {
  const history = useHistory();
  const goBack = useCallback(() => history.goBack(), [history]);

  const renderForm = useCallback(
    (props: FormikProps<FormValues>) => {
      if (therapyCase && patient && appointment) {
        return (
          <PaymentForm
            therapyCase={therapyCase}
            patient={patient}
            appointment={appointment}
            {...props}
          />
        );
      }
      return null;
    },
    [therapyCase, patient, appointment]
  );

  if (!appointment) {
    return <AppointmentNotFoundPage />;
  }

  const initialValues: FormValues = {
    ...defaultInitialValues,
    ...(appointment
      ? {
          cashCheckValue: String(getTotalAppointmentCost(appointment)),
        }
      : {}),
    isCreditCardValid:
      !!patient && !!getPatientCardInfo({ patient }).paymentSourceId,
  };

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Payment"
        right={<CloseIcon size={20} onClick={goBack} />}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
        patient={patient}
        enableReinitialize
      >
        {renderForm}
      </Formik>
    </Flex>
  );
};

export const PaymentMethodPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMethodComponent);
