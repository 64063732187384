import {CustomEpic} from "types/types";
import {ofType} from "redux-observable";
import {FETCH_APPOINTMENT_SERVICE_TYPES} from "../actionTypes";
import {switchMap} from "rxjs/operators";
import {appointmentServiceTypesEndpoint} from "../constants";
import {getAuthHeaders} from "api/getAuthHeaders";
import {handleAjaxSuccess} from "utils/epics/handleAjaxSuccess";
import {fetchAppointmentServiceTypesFailure, fetchAppointmentServiceTypesSuccess} from "../actions";
import {handleAjaxError} from "utils/epics/handleAjaxError";
import { translateFetchAppointmentServiceTypes } from "modules/appointmentServiceTypes/utils/translateFetchAppointmentServiceTypes";

export const fetchAppointmentServiceTypes: CustomEpic<void> = (
  action$,
  state$,
  { getApi$ },
) => action$.pipe(
  ofType(FETCH_APPOINTMENT_SERVICE_TYPES),
  switchMap(
    () => getApi$({
      endpoint: appointmentServiceTypesEndpoint,
      headers: getAuthHeaders(state$.value),
    })
      .pipe(
        handleAjaxSuccess(fetchAppointmentServiceTypesSuccess, translateFetchAppointmentServiceTypes),
        handleAjaxError(fetchAppointmentServiceTypesFailure),
      )
  )
);
