import { NavItemProps } from 'types/types';
import { BookingIcon } from 'components/icons/BookingIcon';
import {
  BOOKING_PATH,
  COMMUNICATION_PATH,
  MESSAGES_PATH,
  SCHEDULE_PATH,
  MORE_PATH,
} from 'routing/routePaths';
import React from 'react';
import { ScheduleIcon } from 'components/icons/ScheduleIcon';
import { MessageIcon } from 'components/icons/MessageIcon';
import { MenuIcon } from 'components/icons/MenuIcon';

const BOOKING_NAV: NavItemProps = {
  icon: <BookingIcon size="30px" />,
  label: 'Booking',
  route: BOOKING_PATH,
};
const SCHEDULE_NAV: NavItemProps = {
  icon: <ScheduleIcon size="30px" />,
  label: 'Schedule',
  route: SCHEDULE_PATH,
};
const MESSAGE_NAV: NavItemProps = {
  icon: <MessageIcon size="30px" />,
  label: 'Message',
  route: MESSAGES_PATH,
  isDisabled: true,
};
const COMMUNICATION_NAV: NavItemProps = {
  icon: <MessageIcon size="30px" />,
  label: 'Communication',
  route: COMMUNICATION_PATH,
};
const MENU_NAV: NavItemProps = {
  icon: <MenuIcon size="30px" />,
  label: 'More',
  route: MORE_PATH,
};

export const REGULAR_NAVBAR: NavItemProps[] = [
  BOOKING_NAV,
  SCHEDULE_NAV,
  MESSAGE_NAV,
  MENU_NAV,
];

export const OFFER_NAVBAR: NavItemProps[] = [
  BOOKING_NAV,
  SCHEDULE_NAV,
  COMMUNICATION_NAV,
  MENU_NAV,
];
