import React, { useCallback, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { FormValues as PaymentMethodFormValues, PaymentMethodPage } from "pages/payment/PaymentMethod";
import { PaymentReviewPage } from "pages/paymentReview/PaymentReviewPage";
import { PaymentMethod } from "pages/payment/types";
import { AppointmentReschedulePathParams } from "routing/routePaths";
import { TriggerCardPayment } from "pages/payment/TriggerCardPayment";
import { TriggerAppointmentPaymentUpdate } from "pages/payment/TriggerAppointmentPaymentUpdate";
import { getPaymentSuccessPath } from "routing/routeFunctions";

const paymentReviewRoute = 'review';
const paymentTriggerRoute = 'makePayment';
const appointmentUpdateTriggerRoute = 'updateAppointment';

export const PaymentPage = () => {
  const { path, url, params: { appointmentId } } = useRouteMatch<AppointmentReschedulePathParams>();
  const { push } = useHistory();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [totalAmount, setTotalAmount] = useState<string | null>('0');

  const paymentMethodPath = `${path}`;
  const paymentReviewPath = `${path}/${paymentReviewRoute}`;
  const paymentReviewUrl = `${url}/${paymentReviewRoute}`;
  const paymentTriggerPath = `${path}/${paymentTriggerRoute}`;
  const paymentTriggerUrl = `${url}/${paymentTriggerRoute}`;
  const appointmentPaymentUpdateTriggerPath = `${path}/${appointmentUpdateTriggerRoute}`;
  const appointmentPaymentUpdateTriggerUrl = `${url}/${appointmentUpdateTriggerRoute}`;

  const onPaymentMethodSubmit = useCallback(
    ({
      paymentMethod,
      cashCheckValue,
     }: PaymentMethodFormValues) => {
      setPaymentMethod(paymentMethod);
      setTotalAmount(cashCheckValue);
      push(paymentReviewUrl);
    },
    [
      setPaymentMethod,
      setTotalAmount,
      push,
      paymentReviewUrl,
    ],
  );

  const onPaymentReviewSubmit = useCallback(
    () => {
      switch(paymentMethod) {
        case PaymentMethod.CashOrCheck:
          push(appointmentPaymentUpdateTriggerUrl);
          break;
        case PaymentMethod.CreditOrDebit:
          push(paymentTriggerUrl);
          break;
      }
    },
    [
      push,
      paymentTriggerUrl,
      paymentMethod,
      appointmentPaymentUpdateTriggerUrl,
    ],
  );

  const onPaymentSuccess = useCallback(
    () => push(appointmentPaymentUpdateTriggerUrl),
    [
      push,
      appointmentPaymentUpdateTriggerUrl,
    ],
  );

  const onAppointmentPaymentUpdateSuccess = useCallback(
    () => push(getPaymentSuccessPath({ appointmentId })),
    [
      push,
      appointmentId,
    ],
  );

  return (
    <Switch>
      <Route
        exact
        path={paymentReviewPath}
      >
        <PaymentReviewPage
          appointmentId={appointmentId}
          paymentMethod={paymentMethod}
          totalAmount={totalAmount}
          onSubmit={onPaymentReviewSubmit}
        />
      </Route>
      <Route
        path={paymentTriggerPath}
      >
        <TriggerCardPayment
          appointmentId={appointmentId}
          onSuccess={onPaymentSuccess}
        />
      </Route>
      <Route
        path={appointmentPaymentUpdateTriggerPath}
      >
        <TriggerAppointmentPaymentUpdate
          appointmentId={appointmentId}
          onSuccess={onAppointmentPaymentUpdateSuccess}
        />
      </Route>
      <Route
        path={paymentMethodPath}
      >
        <PaymentMethodPage
          appointmentId={appointmentId}
          onSubmit={onPaymentMethodSubmit}
        />
      </Route>
    </Switch>
  );
};
