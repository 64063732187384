import React, { useCallback } from 'react';
import { Box, BoxProps } from 'rebass';
import { CssTypeFix } from 'types/types';

type Props = {
  children: React.ReactNode;
  text: string;
} & BoxProps &
  CssTypeFix;

export const ClickToCopy = ({ children, text, ...props }: Props) => {
  const copyText = useCallback(
    async () => navigator.clipboard.writeText(text),
    [text]
  );

  return (
    <Box
      {...props}
      onClick={copyText}
      sx={{
        cursor: 'copy',
      }}
    >
      {children}
    </Box>
  );
};
