import { combineReducers } from 'redux';
import { FetchStatus, Lead } from 'types/types';
import { Action, handleActions } from 'redux-actions';
import {
  FETCH_LEAD_FAILURE,
  FETCH_LEAD_START,
  FETCH_LEAD_SUCCESS,
} from 'modules/leads/actionTypes';
import {
  GET_SCHEDULE,
  GET_SCHEDULE_FAILURE,
  GET_SCHEDULE_SUCCESS,
} from 'modules/schedule/actionTypes';
import { FetchLeadSuccessPayload } from 'modules/leads/actions';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';
import { GetScheduleSuccessActionPayload } from '../modules/schedule/actions';
import { UploadAppFileSuccessPayload } from 'modules/files/actions';
import {
  REQUEST_FORM_SUCCESS,
  UPLOAD_APP_FILE_SUCCESS,
} from 'modules/files/actionTypes';

export type LeadMap = Record<string, Lead>;
export interface LeadsState {
  normalized: LeadMap;
  fetchStatus: FetchStatus;
}

const initialState: LeadMap = {};

export const normalized = handleActions<LeadMap, any>(
  {
    [FETCH_LEAD_SUCCESS]: (state, action: Action<FetchLeadSuccessPayload>) => ({
      ...state,
      [String(action.payload.id)]: action.payload,
    }),
    [REQUEST_FORM_SUCCESS]: (
      state,
      action: Action<{ missingDoc: string; leadId: string }>
    ) => {
      if (!action.payload.leadId || !action.payload.missingDoc) return state;

      const lead = state[action.payload.leadId];

      return {
        ...state,
        [action.payload.leadId]: {
          ...lead,
          missingDocs: lead.missingDocs.filter(
            doc => doc !== action.payload.missingDoc
          ),
        },
      };
    },
    [UPLOAD_APP_FILE_SUCCESS]: (
      state,
      action: Action<UploadAppFileSuccessPayload>
    ) => {
      if (!action.payload.leadId || !action.payload.uploadType) return state;

      const lead = state[action.payload.leadId];

      return {
        ...state,
        [action.payload.leadId]: {
          ...lead,
          missingDocs: lead.missingDocs.filter(
            doc => doc !== action.payload.uploadType
          ),
        },
      };
    },
    [GET_SCHEDULE_SUCCESS]: (
      state,
      action: Action<GetScheduleSuccessActionPayload>
    ) => ({
      ...state,
      ...action.payload.leads,
    }),
  },
  initialState
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [FETCH_LEAD_START, GET_SCHEDULE],
  successActions: [FETCH_LEAD_SUCCESS, GET_SCHEDULE_SUCCESS],
  failureActions: [FETCH_LEAD_FAILURE, GET_SCHEDULE_FAILURE],
});

export default combineReducers<LeadsState>({
  normalized,
  fetchStatus,
});
