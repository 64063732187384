import { RawAppointmentServiceType } from "modules/appointmentServiceTypes/types";
import { AppointmentServiceType } from "types/types";
import { normalizeAppointmentServiceType } from "modules/appointmentServiceTypes/utils/appointmentServiceTypeNormalization";

export const translateFetchAppointmentServiceTypes = (response: RawAppointmentServiceType[]): Record<string, AppointmentServiceType> =>
  response
    .map(rawAppointmentServiceType => normalizeAppointmentServiceType(rawAppointmentServiceType))
    .reduce(
      (result, element) => {
        result[String(element.id)] = element;
        return result;
      },
      {} as Record<string, AppointmentServiceType>,
    );
