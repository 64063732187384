import React, { useCallback } from 'react';
import { Flex } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { AppointmentPathParams } from 'routing/routePaths';
import { History } from 'history';
import {
  Appointment,
  Case,
  Doctor,
  Insurance,
  Lead,
  Patient,
} from 'types/types';
import { match } from 'react-router-dom';
import { SessionInfo } from 'components/SessionInfo/SessionInfo';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { ConnectedAppointmentInfo } from 'containers/ConnectedAppointmentInfo/ConnectedAppointmentInfo';

interface Props {
  match: match<AppointmentPathParams>;
  history: History;
  appointment: Appointment;
  patient?: Patient;
  therapyCase?: Case;
  doctor?: Doctor;
  lead?: Lead;
  insurance?: Insurance;
}

export const FinishedAppointmentPage = ({
  history,
  appointment,
  patient,
  therapyCase,
  lead,
  insurance,
}: Props) => {
  const goBack = useCallback(() => history.goBack(), [history]);

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Session Finished"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
      />
      <Flex padding={3} paddingBottom={0}>
        <SessionInfo
          appointment={appointment}
          patient={patient}
          lead={lead}
          therapyCase={therapyCase}
        >
          Session Finished
        </SessionInfo>
      </Flex>
      <ScrollBox
        height="100%"
        flexDirection="column"
        sx={{
          borderTop: '1px solid',
          borderColor: 'muted',
        }}
      >
        <ConnectedAppointmentInfo appointment={appointment} />
      </ScrollBox>
    </Flex>
  );
};
