import React from 'react';
import { FullOverlay } from 'components/FullOverlay/FullOverlay';
import { useBoolean } from 'utils/hooks/useBoolean';
import { Box, BoxProps } from 'rebass';
import { CssTypeFix } from 'types/types';

export interface RenderButtonProps {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

interface RenderChildrenProps {
  closeModal: () => void;
}

type Props = {
  renderChildren: (props: RenderChildrenProps) => React.ReactNode;
  renderButton: (props: RenderButtonProps) => React.ReactNode;
} & Omit<BoxProps, 'onSubmit'> &
  CssTypeFix;

export const ModalButton = ({ renderChildren, renderButton }: Props) => {
  const {
    value: isModalOpen,
    setTrue: openModal,
    setFalse: closeModal,
  } = useBoolean(false);
  return (
    <Box>
      {isModalOpen && (
        <FullOverlay backgroundColor="white">
          {renderChildren({ closeModal })}
        </FullOverlay>
      )}
      {renderButton({ isModalOpen, openModal, closeModal })}
    </Box>
  );
};
