// API actions
export const FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS';
export const FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS';
export const FETCH_APPOINTMENTS_FAILURE = 'FETCH_APPOINTMENTS_FAILURE';
export const BOOK_APPOINTMENT = 'BOOK_APPOINTMENT';
export const BOOK_APPOINTMENT_SUCCESS = 'BOOK_APPOINTMENT_SUCCESS';
export const BOOK_APPOINTMENT_FAILURE = 'BOOK_APPOINTMENT_FAILURE';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';
export const END_SESSION = 'END_SESSION';
export const END_SESSION_SUCCESS = 'END_SESSION_SUCCESS';
export const END_SESSION_FAILURE = 'END_SESSION_FAILURE';
export const HOME_HEALTH_APPOINTMENT_CANCEL = 'HOME_HEALTH_APPOINTMENT_CANCEL';
export const HOME_HEALTH_APPOINTMENT_CANCEL_SUCCESS =
  'HOME_HEALTH_APPOINTMENT_CANCEL_SUCCESS';
export const HOME_HEALTH_APPOINTMENT_CANCEL_FAILURE =
  'HOME_HEALTH_APPOINTMENT_CANCEL_FAILURE';

// Local actions
export const SET_LAST_APPOINTMENT = 'SET_LAST_APPOINTMENT';
