import { CardBrand, Patient } from "types/types";
import { getPatientPaymentSourceId } from "utils/patient/getPatientPaymentSourceId";

interface Output {
  paymentSourceId: string | undefined;
  cardBrand: CardBrand;
  cardLast4: string | undefined;
}

export const getPatientCardInfo = ({ patient, }: { patient: Patient }): Output => {
  const { cardBrand, cardLast4 } = patient;
  const paymentSourceId = getPatientPaymentSourceId({ patient });
  return {
    paymentSourceId,
    // Translate the API's different casing
    cardBrand: cardBrand?.toUpperCase() as CardBrand,
    cardLast4,
  }
};
