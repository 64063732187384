import { RawAppointment } from 'modules/appointments/types';
import { Appointment } from 'types/types';
import { convertCamelToSnakeCase } from 'utils/convertCamelToSnakeCase';

export const normalizeAppointment = ({
  cxlnsReason,
  cxlFee,
  ...rest
}: RawAppointment): Appointment => ({
  cancellationReason: cxlnsReason,
  cancellationFee: cxlFee,
  ...rest,
});

export const denormalizeAppointment = ({
  cancellationReason,
  cancellationFee,
  ...rest
}: Partial<Appointment>): Partial<RawAppointment> => ({
  cxlns_reason: cancellationReason,
  cxl_fee: cancellationFee,
  ...convertCamelToSnakeCase(rest),
});
