import React from 'react';
import { Flex, Text } from 'rebass';
import { Field } from 'formik';
import { FormikTextField } from 'components/forms/FormikTextField';

export const ReferralFields = () => {
  return (
    <Flex flexDirection="column" width="100%">
      <Text color="grey">Patient being referred:</Text>
      <Field
        name="referralName"
        component={FormikTextField}
        placeholder="Full Name"
        disableError={false}
      />
      <Field
        name="referralEmail"
        component={FormikTextField}
        placeholder="Email"
        disableError={false}
      />
      <Field
        name="referralPhone"
        component={FormikTextField}
        placeholder="Phone Number"
        disableError={false}
      />
    </Flex>
  );
};
