import React from 'react';
import { Flex, FlexProps } from 'rebass';
import { CssTypeFix } from '../../types/types';

type CombinedFlexProps = FlexProps & CssTypeFix;

interface Props extends CombinedFlexProps {
  src: string;
}

export const ImageViewer = ({ src, sx = {}, ...props }: Props) => (
  <Flex
    alignItems="flex-start"
    justifyContent="center"
    {...props}
    sx={{
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      overflow: 'auto',
      ...sx,
    }}
  >
    <img width="100%" src={src} alt="file" />
  </Flex>
);
