import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { useGoBack } from 'utils/hooks/useGoBack';
import { AppointmentDate } from 'components/AppointmentDate/AppointmentDate';
import { Appointment, Case, Patient } from 'types/types';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { formatAppointmentTime } from 'utils/appointment/formatAppointmentTime';
import { getPatient } from 'selectors/patients/getPatient';
import { PatientBasicInfo } from 'components/PatientBasicInfo/PatientBasicInfo';
import { getCase } from 'selectors/cases/getCase';
import { FieldDisplayer } from 'components/FieldDisplayer/FieldDisplayer';
import { PatientAddress } from 'components/PatientAddress/PatientAddress';
import { Separator } from 'components/Separator';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { helpMessage, paymentMethodString } from 'appConstants';
import { PaymentMethod } from 'pages/payment/types';
import { AppointmentNotFoundPage } from 'pages/appointmentNotFound/AppointmentNotFoundPage';
import { getAppointmentCaseProgressionFraction } from 'utils/case/caseProgression/getAppointmentCaseProgressionFraction';
import { PatientCreditCardInfo } from 'components/PatientCreditCardInfo/PatientCreditCardInfo';
import { formatCurrency } from 'utils/formatCurrency';

// https://app.zeplin.io/project/5e0510850d97c818513015b3/screen/5e0c0df76b256c1a292bc58e

interface OwnProps {
  appointmentId: string;
  paymentMethod: PaymentMethod | null;
  totalAmount: string | null;
  onSubmit: () => void;
}

interface StateProps {
  appointment?: Appointment;
  patient?: Patient;
  therapyCase?: Case;
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  { appointmentId }: OwnProps
): StateProps => {
  const appointment = getAppointment(state, { appointmentId });
  return {
    appointment,
    patient:
      appointment && getPatient(state, { patientId: appointment.patientId }),
    therapyCase:
      appointment && getCase(state, { caseId: String(appointment.caseId) }),
  };
};

const mapDispatchToProps: DispatchProps = {};

const PaymentReviewPageComponent = ({
  appointment,
  patient,
  therapyCase,
  paymentMethod,
  totalAmount,
  onSubmit,
}: Props) => {
  const goBack = useGoBack();

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Review Service"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
      />
      <Flex padding={3} paddingBottom={0}>
        {appointment ? (
          <Flex flexDirection="column" width="100%">
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              marginBottom={3}
            >
              <Text fontWeight="bold">
                <AppointmentDate appointment={appointment} />
              </Text>
              <Text>
                {formatAppointmentTime({
                  appointment,
                })}
              </Text>
            </Flex>
            {patient && (
              <Flex marginBottom={3}>
                <PatientBasicInfo patient={patient}>Patient</PatientBasicInfo>
              </Flex>
            )}
            <Flex width="100%" justifyContent="space-between" marginBottom={3}>
              <FieldDisplayer title="Insurance" value={patient?.insurance} />
              <FieldDisplayer
                title="Session"
                value={
                  therapyCase
                    ? getAppointmentCaseProgressionFraction({
                        therapyCase,
                        appointment,
                      })
                    : 'N/A'
                }
              />
            </Flex>
            {patient && (
              <Flex marginBottom={3}>
                <FieldDisplayer
                  title="Place of Service"
                  value={<PatientAddress patient={patient} />}
                />
              </Flex>
            )}
          </Flex>
        ) : (
          <AppointmentNotFoundPage />
        )}
      </Flex>
      <Separator />
      <Flex flexDirection="column" paddingLeft={3} paddingRight={3}>
        <Text fontWeight="bold" marginBottom={2}>
          Payment Information
        </Text>
        {paymentMethod && paymentMethodString[paymentMethod]}
        {paymentMethod === PaymentMethod.CreditOrDebit && patient && (
          <Flex
            padding={3}
            margin={2}
            sx={{
              border: '1px solid',
              borderColor: 'grey',
              borderRadius: 'borderRadius',
            }}
          >
            <PatientCreditCardInfo patient={patient} />
          </Flex>
        )}
      </Flex>
      <Separator />
      <Flex flexDirection="column" paddingLeft={3} paddingRight={3}>
        <Text fontWeight="bold" marginBottom={2}>
          Charge for service
        </Text>
        <Text color="grey" fontSize="10pt" marginBottom={3}>
          Full-fee, copay and/or cancellation fee*
        </Text>
        <Flex justifyContent="flex-end">
          <Text fontSize="14pt" fontWeight="bold">
            Total Fee:{' '}
            {formatCurrency({
              amount: parseFloat(
                totalAmount || appointment?.patientResponsibility || ''
              ),
            })}
          </Text>
        </Flex>
      </Flex>
      <Flex flex={1} />
      <FooterButton label="Pay" onClick={onSubmit} />
      <Text color="grey" textAlign="center" marginBottom={3}>
        {helpMessage}
      </Text>
    </Flex>
  );
};

export const PaymentReviewPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentReviewPageComponent);
