import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import { AppointmentServiceTypeSelector } from './AppointmentServiceTypeSelector';
import { ProviderSessionInfo } from '../../components/ProviderSessionInfo/ProviderSessionInfo';
import { Separator } from '../../components/Separator';
import { DatePickerButton } from '../../components/DatePickerButton/DatePickerButton';
import { combineTimes } from '../../utils/combineTimes';
import { FormValues } from './Booking';
import { Patient } from '../../types/types';
import { ACTION_HOOKS, CallAction } from '../../utils/hooks/useCall/config';
import { isFetching } from '../../utils/fetchStatus';
import { Loader } from '../../components/Loader/Loader';
import { CaseSelector } from './CaseSelector';
import { useSelectorWithProps } from '../../utils/hooks/useSelectorWithProps';
import { getCasesForPatient } from '../../selectors/cases/getCasesForPatient';

interface Props
  extends Pick<
    FormValues,
    'appointmentServiceType' | 'appointmentTime' | 'appointmentDate'
  > {
  onDatePickerClick: (e: SyntheticEvent) => void;
  patient: Patient;
}

export const PatientSubform = ({
  onDatePickerClick,
  appointmentServiceType,
  appointmentTime,
  appointmentDate,
  patient,
}: Props) => {
  const cases = useSelectorWithProps(getCasesForPatient, {
    patientId: patient.id,
  });
  const [selectedCaseId, setSelectedCaseId] = useState<number | undefined>();

  const {
    call: callFetchPatientCases,
    fetchStatus: fetchPatientCasesFetchStatus,
  } = ACTION_HOOKS[CallAction.FetchPatientCases]({});
  const {
    call: callFetchCaseAppTypes,
    fetchStatus: fetchCaseAppTypesFetchStatus,
  } = ACTION_HOOKS[CallAction.FetchCaseAppTypes]({});

  const fetchPatientCases = useCallback(() => {
    callFetchPatientCases({
      patientId: String(patient.id),
    });
  }, [callFetchPatientCases, patient]);

  const isLoading = isFetching(fetchPatientCasesFetchStatus);
  const isLoadingCaseAppTypes = isFetching(fetchCaseAppTypesFetchStatus);

  useEffect(() => {
    fetchPatientCases();
  }, [fetchPatientCases]);

  const handleCaseChange = (
    field: string,
    option: { label: string; value: number }
  ) => {
    setSelectedCaseId(option.value);
    callFetchCaseAppTypes(option.value);
  };

  return (
    <Loader isLoading={isLoading}>
      <Flex flexDirection="column" marginTop={3}>
        <Flex flexDirection="column" padding={3}>
          <Text marginBottom={1}>Case:</Text>
          {cases && cases.length ? (
            <CaseSelector cases={cases} onChangeField={handleCaseChange} />
          ) : (
            <Text>No cases found for patient</Text>
          )}
        </Flex>
        {selectedCaseId ? (
          <Loader size={30} isLoading={isLoadingCaseAppTypes}>
            <Flex flexDirection="column" padding={3}>
              <Text marginBottom={1}>Appointment type:</Text>
              <AppointmentServiceTypeSelector selectedCaseId={selectedCaseId} />
            </Flex>
          </Loader>
        ) : null}
        {appointmentServiceType && (
          <Flex flexDirection="column" paddingLeft={3} paddingRight={3}>
            <Box
              sx={{
                color: 'grey',
              }}
            >
              Provider:
            </Box>
            <ProviderSessionInfo
              appointmentServiceType={appointmentServiceType}
              patient={patient}
            />
          </Flex>
        )}
        <Separator />
        <Flex flexDirection="column" paddingLeft={3} paddingRight={3}>
          <Text color="grey" marginBottom={1}>
            Select Date to Contact
          </Text>
          <DatePickerButton
            dateTime={combineTimes({
              date: appointmentDate,
              time: appointmentTime,
            })}
            onClick={onDatePickerClick}
          />
        </Flex>
      </Flex>
    </Loader>
  );
};
