import { AppointmentService, AppointmentStatusName } from 'types/types';

export const APPOINTMENTS_ENDPOINT = 'appointments';

export type CancellationReason =
  | AppointmentStatusName.CancelledWithNotice
  | AppointmentStatusName.CancelledWithoutNotice1st
  | AppointmentStatusName.CancelledMoreWithoutNotice
  | AppointmentStatusName.NoShow;

export const evalAppointmentServices = Object.values(
  AppointmentService
).filter(appointmentService => appointmentService.match(/eval/i));
