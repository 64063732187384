import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { CustomLink } from 'components/CustomLink/CustomLink';
import { CheckMarkIcon } from 'components/icons/CheckMarkIcon';

export interface ListItem {
  key: string;
  label: React.ReactNode;
}
interface Props {
  title: React.ReactNode;
  onClickBottomLink: () => void;
  bottomLinkText: React.ReactNode;
  list?: ListItem[];
  selectedItemIndex?: number;
  onClickOption?: (index: number) => void;
  children?: React.ReactNode;
}

export const ListSelector = ({
  title,
  onClickBottomLink,
  bottomLinkText,
  list = [],
  selectedItemIndex,
  onClickOption = () => {},
  children = null,
}: Props) => (
  <Flex flexDirection="column" flex={1}>
    <Flex padding={3} backgroundColor="muted">
      {title}
    </Flex>
    {list?.map(({ label, key }, i) => (
      <Flex
        key={key}
        padding={3}
        onClick={() => onClickOption(i)}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderBottom: '1px solid',
          borderColor: 'muted',
        }}
      >
        {label}
        {selectedItemIndex === i && (
          <Box>
            <CheckMarkIcon size={25} />
          </Box>
        )}
      </Flex>
    ))}
    <CustomLink onClick={onClickBottomLink}>
      <Flex padding={3}>
        <Text variant="link">{bottomLinkText}</Text>
      </Flex>
    </CustomLink>
    <Flex backgroundColor="muted" flex={1}>
      {children}
    </Flex>
  </Flex>
);
