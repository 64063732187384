import React from 'react';
import { Field } from 'formik';
import { FormikSelectField } from 'components/forms/FormikSelectField';
import { Case } from '../../types/types';

interface Props {
  cases: Case[];
  onChangeField?: (
    field: string,
    option: { label: string; value: number }
  ) => void;
}

export const CaseSelector = ({ cases, onChangeField }: Props) => {
  return (
    <Field
      name="caseId"
      component={FormikSelectField}
      onChangeField={onChangeField}
      options={cases.map(therapyCase => ({
        label: therapyCase.name || therapyCase.description,
        value: therapyCase.id,
      }))}
    />
  );
};
