import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Flex, Text } from 'rebass';

import { fetchGoogleOauth2Tokens } from 'modules/auth/actions';
import Logo from '../../../assets/peteLogo1.png';
import { CirclesLoader } from 'components/CirclesLoader/CircleLoader';
import { GlobalState } from 'reducers';
import { getGoogleOauth2Status } from 'selectors/auth/getFetchGoogleOauth2Satus';
import { FetchStatus } from 'types/types';
import { DEFAULT_LOGIN_PATH } from 'routing/routePaths';
import { getIsAuthenticated } from 'selectors/auth/getIsAuthenticated';

type LoginGoogleRedirectComponentProps = {
  googleOauth2Status: FetchStatus;
  isAuthenticated: boolean;
  fetchGoogleTokens: typeof fetchGoogleOauth2Tokens;
};

export const LoginGoogleRedirectComponent = ({
  googleOauth2Status,
  isAuthenticated,
  fetchGoogleTokens,
}: LoginGoogleRedirectComponentProps) => {
  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  useEffect(() => {
    if (queryParams.code) {
      fetchGoogleTokens({ authorizationCode: queryParams.code as string });
    }
  }, [queryParams.code, fetchGoogleTokens]);

  const hasFailed = googleOauth2Status === FetchStatus.Failure;
  const isUnauthorized = googleOauth2Status === FetchStatus.Unauthorized;

  if (isAuthenticated) {
    return <Redirect to={DEFAULT_LOGIN_PATH} />;
  }

  return (
    <Flex flexDirection="column" width="100%" padding={2}>
      <Flex flex="1" justifyContent="center" alignItems="center">
        <img src={Logo} alt="logo" height={78} width={200} />
      </Flex>
      <Flex flex="2" justifyContent="center" alignItems="center">
        {hasFailed ? (
          <Text textAlign="center" width={300} fontSize={16} lineHeight={1.6}>
            An error happened with Google authentication. Please try again or
            contact support.
          </Text>
        ) : isUnauthorized ? (
          <Text textAlign="center" width={300} fontSize={16} lineHeight={1.6}>
            Your user is not authorized to access. Please contact support.
          </Text>
        ) : (
          <CirclesLoader></CirclesLoader>
        )}
      </Flex>
    </Flex>
  );
};

export const LoginGoogleRedirect = connect(
  (state: GlobalState) => ({
    googleOauth2Status: getGoogleOauth2Status(state),
    isAuthenticated: getIsAuthenticated(state),
  }),
  { fetchGoogleTokens: fetchGoogleOauth2Tokens }
)(LoginGoogleRedirectComponent);
