import { Case } from 'types/types';

interface Props {
  therapyCase: Case;
}

interface Output {
  casesDone: string;
  casesLeft: string;
}

export const getCaseProgression = ({ therapyCase }: Props): Output => ({
  casesDone: String(therapyCase.lastVisitAppCount),
  casesLeft: therapyCase.totalPrescribedVisits,
});
