import React, { useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { BOOKING_PATH, SCHEDULE_PATH } from 'routing/routePaths';
import { useHistory } from 'react-router-dom';
import { Flex } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { stringify } from 'query-string';
import { OptionButton } from 'pages/postSession/OptionButton';
// import { getHasFutureAppointment } from "selectors/cases/getHasFutureAppointment";
import { FormValues, OnHoldForm } from 'pages/postSession/OnHoldForm';
// import { fetchFutureAppointments as fetchFutureAppointmentsAction } from "modules/appointments/actions";
import {
  dischargeCase as dischargeCaseAction,
  holdCase as holdCaseAction,
} from 'modules/cases/actions';
import { OnHoldReason } from 'pages/postSession/constants';
import { getCaseFetchStatus } from 'selectors/cases/getCaseFetchStatus';
import { Appointment, FetchStatus } from 'types/types';
import { useFetchStatusHooks } from 'utils/hooks/useFetchStatusHooks';
import { setAppointmentAsFinalized as setAppointmentAsFinalizedAction } from 'modules/appointments/actions';
import { getAmountOwed } from 'utils/appointment/getAmountOwed';
import { getIsPaymentEnabled } from 'selectors/features/getIsPaymentEnabled';

interface OwnProps {
  appointment: Appointment;
}

interface StateProps {
  // hasFutureAppointment: boolean;
  fetchStatus: FetchStatus;
}

interface DispatchProps {
  // fetchFutureAppointments: typeof fetchFutureAppointmentsAction;
  dischargeCase: typeof dischargeCaseAction;
  holdCase: typeof holdCaseAction;
  setAppointmentAsFinalized: typeof setAppointmentAsFinalizedAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: GlobalState): StateProps => ({
  // hasFutureAppointment: getHasFutureAppointment(state, { caseId }),
  fetchStatus: getCaseFetchStatus(state),
});

const mapDispatchToProps: DispatchProps = {
  // fetchFutureAppointments: fetchFutureAppointmentsAction,
  dischargeCase: dischargeCaseAction,
  holdCase: holdCaseAction,
  setAppointmentAsFinalized: setAppointmentAsFinalizedAction,
};

const PostSessionComponent = ({
  appointment,
  // hasFutureAppointment,
  // fetchFutureAppointments,
  // dischargeCase,
  holdCase,
  fetchStatus,
  setAppointmentAsFinalized,
}: Props) => {
  // Disabling the ability to fetch future appointments for now
  /*
  useEffect(
    () => {
      if (caseId) {
        fetchFutureAppointments({ caseId });
      }
    },
    [caseId, fetchFutureAppointments],
  );
  */
  const isPaymentEnabled = useSelector(getIsPaymentEnabled);
  const { caseId } = appointment;
  const history = useHistory();
  const navigateToBooking = useCallback(
    () => history.push(`${BOOKING_PATH}?${stringify({ caseId })}`),
    [caseId, history]
  );
  const navigateToSchedule = useCallback(() => history.push(SCHEDULE_PATH), [
    history,
  ]);
  const { armHook } = useFetchStatusHooks({
    fetchStatus,
    onSuccess: navigateToSchedule,
  });
  const handleHoldCase = useCallback(
    ({ reason, otherReason }: FormValues) => {
      const finalReason =
        reason === OnHoldReason.Other ? `Other: ${otherReason}` : reason;
      holdCase({
        caseId,
        reason: finalReason || 'Unknown reason',
      });
      armHook();
    },
    [holdCase, caseId, armHook]
  );
  const onDone = useCallback(() => {
    if (!isPaymentEnabled) {
      // Considering the appointment paid now in absence of the payment flow, so that the therapist can access the appointment finished page
      setAppointmentAsFinalized({
        id: appointment.id,
        amountPaid: getAmountOwed({ appointment }),
      });
    }
    navigateToSchedule();
  }, [
    navigateToSchedule,
    appointment,
    setAppointmentAsFinalized,
    isPaymentEnabled,
  ]);

  const isFormDisabled = fetchStatus === FetchStatus.Fetching;

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar title="Post Session" />
      <Flex padding={3} flexDirection="column">
        <OptionButton onClick={navigateToBooking} disabled={isFormDisabled}>
          Book Future Appointment
        </OptionButton>
        <Flex
          padding={3}
          flexDirection="column"
          marginBottom={3}
          sx={{
            borderRadius: '10px',
            border: '1px solid',
            borderColor: 'muted',
          }}
        >
          <OnHoldForm onSubmit={handleHoldCase} isDisabled={isFormDisabled} />
        </Flex>
        {/* Once future appointment fetching re-enabled, only render this if has future appointments */}
        <OptionButton
          variant="primary"
          onClick={onDone}
          disabled={isFormDisabled}
        >
          Done
        </OptionButton>
      </Flex>
    </Flex>
  );
};

export const PostSession = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostSessionComponent);
