import React from 'react';
import { Flex, FlexProps } from 'rebass';

interface Props extends FlexProps {
  size?: number;
  children: React.ReactNode;
}

export const Avatar = ({ children, size, ...rest }: Props) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    color="white"
    height={size}
    width={size}
    minWidth={size}
    {...rest}
    sx={{
      backgroundColor: 'black',
      borderRadius: '50%',
      ...(rest.sx ? rest.sx : {}),
    }}
  >
    {children}
  </Flex>
);
