import { TimeBlock } from 'utils/appointment/timeSlots/constants';

// Parses time in format of hh:mm
export const parseTime = (time: string, duration: number): TimeBlock => {
  const stringMatch = time.match(/(\d\d):(\d\d)/);
  if (!stringMatch) {
    throw Error('Mismatched string');
  }
  const [, hr, min] = stringMatch;
  return {
    hr: Number(hr),
    min: Number(min),
    duration,
  };
};
