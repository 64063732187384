import React, {
  TouchEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Box, BoxProps } from 'rebass';
import { Canvas } from 'components/Canvas/Canvas';
import { RotationContext } from 'components/Rotated/RotationContext';
import { Rotation } from 'components/Rotated/types';
import { CssTypeFix } from 'types/types';

interface Coordinates {
  x: number;
  y: number;
}

type Props = BoxProps &
  CssTypeFix & {
    getCanvas?: (canvas: HTMLCanvasElement) => void;
  };

export const WhiteBoard = ({ getCanvas = () => {}, ...props }: Props) => {
  const rotation = useContext(RotationContext);
  const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
  const [offsetCoords, setOffsetCoords] = useState<Coordinates>({ x: 0, y: 0 });

  const getTranslatedCoordinates = useCallback(
    (e: TouchEvent): Coordinates => {
      // Page coordinates of touch event
      const touchX = e.touches[0].clientX;
      // pageY and screenY are bugged on safari, so we use clientY
      const touchY = e.touches[0].clientY;

      // Page coordinates of element
      const { x, y } = offsetCoords;

      // Original element coordinates, irrespective of rotation
      const elementX = touchX - x;
      const elementY = touchY - y;

      // New coordinates to return
      let convertedX;
      let convertedY;

      if (rotation === Rotation.None) {
        convertedX = elementX;
        convertedY = elementY;
      } else if (rotation === Rotation.Clockwise) {
        // this is actually width due to rotation
        const width = canvas?.clientHeight;
        if (!width) {
          throw new Error('Canvas width not found');
        }
        convertedX = elementY;
        convertedY = width - elementX;
      } else {
        throw new Error(
          `Rotation ${rotation} not yet supported for WhiteBoard`
        );
      }

      return {
        x: convertedX,
        y: convertedY,
      };
    },
    [rotation, offsetCoords, canvas]
  );

  useEffect(() => {
    if (canvas) {
      // Save offset coordinates
      const { x, y } = canvas.getBoundingClientRect();
      setOffsetCoords({ x, y });
    }
  }, [canvas]);

  // Send canvas up and save it locally
  const handleCanvas = useCallback(
    (canvas: HTMLCanvasElement): void => {
      setCanvas(canvas);
      getCanvas(canvas);
    },
    [setCanvas, getCanvas]
  );

  const onTouchStart = (e: TouchEvent) => {
    const ctx = canvas && canvas.getContext('2d');
    if (ctx) {
      const { x, y } = getTranslatedCoordinates(e);
      ctx.moveTo(x, y);
    }
  };

  const onTouchMove = (e: TouchEvent) => {
    const ctx = canvas && canvas.getContext('2d');
    if (ctx) {
      const { x, y } = getTranslatedCoordinates(e);
      ctx.lineTo(x, y);
      ctx.stroke();
    }
  };

  return (
    <Box
      width="100%"
      height="100%"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      {...props}
    >
      <Canvas getCanvas={handleCanvas} />
    </Box>
  );
};
