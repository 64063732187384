import React, { useCallback } from 'react';
import { OwnProps as SignatureFormProps } from 'components/forms/SignatureForm/DisclaimerSignatureForm';
import { BoxProps } from 'rebass';
import { CssTypeFix } from 'types/types';
import { getSignatureFormComponent } from 'components/forms/SignatureForm/utils/getSignatureFormComponent';
import { SignatureFormType } from 'components/forms/SignatureForm/constants';
import { ModalButton, RenderButtonProps } from '../../ModalButton/ModalButton';

type Props = {
  children?: React.ReactNode;
  onSubmit: SignatureFormProps['onSubmit'];
  terms?: SignatureFormProps['terms'];
  renderButton: (props: RenderButtonProps) => React.ReactNode;
  signatureFormType?: SignatureFormType;
} & Omit<BoxProps, 'onSubmit'> &
  CssTypeFix;

export const SignatureFormModal = ({
  children,
  onSubmit,
  renderButton,
  terms,
  signatureFormType = SignatureFormType.Default,
}: Props) => {
  const SignatureFormComponent = getSignatureFormComponent({
    signatureFormType,
  });

  const renderChildren = useCallback(
    ({ closeModal }) => {
      const onModalSubmit: Parameters<
        ReturnType<typeof getSignatureFormComponent>
      >[0]['onSubmit'] = (...values) => {
        onSubmit(...(values as Parameters<Props['onSubmit']>));
        closeModal();
      };
      return (
        <>
          {children}
          <SignatureFormComponent
            onClose={closeModal}
            onSubmit={onModalSubmit}
            terms={terms}
          />
        </>
      );
    },
    [onSubmit, terms, children]
  );

  return (
    <ModalButton renderChildren={renderChildren} renderButton={renderButton} />
  );
};
