import React from 'react';
import { Box, Flex } from 'rebass';

interface Props {
  title?: React.ReactNode;
  left?: React.ReactNode;
  right?: React.ReactNode;
}

export const HeaderBar = ({ left, title, right }: Props) => (
  <Flex
    justifyContent="space-between"
    width="100%"
    height="45px"
    padding="11px"
    sx={{
      borderBottom: 'border',
      borderColor: 'muted',
      position: 'relative',
    }}
  >
    <Flex alignItems="center">{left}</Flex>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        textAlign: 'center',
      }}
    >
      {title}
    </Box>
    <Flex alignItems="center">{right}</Flex>
  </Flex>
);
