import { Lead } from "types/types";

interface Props {
  lead: Lead;
}

export const getInjury = ({ lead }: Props): string | undefined => {
  if (lead) {
    return lead.injuryBodyArea;
  }
}