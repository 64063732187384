import React, { useCallback } from 'react';
import { RescheduleRouting } from "pages/appointment/AppointmentReschedulePage/RescheduleRouting";
import { Formik, FormikProps } from "formik";
import moment, { Moment } from "moment";
import { connect } from "react-redux";
import { rescheduleAppointment as rescheduleAppointmentAction } from "modules/appointments/actions";
import { useRouteMatch, useHistory } from "react-router-dom";
import { FetchStatus } from "types/types";
import { GlobalState } from "reducers";
import { getAppointmentsFetchStatus } from "selectors/appointments/getAppointmentsFetchStatus";
import { useFetchStatusHooks } from "utils/hooks/useFetchStatusHooks";
import { getRescheduleSuccessPath } from "routing/routeFunctions";
import { AppointmentReschedulePathParams } from "routing/routePaths";

export interface FormValues {
  reason?: string;
  date?: Moment;
  time?: string; // hh:mm
}

interface StateProps {
  appointmentFetchStatus: FetchStatus;
}

interface DispatchProps {
  rescheduleAppointment: typeof rescheduleAppointmentAction;
}

type Props = StateProps & DispatchProps;

const initialValues: FormValues = {
  reason: undefined,
  date: moment(),
  time: undefined,
}

const mapStateToProps = (state: GlobalState): StateProps => ({
  appointmentFetchStatus: getAppointmentsFetchStatus(state),
});

const mapDispatchToProps: DispatchProps = ({
  rescheduleAppointment: rescheduleAppointmentAction,
});

export const AppointmentReschedulePageComponent = ({
  rescheduleAppointment,
  appointmentFetchStatus,
}: Props) => {
  const { params: { appointmentId } } = useRouteMatch<AppointmentReschedulePathParams>();
  const { push } = useHistory();

  const {armHook} = useFetchStatusHooks({
    fetchStatus: appointmentFetchStatus,
    onSuccess: () => {
      push(getRescheduleSuccessPath({ appointmentId }));
    },
  })


  const onSubmit = useCallback(
    ({ reason, date, time}: FormValues) => {
      if(!(reason && date && time)) {
        throw new Error('Attempting to submit with fields missing');
      }
      const parsedTime = moment(time, 'HH:mm A');
      if (!parsedTime.isValid()) {
        throw new Error(`time improperly formatted: ${time}`);
      }
      const newTime = date.clone()
        .hour(parsedTime.hour())
        .minute(parsedTime.minute())
        .format();

      armHook();
      rescheduleAppointment({
        id: Number(appointmentId),
        newTime,
        reason,
      });
    },
    [rescheduleAppointment, appointmentId, armHook],
  );

  const renderForm = useCallback(
    ({ submitForm }: FormikProps<FormValues>) => {
      return (
        <RescheduleRouting
          onSubmit={submitForm}
        />
      );
    },
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {renderForm}
    </Formik>
  );
}

export const AppointmentReschedulePage = connect(mapStateToProps, mapDispatchToProps)(AppointmentReschedulePageComponent);
