import { useCallback, useState } from 'react';

interface Props {
  onConfirm: () => void;
  onReject?: () => void;
}
interface Output {
  isConfirmationVisible: boolean;
  confirm: () => void;
  reject: () => void;
  areYouSure: () => void;
}

/*
  Helper hook to defer an action until confirmed
 */
export const useDeferred = ({
  onConfirm,
  onReject = () => {},
}: Props): Output => {
  const [isConfirmationVisible, setIsConfirmationVisible] = useState<boolean>(
    false
  );

  const hideConfirmation = useCallback(() => {
    setIsConfirmationVisible(false);
  }, [setIsConfirmationVisible]);

  const confirm = useCallback(() => {
    hideConfirmation();
    onConfirm();
  }, [hideConfirmation, onConfirm]);

  const reject = useCallback(() => {
    hideConfirmation();
    onReject();
  }, [hideConfirmation, onReject]);

  const areYouSure = useCallback(() => {
    setIsConfirmationVisible(true);
  }, [setIsConfirmationVisible]);

  return {
    isConfirmationVisible,
    confirm,
    reject,
    areYouSure,
  };
};
