import React from 'react';
import {
  BoldDisplayer,
  Props as BoldDisplayerProps,
} from 'components/BoldDisplayer/BoldDisplayer';
import { Flex } from 'rebass';

export type Stats = BoldDisplayerProps[];
export type StatsColumns = Stats[];

interface Props {
  config: StatsColumns;
}

export const StatsDisplayer = ({ config }: Props) => {
  return (
    <Flex width="100%" justifyContent="space-around">
      {config.map(stats => (
        <Flex
          flexDirection="column"
          key={stats.map(({ boldText }) => boldText).join()}
        >
          {stats.map(displayerProps => {
            if (!displayerProps.boldText) {
              return null;
            }
            return (
              <BoldDisplayer
                {...displayerProps}
                key={`${displayerProps.boldText}-${displayerProps.postText}`}
              />
            );
          })}
        </Flex>
      ))}
    </Flex>
  );
};
