import { GetScheduleResponseSuccess } from './types';
import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';
import { GetScheduleSuccessActionPayload } from './actions';
import { normalizeAppointment } from 'modules/appointments/utils/normalizeAppointment';
import { normalizePatient } from 'modules/patients/utils/normalizePatient';

/*
{
  id: 19575
  app_date: "2020-03-17"
  app_time: "07:00:00"
  service: "Private Treatment"
  duration: 60
  status: "Treat Client"
  app_type: "Ongoing"
  patient_id: 1648
  patient_first_name: "Meagan"
  patient_last_name: "Tobin"
  patient_address: "5251 Vineland Ave"
  patient_city: "North Hollywood"
  patient_state: "CA"
  patient_zip: "91601"
  patient_insurance_1: null
  patient_phone: "2135983931"
  patient_email: "meagantobin@gmail.com"
  patient_date_of_birth: "1986-01-21"
}
*/

export const getScheduleCallTranslator = (isOnHoldFeatureEnabled: boolean) => (
  response: Object[]
): GetScheduleSuccessActionPayload => {
  const convertedResponse: GetScheduleResponseSuccess[] = convertSnakeToCamelCase(
    response
  );
  const result: GetScheduleSuccessActionPayload = {
    appointmentList: [],
    appointments: {},
    patients: {},
    insurances: {},
    doctors: {},
    cases: {},
    leads: {},
  };
  convertedResponse.forEach(
    ({ appointment, patient, insurance, doctor, case: therapyCase, lead }) => {
      if (
        isOnHoldFeatureEnabled &&
        therapyCase &&
        therapyCase.status === 'On Hold'
      ) {
        return;
      }
      if (appointment) {
        result.appointmentList.push(appointment.id);
        result.appointments[String(appointment.id)] = normalizeAppointment(
          appointment
        );
      }
      if (patient) {
        result.patients[String(patient.id)] = normalizePatient(patient);
      }
      if (insurance) {
        result.insurances[String(insurance.id)] = insurance;
      }
      if (doctor) {
        result.doctors[String(doctor.id)] = doctor;
      }
      if (therapyCase) {
        result.cases[String(therapyCase.id)] = therapyCase;
      }
      if (lead) {
        result.leads[String(lead.id)] = lead;
      }
    }
  );
  return result;
};
