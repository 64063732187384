import { createSelector } from "reselect";
import { getAppointments } from "selectors/appointments/getAppointments";
import { GlobalState } from "reducers";
import { Appointment } from "types/types";

export const getAppointment = createSelector(
  getAppointments,
  (_: GlobalState, { appointmentId }: { appointmentId: string }) => appointmentId,
  (appointments, appointmentId): Appointment | undefined => appointments[appointmentId],
);
