import React from 'react';
import { Appointment } from 'types/types';
import { appointmentToMoment } from 'modules/schedule/utils/appointmentToMoment';
import { SmartDate } from 'components/SmartDate/SmartDate';

interface Props {
  appointment?: Appointment;
}

export const AppointmentDate = ({ appointment }: Props) => {
  if (!appointment) {
    return null;
  }
  const parsedDate = appointmentToMoment(appointment);
  return <SmartDate date={parsedDate} />;
};
