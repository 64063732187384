import React from 'react';
import EventEmitter from 'events';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'timers';
import toast from 'react-hot-toast';
import { isDevelopmentMode } from './isDevelopmentMode';

let interval: any = null;
let offlineToastId: string | undefined;
let offlineToastTimeout: NodeJS.Timeout | undefined;

export let currentConnectionStatus: 'online' | 'offline' = 'online';

export const connectionStatus = new EventEmitter();

const showErrorMessage = () => {
  const Message = ({ children, t }: any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p style={{ flex: 1, margin: 0, padding: 0 }}>{children}</p>
      <button
        style={{
          background: 'white',
          border: 'none',
          borderRadius: 4,
          padding: '5px 10px',
          marginLeft: 15,
          backgroundColor: 'white',
        }}
        onClick={() => {
          toast.dismiss(t.id);
          offlineToastTimeout = setTimeout(showErrorMessage, 4000);
        }}
      >
        Dismiss
      </button>
    </div>
  );
  offlineToastId = toast.error(
    t => <Message t={t}>Connection is lost or unstable</Message>,
    { duration: Infinity }
  );
};

export const startPolling = () => {
  interval = setInterval(
    async () => {
      try {
        await fetch('https://httpbin.org/get');
        if (currentConnectionStatus === 'offline') {
          currentConnectionStatus = 'online';
          if (offlineToastId) {
            toast.dismiss(offlineToastId);
            offlineToastId = undefined;
          }
          if (offlineToastTimeout) {
            clearTimeout(offlineToastTimeout);
          }
          connectionStatus.emit(currentConnectionStatus);
        }
      } catch {
        if (currentConnectionStatus === 'online') {
          currentConnectionStatus = 'offline';
          showErrorMessage();
          connectionStatus.emit(currentConnectionStatus);
        }
      }
    },
    isDevelopmentMode ? 3000 : 30000
  ); //
};

export const stopPolling = () => {
  if (interval) {
    clearInterval(interval);
  }
};
