import React from 'react';
import { Therapist } from 'types/types';
import { Flex, Text } from 'rebass';

interface Props {
  therapist: Therapist;
}

export const TherapistAddress = ({
  therapist: { address, city, state, zip },
}: Props) => (
  <Flex flexDirection="column">
    <Text>{address}</Text>
    <Text>
      {city}, {state} {zip}
    </Text>
  </Flex>
);
