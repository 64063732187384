import React from 'react';
import { Flex } from 'rebass';
import therapistIcon from 'assets/therapistIcon.png';

interface Props {
  width?: number;
  height?: number;
}

export const DefaultTherapistAvatar = ({ width = 60, height = 60 }: Props) => (
  <Flex>
    <img
      src={therapistIcon}
      width={width}
      height={height}
      alt="therapist avatar"
    />
  </Flex>
);
