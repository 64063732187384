import React from 'react';
import { PINK } from 'theme/colors';
import { Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner';

type Props = FlexProps &
  CssTypeFix & {
    isLoading: boolean;
    children?: React.ReactNode;
    size?: number;
    spinnerColor?: string;
  };

export const Loader = ({
  isLoading,
  children = null,
  size = 50,
  spinnerColor = PINK,
  ...restProps
}: Props) => {
  if (isLoading) {
    return (
      <Flex flex={1} justifyContent="center" alignItems="center" {...restProps}>
        <LoadingSpinner size={size} />
      </Flex>
    );
  }
  return <>{children}</>;
};
