import React, { useMemo, useRef } from 'react';
import { Box, Button, Flex } from 'rebass';
import { RenderParams } from '../FileInputWrapper/FileInputWrapper';
import { SimpleFileInputWrapper } from '../FileInputWrapper/SimpleFileInputWrapper';
import { readAndCompressImage } from 'browser-image-resizer';

interface Props {
  onSubmit: (result: string) => void;
  onCancel: () => void;
  accept?: string; // file types accepted by the uploader
}

export const CompositeImageInput = ({ onSubmit, onCancel, accept }: Props) => {
  const frontImage = useRef<HTMLImageElement | null>(null);
  const backImage = useRef<HTMLImageElement | null>(null);
  const getButtonRenderer = ({ label }: { label: string }) => ({
    handleClick,
  }: RenderParams) => (
    <Button width="40%" onClick={handleClick}>
      {label}
    </Button>
  );
  const firstButtonRenderer = useMemo(
    () =>
      getButtonRenderer({
        label: 'Front Side',
      }),
    []
  );
  const secondButtonRenderer = useMemo(
    () =>
      getButtonRenderer({
        label: 'Back Side',
      }),
    []
  );

  const compositeImageCanvas = useRef<HTMLCanvasElement>(null);

  const handleImageChange = (
    callback: (imageDataURL: string | null) => void
  ) => (image: File) => {
    // Note: A single file comes from event.target.files on <input>
    readAndCompressImage(image, {
      quality: 1,
      maxWidth: 1200,
      maxHeight: 1200,
      debug: false,
    }).then(resizedImage => {
      const fileReader = new FileReader();
      if (fileReader && resizedImage) {
        fileReader.onload = () => {
          callback(fileReader.result as string);
        };
        fileReader.readAsDataURL(resizedImage);
      }
    });
  };

  const handleFirstImageChange = handleImageChange(frontImageDataURL => {
    const canvas = compositeImageCanvas.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas) {
      return;
    }
    const img = new Image();
    img.src = frontImageDataURL as string;
    img.onload = () => {
      const back = backImage.current;
      canvas.height = back ? back.height + img.height : img.height;
      canvas.width = back ? Math.max(back.width, img.width) : img.width;
      ctx.drawImage(img, 0, 0);
      if (back) {
        ctx.drawImage(back, 0, img.height);
      }
      frontImage.current = img;
    };
  });
  const handleBackImageChange = handleImageChange(backImageDataURL => {
    const canvas = compositeImageCanvas.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas) {
      return;
    }
    const img = new Image();
    img.src = backImageDataURL as string;
    img.onload = () => {
      const front = frontImage.current;
      canvas.height = front ? front.height + img.height : img.height;
      canvas.width = front ? Math.max(front.width, img.width) : img.width;
      ctx.drawImage(img, 0, front ? front.height : 0);
      if (front) {
        ctx.drawImage(front, 0, 0);
      }
      backImage.current = img;
    };
  });

  const submitCombinedImages = () => {
    const submit = async () => {
      if (frontImage.current && backImage.current) {
        if (compositeImageCanvas.current) {
          onSubmit(compositeImageCanvas.current.toDataURL('image/png'));
        }
      }
    };
    submit();
  };

  return (
    <Flex flexDirection="column" height="100%" width="100%">
      <Flex
        width="100%"
        justifyContent="space-around"
        marginTop={3}
        marginBottom={2}
      >
        <SimpleFileInputWrapper
          onFileSubmit={handleFirstImageChange}
          render={firstButtonRenderer}
          accept={accept}
        />
        <SimpleFileInputWrapper
          onFileSubmit={handleBackImageChange}
          render={secondButtonRenderer}
          accept={accept}
        />
      </Flex>
      <Box overflow="auto" textAlign="center" flex={1}>
        <canvas
          ref={compositeImageCanvas}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        ></canvas>
      </Box>
      <Flex
        width="100%"
        justifyContent="space-around"
        marginTop={2}
        marginBottom={3}
      >
        <Button width="40%" onClick={submitCombinedImages}>
          Submit
        </Button>
        <Button width="40%" onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </Flex>
    </Flex>
  );
};
