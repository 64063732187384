import React from 'react';
import { Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';
import 'components/FullOverlay/FullOverlay.css';

export type Props = FlexProps & CssTypeFix;

export const FullOverlay = (props: Props) => (
  <Flex
    className="full-height"
    width="100vw"
    {...props}
    sx={{
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 999,
      ...(props.sx || {}),
    }}
  />
);
