import { AppFile, FetchStatus } from '../types/types';
import { createFetchStatusReducer } from '../utils/createFetchStatusReducer';
import {
  FETCH_APP_FILE_FAILURE,
  FETCH_APP_FILE_START,
  FETCH_APP_FILE_SUCCESS,
  FETCH_APP_FILES_FAILURE,
  FETCH_APP_FILES_START,
  FETCH_APP_FILES_SUCCESS,
  UPLOAD_APP_FILE_SUCCESS,
} from '../modules/files/actionTypes';
import { Action, handleActions } from 'redux-actions';
import {
  FetchAppFilesSuccessPayload,
  FetchAppFileSuccessPayload,
  UploadAppFileSuccessPayload,
} from '../modules/files/actions';
import { combineReducers } from 'redux';
import _ from 'lodash';
import { getAppFileType } from '../utils/file/getAppFileType';
import { stripFileExtension } from '../utils/stripFileExtension';
import { AppFileType } from 'utils/file/constants';

type FileRecord = Record<string, AppFile>; // key is file name
export interface FilesState {
  fetchStatus: FetchStatus;
  fileFetchStatus: FetchStatus;
  pendingApprovals: Record<string, (AppFileType | undefined)[]>; // key is case ID
  normalized: Record<string, FileRecord>; // key is case ID
}

const fileFetchStatus = createFetchStatusReducer({
  fetchingActions: [FETCH_APP_FILE_START],
  successActions: [FETCH_APP_FILE_SUCCESS],
  failureActions: [FETCH_APP_FILE_FAILURE],
});

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [FETCH_APP_FILES_START],
  successActions: [FETCH_APP_FILES_SUCCESS],
  failureActions: [FETCH_APP_FILES_FAILURE],
});

const pendingApprovals = handleActions<FilesState['pendingApprovals'], any>(
  {
    [UPLOAD_APP_FILE_SUCCESS]: (
      state,
      { payload }: Action<UploadAppFileSuccessPayload>
    ) => {
      const shouldFileWaitForApproval =
        payload.uploadType === AppFileType.AbnForm ||
        payload.uploadType === AppFileType.Rx;

      if (
        !payload.caseId ||
        !payload.uploadType ||
        !shouldFileWaitForApproval
      ) {
        return state;
      }

      const caseFilesPendingApproval = state[payload.caseId] || [];
      const isUploadedFilePendingApproval = caseFilesPendingApproval.some(
        fileType => fileType === payload.uploadType
      );

      if (isUploadedFilePendingApproval) {
        return state;
      }

      return {
        ...state,
        [payload.caseId]: [...caseFilesPendingApproval, payload.uploadType],
      };
    },
    [FETCH_APP_FILES_SUCCESS]: (
      state,
      { payload: { files, caseId } }: Action<FetchAppFilesSuccessPayload>
    ) => {
      const approvedFiles = files.filter(file => {
        const isAbnForm = _.startsWith(file.fileName, AppFileType.AbnForm);
        const isRx = _.startsWith(file.fileName, AppFileType.Rx);
        return isAbnForm || isRx;
      });

      const previousFileTypesPendingApproval = state[caseId] || [];

      const currentFileTypesPendingApproval = previousFileTypesPendingApproval.filter(
        (pendingApprovalFileType?: AppFileType) =>
          approvedFiles.some(
            file => !_.startsWith(file.fileName, pendingApprovalFileType)
          )
      );

      return {
        ...state,
        [caseId]: currentFileTypesPendingApproval,
      };
    },
  },
  {}
);

const normalized = handleActions<FilesState['normalized'], any>(
  {
    [UPLOAD_APP_FILE_SUCCESS]: (
      state,
      { payload }: Action<UploadAppFileSuccessPayload>
    ) => {
      // Update the file ID
      const { filename, fileId } = payload;
      const strippedFileName = stripFileExtension({ filename: filename || '' });
      if (strippedFileName) {
        return {
          ...state,
          [payload.caseId || '']: {
            ...state[payload.caseId || ''],
            [strippedFileName]: {
              ...state[payload.caseId || ''][strippedFileName],
              fileName: stripFileExtension({ filename: fileId }),
              fileId,
            },
          },
        };
      }
      return state;
    },
    [FETCH_APP_FILE_SUCCESS]: (
      state,
      {
        payload: { fileName, fileUrl, caseId },
      }: Action<FetchAppFileSuccessPayload>
    ) => {
      const appFileType = getAppFileType(fileName) || 'unknown';
      return {
        ...state,
        [caseId]: {
          ...(state[caseId] || {}),
          [appFileType]: {
            ...(state[caseId][appFileType] || {}),
            fileUrl,
          },
        },
      };
    },
    [FETCH_APP_FILES_SUCCESS]: (
      state,
      { payload: { files, caseId } }: Action<FetchAppFilesSuccessPayload>
    ) => ({
      /*
        NOTE: the last item for each file type in the returned attachments array
        is used. Response must be ordered or users won't see the most recent image
      */
      // overwrite previous state for security reasons
      [caseId]: _.keyBy(
        files,
        fileResult => getAppFileType(fileResult.fileName) || 'unknown'
      ),
    }),
  },
  {}
);

export default combineReducers<FilesState>({
  normalized,
  fetchStatus,
  fileFetchStatus,
  pendingApprovals,
});
