import React from 'react';
import { Flex, Text } from 'rebass';
import { ReactComponent as SuccessImage } from 'assets/Successful _icon@1x.svg';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { useGoTo } from 'utils/hooks/useGoTo';
import { MORE_PATH } from 'routing/routePaths';

export const PatientReferralSuccessPage = () => {
  const goToMore = useGoTo(MORE_PATH);
  return (
    <Flex flexDirection="column" width="100%" alignItems="center">
      <Flex
        flexDirection="column"
        marginTop="35%"
        textAlign="center"
        alignItems="center"
        marginLeft={4}
        marginRight={4}
      >
        <Text fontSize="20pt" marginBottom={3}>
          Success!
        </Text>
        <SuccessImage height={80} width={80} />
        <Text fontSize="20pt" marginTop={4}>
          Patient referral has been sent out!
        </Text>
      </Flex>
      <Flex flex={1} />
      <FooterButton label="Ok" onClick={goToMore} />
    </Flex>
  );
};
