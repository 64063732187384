import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Appointment, FetchStatus } from 'types/types';
import { setAppointmentAsFinalized as finalizeAppointmentAction } from 'modules/appointments/actions';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { getAppointmentsFetchStatus } from 'selectors/appointments/getAppointmentsFetchStatus';
import { useFetchStatusHooks } from 'utils/hooks/useFetchStatusHooks';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { SCHEDULE_PATH } from 'routing/routePaths';

/**
 * This page will set an appointment to "Paid" with amount paid, redirecting to a URL of choice when
 * finished.
 */

const TITLE = 'Payment in Progress';

interface OwnProps {
  appointmentId: string;
  onSuccess: () => void;
}

interface StateProps {
  appointment?: Appointment;
  fetchStatus: FetchStatus;
}

interface DispatchProps {
  finalizeAppointment: typeof finalizeAppointmentAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  { appointmentId }: OwnProps
): StateProps => {
  const appointment = getAppointment(state, { appointmentId });
  return {
    appointment,
    fetchStatus: getAppointmentsFetchStatus(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  finalizeAppointment: finalizeAppointmentAction,
};

const TriggerAppointmentPaymentUpdateComponent = ({
  fetchStatus,
  appointmentId,
  appointment,
  finalizeAppointment,
  onSuccess,
}: Props) => {
  const { armHook, isArmed } = useFetchStatusHooks({
    fetchStatus,
    onSuccess,
  });

  useEffect(() => {
    finalizeAppointment({
      id: Number(appointmentId),
      amountPaid: appointment?.patientResponsibility || undefined,
    });
    armHook();
    // Disabling since we only want to run this once
  }, []); // eslint-disable-line

  if (isArmed && fetchStatus === FetchStatus.Failure) {
    return (
      <PageWrapper title={TITLE}>
        Payment succeeded, however appointment update failed. Please contact
        operations
        <CustomTextLink to={SCHEDULE_PATH} marginTop={3}>
          Return to schedule
        </CustomTextLink>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title={TITLE}>
      Payment succeeded, updating appointment record...
    </PageWrapper>
  );
};

export const TriggerAppointmentPaymentUpdate = connect(
  mapStateToProps,
  mapDispatchToProps
)(TriggerAppointmentPaymentUpdateComponent);
