import { EmploymentType, Therapist } from 'types/types';
import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';
import {
  employmentTypeToFtPt,
  ftPtToEmploymentType,
} from 'modules/therapist/constants';
import { convertCamelToSnakeCase } from 'utils/convertCamelToSnakeCase';
import { pipe } from 'rxjs/internal-compatibility';

const translateEmploymentType = (rawValue: string): EmploymentType =>
  ftPtToEmploymentType[rawValue] || EmploymentType.None;

const translateTherapistEmploymentType = (
  untranslatedTherapist: Object
): Object => {
  // @ts-ignore
  const { employmentType, ...remainingValues } = untranslatedTherapist;
  return {
    ...remainingValues,
    employmentType: translateEmploymentType(employmentType),
  };
};

const reverseTranslateEmploymentType = (
  employmentType: EmploymentType
): string | null => employmentTypeToFtPt[employmentType] || null;
const reverseTranslateTherapistEmploymentType = (
  therapist: Partial<Therapist>
): Object => {
  const { employmentType, ...remainingValues } = therapist;
  const result = { ...remainingValues };
  if (employmentType) {
    // @ts-ignore
    result.ftPt = reverseTranslateEmploymentType(employmentType);
  }
  return result;
};

export const translateTherapist = pipe(
  convertSnakeToCamelCase,
  translateTherapistEmploymentType
);

export const reverseTranslateTherapist = pipe(
  reverseTranslateTherapistEmploymentType,
  convertCamelToSnakeCase
);
