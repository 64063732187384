import { CustomEpic } from "types/types";
import { FetchInsuranceActionPayload, fetchInsuranceFailure, fetchInsuranceSuccess } from "modules/insurance/actions";
import { ofType } from "redux-observable";
import { FETCH_INSURANCE_START } from "modules/insurance/actionTypes";
import { switchMap } from "rxjs/operators";
import { getInsuranceUrl } from "modules/insurance/utils/getInsuranceUrl";
import { handleAjaxSuccess } from "utils/epics/handleAjaxSuccess";
import { translateInsurance } from "modules/insurance/translator";
import { handleAjaxError } from "utils/epics/handleAjaxError";
import { getAuthHeaders } from "api/getAuthHeaders";

export const fetchInsurance$: CustomEpic<FetchInsuranceActionPayload> = (
  action$,
  state$,
  { getApi$ },
) => action$.pipe(
  ofType(FETCH_INSURANCE_START),
  switchMap(
    ({
      payload: {
        insuranceId,
        insuranceName,
      },
    }) => getApi$({
      endpoint: getInsuranceUrl({
        insuranceId,
        insuranceName: insuranceName && encodeURIComponent(insuranceName),
      }),
      headers: getAuthHeaders(state$.value),
    })
      .pipe(
        handleAjaxSuccess(fetchInsuranceSuccess, translateInsurance),
        handleAjaxError(fetchInsuranceFailure),
      )
  )
);
