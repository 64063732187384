import React from 'react';
import { OfferStatus } from 'types/types';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { ClaimButton } from 'containers/OfferActionButton/ClaimButton';
import { UnclaimButton } from 'containers/OfferActionButton/UnclaimButton';

interface Props {
  offerStatus: OfferStatus;
  offerId: number;
}

const offerStatusLabels: Record<OfferStatus, React.ReactNode | null> = {
  [OfferStatus.PatientCancelled]: null,
  [OfferStatus.Scheduled]: null,
  [OfferStatus.Offered]: 'Claim Offer',
  [OfferStatus.Unclaimed]: null,
  [OfferStatus.Claimed]: 'Unclaim Offer',
  [OfferStatus.Ready]: null,
};

export const OfferActionButton = ({ offerStatus, offerId }: Props) => {
  const offerStatusLabel = offerStatusLabels[offerStatus];

  if (!offerStatusLabel) {
    return null;
  }

  if (offerStatus === OfferStatus.Offered) {
    return <ClaimButton offerId={offerId} label={offerStatusLabel} />;
  }

  if (offerStatus === OfferStatus.Claimed) {
    return <UnclaimButton offerId={offerId} label={offerStatusLabel} />;
  }

  return <FooterButton label={offerStatusLabel} />;
};
