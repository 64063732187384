import React from 'react';
import { Therapist } from 'types/types';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';

interface Props {
  therapist: Therapist;
}

export const TherapistPhoneNumber = ({ therapist }: Props) => (
  <>{formatPhoneNumber(therapist.phone)}</>
);
