import { GlobalState } from "reducers";
import { Appointment, Case, Doctor, Lead, Patient } from "types/types";
import { getPatient } from "selectors/patients/getPatient";
import { getCase } from "selectors/cases/getCase";
import { getDoctor } from "selectors/doctors/getDoctor";
import { getLead } from "selectors/leads/getLead";

interface Props {
  appointment: Appointment;
}

export interface AppointmentData {
  patient?: Patient;
  therapyCase?: Case;
  doctor?: Doctor;
  lead?: Lead;
}

export const getAppointmentData = (state: GlobalState, { appointment }: Props): AppointmentData => {
  const therapyCase = getCase(state, { caseId: String(appointment.caseId) });
  return {
    patient: getPatient(state, { patientId: appointment.patientId }),
    therapyCase,
    doctor: therapyCase && getDoctor(state, { doctorId: String(therapyCase.doctorId) }),
    lead: therapyCase && getLead(state, { leadId: String(therapyCase.leadId )}),
  };
};
