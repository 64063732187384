import React from 'react';
import { Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';

export type Props = FlexProps & CssTypeFix;

export const FloatingCard = ({ children, ...restProps }: Props) => {
  return (
    <Flex
      width="100%"
      marginLeft={1}
      marginRight={1}
      marginTop={1}
      marginBottom={1}
      padding={2}
      sx={{
        border: '1px solid',
        borderColor: 'muted',
        borderRadius: '8px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.2)',
      }}
      {...restProps}
    >
      {children}
    </Flex>
  );
};
