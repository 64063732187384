import { acceptedFileTypesEnvVar, AppFileType } from 'utils/file/constants';
import { getAcceptedMimeTypes } from 'utils/getAcceptedMimeTypes';

export const getAcceptedAppFileExtensions = (fileType: AppFileType): string => {
  const acceptedFileTypes = process.env[acceptedFileTypesEnvVar[fileType] || 0];

  if (!acceptedFileTypes) {
    return '';
  }

  return getAcceptedMimeTypes(acceptedFileTypes);
};
