import React from 'react';
import { Patient } from 'types/types';
import { PatientCardInfo } from 'components/PatientCardInfo/PatientCardInfo';
import { Flex, Text } from 'rebass';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { getCreditCardInputPath } from 'routing/routeFunctions';
import { getPatientPaymentSourceId } from 'utils/patient/getPatientPaymentSourceId';
import { useDispatch } from 'react-redux';
import { updatePatientPaymentMethod } from 'modules/patients/actions';

interface Props {
  appointmentId?: string;
  patient: Patient;
}

export const PatientCardLink = ({ appointmentId, patient }: Props) => {
  const dispatch = useDispatch();
  const paymentSourceId = getPatientPaymentSourceId({ patient });
  if (paymentSourceId) {
    return (
      <Flex alignItems="center">
        <PatientCardInfo patient={patient} />
        {appointmentId && (
          <>
            <Text marginRight={1}>,</Text>
            <CustomTextLink to={getCreditCardInputPath({ appointmentId })}>
              Update
            </CustomTextLink>
            {patient.cardBrand && patient.cardLast4 ? (
              <Text
                variant="link"
                onClick={() => {
                  dispatch(
                    updatePatientPaymentMethod({
                      patientId: `${patient.id}`,
                      paymentMethod: {
                        id: paymentSourceId,
                        card: {
                          brand: patient.cardBrand as string,
                          last4: patient.cardLast4 as string,
                        },
                      },
                    })
                  );
                }}
              >
                , Refresh
              </Text>
            ) : null}
          </>
        )}
      </Flex>
    );
  }
  if (appointmentId) {
    return (
      <Flex>
        <Text marginRight={1}>No card on file.</Text>
        <CustomTextLink to={getCreditCardInputPath({ appointmentId })}>
          Add one
        </CustomTextLink>
      </Flex>
    );
  }
  return <Text>No card on file</Text>;
};
