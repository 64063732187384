import React, { useState } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { CloseIcon } from 'components/icons/CloseIcon';
import { useGoBack } from 'utils/hooks/useGoBack';
import { ListItem, ListSelector } from 'components/ListSelector/ListSelector';
import { match } from 'react-router-dom';
import { AddressUpdatePathParams } from 'routing/routePaths';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { Patient } from 'types/types';
import { getPatient } from 'selectors/patients/getPatient';
import { PatientAddress } from 'components/PatientAddress/PatientAddress';
import { useGoTo } from 'utils/hooks/useGoTo';
import { getAddressInputPath } from 'routing/routeFunctions';

/**
 * Displays the list of credit addresses, clicking on one will lead user to payment review page
 */

interface OwnProps {
  match: match<AddressUpdatePathParams>;
}

interface StateProps {
  patient?: Patient;
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  {
    match: {
      params: { patientId },
    },
  }: OwnProps
): StateProps => ({
  patient: getPatient(state, { patientId: Number(patientId) }),
});

const mapDispatchToProps: DispatchProps = {};

const AddressSelectorPageComponent = ({ patient }: Props) => {
  const goBack = useGoBack();
  const goToInput = useGoTo(
    getAddressInputPath({ patientId: patient?.id || 0 })
  );
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);

  const list: ListItem[] = [
    {
      label: patient ? <PatientAddress patient={patient} /> : '',
      key: patient?.address || '',
    },
  ];

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Place of Service"
        right={<CloseIcon size={20} onClick={goBack} />}
      />
      <ListSelector
        title="Address"
        onClickBottomLink={goToInput}
        bottomLinkText="Add Additional Address"
        list={list}
        onClickOption={setSelectedAddressIndex}
        selectedItemIndex={selectedAddressIndex}
      >
        <Text padding={3} color="grey" fontSize="11pt">
          *Select the location for service. (This will change future
          appointments as well).
        </Text>
      </ListSelector>
      <FooterButton label="Confirm Selected" onClick={goBack} />
    </Flex>
  );
};

export const AddressSelectorPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressSelectorPageComponent);
