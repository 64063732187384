import React from 'react';
import { Flex } from 'rebass';
import { formatAppointmentTime } from 'utils/appointment/formatAppointmentTime';
import { Appointment } from 'types/types';

interface Props {
  appointment: Appointment;
}

export const AppointmentDurationLocationInfo = ({ appointment }: Props) => (
  <Flex flexDirection="column">
    <Flex minWidth={105} fontSize="12pt">
      {formatAppointmentTime({
        appointment,
      })}
    </Flex>
    <Flex>{/* patient home icons go here */}</Flex>
  </Flex>
);
