import React, { useCallback } from 'react';
import { Flex, Text } from 'rebass';
import { useGoBack } from 'utils/hooks/useGoBack';
import { UserDetailsCard } from 'containers/UserDetailsCard/UserDetailsCard';
import { updateTherapist as updateTherapistAction } from 'modules/therapist/actions';
import { useDispatchFunction } from 'utils/hooks/useDispatchFunction';
import { EditUserDetailsPageForm } from 'pages/more/EditUserDetailsPage/EditUserDetailsPageForm';
import { FormValues } from 'pages/more/EditUserDetailsPage/constants';
import { FetchStatusContext } from 'context/FetchStatusContext/FetchStatusContext';
import { useSelector } from 'react-redux';
import { getUserFetchStatus } from 'selectors/user/getUserFetchStatus';
import { useFetchStatusHooks } from 'utils/hooks/useFetchStatusHooks';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { getUserFailureMessage } from 'selectors/user/getUserFailureMessage';

export const EditUserDetailsPage = () => {
  const goBack = useGoBack();
  const updateTherapist = useDispatchFunction(updateTherapistAction);
  const therapistFetchStatus = useSelector(getUserFetchStatus);
  const therapistFailureMessage = useSelector(getUserFailureMessage);

  const { armHook, isArmed } = useFetchStatusHooks({
    fetchStatus: therapistFetchStatus,
    onSuccess: goBack,
    onFailure: () => console.log('failure'),
  });

  const onSubmit = useCallback(
    (values: FormValues) => {
      updateTherapist({ therapist: values });
      armHook();
    },
    [armHook, updateTherapist]
  );

  return (
    <FetchStatusContext.Provider value={therapistFetchStatus}>
      <Flex flexDirection="column" width="100%">
        <UserDetailsCard>
          <CustomTextLink onClick={goBack}>Cancel</CustomTextLink>
        </UserDetailsCard>
        {isArmed && therapistFailureMessage && (
          <Text color="red" alignSelf="center" margin={1}>
            {therapistFailureMessage}
          </Text>
        )}
        <EditUserDetailsPageForm onSubmit={onSubmit} />
      </Flex>
    </FetchStatusContext.Provider>
  );
};
