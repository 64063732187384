
interface Props {
  insuranceId?: string;
  insuranceName?: string;
}

export const getInsuranceUrl = ({ insuranceId, insuranceName }: Props) => {
  const param = insuranceId || insuranceName;
  if (!param) {
    throw new Error('No insurance id or name provided');
  }
  return `insurances/${param}`;
};
