/*
  Convert appointment service type to color code key used in theme
*/
import { AppointmentService } from '../types/types';
import {
  COLOR_CODE_DISCHARGE_KEY,
  COLOR_CODE_EVALUATION_KEY,
  COLOR_CODE_NONE_KEY,
  COLOR_CODE_PATIENT_KEY,
  COLOR_CODE_TREATMENT_KEY,
} from 'theme/constants';

/*
TODO: sort these too
CashTeletherapy = 'Cash Teletherapy',
EvalOnly = 'Eval Only',
MedicareEVisit = 'Midicare E-Visit',
MedicareTeletherapyEval = 'Medicare Teletherapy Eval',
PrivateTeletherapy = 'Private Teletherapy',
 */

export const serviceToColorCodeKey = ({
  service,
}: {
  service: AppointmentService;
}) => {
  switch (service) {
    case AppointmentService.DischargeEval:
      return COLOR_CODE_DISCHARGE_KEY;
    case AppointmentService.PatientDoctorProgressVisit:
      return COLOR_CODE_PATIENT_KEY;
    case AppointmentService.BcCignaEval:
    case AppointmentService.CashEval:
    case AppointmentService.MedicareEval:
    case AppointmentService.PrivateEval:
      return COLOR_CODE_EVALUATION_KEY;
    case AppointmentService.BcCignaHcpTreatment:
    case AppointmentService.BcCignaTreatment:
    case AppointmentService.CashTreatment:
    case AppointmentService.MedicareTreatment:
    case AppointmentService.PrivateTreatment:
    case AppointmentService.BcTeletherapy:
    case AppointmentService.MedicareTeletherapy:
      return COLOR_CODE_TREATMENT_KEY;
    default:
      return COLOR_CODE_NONE_KEY;
  }
};
