import React from 'react';
import { Box, BoxProps, Text } from 'rebass';
import { Input, InputProps } from '@rebass/forms';
import { CssTypeFix } from 'types/types';
import { FormikErrors, FormikTouched } from 'formik';

type Props = {
  error?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined;
  wrapperProps?: BoxProps & CssTypeFix;
} & InputProps &
  CssTypeFix;

export const TextField = ({
  error,
  touched,
  wrapperProps = {},
  ...rest
}: Props) => (
  <Box
    marginTop={2}
    sx={{
      flexShrink: 1, // for safari
    }}
    {...wrapperProps}
  >
    <Input
      sx={{
        border: 'border',
        borderRadius: 'borderRadius',
      }}
      {...rest}
    />
    <Text color="red" fontSize="10pt">
      {touched && error}
    </Text>
  </Box>
);
