import { CustomEpic } from "types/types";
import { ofType } from "redux-observable";
import { FETCH_ALL_PATIENTS_START } from "modules/patients/actionTypes";
import { switchMap } from "rxjs/operators";
import { fetchAllPatientsUrl } from "modules/patients/constants";
import { getAuthHeaders } from "api/getAuthHeaders";
import { handleAjaxSuccess } from "utils/epics/handleAjaxSuccess";
import {
  FetchAllPatientsActionPayload,
  fetchAllPatientsFailure,
  fetchAllPatientsSuccess
} from "modules/patients/actions";
import { translatePatientList } from "modules/patients/utils/translatePatientList";
import { handleAjaxError } from "utils/epics/handleAjaxError";


export const fetchAllPatients$: CustomEpic<FetchAllPatientsActionPayload> = (
  action$,
  state$,
  { getApi$ },
) => action$.pipe(
  ofType(FETCH_ALL_PATIENTS_START),
  switchMap(
    ({
      payload: {
        activeCasesOnly = true,
      },
     }) => getApi$({
      endpoint: fetchAllPatientsUrl,
      headers: getAuthHeaders(state$.value),
      payload: {
        ...(activeCasesOnly ? {
          active_case: true,
        }: {}),
      },
    })
      .pipe(
        handleAjaxSuccess(fetchAllPatientsSuccess, translatePatientList),
        handleAjaxError(fetchAllPatientsFailure),
      ),
  ),
);
