import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Appointment, Case, Patient } from 'types/types';
import { getPatient } from 'selectors/patients/getPatient';
import { DetailedPatientInfo } from 'components/DetailedPatientInfo/DetailedPatientInfo';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { getCase } from 'selectors/cases/getCase';
import { getDurationFromAppointment } from 'selectors/appointmentServiceTypes/getDurationFromAppointment';

interface OwnProps {
  appointmentId: string;
}

interface StateProps {
  patient?: Patient;
  therapyCase?: Case;
  appointment?: Appointment;
  duration?: number;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  { appointmentId }: OwnProps
): StateProps => {
  const appointment = getAppointment(state, { appointmentId });

  return {
    appointment,
    therapyCase: getCase(state, { caseId: String(appointment?.caseId) }),
    patient: getPatient(state, { patientId: appointment?.patientId || 0 }),
    duration: getDurationFromAppointment(state, { appointmentId }),
  };
};

const ConnectedDetailedPatientInfoComponent = ({
  patient,
  therapyCase,
  appointment,
  duration,
}: Props) => {
  if (patient && therapyCase && appointment) {
    return (
      <DetailedPatientInfo
        patient={patient}
        therapyCase={therapyCase}
        appointment={appointment}
        duration={duration}
      />
    );
  }
  return null;
};

export const ConnectedDetailedPatientInfo = connect(mapStateToProps)(
  ConnectedDetailedPatientInfoComponent
);
