import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Text } from 'rebass';
import { PaymentSuccessPathParams, SCHEDULE_PATH } from 'routing/routePaths';
import { match, useHistory } from 'react-router-dom';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { Appointment } from 'types/types';
import { AppointmentNotFoundPage } from 'pages/appointmentNotFound/AppointmentNotFoundPage';
import { SuccessPageTemplate } from 'containers/SuccessPageTemplate/SuccessPageTemplate';
import { formatCurrency } from 'utils/formatCurrency';
import { isCardRequired } from 'utils/appointment/isCardRequired';
import { getPostSessionPath } from 'routing/routeFunctions';

// https://app.zeplin.io/project/5e0510850d97c818513015b3/screen/5e0c0df721e80e1631ef75b7

interface OwnProps {
  match: match<PaymentSuccessPathParams>;
}

interface StateProps {
  appointment?: Appointment;
  hadPaid: boolean;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  {
    match: {
      params: { appointmentId },
    },
  }: OwnProps
): StateProps => {
  const appointment = getAppointment(state, { appointmentId });
  return {
    appointment,
    hadPaid: !!appointment && isCardRequired({ appointment }),
  };
};

const PaymentSuccessComponent = ({ appointment, hadPaid }: Props) => {
  const { push } = useHistory();
  const appointmentId = appointment?.id;
  const onFooterClick = useCallback(() => {
    if (hadPaid && appointmentId) {
      push(getPostSessionPath({ appointmentId }));
    } else {
      push(SCHEDULE_PATH);
    }
  }, [push, hadPaid, appointmentId]);

  if (!appointment) {
    return <AppointmentNotFoundPage />;
  }

  return (
    <SuccessPageTemplate
      therapyCaseId={appointment.caseId}
      patientId={appointment.patientId}
      footerLabel={hadPaid ? 'Schedule' : 'Back to my schedule'}
      onFooterClick={onFooterClick}
    >
      <Text fontSize="20pt">Success!</Text>
      <Text fontSize="20pt" fontWeight="bold">
        Total Fee:{' '}
        {appointment?.patientResponsibility
          ? formatCurrency({
              amount: parseFloat(appointment?.patientResponsibility || ''),
            })
          : 'None'}
      </Text>
      <Text color="grey">Charge Submitted</Text>
    </SuccessPageTemplate>
  );
};

export const PaymentSuccess = connect(mapStateToProps)(PaymentSuccessComponent);
