import { createAction } from 'redux-actions';
import {
  EVENT_HOOK_FAILURE,
  EVENT_HOOK_SUCCESS,
  FILE_UPLOAD_HOOK_FAILURE,
  FILE_UPLOAD_HOOK_SUCCESS,
} from './actionTypes';

// Success response: "Got it, thanks! Sent notice to schedule@petehealth.com."
export const fileUploadHookSuccess = createAction(FILE_UPLOAD_HOOK_SUCCESS);

export const fileUploadHookFailure = createAction(FILE_UPLOAD_HOOK_FAILURE);

interface EventHookSuccessParams {
  caseId: number;
  eventType: string;
}
export const eventHookSuccess = createAction<EventHookSuccessParams>(
  EVENT_HOOK_SUCCESS
);

export const eventHookFailure = createAction(EVENT_HOOK_FAILURE);
