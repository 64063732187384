import { from } from 'rxjs';
import { api } from './api';

type PostParams = {
  endpoint: string;
  payload?: Record<string, string>;
  headers?: Record<string, string>;
};

export const post$ = ({ endpoint, headers = {}, payload }: PostParams) => {
  return from(
    api({
      url: endpoint,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      data: payload ? payload : {},
    })
  );
};
