import { combineReducers } from 'redux';
import { FetchStatus, Patient } from 'types/types';
import { Action, handleActions } from 'redux-actions';
import { GetScheduleSuccessActionPayload } from 'modules/schedule/actions';
import { GET_SCHEDULE_SUCCESS } from 'modules/schedule/actionTypes';
import {
  FETCH_ALL_PATIENTS_FAILURE,
  FETCH_ALL_PATIENTS_START,
  FETCH_ALL_PATIENTS_SUCCESS,
  FETCH_CURRENT_PATIENTS_FAILURE,
  FETCH_CURRENT_PATIENTS_START,
  FETCH_CURRENT_PATIENTS_SUCCESS,
  UPDATE_PATIENT_FAILURE,
  UPDATE_PATIENT_START,
  UPDATE_PATIENT_SUCCESS,
} from 'modules/patients/actionTypes';
import {
  FetchAllPatientsSuccessPayload,
  FetchCurrentPatientsSuccessPayload,
  UpdatePatientSuccessPayload,
} from 'modules/patients/actions';
import _ from 'lodash';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';

export interface PatientMap {
  [key: string]: Patient;
}
export interface PatientsState {
  patients: PatientMap;
  fetchStatus: FetchStatus;
}

// some sample data
const initialState: PatientMap = {};

export const patients = handleActions<PatientMap, any>(
  {
    [GET_SCHEDULE_SUCCESS]: (
      state,
      action: Action<GetScheduleSuccessActionPayload>
    ) => ({
      ...state,
      ...action.payload.patients,
    }),
    [FETCH_CURRENT_PATIENTS_SUCCESS]: (
      state,
      action: Action<FetchCurrentPatientsSuccessPayload>
    ) => ({
      ...state,
      ..._.keyBy(action.payload, 'id'),
    }),
    [FETCH_ALL_PATIENTS_SUCCESS]: (
      state,
      action: Action<FetchAllPatientsSuccessPayload>
    ) => ({
      ...state,
      ..._.keyBy(action.payload, 'id'),
    }),
    [UPDATE_PATIENT_SUCCESS]: (
      state,
      action: Action<UpdatePatientSuccessPayload>
    ) => ({
      ...state,
      [action.payload.id]: action.payload,
    }),
  },
  initialState
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [
    FETCH_CURRENT_PATIENTS_START,
    FETCH_ALL_PATIENTS_START,
    UPDATE_PATIENT_START,
  ],
  successActions: [
    FETCH_CURRENT_PATIENTS_SUCCESS,
    FETCH_ALL_PATIENTS_SUCCESS,
    UPDATE_PATIENT_SUCCESS,
  ],
  failureActions: [
    FETCH_CURRENT_PATIENTS_FAILURE,
    FETCH_ALL_PATIENTS_FAILURE,
    UPDATE_PATIENT_FAILURE,
  ],
});

export default combineReducers<PatientsState>({
  patients,
  fetchStatus,
});
