import React, { useCallback } from 'react';
import { useRouteMatch, useHistory } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { RescheduleReasonPage } from "pages/appointment/AppointmentReschedulePage/RescheduleReasonPage";
import { RescheduleTimePage } from "pages/appointment/AppointmentReschedulePage/RescheduleTimePage/RescheduleTimePage";
import { TriggerRestartReschedule } from "pages/appointment/AppointmentReschedulePage/TriggerRestartReschedule";

const timePageRoute = 'time';

interface Props {
  onSubmit: () => void;
}

export const RescheduleRouting = ({ onSubmit }: Props) => {
  const { path, url } = useRouteMatch();
  const { push } = useHistory();

  const timePagePath = `${path}/${timePageRoute}`;
  const timePageUrl = `${url}/${timePageRoute}`;

  const goToTimePage = useCallback(
    () => push(timePageUrl),
    [push, timePageUrl],
  );

  return (
    <>
      <Switch>
        <Route exact path={path}>
          <RescheduleReasonPage
            onSubmit={goToTimePage}
          />
        </Route>
        <>
          {/* This redirects the user back to the start if the following pages are rendered with invalid form state */}
          <TriggerRestartReschedule
            initialPath={path}
          />
          <Switch>
            <Route
              exact
              path={timePagePath}
            >
              <RescheduleTimePage
                onSubmit={onSubmit}
              />
            </Route>
          </Switch>
        </>
      </Switch>
    </>
  );
};
