import { createSelector } from "reselect";
import { getAppointment } from "selectors/appointments/getAppointment";
import { getAppointmentServiceTypes } from "selectors/appointmentServiceTypes/getAppointmentServiceTypes";

export const getAppointmentServiceTypeFromAppointment = createSelector(
  getAppointment,
  getAppointmentServiceTypes,
  (appointment, appointmentServiceTypes) =>
    appointment && Object.values(appointmentServiceTypes).find(
      ({ name }) => name === appointment.service,
    ),
);
