import React from 'react';
import { Flex, Text } from 'rebass';

interface Props {
  children: React.ReactNode;
  label: string;
}

export const TherapistInfoDisplayer = ({ children, label }: Props) => {
  return (
    <Flex flexDirection="column" marginBottom={2}>
      <Text color="black" fontWeight="bold" marginBottom={1}>
        {label}
      </Text>
      <Text color="grey">{children}</Text>
    </Flex>
  );
};
