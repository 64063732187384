import { Appointment } from 'types/types';
import { sortAppointmentsByTime } from 'utils/appointment/sortAppointmentsByTime';

/**
 * Converts a dictionary of appointments into a list sorted by datetime
 * @param dict Dictionary of appointments
 */
export const appointmentsDictToList = (
  dict: Record<string, Appointment>
): Appointment[] => sortAppointmentsByTime(Object.values(dict));
