import { Patient } from 'types/types';
import { patientToString } from 'utils/patient/patientToString';

export const getPatientFromName = ({
  name,
  patients
}: {
  name: string; // "lastName, firstName"
  patients: Patient[];
}) => patients.find(patient => patientToString(patient) === name);
