import React from 'react';
import { OfferStatus } from 'types/types';
import { Flex, Text } from 'rebass';
import { formattedOfferStatus, offerStatusColors } from 'appConstants';

interface Props {
  offerStatus: OfferStatus;
}

export const OfferListHeader = ({ offerStatus }: Props) => {
  const statusStyle = offerStatusColors[offerStatus];

  return (
    <Flex
      margin={1}
      padding={3}
      justifyContent="center"
      backgroundColor={statusStyle.color}
      sx={{
        borderRadius: '8px',
      }}
    >
      <Text color={statusStyle.textColor} fontWeight="bold">
        {formattedOfferStatus[offerStatus]}
      </Text>
    </Flex>
  );
};
