import React, { useCallback } from 'react';
import { Flex } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { useGoBack } from 'utils/hooks/useGoBack';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { Formik } from 'formik';
import { ReferralForm } from 'pages/patientReferral/ReferralForm';
import { PatientReferralFormValues } from 'pages/patientReferral/constants';
import * as Yup from 'yup';
import { PHONE_REGEX } from 'appConstants';
import { referPatient as referPatientAction } from 'modules/patientReferral/actions';
import { useDispatchFunction } from 'utils/hooks/useDispatchFunction';
import { useFetchStatusHooks } from 'utils/hooks/useFetchStatusHooks';
import { useSelector } from 'react-redux';
import { getPatientReferralFetchStatus } from 'selectors/api/getPatientReferralFetchStatus';
import { useGoTo } from 'utils/hooks/useGoTo';
import { PATIENT_REFERRAL_SUCCESS_PATH } from 'routing/routePaths';

const initialValues: PatientReferralFormValues = {
  referralName: '',
  referralEmail: '',
  referralPhone: '',
};

const validationSchema = Yup.object().shape(
  {
    referralName: Yup.string().required('Name is required'),
    referralEmail: Yup.string()
      .email()
      .required('Email is required'),
    referralPhone: Yup.string()
      .matches(PHONE_REGEX, 'Must be a valid phone number')
      .required('Phone number is required'),
  },
  // pairing patient phone and email together prevents a Yup circular dependency error since they both depend on the value of the other
  [['patientPhone', 'patientEmail']]
);

export const PatientReferralPage = () => {
  const goBack = useGoBack();
  const goToReferralSuccess = useGoTo(PATIENT_REFERRAL_SUCCESS_PATH);
  const fetchStatus = useSelector(getPatientReferralFetchStatus);
  const { armHook } = useFetchStatusHooks({
    fetchStatus,
    onSuccess: goToReferralSuccess,
  });

  const renderReferralForm = useCallback(
    props => <ReferralForm {...props} fetchStatus={fetchStatus} />,
    [fetchStatus]
  );

  const referPatient = useDispatchFunction(referPatientAction);
  const callReferPatient = useCallback(
    ({
      patientFirstName,
      patientLastName,
      patientPhone,
      patientEmail,
      referralName,
      referralEmail,
      referralPhone,
    }: PatientReferralFormValues) => {
      referPatient({
        patientFirstName: patientFirstName!,
        patientLastName: patientLastName!,
        patientPhone,
        patientEmail,
        referralName: referralName!,
        referralEmail: referralEmail!,
        referralPhone,
      });
      armHook();
    },
    [armHook, referPatient]
  );
  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Patient Referral"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={callReferPatient}
        validationSchema={validationSchema}
        validateOnMount
      >
        {renderReferralForm}
      </Formik>
    </Flex>
  );
};
