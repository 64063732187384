import React from 'react';
import { Flex } from 'rebass';

import Logo from '../../../assets/peteLogo1.png';
import { LoginWithGoogleButton } from 'components/LoginWithGoogleButton/LoginWithGoogleButton';
import { fetchGoogleOauth2AuthorizationCode } from 'modules/auth/actions';
import { connect, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { DEFAULT_LOGIN_PATH } from 'routing/routePaths';
import { GlobalState } from 'reducers';
import { getIsAuthenticated } from 'selectors/auth/getIsAuthenticated';
import { DevLoginButton } from 'components/DevLoginButton/DevLoginButton';
import { getIsDeveloperLoginEnabled } from 'selectors/features/getIsDeveloperLoginEnabled';

type LoginGoogleComponentProps = {
  isAuthenticated: boolean;
  fetchGoogleAuthorizationCode: typeof fetchGoogleOauth2AuthorizationCode;
};

export const LoginGoogleComponent = ({
  isAuthenticated,
  fetchGoogleAuthorizationCode,
}: LoginGoogleComponentProps) => {
  const isDeveloperLoginEnabled = useSelector(getIsDeveloperLoginEnabled);

  if (isAuthenticated) {
    return <Redirect to={DEFAULT_LOGIN_PATH} />;
  }

  return (
    <Flex flexDirection="column" width="100%" padding={2}>
      <Flex flex="1" justifyContent="center" alignItems="center">
        <img src={Logo} alt="logo" height={78} width={200} />
      </Flex>
      <Flex
        flex="2"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <LoginWithGoogleButton onClick={() => fetchGoogleAuthorizationCode()} />
        {isDeveloperLoginEnabled && (
          <Flex marginTop={4}>
            <DevLoginButton />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export const LoginGoogle = connect(
  (state: GlobalState) => ({
    isAuthenticated: getIsAuthenticated(state),
  }),
  {
    fetchGoogleAuthorizationCode: fetchGoogleOauth2AuthorizationCode,
  }
)(LoginGoogleComponent);
