import React, { SyntheticEvent } from 'react';
import { HeaderBar } from 'components/HeaderBar';
import { CloseIcon } from 'components/icons/CloseIcon';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { Flex } from 'rebass';
import { useGoBack } from 'utils/hooks/useGoBack';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { LabelledRadioFieldGroup } from 'components/forms/LabelledRadioFieldGroup';
import { RescheduleReason } from 'pages/appointment/AppointmentReschedulePage/constants';
import { useField } from 'formik';

const reasonFieldName = 'reason';

const options = [
  {
    label: 'Patient Not Feeling Well',
    value: RescheduleReason.NotFeelingWell,
  },
  {
    label: 'Medical Emergency',
    value: RescheduleReason.MedicalEmergency,
  },
  {
    label: 'Patient Requests to End Early for Personal Reason',
    value: RescheduleReason.PersonalReasons,
  },
  {
    label: 'Therapist needs to reschedule',
    value: RescheduleReason.Therapist,
  },
];

interface Props {
  onSubmit: (e: SyntheticEvent) => void;
}

export const RescheduleReasonPage = ({ onSubmit }: Props) => {
  const goBack = useGoBack();
  const [{ value: fieldValue }] = useField(reasonFieldName);

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Reschedule Appointment"
        right={<CloseIcon size={20} onClick={goBack} />}
      />
      <ScrollBox flexDirection="column" flex={1} padding={2}>
        <LabelledRadioFieldGroup
          headerText="Please select the reason(s):"
          options={options}
          fieldName={reasonFieldName}
        />
      </ScrollBox>
      <FooterButton
        label="Continue"
        onClick={onSubmit}
        disabled={!fieldValue}
      />
    </Flex>
  );
};
