import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Appointment } from 'types/types';
import { getSchedule } from 'selectors/schedule/getSchedule';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { Box, Flex } from 'rebass';
import moment, { Moment } from 'moment';
import { daysOfWeek } from 'appConstants';
import { BACKGROUND_GRADIENT } from 'theme/constants';
import { appointmentToMoment } from 'modules/schedule/utils/appointmentToMoment';

export interface OwnProps {
  weeksToShow?: number;
  startDate?: Moment;
  onDateClick?: (date: Moment) => void;
  selectedDate?: Moment;
  isDateDisabled?: (date: Moment) => boolean;
}

interface StateProps {
  appointments: Appointment[];
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const today = moment();

const renderWeekDay = (weekday: string) => {
  const isWeekdayPassed =
    daysOfWeek.indexOf(weekday) < daysOfWeek.indexOf(moment().format('ddd'));
  return (
    <Box
      sx={{
        ...(isWeekdayPassed ? { color: 'grey' } : {}),
        ...(moment().format('ddd') === weekday ? { fontWeight: 'bold' } : {}),
      }}
    >
      {weekday}
    </Box>
  );
};

const mapStateToProps = (state: GlobalState): StateProps => ({
  appointments: getSchedule(state),
});

const mapDispatchToProps: DispatchProps = {};

const ConnectedDatePickerComponent = ({
  appointments,
  weeksToShow = 1,
  startDate,
  onDateClick,
  selectedDate,
  isDateDisabled,
}: Props) => {
  const appointmentDates = appointments.map(appointment =>
    appointmentToMoment(appointment).format('YYYY-MM-DD')
  );
  const renderDay = (momentDate: Moment) => {
    const isAppointmentDay = appointmentDates.includes(
      momentDate.format('YYYY-MM-DD')
    );
    const isCurrentDay = momentDate.isSame(moment(), 'day');
    const isSelectedDate =
      selectedDate && momentDate.isSame(selectedDate, 'day');

    return (
      <Flex
        sx={{
          ...(isSelectedDate
            ? {
                backgroundColor: 'muted',
              }
            : {}),
        }}
      >
        <Flex
          sx={{
            padding: 2,
            ...(isAppointmentDay && !isCurrentDay
              ? {
                  color: 'primary',
                  textDecoration: 'underline',
                }
              : {}),
            ...(isCurrentDay
              ? {
                  color: 'white',
                  backgroundImage: BACKGROUND_GRADIENT,
                  borderRadius: '50%',
                  fontWeight: 'bold',
                  width: '35px',
                  height: '35px',
                  justifyContent: 'center',
                }
              : {}),
          }}
        >
          {momentDate.format('D')}
        </Flex>
      </Flex>
    );
  };

  return (
    <DatePicker
      weeksToShow={weeksToShow}
      activeDate={today}
      renderWeekDay={renderWeekDay}
      renderDay={renderDay}
      startDate={startDate}
      onDateClick={onDateClick}
      isDateDisabled={isDateDisabled}
    />
  );
};

export const ConnectedDatePicker = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedDatePickerComponent);
