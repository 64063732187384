import { createAction } from "redux-actions";
import { FETCH_LEAD_FAILURE, FETCH_LEAD_START, FETCH_LEAD_SUCCESS } from "modules/leads/actionTypes";
import { Lead } from "types/types";

export interface FetchLeadActionPayload {
  leadId: string;
}
export const fetchLead = createAction<FetchLeadActionPayload>(FETCH_LEAD_START);

export type FetchLeadSuccessPayload = Lead;
export const fetchLeadSuccess = createAction<FetchLeadSuccessPayload>(FETCH_LEAD_SUCCESS);

export const fetchLeadFailure = createAction(FETCH_LEAD_FAILURE);
