import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { Flex } from 'rebass';
import { CloseIcon } from 'components/icons/CloseIcon';
import { SignatureFormFields as SignatureFormFieldsComponent } from 'components/forms/SignatureForm/SignatureFormFields';

export interface FormValues {
  isAgree: boolean;
  signature: string;
}

const initialValues: FormValues = {
  isAgree: false,
  signature: '',
};

export interface OwnProps {
  onClose?: () => void;
  onSubmit: (values: FormValues) => void;
  terms?: React.ReactNode;
}

export const SignatureForm = ({ onClose, onSubmit, terms }: OwnProps) => {
  const SignatureFormFields = useCallback(
    ({ ...props }) => <SignatureFormFieldsComponent {...props} terms={terms} />,
    [terms]
  );
  return (
    <Flex width="100%" flexDirection="column" padding={3}>
      <Flex width="100%" justifyContent="flex-end">
        {onClose && <CloseIcon size={20} onClick={onClose} />}
      </Flex>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnMount>
        {SignatureFormFields}
      </Formik>
    </Flex>
  );
};
