import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';
import _ from 'lodash';
import { claimedOfferPropertyWhitelist } from 'modules/offers/constants';
import { ClaimOfferSuccessPayload } from 'modules/offers/actions';

export const translateClaimOffer = (
  response: Object
): ClaimOfferSuccessPayload => {
  const fullResult = convertSnakeToCamelCase(response);
  return _.pick(fullResult, claimedOfferPropertyWhitelist);
};
