import { CustomEpic } from 'types/types';
import { ofType } from 'redux-observable';
import toast from 'react-hot-toast';
import { switchMap } from 'rxjs/operators';
import { handleAjaxSuccess } from '../../../utils/epics/handleAjaxSuccess';
import { handleAjaxError } from '../../../utils/epics/handleAjaxError';
import {
  UploadAppFileActionPayload,
  uploadAppFileFailure,
  uploadAppFileSuccess,
} from '../actions';
import { UPLOAD_APP_FILE_START } from '../actionTypes';
import { getUploadAppFileEndpoint } from '../constants';
import { getAuthHeaders } from '../../../api/getAuthHeaders';
import { fileToBase64 } from '../../../utils/file/fileToBase64';
import { convertSnakeToCamelCase } from '../../../utils/convertSnakeToCamelCase';

// TODO: Re-enable deferred file upload for new file API.
// Old implementation: https://gitlab.com/PeteHealth/PH-Apps/-/blob/2dc011660ae344af41a60cffc725c7593ebd22a2/therapist/src/modules/dropbox/epics/uploadDropboxFile$.ts
export const uploadAppFile$: CustomEpic<UploadAppFileActionPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(UPLOAD_APP_FILE_START),
    switchMap(async ({ payload, ...props }) => {
      const base64File = await fileToBase64(payload.file);

      return {
        payload: {
          ...payload,
          file: base64File,
        },
        ...props,
      };
    }),
    switchMap(
      ({ payload: { caseId, filename, leadId, file, upload_type } }) => {
        const toastId = toast.loading('Uploading file');
        return postApi$({
          endpoint: getUploadAppFileEndpoint({ caseId }),
          headers: getAuthHeaders(state$.value),
          payload: {
            file_name: filename,
            file_contents: file,
            upload_type,
          },
        }).pipe(
          handleAjaxSuccess(uploadAppFileSuccess, result => {
            result.filename = filename;
            result.caseId = caseId;
            result.uploadType = upload_type;
            result.leadId = leadId;
            toast.dismiss(toastId);
            toast.success('File uploaded');
            return convertSnakeToCamelCase(result);
          }),
          handleAjaxError(
            uploadAppFileFailure,
            'There was an issue uploading your file',
            toastId
          )
        );
      }
    )
  );
