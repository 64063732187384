import { AppointmentServiceType } from 'types/types';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import { GetAppointmentServiceTypesSuccessActionPayload } from 'modules/appointmentServiceTypes/actions';
import { FETCH_APPOINTMENT_SERVICE_TYPES_SUCCESS } from 'modules/appointmentServiceTypes/actionTypes';

export interface AppointmentServiceTypesState {
  normalized: Record<string, AppointmentServiceType>;
}

const normalized = handleActions<
  Record<string, AppointmentServiceType>,
  GetAppointmentServiceTypesSuccessActionPayload
>(
  {
    [FETCH_APPOINTMENT_SERVICE_TYPES_SUCCESS]: (state, { payload }) => payload,
  },
  {}
);

export default combineReducers<AppointmentServiceTypesState>({
  normalized,
});
