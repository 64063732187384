import { combineEpics } from 'redux-observable';

import { fetchAppointments$ } from 'modules/appointments/epics/fetchAppointments$';
import { bookAppointment$ } from 'modules/appointments/epics/bookAppointment$';
import { updateAppointment$ } from 'modules/appointments/epics/updateAppointment$';
import { endSession$ } from './endSession$';
import { homeHealthCancel$ } from './homeHealthCancel$';

export default combineEpics(
  fetchAppointments$,
  bookAppointment$,
  updateAppointment$,
  endSession$,
  homeHealthCancel$
);
