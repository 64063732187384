import { Cohort, Therapist } from 'types/types';
import { isTherapistFullTime } from 'utils/features/isTherapistFullTime';
import { isTherapistAnInsider } from 'utils/features/isTherapistAnInsider';
import { assertUnreachable } from 'utils/assertUnreachable';
import { isDevelopmentMode } from 'utils/isDevelopmentMode';

interface Params {
  therapist: Therapist;
  cohort: Cohort;
}

export const isTherapistInCohort = ({ therapist, cohort }: Params): boolean => {
  switch (cohort) {
    case Cohort.Insiders:
      return isTherapistAnInsider({ therapist });
    case Cohort.FullTime:
      return isTherapistFullTime({ therapist });
    case Cohort.Developers:
      return isDevelopmentMode;
    case Cohort.None:
      return false;
    default:
      return assertUnreachable(cohort);
  }
};
