import { CustomEpic } from 'types/types';
import {
  unclaimOfferFailure,
  UnclaimOfferPayload,
  unclaimOfferSuccess,
} from 'modules/offers/actions';
import { ofType } from 'redux-observable';
import { UNCLAIM_OFFER_START } from 'modules/offers/actionsTypes';
import { switchMap } from 'rxjs/operators';
import { getUnclaimOfferUrl } from 'modules/offers/utils/getUnclaimOfferUrl';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { translateUnclaimOffer } from 'modules/offers/utils/translateUnclaimOffer';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { getUser } from 'selectors/user/getUser';

export const unclaimOffer$: CustomEpic<UnclaimOfferPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(UNCLAIM_OFFER_START),
    switchMap(({ payload: { offerId } }) =>
      postApi$({
        endpoint: getUnclaimOfferUrl({ offerId }),
        headers: getAuthHeaders(state$.value),
        payload: {
          therapist_id: getUser(state$.value).id,
        },
      }).pipe(
        handleAjaxSuccess(unclaimOfferSuccess, translateUnclaimOffer),
        handleAjaxError(unclaimOfferFailure)
      )
    )
  );
