import React from 'react';
import { Appointment, Case, CssTypeFix, Patient } from 'types/types';
import { Flex, Text, TextProps } from 'rebass';
import { COLOR_CODE_PINK } from 'theme/constants';
import { getCaseProgressionStatement } from 'utils/case/caseProgression/getCaseProgressionStatement';
import { formatAppointmentTime } from 'utils/appointment/formatAppointmentTime';
import { appointmentMoment } from 'utils/appointment/appointmentMoment';

interface Props {
  patient: Patient;
  therapyCase: Case;
  appointment: Appointment;
  duration?: number;
}

const LabelText = ({
  children,
  ...rest
}: { children: React.ReactNode } & TextProps & CssTypeFix) => (
  <Text color="grey" fontSize={13} {...rest}>
    {children}
  </Text>
);

export const DetailedPatientInfo = ({
  patient,
  therapyCase,
  appointment,
  duration,
}: Props) => {
  return (
    <Flex justifyContent="space-between" padding={3}>
      <Flex flexDirection="column">
        <LabelText color={COLOR_CODE_PINK} fontWeight="bold">
          {patient.lastName}, {patient.firstName}
        </LabelText>
        <LabelText>{appointment.service}</LabelText>
        <LabelText>{therapyCase.payers}</LabelText>
      </Flex>
      <Flex flexDirection="column">
        <LabelText fontWeight="bold">
          {getCaseProgressionStatement({ therapyCase, appointment })}
        </LabelText>
        <LabelText>
          {appointmentMoment(appointment).format('MMM d')},{' '}
          {formatAppointmentTime({ appointment })}
        </LabelText>
        <LabelText>
          <Flex flexDirection="column">
            <Flex>Session allow</Flex>
            {duration && <Flex>{duration} mins</Flex>}
          </Flex>
        </LabelText>
      </Flex>
    </Flex>
  );
};
