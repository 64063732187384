import React, { useEffect, useRef } from 'react';
import { DateSpacer } from "pages/schedule/DateSpacer";

/*
takes a prop: selected date
when this prop changes value, scroll to this item if the new value
matches this spacer's value
 */

interface Props {
  date: string;
  currentlySelectedDate?: string;
}


export const SmartDateSpacer = ({
  date,
  currentlySelectedDate,
}: Props) => {
  const spacerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (spacerRef.current && currentlySelectedDate === date) {
      spacerRef.current?.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [spacerRef, currentlySelectedDate, date]);

  return (
    <DateSpacer
      date={date}
      ref={spacerRef}
    />
  );
};
