import React, { useCallback, useState } from 'react';
import {
  FullOverlay,
  Props as FullOverlayProps,
} from 'components/FullOverlay/FullOverlay';
import { Flex } from 'rebass';
import { CloseIcon } from 'components/icons/CloseIcon';

interface Props extends FullOverlayProps {
  onClose?: () => void;
}

export const ClosableFullOverlay = ({
  children,
  onClose,
  ...restProps
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);

  if (!isModalOpen) {
    return null;
  }
  return (
    <FullOverlay {...restProps}>
      <Flex
        sx={{
          position: 'absolute',
          right: 10,
          top: 10,
          backgroundColor: 'white',
          borderRadius: '50%',
        }}
      >
        <CloseIcon size={20} onClick={onClose || closeModal} />
      </Flex>
      {children}
    </FullOverlay>
  );
};
