import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from 'components/forms/TextField';

interface Props extends FieldProps {
  disableError: boolean;
}

export const FormikTextField = ({
  field,
  form,
  meta,
  disableError = true,
  ...rest
}: Props) => (
  <TextField
    {...field}
    {...form}
    {...meta}
    {...rest}
    error={disableError ? undefined : form.errors[field.name]}
    touched={form?.touched?.[field.name]}
  />
);
