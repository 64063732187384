import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { getAppointmentPath } from "routing/routeFunctions";

interface Props {
  appointmentId: string;
}

export const useGoToAppointment = ({ appointmentId }: Props) => {
  const { push } = useHistory();
  return useCallback(
    () => push(getAppointmentPath({ appointmentId })),
    [push, appointmentId],
  );
};
