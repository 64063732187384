import React, { SyntheticEvent } from 'react';
import { Flex, Text } from 'rebass';
import { CalendarIcon } from 'components/icons/CalendarIcon';
import { Moment } from 'moment';

interface Props {
  onClick: (e: SyntheticEvent) => void;
  dateTime?: Moment;
}

const dateTimeFormat = 'MMM DD, YYYY [at] h:mm A'; // "Apr 4, 2020 at 3:19 PM"

export const DatePickerButton = ({ dateTime, onClick }: Props) => (
  <Flex
    padding={2}
    onClick={onClick}
    alignItems="center"
    sx={{
      border: '1px solid',
      borderColor: 'grey',
    }}
  >
    <CalendarIcon size={25} />
    {(dateTime && (
      <Text marginLeft={2}>{dateTime.format(dateTimeFormat)}</Text>
    )) || (
      <Text marginLeft={2} fontWeight="bold">
        Select Date and Time
      </Text>
    )}
  </Flex>
);
