import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { OFFER_PATH, OFFERS_PATH } from 'routing/routePaths';
import { OffersPage } from 'pages/offers/OffersPage';
import { CommunicationPage } from 'pages/communication/CommunicationPage';
import { useDispatchFunction } from 'utils/hooks/useDispatchFunction';
import { fetchOffers as fetchOffersAction } from 'modules/offers/actions';
import { OfferPage } from 'pages/offers/OfferPage/OfferPage';

export const CommunicationRouter = () => {
  const fetchOffers = useDispatchFunction(fetchOffersAction);
  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);
  return (
    <Switch>
      <Route path={OFFER_PATH}>
        <OfferPage />
      </Route>
      <Route path={OFFERS_PATH}>
        <OffersPage />
      </Route>
      <Route>
        <CommunicationPage />
      </Route>
    </Switch>
  );
};
