import React from 'react';
import { Flex } from 'rebass';
import moment from 'moment';
import { SmartDate } from 'components/SmartDate/SmartDate';

interface Props {
  date: string; // example: 2021-06-23
}

const spacerLine = (
  <Flex flex={1} alignItems="center">
    <Flex
      width="100%"
      sx={{
        borderTop: '1px solid',
        borderColor: 'muted',
      }}
    />
  </Flex>
);

export const DateSpacer = React.forwardRef(({ date }: Props, ref) => (
  <Flex justifyContent="space-between" marginTop={3} marginBottom={3} ref={ref}>
    {spacerLine}
    <Flex marginLeft={2} marginRight={2}>
      <SmartDate date={moment(date)} />
    </Flex>
    {spacerLine}
  </Flex>
));
