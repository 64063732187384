import { AppointmentStatusName, CustomEpic } from 'types/types';
import {
  UpdateAppointmentActionPayload,
  endSessionFailure,
  updateAppointmentSuccess,
} from 'modules/appointments/actions';
import { ofType } from 'redux-observable';
import toast from 'react-hot-toast';
import { END_SESSION } from 'modules/appointments/actionTypes';
import { switchMap, flatMap } from 'rxjs/operators';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { updateAppointmentCallTranslator } from '../translator';
import { getIsMissingDocsNoticesEnabled } from 'selectors/features/getIsMissingDocsNoticesEnabled';
import { getIsNewEndSessionEndpointEnabled } from 'selectors/features/getIsNewEndSessionEndpointEnabled';
import { getEvalSessionSkipNotes } from 'reducers/evalSession';
import { getUpdateAppointmentUrl } from 'modules/appointments/utils/getUpdateAppointmentUrl';
import { denormalizeAppointment } from 'modules/appointments/utils/normalizeAppointment';
import { AxiosResponse } from 'axios';

export const endSession$: CustomEpic<UpdateAppointmentActionPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(END_SESSION),
    getIsNewEndSessionEndpointEnabled(state$.value)
      ? switchMap(({ payload: { id, missingDocs } }) => {
          const toastId = toast.loading('Ending session...');
          return postApi$({
            endpoint: `/appointments/${id}/end_session`,
            headers: getAuthHeaders(state$.value),
            payload: {
              missingDocs: getIsMissingDocsNoticesEnabled(state$.value)
                ? []
                : missingDocs,
              ...getEvalSessionSkipNotes(state$.value),
            },
          }).pipe(
            handleAjaxSuccess(
              updateAppointmentSuccess,
              updateAppointmentCallTranslator,
              toastId,
              'Session ended successfully'
            ),
            handleAjaxError(endSessionFailure, 'End session failed', toastId)
          );
        })
      : switchMap(({ payload: { id, missingDocs } }) =>
          postApi$({
            endpoint: getUpdateAppointmentUrl({ appointmentId: id }),
            headers: getAuthHeaders(state$.value),
            payload: denormalizeAppointment({
              appStatus: AppointmentStatusName.Finished,
            }),
          }).pipe(
            missingDocs && getIsMissingDocsNoticesEnabled(state$.value)
              ? flatMap((response: AxiosResponse) => {
                  return postApi$({
                    endpoint: 'endSession',
                    headers: getAuthHeaders(state$.value),
                    payload: {
                      id,
                      missingDocs,
                      ...getEvalSessionSkipNotes(state$.value),
                    },
                  }).pipe(
                    flatMap(() => {
                      const result = updateAppointmentCallTranslator(
                        response?.data
                      );
                      return [updateAppointmentSuccess(result)];
                    }),
                    handleAjaxError(endSessionFailure)
                  );
                })
              : handleAjaxSuccess(
                  updateAppointmentSuccess,
                  updateAppointmentCallTranslator
                ),
            handleAjaxError(endSessionFailure)
          )
        )
  );
