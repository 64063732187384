import React from 'react';
import { Flex } from 'rebass';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { useGoBack } from 'utils/hooks/useGoBack';
import { UserDetailsCard } from 'containers/UserDetailsCard/UserDetailsCard';
import { UserInfo } from 'containers/UserInfo/UserInfo';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { EDIT_USER_DETAILS_PATH } from 'routing/routePaths';
import { TherapistInfoDisplayer } from 'components/TherapistInfo/TherapistInfoDisplayer';
import { TherapistNotes } from 'components/TherapistNotes/TherapistNotes';

export const UserDetailsPage = () => {
  const goBack = useGoBack();

  return (
    <Flex flexDirection="column" width="100%">
      <UserDetailsCard>
        <CustomTextLink to={EDIT_USER_DETAILS_PATH}>Edit</CustomTextLink>
      </UserDetailsCard>
      <ScrollBox flexDirection="column" flex={1}>
        <UserInfo>
          <TherapistInfoDisplayer label="Notes:">
            <TherapistNotes />
          </TherapistInfoDisplayer>
        </UserInfo>
      </ScrollBox>
      <FooterButton label="Go Back" onClick={goBack} variant="secondary" />
    </Flex>
  );
};
