import {CustomEpic} from "types/types";
import {FetchAppointmentsActionPayload, fetchAppointmentsFailure, fetchAppointmentsSuccess} from "../actions";
import {ofType} from "redux-observable";
import {FETCH_APPOINTMENTS} from "../actionTypes";
import {switchMap} from "rxjs/operators";
import {APPOINTMENTS_ENDPOINT} from "../constants";
import {getAuthHeaders} from "api/getAuthHeaders";
import {handleAjaxSuccess} from "utils/epics/handleAjaxSuccess";
import {handleAjaxError} from "utils/epics/handleAjaxError";
import { fetchAppointmentCallTranslator } from "modules/appointments/translator";

export const fetchAppointments$: CustomEpic<FetchAppointmentsActionPayload> = (
  action$,
  state$,
  {getApi$},
) => action$.pipe(
  ofType(FETCH_APPOINTMENTS),
  switchMap(
    ({
      payload: {
        caseId,
        status,
        includeCancelled = false,
      }
    }) => getApi$({
      endpoint: APPOINTMENTS_ENDPOINT,
      headers: getAuthHeaders(state$.value),
      payload: {
        ...(caseId ? { case_id: caseId } : {}),
        ...(status ? { status } : {}),
        cx: includeCancelled,
      },
    })
      .pipe(
        handleAjaxSuccess(fetchAppointmentsSuccess, fetchAppointmentCallTranslator),
        handleAjaxError(fetchAppointmentsFailure),
      )
  )
);
