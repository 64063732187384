export const FETCH_APP_FILES_START = 'FETCH_APP_FILES_START';
export const FETCH_APP_FILES_SUCCESS = 'FETCH_APP_FILES_SUCCESS';
export const FETCH_APP_FILES_FAILURE = 'FETCH_APP_FILES_FAILURE';

export const FETCH_APP_FILE_START = 'FETCH_APP_FILE_START';
export const FETCH_APP_FILE_SUCCESS = 'FETCH_APP_FILE_SUCCESS';
export const FETCH_APP_FILE_FAILURE = 'FETCH_APP_FILE_FAILURE';

export const UPLOAD_APP_FILE_START = 'UPLOAD_APP_FILE_START';
export const UPLOAD_APP_FILE_SUCCESS = 'UPLOAD_APP_FILE_SUCCESS';
export const UPLOAD_APP_FILE_FAILURE = 'UPLOAD_APP_FILE_FAILURE';

export const REQUEST_FORM = 'REQUEST_FORM';
export const REQUEST_FORM_SUCCESS = 'REQUEST_FORM_SUCCESS';
export const REQUEST_FORM_FAILURE = 'REQUEST_FORM_FAILURE';
export const REQUEST_FORM_CANCEL = 'REQUEST_FORM_CANCEL';
