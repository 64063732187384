import { combineEpics } from 'redux-observable';
import { fetchAppFile$ } from './fetchAppFile$';
import { fetchAppFiles$ } from './fetchAppFiles$';
import { uploadAppFile$ } from './uploadAppFile$';
import { requestForm$ } from './requestForm$';

export default combineEpics(
  fetchAppFile$,
  fetchAppFiles$,
  uploadAppFile$,
  requestForm$
);
