import React from 'react';
import { FullOverlay } from 'components/FullOverlay/FullOverlay';
import { Flex } from 'rebass';
import { Confirmation } from 'components/ConfirmationModal/Confirmation';

export interface Props {
  onAccept: () => void;
  onCancel: () => void;
  onClose?: () => void;
  children?: React.ReactNode;
}

export const ConfirmationModal = ({
  onAccept,
  onCancel,
  onClose = () => {},
  children,
}: Props) => {
  return (
    <FullOverlay flexDirection="column">
      <Flex flex={1} backgroundColor="black" opacity={0.5} onClick={onClose} />
      <Confirmation
        backgroundColor="white"
        width="100%"
        onAccept={onAccept}
        onCancel={onCancel}
      >
        {children}
      </Confirmation>
    </FullOverlay>
  );
};
