import { CustomEpic } from 'types/types';
import { ofType } from 'redux-observable';
import {
  FetchAppFileActionPayload,
  fetchAppFileFailure,
  fetchAppFileSuccess,
} from '../actions';
import { FETCH_APP_FILE_START } from '../actionTypes';
import { switchMap } from 'rxjs/operators';
import { getFetchAppFileEndpoint } from '../constants';
import { getAuthHeaders } from '../../../api/getAuthHeaders';
import { handleAjaxSuccess } from '../../../utils/epics/handleAjaxSuccess';
import { handleAjaxError } from '../../../utils/epics/handleAjaxError';
import { getCaseFile } from '../../../selectors/files/getCaseFile';
import { getTranslateFetchAppFile } from '../translator';
import { getAppFileType } from '../../../utils/file/getAppFileType';

export const fetchAppFile$: CustomEpic<FetchAppFileActionPayload> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(FETCH_APP_FILE_START),
    switchMap(({ payload: { fileName, caseId } }) =>
      getApi$({
        endpoint: getFetchAppFileEndpoint({
          caseId,
          fileId:
            getCaseFile(state$.value, {
              caseId,
              appFileType: getAppFileType(fileName),
            })?.fileId || 'unknown',
        }),
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(
          fetchAppFileSuccess,
          getTranslateFetchAppFile({ fileName, caseId })
        ),
        handleAjaxError(fetchAppFileFailure)
      )
    )
  );
