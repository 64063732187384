import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { SuccessPageTemplate } from 'containers/SuccessPageTemplate/SuccessPageTemplate';
import { PaymentSuccessPathParams, SCHEDULE_PATH } from 'routing/routePaths';
import { match, useHistory } from 'react-router-dom';
import { Appointment } from 'types/types';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { AppointmentNotFoundPage } from 'pages/appointmentNotFound/AppointmentNotFoundPage';
import { Text } from 'rebass';

interface OwnProps {
  match: match<PaymentSuccessPathParams>;
}

interface StateProps {
  appointment?: Appointment;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  {
    match: {
      params: { appointmentId },
    },
  }: OwnProps
): StateProps => {
  return {
    appointment: getAppointment(state, { appointmentId }),
  };
};

const RescheduleSuccessPageComponent = ({ appointment }: Props) => {
  const { push } = useHistory();
  const onFooterClick = useCallback(() => push(SCHEDULE_PATH), [push]);

  if (!appointment) {
    return <AppointmentNotFoundPage />;
  }
  return (
    <SuccessPageTemplate
      therapyCaseId={appointment.caseId}
      patientId={appointment.patientId}
      footerLabel="Back to my schedule"
      onFooterClick={onFooterClick}
    >
      <Text fontSize="20pt" textAlign="center" margin={2}>
        Successfully Rescheduled Appointment
      </Text>
    </SuccessPageTemplate>
  );
};

export const RescheduleSuccessPage = connect(mapStateToProps)(
  RescheduleSuccessPageComponent
);
