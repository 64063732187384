import { ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AxiosError } from 'axios';

import { CustomEpic, ErrorActionPayload } from 'types/types';
import {
  FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE,
  FETCH_GOOGLE_OAUTH2_TOKENS,
} from '../actionTypes';
import { GOOGLE_AUTHORIZATION_CODE_ENDPOINT } from '../constants';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import {
  fetchGoogleOauth2TokensFailure,
  fetchGoogleOauth2TokensSuccess,
  fetchGoogleOauth2TokensUnauthorized,
} from '../actions';

export const fetchAuthorizationCode$: CustomEpic<undefined> = action$ =>
  action$.pipe(
    ofType(FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE),
    map(() => {
      const client = google.accounts.oauth2.initCodeClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
        scope:
          'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid',
        ux_mode: 'redirect',
        redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH2_REDIRECT_URL || '',
        state: process.env.REACT_APP_GOOGLE_OAUTH2_STATE || '',
      });
      client.requestCode();
      return { type: 'FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE_STARTED' };
    })
  );

type FetchGoogleOauth2TokensPayload = {
  authorizationCode: string;
};

export const fetchGoogleOauth2Tokens$: CustomEpic<FetchGoogleOauth2TokensPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(FETCH_GOOGLE_OAUTH2_TOKENS),
    switchMap(({ payload: { authorizationCode } }) => {
      return postApi$({
        endpoint: GOOGLE_AUTHORIZATION_CODE_ENDPOINT,
        payload: { code: authorizationCode },
        headers: {},
      }).pipe(
        handleAjaxSuccess(fetchGoogleOauth2TokensSuccess),
        catchError((err: AxiosError) => {
          const errorActionPayload: ErrorActionPayload = {
            message: err?.message,
            name: err?.name,
            status: err?.response?.status,
          };
          const failActionCreator =
            err?.response?.status === 401
              ? fetchGoogleOauth2TokensUnauthorized
              : fetchGoogleOauth2TokensFailure;
          return [failActionCreator(errorActionPayload)];
        })
      );
    })
  );
