import React from 'react';
import { Flex } from 'rebass';
import { Avatar } from 'components/Avatar/Avatar';
import { DefaultUserAvatar } from 'components/icons/DefaultUserAvatar';

interface Props {
  firstName?: string;
  lastName?: string;
  children?: React.ReactNode;
}

export const PatientInfoFrame = ({ firstName, lastName, children }: Props) => (
  <Flex>
    <Flex marginRight={2}>
      <Avatar size={25}>
        <DefaultUserAvatar size={20} />
      </Avatar>
    </Flex>
    <Flex flexDirection="column" marginLeft={1}>
      {(firstName || lastName) && (
        <Flex
          sx={{
            fontWeight: 'bold',
          }}
        >
          {lastName}, {firstName}
        </Flex>
      )}
      {children}
    </Flex>
  </Flex>
);
