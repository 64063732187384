import React from 'react';
import {
  Map as GoogleMap,
  GoogleApiWrapper,
  IMapProps,
} from 'google-maps-react';
import { Box } from 'rebass';

type Props = {
  height?: number;
  width?: number;
  children?: React.ReactNode;
} & IMapProps;

export const MapComponent = ({
  google,
  height,
  width,
  children,
  ...rest
}: Props) => (
  <Box
    height={height}
    width={width}
    sx={{
      position: 'relative',
    }}
  >
    <GoogleMap google={google} {...rest}>
      {children}
    </GoogleMap>
  </Box>
);

export const Map = GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
})(MapComponent);
