import { createAction } from 'redux-actions';
import {
  FETCH_CASE,
  FETCH_CASE_FAILURE,
  FETCH_CASE_SUCCESS,
  FETCH_THERAPIST_CASES,
  FETCH_CASES_FAILURE,
  FETCH_CASES_SUCCESS,
  UPDATE_CASE,
  UPDATE_CASE_FAILURE,
  UPDATE_CASE_SUCCESS,
  FETCH_PATIENT_CASES,
  FETCH_CASE_APP_TYPES,
  FETCH_CASE_APP_TYPES_SUCCESS,
  FETCH_CASE_APP_TYPES_FAILURE,
} from './actionTypes';
import { AppointmentServiceType, Case, CaseStatus } from 'types/types';

interface CommonCasesParams {
  onlyOpen?: boolean; // "only"
}

export interface GetTherapistCasesActionPayload extends CommonCasesParams {
  therapistId?: number;
}

export const fetchTherapistCases = createAction<GetTherapistCasesActionPayload>(
  FETCH_THERAPIST_CASES
);

export interface GetPatientCasesActionPayload extends CommonCasesParams {
  patientId: string;
}

export const fetchPatientCases = createAction<GetPatientCasesActionPayload>(
  FETCH_PATIENT_CASES
);

export type GetCasesSuccessActionPayload = Record<string, Case>;

export const fetchCasesSuccess = createAction<GetCasesSuccessActionPayload>(
  FETCH_CASES_SUCCESS
);

export const fetchCasesFailure = createAction(FETCH_CASES_FAILURE);

export interface FetchCaseActionPayload {
  caseId: string;
}

export const fetchCase = createAction<FetchCaseActionPayload>(FETCH_CASE);

export type FetchCaseSuccessActionPayload = Case;
export const fetchCaseSuccess = createAction<FetchCaseSuccessActionPayload>(
  FETCH_CASE_SUCCESS
);

export const fetchCaseFailure = createAction(FETCH_CASE_FAILURE);

export interface UpdateCaseActionPayload extends Partial<Case> {
  id: number;
}
export const updateCase = createAction<UpdateCaseActionPayload>(UPDATE_CASE);
export type UpdateCaseSuccessActionPayload = Case;
export const updateCaseSuccess = createAction<UpdateCaseSuccessActionPayload>(
  UPDATE_CASE_SUCCESS
);
export const updateCaseFailure = createAction(UPDATE_CASE_FAILURE);

export interface HoldCaseParams {
  caseId: number | string;
  reason: string;
}
export const holdCase = ({ caseId, reason }: HoldCaseParams) =>
  updateCase({
    id: Number(caseId),
    status: CaseStatus.OnHold,
    statusComment: reason,
  });

export const dischargeCase = ({ caseId }: { caseId: number | string }) =>
  updateCase({
    id: Number(caseId),
    status: CaseStatus.Discharged,
  });

export type FetchCaseAppTypesSuccessActionPayload = {
  caseId: string;
  appointmentTypes: AppointmentServiceType[];
};
export const fetchCaseAppTypes = createAction<number>(FETCH_CASE_APP_TYPES);
export const fetchCaseAppTypesSuccess = createAction<
  FetchCaseAppTypesSuccessActionPayload
>(FETCH_CASE_APP_TYPES_SUCCESS);
export const fetchCaseAppTypesFailure = createAction<any>(
  FETCH_CASE_APP_TYPES_FAILURE
);
