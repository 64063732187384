import React from 'react';
import { getCreditCardInputPath } from 'routing/routeFunctions';
import { Flex, Text } from 'rebass';
import { ArrowForwardIcon } from 'components/icons/ArrowForwardIcon';
import { CustomLink } from 'components/CustomLink/CustomLink';
import { Appointment, CardBrand, Patient } from 'types/types';
import { getPatientCardInfo } from 'utils/patient/getPatientCardInfo';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { CreditCardInfo } from 'components/CreditCardInfo/CreditCardInfo';

interface Props {
  appointment: Appointment;
  patient: Patient;
}

const Displayer = ({ children }: { children: React.ReactNode }) => (
  <Flex
    justifyContent="space-between"
    alignItems="center"
    padding={3}
    marginBottom={2}
    sx={{
      border: '1px solid',
      borderColor: 'grey',
      borderRadius: 'borderRadius',
    }}
  >
    {children}
  </Flex>
);

export const CurrentOrNewCard = ({ appointment, patient }: Props) => {
  const {
    paymentSourceId,
    cardBrand = CardBrand.Unknown,
    cardLast4,
  } = getPatientCardInfo({ patient });
  const creditCardUpdatePath = getCreditCardInputPath({
    appointmentId: appointment.id,
  });

  if (paymentSourceId) {
    return (
      <Flex flexDirection="column">
        <Displayer>
          <CreditCardInfo cardBrand={cardBrand} cardLast4={cardLast4} />
        </Displayer>
        <CustomTextLink to={creditCardUpdatePath}>Update card</CustomTextLink>
      </Flex>
    );
  }
  return (
    <CustomLink to={creditCardUpdatePath}>
      <Displayer>
        <Text color="grey">Add Payment</Text>
        <ArrowForwardIcon size="20px" />
      </Displayer>
    </CustomLink>
  );
};
