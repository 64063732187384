import React, { Fragment, useEffect } from 'react';
import { Flex } from 'rebass';
import { fetchAppFiles } from '../../../modules/files/actions';
import { useDispatchFunction } from '../../../utils/hooks/useDispatchFunction';
import { useSelector } from 'react-redux';
import { getFilesFetchStatus } from '../../../selectors/files/getFilesFetchStatus';
import { FetchStatus } from '../../../types/types';

interface Props {
  caseId: string;
  children?: React.ReactNode;
}

const FetchState = () => (
  <Flex width="100%" marginTop={2} marginBottom={2}>
    Fetching...
  </Flex>
);

const FailureState = () => (
  <Flex width="100%" marginTop={2} marginBottom={2}>
    Error: Could not load files
  </Flex>
);

export const FileFetcher = ({ caseId, children }: Props) => {
  const fetchFiles = useDispatchFunction(fetchAppFiles);

  useEffect(() => {
    fetchFiles({ caseId });
  }, [fetchFiles, caseId]);

  const fetchStatus = useSelector(getFilesFetchStatus);

  if (fetchStatus === FetchStatus.Fetching) {
    return <FetchState />;
  }

  if (fetchStatus === FetchStatus.Failure) {
    return <FailureState />;
  }

  return <Fragment>{children}</Fragment>;
};
