import { CustomEpic } from 'types/types';
import {
  PatientReferralActionPayload,
  referPatientFailure,
  referPatientSuccess,
} from 'modules/patientReferral/actions';
import { ofType } from 'redux-observable';
import { REFER_PATIENT } from 'modules/patientReferral/actionTypes';
import { switchMap } from 'rxjs/operators';
import { getPatientReferralUrl } from 'modules/patientReferral/utils/getPatientReferralUrl';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { convertCamelToSnakeCase } from '../../../utils/convertCamelToSnakeCase';

export const referPatient$: CustomEpic<PatientReferralActionPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(REFER_PATIENT),
    switchMap(({ payload }) =>
      postApi$({
        endpoint: getPatientReferralUrl(),
        payload: convertCamelToSnakeCase(payload),
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(referPatientSuccess),
        handleAjaxError(referPatientFailure)
      )
    )
  );
