import { Patient } from "types/types";

interface Props {
  patient?: Patient;
}

// This gets the payment method id accepted by https://stripe.com/docs/js/payment_intents/confirm_card_payment
// It accepts either a card id or payment method id in the payment_method parameter
export const getPatientPaymentSourceId = ({ patient }: Props): string | undefined =>
  patient?.paymentMethodId || patient?.cardId || undefined;
