import React from 'react';
import { FormikProps } from 'formik';
import { PatientReferralFormValues } from 'pages/patientReferral/constants';
import { Flex } from 'rebass';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { ReferralFields } from 'pages/patientReferral/ReferralFields';
import { FetchStatus } from 'types/types';
import { isFetching } from 'utils/fetchStatus';

interface Props extends FormikProps<PatientReferralFormValues> {
  fetchStatus: FetchStatus;
}

export const ReferralForm = ({ handleSubmit, isValid, fetchStatus }: Props) => {
  return (
    <Flex
      flexDirection="column"
      as="form"
      // @ts-ignore
      onSubmit={handleSubmit}
      flex={1}
    >
      <Flex padding={3}>
        <ReferralFields />
      </Flex>
      <Flex flex={1} />
      <FooterButton
        isSubmit
        label="Send Referral"
        disabled={!isValid || isFetching(fetchStatus)}
      />
    </Flex>
  );
};
