import React, { Fragment } from 'react';
import { Moment } from 'moment';

interface Props {
  startDate: Moment;
  daysToShow: number;
  renderDay: (moment: Moment) => React.ReactNode;
}

export const Days = ({ startDate, daysToShow = 1, renderDay }: Props) => {
  const result: Moment[] = [];
  let current = startDate.clone().startOf('week');
  for (let i = 0; i < daysToShow; i++) {
    result.push(current.clone());
    current.add(1, 'day');
  }
  return <Fragment>{result.map(renderDay)}</Fragment>;
};
