import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import moment, { Moment } from 'moment';
import { dateFormat } from 'appConstants';
import { Props as DatePickerBarProps } from 'components/DatePickerBar/DatePickerBar';
import { getSchedule as getScheduleAction } from 'modules/schedule/actions';

export interface RenderProps {
  onRangeShift: DatePickerBarProps['onRangeShift'];
}

interface OwnProps {
  render: (props: RenderProps) => React.ReactNode;
}

interface StateProps {}

interface DispatchProps {
  getSchedule: typeof getScheduleAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: GlobalState): StateProps => ({});

const mapDispatchToProps = {
  getSchedule: getScheduleAction,
};

const SmartDatePickerBarComponent = ({ render, getSchedule }: Props) => {
  const fetchSchedule = useCallback(
    ({ startingFrom = moment() }: { startingFrom?: Moment }) =>
      getSchedule({
        since: startingFrom
          .clone()
          .startOf('week')
          .format(dateFormat),
        until: startingFrom
          .clone()
          .add(3, 'weeks')
          .endOf('week')
          .format(dateFormat),
      }),
    [getSchedule]
  );

  const onRangeShift = useCallback(
    (activeDay: Moment) => {
      fetchSchedule({
        startingFrom: activeDay,
      });
    },
    [fetchSchedule]
  );

  useEffect(() => {
    fetchSchedule({});
  }, [fetchSchedule]);

  return <>{render({ onRangeShift })}</>;
};

export const SmartDatePickerBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(SmartDatePickerBarComponent);
