export const formatPhoneNumber = (phone: string | null): string => {
  if (!phone) {
    return '';
  }
  const match = phone.match(/(\d\d\d)(\d\d\d)(\d\d\d\d)/);
  if (!match) {
    return phone;
  }
  const [, first, second, third] = match;
  return `(${first}) ${second}-${third}`;
};
