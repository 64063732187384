import React from 'react';
import { Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';

type Props = {
  children: React.ReactNode;
} & FlexProps &
  CssTypeFix;

export const Footer = ({ children, ...props }: Props) => (
  <Flex
    {...props}
    width="100%"
    justifyContent="center"
    alignItems="center"
    paddingTop={3}
    paddingBottom={3}
    sx={{
      borderTop: '1px solid',
      borderColor: 'muted',
      ...props.sx,
    }}
  >
    {children}
  </Flex>
);
