import React, { useCallback, useState } from 'react';
import { PaymentMethod, StripeError } from '@stripe/stripe-js';
import { Flex, Text } from 'rebass';
import { CreditCardInputFormElements } from 'components/forms/CreditCardInput/CreditCardInputFormElements';
import { Formik, FormikProps } from 'formik';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { createPaymentMethod } from 'utils/stripe/createPaymentMethod';
import * as Yup from 'yup';
import { useIsMountedRef } from 'utils/hooks/useIsMountedRef';

/*
  Returns a stripe payment method object if successful
 */

const disclaimerText =
  '*Appointment/ Compliance: There is a $50 charge for no-show or cancellations without a 2 BUSINESS DAY notice. Credit card information will be collected by the physical therapist at the time of your evaluation. You will be held responsible for the fee and your credit card will be charged.';

export interface FormValues {
  zipCode: string;
}

interface Props {
  customerId: string; // passed into payment processor
  onSuccess: (paymentInfo: PaymentMethod) => void;
}

const validationSchema = Yup.object().shape({
  zipCode: Yup.mixed().required('Required'),
});

export const CreditCardInput = ({ customerId, onSuccess }: Props) => {
  const isMountedRef = useIsMountedRef();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const onError = useCallback(
    (error: StripeError | null) => {
      if (error) {
        setErrorMessage(error.message || 'An error occurred');
      } else {
        setErrorMessage('');
      }
    },
    [setErrorMessage]
  );
  const stripe = useStripe();
  const elements = useElements();
  const submitForm = useCallback(
    async (values: FormValues) => {
      const additionalParams = {
        billing_details: {
          name: customerId,
          address: {
            postal_code: values.zipCode,
          },
        },
      };
      setIsLoading(true);
      await createPaymentMethod({
        stripe,
        elements,
        paymentRequestParams: additionalParams,
        onError,
        onSuccess,
      });
      // To avoid a memory leak
      if (isMountedRef.current) {
        setIsLoading(false);
      }
    },
    [isMountedRef, stripe, elements, onError, onSuccess, customerId]
  );

  const RenderForm = useCallback(
    ({ submitForm, isValid }: FormikProps<FormValues>) => (
      <>
        <Flex width="100%" flexDirection="column" padding={3}>
          <Text fontSize="12pt" fontWeight="bold" marginBottom={1}>
            Add your credit card for your co-pay charge
          </Text>
          <Text color="grey" fontSize="10pt" marginBottom={2}>
            *we will confirm your insurance coverage with you before charging
            your card
          </Text>
          {errorMessage && (
            <Flex justifyContent="center" marginBottom={1}>
              <Text color="red">{errorMessage}</Text>
            </Flex>
          )}
          <CreditCardInputFormElements />
        </Flex>
        <Flex flex={1} />
        <Text padding={3} fontSize="12px" color="grey">
          {disclaimerText}
        </Text>
        <FooterButton
          label="Save Payment"
          isSubmit
          onClick={submitForm}
          loading={isLoading}
          disabled={!isValid}
        />
      </>
    ),
    [errorMessage, isLoading]
  );

  return (
    <Formik
      initialValues={{
        zipCode: '',
      }}
      onSubmit={submitForm}
      validationSchema={validationSchema}
    >
      {RenderForm}
    </Formik>
  );
};
