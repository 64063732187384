import React from 'react';
import { Flex } from 'rebass';
import { NavItem, Props as NavItemProps } from 'components/NavBar/NavItem';
import { useHistory } from 'react-router-dom';

export type Props = {
  items: NavItemProps[];
};

export const NavBar = ({ items }: Props) => {
  const history = useHistory();
  return (
    <Flex width="100%">
      {items.map(navItemProps => (
        <Flex
          key={navItemProps?.route}
          flex={1}
          justifyContent="center"
          onClick={() =>
            !navItemProps.isDisabled && history.push(navItemProps.route)
          }
        >
          <NavItem {...navItemProps} />
        </Flex>
      ))}
    </Flex>
  );
};
