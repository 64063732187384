import { CustomEpic } from 'types/types';
import { ofType } from 'redux-observable';
import { LOGIN } from '../actionTypes';
import { switchMap } from 'rxjs/operators';
import { LOGIN_ENDPOINT } from '../constants';
import { LoginActionPayload, loginFailure, loginSuccess } from '../actions';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';

export const login$: CustomEpic<LoginActionPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap(({ payload: { username, password } }) =>
      postApi$({
        endpoint: LOGIN_ENDPOINT,
        payload: {
          user: username,
          password,
        },
        headers: {}, // so username and password aren't included
      }).pipe(handleAjaxSuccess(loginSuccess), handleAjaxError(loginFailure))
    )
  );
