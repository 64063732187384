import { useConnectionStatus } from 'hooks/useConnectionStatus';
import { REQUEST_FORM } from 'modules/files/actionTypes';
import { fileTypeToUploadType } from 'modules/hooks/constants';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Text } from 'rebass';
import { AppFileType } from 'utils/file/constants';

export const FormRequestButton = ({
  leadId,
  caseId,
  appFileType,
  resign,
}: {
  leadId: string;
  caseId: string;
  appFileType: AppFileType;
  resign?: boolean;
}) => {
  const { isOnline } = useConnectionStatus();
  const dispatch = useDispatch();

  const fileName = fileTypeToUploadType[appFileType];

  const handleClick = () => {
    dispatch({
      type: REQUEST_FORM,
      payload: {
        leadId,
        caseId,
        form_type: fileName,
        embed: isOnline,
      },
    });
  };

  return (
    <Text variant="link" onClick={handleClick}>
      {resign ? 'Re-sign' : 'Sign'}
    </Text>
  );
};
