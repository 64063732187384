import React, { useContext, createContext, useState, useEffect } from 'react';
import {
  connectionStatus,
  startPolling,
  stopPolling,
} from 'utils/connectionStatus';

const ConnectionStatusContext = createContext({
  isOnline: true,
});

export const useConnectionStatus = () => useContext(ConnectionStatusContext);

export const ConnectionStatusProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const handleChange = (newValue: boolean) => () => {
      setIsOnline(newValue);
    };

    const handleOnline = handleChange(true);
    const handleOffline = handleChange(false);

    connectionStatus.on('online', handleOnline);
    connectionStatus.on('offline', handleOffline);

    startPolling();

    return () => {
      connectionStatus.off('online', handleOnline);
      connectionStatus.off('offline', handleOffline);

      stopPolling();
    };
  }, []);

  return (
    <ConnectionStatusContext.Provider value={{ isOnline }}>
      {children}
    </ConnectionStatusContext.Provider>
  );
};
