import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, BoxProps } from 'rebass';
import { CssTypeFix } from 'types/types';
import ResizeObserver from 'resize-observer-polyfill';

type Props = BoxProps &
  CssTypeFix & {
    getCanvas?: (canvas: HTMLCanvasElement) => void;
  };

interface Dimensions {
  width: number;
  height: number;
}

export const Canvas = ({ getCanvas = () => {}, ...rest }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  const saveDimensions = useCallback(() => {
    setDimensions({
      width: containerRef.current?.clientWidth || 0,
      height: containerRef.current?.clientHeight || 0,
    });
  }, [setDimensions, containerRef]);

  // Set dimensions on load
  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver(() => {
      saveDimensions();
    });
    saveDimensions();
  }, [saveDimensions, resizeObserverRef]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && resizeObserverRef.current) {
      resizeObserverRef.current.observe(container);
    }
    return () => {
      if (container && resizeObserverRef.current) {
        resizeObserverRef.current.unobserve(container);
      }
    };
  }, [saveDimensions, containerRef]);

  // Forward canvas when dimensions established or changed
  useEffect(() => {
    if (canvasRef.current && dimensions.width && dimensions.height) {
      getCanvas(canvasRef.current);
    }
  }, [canvasRef, dimensions, getCanvas]);

  return (
    <Box width="100%" height="100%" {...rest} ref={containerRef}>
      <canvas
        height={dimensions.height}
        width={dimensions.width}
        ref={canvasRef}
        style={{
          position: 'absolute',
        }}
      />
    </Box>
  );
};
