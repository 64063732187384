export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE =
  'FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE';
export const FETCH_GOOGLE_OAUTH2_TOKENS = 'FETCH_GOOGLE_OAUTH2_TOKENS';
export const FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS =
  'FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS';
export const FETCH_GOOGLE_OAUTH2_TOKENS_FAILURE =
  'FETCH_GOOGLE_OAUTH2_TOKENS_FAILURE';
export const FETCH_GOOGLE_OAUTH2_TOKENS_UNAUTHORIZED =
  'FETCH_GOOGLE_OAUTH2_TOKENS_UNAUTHORIZED';
