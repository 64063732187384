import { DetailedOffer, OfferType } from 'types/types';
import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';

export const translateDetailedOffer = (response: Object): DetailedOffer => {
  const camelCased = convertSnakeToCamelCase(response);
  const offer = camelCased.offerDetails;
  return {
    offerType: OfferType.DetailedOffer,
    ...offer,
  };
};
