import React from 'react';
import { Flex, Text } from 'rebass';
import { Appointment, Case, Lead, Patient } from 'types/types';
import 'moment-precise-range-plugin';
import { AppointmentInsuranceAndSessions } from 'components/InsuranceAndSessions/AppointmentInsuranceAndSessions';
import { PatientBasicInfo } from 'components/PatientBasicInfo/PatientBasicInfo';

interface Props {
  appointment: Appointment;
  patient?: Patient;
  lead?: Lead;
  therapyCase?: Case;
  children?: React.ReactNode;
}

export const SessionInfo = ({
  patient,
  lead,
  therapyCase,
  children,
  appointment,
}: Props) => {
  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" justifyContent="space-between">
        {patient && (
          <PatientBasicInfo patient={patient}>
            <Text>Patient - {lead?.injuryBodyArea || 'Unknown injury'}</Text>
          </PatientBasicInfo>
        )}
        {children}
      </Flex>
      {therapyCase && patient && (
        <Flex width="100%" marginTop={3}>
          <AppointmentInsuranceAndSessions
            patient={patient}
            therapyCase={therapyCase}
            appointment={appointment}
          />
        </Flex>
      )}
    </Flex>
  );
};
