import {
  BookAppointmentSuccessActionPayload,
  FetchAppointmentsSuccessActionPayload,
  UpdateAppointmentSuccessActionPayload,
} from 'modules/appointments/actions';
import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';
import { normalizeAppointment } from 'modules/appointments/utils/normalizeAppointment';

/*
{
    "appointment": {
        "id": 22735,
        "cl_id": null,
        "app_date": "2020-05-03",
        "app_time": "2000-01-01T15:30:00.000Z",
        "service": "Cash Treatment",
        "therapist_id": 1,
        "patient_id": 1816,
        "duration": 60,
        "status": "Pending",
        "status_name": "Cash Treatment Scheduled",
        "app_type": "Cash Treatment",
        "case_id": 2199,
        "billing_batch_num": null,
        "patient_responsibility": null,
        "amount_paid": null,
        "allowed_amount": null,
        "cxlns_reason": null,
        "notes": null
    }
}
 */

export const bookAppointmentCallTranslator = (
  response: Object
): BookAppointmentSuccessActionPayload => convertSnakeToCamelCase(response);

export const updateAppointmentCallTranslator = (
  appointment: Object
): UpdateAppointmentSuccessActionPayload => {
  return normalizeAppointment(convertSnakeToCamelCase(appointment));
};

export const fetchAppointmentCallTranslator = (
  response: Object[]
): FetchAppointmentsSuccessActionPayload =>
  response.map(appointment =>
    normalizeAppointment(convertSnakeToCamelCase(appointment))
  );
