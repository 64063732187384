import { contentType } from 'mime-types';

/**
 * Convert accepted file extensions to correct mime types
 * @param acceptedFileExtensions string of accepted file extensions 'jpg pdf'
 * @returns 'image/jpeg, application/pdf'
 */

export const getAcceptedMimeTypes = (
  acceptedFileExtensions?: string
): string => {
  if (!acceptedFileExtensions) {
    return '';
  }
  const extensions = acceptedFileExtensions.split(/\s+/);
  return extensions
    .map(extension => {
      const mimeType = contentType(extension);
      if (!mimeType) {
        throw new Error(
          `Extension type ${extension} not recognized, check env file`
        );
      }
      return mimeType;
    })
    .join(', ');
};
