import { GlobalState } from "reducers";
import { createSelector } from "reselect";
import { getCases } from "selectors/cases/getCases";
import { Case } from "types/types";

export const getCase = createSelector(
  getCases,
  (state: GlobalState, { caseId }: { caseId: string }) => caseId,
  (cases, caseId): Case | undefined => cases[caseId],
);
