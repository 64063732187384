import { Field, FormikProps } from 'formik';
import { FormValues } from 'pages/postSession/OnHoldForm';
import { Flex } from 'rebass';
import { OptionButton } from 'pages/postSession/OptionButton';
import React from 'react';
import { Label } from '@rebass/forms';
import { FormikSelectField } from 'components/forms/FormikSelectField';
import { OnHoldReason, onHoldReasonOptions } from 'pages/postSession/constants';

interface Props extends FormikProps<FormValues> {
  isDisabled?: boolean;
}

export const OnHoldFormFields = ({
  handleSubmit,
  values,
  isDisabled = false,
}: Props) => (
  <Flex
    flexDirection="column"
    as="form"
    // @ts-ignore
    onSubmit={handleSubmit}
  >
    <OptionButton type="submit" disabled={isDisabled}>
      Put on hold
    </OptionButton>
    <Label htmlFor="on-hold-reason">Reason:</Label>
    <Field
      name="reason"
      id="on-hold-reason"
      component={FormikSelectField}
      options={onHoldReasonOptions}
      isDisabled={isDisabled}
    />
    {values.reason === OnHoldReason.Other && (
      <Flex marginTop={3} flexDirection="column">
        <Field
          name="otherReason"
          component="textarea"
          placeholder="Add additional information..."
          rows={4}
          style={{
            padding: '10px',
            resize: 'vertical',
          }}
        />
      </Flex>
    )}
  </Flex>
);
