import { createSelector } from "reselect";
import { getAppointmentIds } from "selectors/schedule/getAppointmentIds";
import { getAppointments } from "selectors/appointments/getAppointments";

export const getSchedule = createSelector(
  getAppointmentIds,
  getAppointments,
  (appointmentIds, appointments) => appointmentIds.map(
    appointmentId => appointments[appointmentId],
  ),
);
