import React from 'react';
import { Flex } from 'rebass';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { getActiveMonths } from 'utils/getActiveMonths';
import { ArrowForwardIcon } from 'components/icons/ArrowForwardIcon';
import { Moment } from 'moment';

interface Props {
  activeDay: Moment;
  weeksToShow: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

export const DatePickerBarTitle = ({
  activeDay,
  weeksToShow,
  onPreviousClick,
  onNextClick,
}: Props) => (
  <Flex sx={{ fontWeight: 'bold' }}>
    <Flex alignItems="center" onClick={onPreviousClick}>
      <ArrowBackIcon />
    </Flex>
    <Flex alignItems="center">
      {getActiveMonths({
        activeDay,
        weeksToShow,
      })}
    </Flex>
    <Flex alignItems="center" onClick={onNextClick}>
      <ArrowForwardIcon />
    </Flex>
  </Flex>
);
