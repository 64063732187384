import { createSelector } from "reselect";
import { getCase } from "selectors/cases/getCase";
import { getAppointments } from "selectors/appointments/getAppointments";
import _ from "lodash";
import { isAppointmentInFuture } from "utils/appointment/isAppointmentInFuture";

export const getFutureAppointments = createSelector(
  getCase,
  getAppointments,
  (therapyCase, appointments) =>
    _.pickBy(appointments, appointment => therapyCase && appointment.caseId === therapyCase.id && isAppointmentInFuture(appointment)),
);
