import React from 'react';

import {
  BrowserRouter,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';
import { LoginGoogleRedirect } from 'pages/login/LoginGoogle/LoginGoogleRedirect';
import { PageNotFound } from 'containers/PageNotFound';
import {
  PATIENT_PAYMENT_PATH,
  APPOINTMENT_PATH,
  AppointmentPathParams,
  BOOKING_PATH,
  CANCEL_APPOINTMENT_PATH,
  LOGIN_PATH,
  ROOT_REDIRECT_PATH,
  SCHEDULE_PATH,
  MORE_PATH,
  END_SESSION_EARLY_PATH,
  PostSessionPathParams,
  POST_SESSION_PATH,
  DEV_PATH,
  PaymentSuccessPathParams,
  PAYMENT_SUCCESS_PATH,
  ADDRESS_UPDATE_PATH,
  AddressUpdatePathParams,
  ADDRESS_INPUT_PATH,
  RESCHEDULE_PATH,
  RESCHEDULE_SUCCESS_PATH,
  CREDIT_CARD_INPUT_PATH,
  USER_DETAILS_PATH,
  EDIT_USER_DETAILS_PATH,
  CARE_PLAN_PATH,
  PATIENT_REFERRAL_PATH,
  PATIENT_REFERRAL_SUCCESS_PATH,
  COMMUNICATION_PATH,
  LOGIN_GOOGLE_REDIRECT_PATH,
} from './routePaths';
import { PrivateRoute } from './PrivateRoute';
import { Schedule } from 'pages/schedule/Schedule';
import { Booking } from 'pages/booking/Booking';
import { MorePage } from 'pages/more/MorePage';
import { AppointmentPage } from 'pages/appointment/AppointmentPage';
import { AppointmentCancellationPage } from 'pages/appointment/AppointmentCancellationPage/AppointmentCancellationPage';
import { PaymentPage } from 'pages/payment/PaymentPage';
import { EndSessionConfirmation } from 'pages/session/EndSessionConfirmation/EndSessionConfirmation';
import { TestPage } from 'pages/development/TestPage';
import { PaymentSuccess } from 'pages/paymentSuccess/PaymentSuccess';
import { AddressSelectorPage } from 'pages/addressUpdate/AddressSelectorPage';
import { AddressInputPage } from 'pages/addressInput/AddressInputPage';
import { AppointmentReschedulePage } from 'pages/appointment/AppointmentReschedulePage/AppointmentReschedulePage';
import { RescheduleSuccessPage } from 'pages/appointment/AppointmentReschedulePage/RescheduleSuccessPage';
import { PostSessionPage } from 'pages/postSession/PostSessionPage';
import { CardInputPage } from 'pages/payment/CardInputPage';
import { UserDetailsPage } from 'pages/more/UserDetailsPage';
import { EditUserDetailsPage } from 'pages/more/EditUserDetailsPage/EditUserDetailsPage';
import { CarePlanPage } from 'pages/carePlan/CarePlanPage';
import { AppContainer } from 'containers/AppContainer/AppContainer';
import { PatientReferralPage } from 'pages/patientReferral/PatientReferralPage';
import { PatientReferralSuccessPage } from 'pages/patientReferral/PatientReferralSuccessPage';
import { CommunicationRouter } from 'pages/communication/CommunicationRouter';
import { Login } from 'pages/login/Login';

const renderBooking = ({ location }: RouteComponentProps) => (
  <Booking location={location} />
);

const renderAppointment = ({
  match,
  history,
}: RouteComponentProps<AppointmentPathParams>) => (
  <AppointmentPage match={match} history={history} />
);

const renderAppointmentCancellation = ({
  match,
  history,
}: RouteComponentProps<AppointmentPathParams>) => (
  <AppointmentCancellationPage match={match} history={history} />
);

const renderEndSessionConfirmation = ({ history }: RouteComponentProps) => (
  <EndSessionConfirmation />
);

const renderPostSession = ({
  match,
}: RouteComponentProps<PostSessionPathParams>) => (
  <PostSessionPage match={match} />
);

const renderPaymentSuccessPage = ({
  match,
}: RouteComponentProps<PaymentSuccessPathParams>) => (
  <PaymentSuccess match={match} />
);

const renderAddressUpdatePage = ({
  match,
}: RouteComponentProps<AddressUpdatePathParams>) => (
  <AddressSelectorPage match={match} />
);

const renderRescheduleSuccessPage = ({
  match,
}: RouteComponentProps<PaymentSuccessPathParams>) => (
  <RescheduleSuccessPage match={match} />
);

export const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path={LOGIN_PATH}>
        <Login />
      </Route>
      <Route path={LOGIN_GOOGLE_REDIRECT_PATH}>
        <LoginGoogleRedirect />
      </Route>
      <PrivateRoute path="/">
        <AppContainer>
          <Switch>
            <Route
              path={CANCEL_APPOINTMENT_PATH}
              render={renderAppointmentCancellation}
            />
            <Route
              path={RESCHEDULE_SUCCESS_PATH}
              render={renderRescheduleSuccessPage}
            />
            <Route
              path={RESCHEDULE_PATH}
              component={AppointmentReschedulePage}
            />
            <Route
              path={END_SESSION_EARLY_PATH}
              render={renderEndSessionConfirmation}
            />
            <Route path={APPOINTMENT_PATH} render={renderAppointment} />
            <Route path={POST_SESSION_PATH} render={renderPostSession} />
            <Route path={SCHEDULE_PATH}>
              <Schedule />
            </Route>
            <Route path={BOOKING_PATH} render={renderBooking} />
            <Route path={MORE_PATH}>
              <MorePage />
            </Route>
            <Route path={EDIT_USER_DETAILS_PATH}>
              <EditUserDetailsPage />
            </Route>
            <Route path={USER_DETAILS_PATH}>
              <UserDetailsPage />
            </Route>
            <Route path={CREDIT_CARD_INPUT_PATH} component={CardInputPage} />
            <Route path={PATIENT_PAYMENT_PATH} component={PaymentPage} />
            <Route
              path={PAYMENT_SUCCESS_PATH}
              render={renderPaymentSuccessPage}
            />
            <Route
              path={ADDRESS_UPDATE_PATH}
              render={renderAddressUpdatePage}
            />
            <Route path={ADDRESS_INPUT_PATH} component={AddressInputPage} />
            <Route path={CARE_PLAN_PATH} component={CarePlanPage} />
            <Route
              path={PATIENT_REFERRAL_SUCCESS_PATH}
              component={PatientReferralSuccessPage}
            />
            <Route
              path={PATIENT_REFERRAL_PATH}
              component={PatientReferralPage}
            />
            <Route path={COMMUNICATION_PATH} component={CommunicationRouter} />
            <Route path={DEV_PATH} component={TestPage} />
            <Route exact path="/">
              <Redirect to={ROOT_REDIRECT_PATH} />
            </Route>
            <Route>
              <PageNotFound />
            </Route>
          </Switch>
        </AppContainer>
      </PrivateRoute>
    </Switch>
  </BrowserRouter>
);
