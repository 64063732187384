import React, { useCallback } from 'react';
import { Field, Formik } from 'formik';
import { Flex, Text } from 'rebass';
import { CloseIcon } from 'components/icons/CloseIcon';
import { SignatureFormFields as SignatureFormFieldsComponent } from 'components/forms/SignatureForm/SignatureFormFields';
import * as Yup from 'yup';
import { LabelledCheckbox } from 'components/forms/LabelledCheckbox';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';

export interface FormValues {
  isAgree: boolean;
  signature: string;
}

const initialValues: FormValues = {
  isAgree: false,
  signature: '',
};

export interface OwnProps {
  onClose?: () => void;
  onSubmit: (values: FormValues) => void;
  terms?: React.ReactNode;
}

const validationSchema = Yup.object().shape({
  isAgree: Yup.boolean()
    .oneOf([true], 'Must agree to the terms')
    .required(),
  // signature: Yup.string().required(), // would have to convert canvas to form field to support this
});

export const DisclaimerSignatureForm = ({
  onClose,
  onSubmit,
  terms,
}: OwnProps) => {
  const SignatureFormFields = useCallback(
    ({ ...props }) => (
      <SignatureFormFieldsComponent {...props} terms={terms}>
        <Flex>
          <Field
            name="isAgree"
            component={LabelledCheckbox}
            checkedValue
            label={
              <Text fontSize="9pt">
                I agree to the Kulagy Physicial Therapy, P.C.
                <CustomTextLink
                  to={process.env.REACT_APP_TERMS_URL}
                  isInline
                  marginLeft={1}
                >
                  Terms
                </CustomTextLink>
                ,
                <CustomTextLink
                  to={process.env.REACT_APP_PRIVACY_POLICY_URL}
                  isInline
                  marginLeft={1}
                  marginRight={1}
                >
                  Privacy
                </CustomTextLink>
                and
                <CustomTextLink
                  to={process.env.REACT_APP_ESIGN_CONSENT_URL}
                  isInline
                  marginLeft={1}
                >
                  E-Sign Consent
                </CustomTextLink>
              </Text>
            }
          />
        </Flex>
      </SignatureFormFieldsComponent>
    ),
    [terms]
  );
  return (
    <Flex width="100%" flexDirection="column" padding={3}>
      <Flex width="100%" justifyContent="flex-end">
        {onClose && <CloseIcon size={20} onClick={onClose} />}
      </Flex>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {SignatureFormFields}
      </Formik>
    </Flex>
  );
};
