import React, { SyntheticEvent, useCallback } from 'react';
import { Box } from 'rebass';
import { IconProps } from 'components/icons/types';
import { CallIcon } from 'components/icons/CallIcon';
import { callPhoneNumber } from 'utils/callPhoneNumber';

interface Props extends IconProps {
  number?: string | null;
}

export const CallButton = ({ number, ...iconProps }: Props) => {
  const callNumber = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      number && callPhoneNumber(number);
    },
    [number]
  );
  return (
    <Box onClick={callNumber}>
      <CallIcon {...iconProps} />
    </Box>
  );
};
