import { useSelector } from "react-redux";
import { useCallback } from "react";

/*
  Allows us to pass a props object into useSelector
*/

type SelectorWithProps<State, Props, Result> = (state: State, props: Props) => Result;

export const useSelectorWithProps =
  <State, Props, Result>(selector: SelectorWithProps<State, Props, Result>, props: Props) => {
    const wrappedSelector = useCallback(
      (state: State) => selector(state, props),
      [selector, props],
    );
    return useSelector(wrappedSelector);
  };
