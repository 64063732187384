import { stringify } from 'query-string';
import { api } from './api';
import { isEmpty } from 'lodash';
import { from } from 'rxjs';

const createURLWithQueryString = (
  endpoint: string,
  payload?: Record<string, any>
) => {
  if (!payload || isEmpty(payload)) return endpoint;
  return `${endpoint}?${stringify(payload)}`;
};

export type GetParams = {
  endpoint: string;
  payload?: Record<string, any>;
  headers?: Record<string, any>;
};

export const get$ = ({ endpoint, payload, headers = {} }: GetParams) => {
  return from(
    api({
      url: `${createURLWithQueryString(endpoint, payload)}`,
      headers: {
        ...headers,
      },
    })
  );
};
