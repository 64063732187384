import { CustomEpic } from 'types/types';
import {
  UpdateTherapistActionPayload,
  updateTherapistFailure,
  updateTherapistSuccess,
} from 'modules/therapist/actions';
import { ofType } from 'redux-observable';
import { UPDATE_THERAPIST } from 'modules/therapist/actionTypes';
import { switchMap } from 'rxjs/operators';
import { getTherapistEndpoint } from 'modules/therapist/utils/getTherapistEndpoint';
import { getId } from 'selectors/user/getId';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import {
  reverseTranslateTherapist,
  translateTherapist,
} from 'modules/therapist/utils/translateTherapist';

export const updateTherapist$: CustomEpic<UpdateTherapistActionPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(UPDATE_THERAPIST),
    switchMap(({ payload: { therapist } }) =>
      postApi$({
        endpoint: getTherapistEndpoint({
          therapistId: therapist.id || getId(state$.value),
        }),
        payload: reverseTranslateTherapist(therapist),
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(updateTherapistSuccess, translateTherapist),
        handleAjaxError(updateTherapistFailure)
      )
    )
  );
