import { FetchCaseSuccessResponse } from 'modules/cases/types';
import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';
import { Case } from 'types/types';
import { convertCamelToSnakeCase } from 'utils/convertCamelToSnakeCase';

export const translateCase = (response: Object): Case => {
  return convertSnakeToCamelCase(response) as FetchCaseSuccessResponse;
};

export const reverseTranslateCase = (
  therapyCase: Partial<Omit<Case, 'id'>>
): Object => {
  return convertCamelToSnakeCase(therapyCase);
};
