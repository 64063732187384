import React from 'react';
import { Appointment } from "types/types";
import { Flex } from "rebass";
import { formatCurrency } from "utils/formatCurrency";

interface Props {
  appointment: Appointment;
}

export const CostBreakdown = ({
  appointment,
}: Props) => (
  <Flex
    flexDirection="column"
    fontSize={10}
  >
    <Flex>
      Patient responsibility: {formatCurrency({ amount: appointment.patientResponsibility })}
    </Flex>
    <Flex>
      Tech Fee: {formatCurrency({ amount: appointment.techFee })}
    </Flex>
  </Flex>
);
