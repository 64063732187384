import React from 'react';
import { Formik, FormikConfig } from 'formik';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/user/getUser';
import _ from 'lodash';
import { EditUserDetailsPageFormFields } from 'pages/more/EditUserDetailsPage/EditUserDetailsPageFormFields';
import {
  FormValues,
  MUTABLE_USER_FIELDS,
} from 'pages/more/EditUserDetailsPage/constants';

interface Props {
  onSubmit: FormikConfig<FormValues>['onSubmit'];
}

export const EditUserDetailsPageForm = ({ onSubmit }: Props) => {
  const user = useSelector(getUser);

  return (
    <Formik
      initialValues={_.pick<FormValues>(user, MUTABLE_USER_FIELDS)}
      onSubmit={onSubmit}
    >
      {EditUserDetailsPageFormFields}
    </Formik>
  );
};
