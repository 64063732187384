import { createAction } from 'redux-actions';
import {
  CLAIM_OFFER_FAILURE,
  CLAIM_OFFER_START,
  CLAIM_OFFER_SUCCESS,
  FETCH_OFFER_FAILURE,
  FETCH_OFFER_START,
  FETCH_OFFER_SUCCESS,
  FETCH_OFFERS_FAILURE,
  FETCH_OFFERS_START,
  FETCH_OFFERS_SUCCESS,
  UNCLAIM_OFFER_FAILURE,
  UNCLAIM_OFFER_START,
  UNCLAIM_OFFER_SUCCESS,
} from 'modules/offers/actionsTypes';
import { DetailedOffer, ListOffer } from 'types/types';

export const fetchOffers = createAction(FETCH_OFFERS_START);

export type FetchOffersSuccessPayload = ListOffer[];
export const fetchOffersSuccess = createAction<FetchOffersSuccessPayload>(
  FETCH_OFFERS_SUCCESS
);

export const fetchOffersFailure = createAction(FETCH_OFFERS_FAILURE);

export interface FetchOfferPayload {
  offerId: string;
}
export const fetchOffer = createAction<FetchOfferPayload>(FETCH_OFFER_START);

export type FetchOfferSuccessPayload = DetailedOffer;
export const fetchOfferSuccess = createAction<FetchOfferSuccessPayload>(
  FETCH_OFFER_SUCCESS
);

export const fetchOfferFailure = createAction(FETCH_OFFER_FAILURE);

export interface ClaimOfferPayload {
  offerId: string;
}
export const claimOffer = createAction<ClaimOfferPayload>(CLAIM_OFFER_START);

// Non-useful props excluded from type
export interface ClaimOfferSuccessPayload {
  id: number;
  claimedAt: string | null;
  // therapistId: number;
  unclaimedAt: string | null;
  acceptedAt: string | null;
  postedAt: string | null;
  createdAt: string | null;
}

export const claimOfferSuccess = createAction<ClaimOfferSuccessPayload>(
  CLAIM_OFFER_SUCCESS
);

export const claimOfferFailure = createAction(CLAIM_OFFER_FAILURE);

export interface UnclaimOfferPayload {
  offerId: string;
}

export const unclaimOffer = createAction<UnclaimOfferPayload>(
  UNCLAIM_OFFER_START
);

export type UnclaimOfferSuccessPayload = ClaimOfferSuccessPayload;
export const unclaimOfferSuccess = createAction<UnclaimOfferSuccessPayload>(
  UNCLAIM_OFFER_SUCCESS
);

export const unclaimOfferFailure = createAction(UNCLAIM_OFFER_FAILURE);
