import React, { useCallback } from 'react';
import { Formik, FormikProps } from "formik";
import { OnHoldFormFields } from "pages/postSession/OnHoldFormFields";
import * as Yup from "yup";
import { OnHoldReason } from "pages/postSession/constants";

export interface FormValues {
  reason?: OnHoldReason;
  otherReason?: string;
}

const initialValues: FormValues = {
};

const validationSchema = Yup.object().shape({
  reason: Yup.string().required('Required'),
});

interface Props {
  onSubmit: (values: FormValues) => void;
  isDisabled?: boolean;
}

export const OnHoldForm = ({ onSubmit, isDisabled }: Props) => {
  const renderFormFields = useCallback(
    (formProps: FormikProps<FormValues>) => (
      <OnHoldFormFields
        {...formProps}
        isDisabled={isDisabled}
      />
    ),
    [isDisabled]
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {renderFormFields}
    </Formik>
  );
}
