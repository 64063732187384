import React from 'react';
import ReactLoader from 'react-loader-spinner';
import { PINK } from 'theme/colors';

interface Props {
  spinnerColor?: string;
  size?: number;
}

export const LoadingSpinner = ({ size = 50, spinnerColor = PINK }: Props) => {
  return (
    <ReactLoader
      type="TailSpin"
      color={spinnerColor}
      height={size}
      width={size}
    />
  );
};
