import React from 'react';
import { Appointment, Case, Doctor, Lead, Patient } from 'types/types';
import { PatientFileLinks } from 'containers/PatientFileLinks/PatientFileLinks/PatientFileLinks';
import { FileFetcher } from '../../containers/Files/FileFetcher/FileFetcher';

interface Props {
  patient: Patient;
  therapyCase: Case;
  doctor?: Doctor;
  lead: Lead;
  appointment: Appointment;
}

export const PatientFiles = ({
  appointment,
  patient,
  therapyCase,
  doctor,
  lead,
}: Props) => {
  return (
    <FileFetcher caseId={String(therapyCase.id)}>
      <PatientFileLinks
        patient={patient}
        therapyCase={therapyCase}
        doctor={doctor}
        lead={lead}
        appointment={appointment}
      />
    </FileFetcher>
  );
};
