import { createStore } from 'redux';
import { omit } from 'lodash';
import { GlobalState, reducers } from 'reducers';
import { epicMiddleware, middleware } from './middleware';
import rootEpic from '../epics';

const LOCAL_STORAGE_KEY = 'state';
const saveToLocalStorage = (state: GlobalState) => {
  try {
    const serializedState = JSON.stringify(omit(state, 'evalSession'));
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch (e) {
    console.error(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const store = createStore(reducers, loadFromLocalStorage(), middleware);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;

epicMiddleware.run(rootEpic);
