import React from 'react';
import { Box, Flex } from 'rebass';
import { Field } from 'formik';
import { LabelledRadio } from 'components/forms/LabelledRadio';

interface Option {
  label: React.ReactNode;
  value: any;
}

interface Props {
  headerText: React.ReactNode;
  options: Option[];
  fieldName: string;
  disabled?: boolean;
}

export const LabelledRadioFieldGroup = ({
  headerText,
  options,
  fieldName,
  disabled = false,
}: Props) => (
  <Flex flexDirection="column" padding={3}>
    {headerText}
    <Box marginTop={3}>
      {options.map(({ label, value }) => (
        <Box key={label?.toString() || value.toString()} marginBottom={1}>
          <Field
            component={LabelledRadio}
            name={fieldName}
            label={label}
            checkedValue={value}
            disabled={disabled}
          />
        </Box>
      ))}
    </Box>
  </Flex>
);
