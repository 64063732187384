import { RawAppointmentServiceType } from 'modules/appointmentServiceTypes/types';
import { AppointmentServiceType } from 'types/types';
import { normalizeAppointmentServiceType } from 'modules/appointmentServiceTypes/utils/appointmentServiceTypeNormalization';

export const translateFetchCaseAppointmentServiceTypes = (caseId: number) => (
  response: RawAppointmentServiceType[]
): { caseId: number; appointmentTypes: AppointmentServiceType[] } => ({
  caseId,
  appointmentTypes: response.map(rawAppointmentServiceType =>
    normalizeAppointmentServiceType(rawAppointmentServiceType)
  ),
});
