import React, { useCallback } from 'react';
import { Box, Text } from 'rebass';
import {
  AppFileInputType,
  AppFileType,
  UploaderRenderer,
} from '../../utils/file/constants';
import { useSelectorWithProps } from '../../utils/hooks/useSelectorWithProps';
import { getCaseFile } from '../../selectors/files/getCaseFile';
import { createGetIsFileTypePendingApproval } from 'selectors/files/createGetIsFileTypePendingApproval';
import { FileUploader } from './FileUploader/FileUploader';
import { useDispatchFunction } from '../../utils/hooks/useDispatchFunction';
import { uploadAppFile } from '../../modules/files/actions';
import { FileViewUpload } from './FileViewUpload';
import { useSelector } from 'react-redux';
import { FormRequestButton } from './FormRequestButton';
import { AbnFormRequestButton } from './AbnFormRequestButton';

interface Props {
  appFileType: AppFileType;
  inputType?: AppFileInputType;
  caseId: string;
  leadId?: string;
}

const renderWaitingApprovalReuploadButton: UploaderRenderer = ({
  handleClick,
}) => {
  return (
    <Box
      sx={{
        display: 'inline',
      }}
    >
      <Text
        color="grey"
        sx={{
          display: 'inline',
        }}
      >
        Pending approval...
      </Text>
      <Text
        variant="link"
        marginLeft={1}
        onClick={handleClick}
        sx={{
          display: 'inline',
        }}
      >
        Re-upload
      </Text>
    </Box>
  );
};

const renderUploadButton: UploaderRenderer = ({ handleClick }) => {
  return (
    <Box
      sx={{
        display: 'inline',
      }}
    >
      <Text
        color="grey"
        sx={{
          display: 'inline',
        }}
      >
        Click here to
      </Text>
      <Text
        variant="link"
        marginLeft={1}
        onClick={handleClick}
        sx={{
          display: 'inline',
        }}
      >
        Add
      </Text>
    </Box>
  );
};

const renderReuploadButton: UploaderRenderer = ({ handleClick }) => (
  <Text
    variant="link"
    onClick={handleClick}
    sx={{
      display: 'inline',
    }}
  >
    Re-upload
  </Text>
);

export const CaseFileControls = ({
  appFileType,
  inputType = AppFileInputType.Upload,
  caseId,
  leadId,
}: Props) => {
  const uploadFile = useDispatchFunction(uploadAppFile);

  const isFilePendingApproval = useSelector(
    createGetIsFileTypePendingApproval(caseId, appFileType)
  );
  const file = useSelectorWithProps(getCaseFile, {
    caseId,
    appFileType,
  });

  const handleUpload = useCallback(
    async (file: File) => {
      uploadFile({
        caseId,
        leadId,
        filename: appFileType,
        file: file,
        upload_type: appFileType,
      });
    },
    [leadId, uploadFile, caseId, appFileType]
  );

  if (isFilePendingApproval) {
    return (
      <FileUploader
        inputType={inputType}
        appFileType={appFileType}
        render={renderWaitingApprovalReuploadButton}
        onUpload={handleUpload}
      />
    );
  }

  const FileInputs: any = {
    [AppFileInputType.AbnForm]: (
      <AbnFormRequestButton
        leadId={leadId || ''}
        caseId={caseId}
        appFileType={appFileType}
      ></AbnFormRequestButton>
    ),
    [AppFileInputType.Form]: (
      <FormRequestButton
        leadId={leadId || ''}
        caseId={caseId}
        appFileType={appFileType}
      ></FormRequestButton>
    ),
    default: (
      <FileUploader
        inputType={inputType}
        appFileType={appFileType}
        render={file ? renderReuploadButton : renderUploadButton}
        onUpload={handleUpload}
      />
    ),
  };

  const FileInput = FileInputs[inputType] || FileInputs.default;

  return file ? (
    <FileViewUpload file={file} caseId={caseId} uploader={FileInput} />
  ) : (
    FileInput
  );
};
