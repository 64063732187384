import { combineEpics } from 'redux-observable';

import { login$ } from './login$';
import { fetchAuthorizationCode$, fetchGoogleOauth2Tokens$ } from './auth$';

export default combineEpics(
  login$,
  fetchAuthorizationCode$,
  fetchGoogleOauth2Tokens$
);
