import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Appointment, Case, Patient } from 'types/types';
import { getBookedAppointment } from 'selectors/schedule/getBookedAppointment';
import { getPatient } from 'selectors/patients/getPatient';
import { getCase } from 'selectors/cases/getCase';
import { SCHEDULE_PATH } from 'routing/routePaths';
import { SuccessPageTemplate } from 'containers/SuccessPageTemplate/SuccessPageTemplate';
import { useGoTo } from 'utils/hooks/useGoTo';
import { Text } from 'rebass';

interface OwnProps {}

interface StateProps {
  bookedAppointment: Appointment | null;
  patient?: Patient;
  therapyCase?: Case; // app should already have case
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: GlobalState): StateProps => {
  // TODO: replace with real appointment type when the return type is updated
  const bookedAppointment = (getBookedAppointment(
    state
  ) as unknown) as Appointment;
  return {
    bookedAppointment,
    patient: getPatient(state, {
      patientId: bookedAppointment?.patientId || 0,
    }),
    therapyCase: getCase(state, {
      caseId: String(bookedAppointment?.caseId) || '',
    }),
  };
};

const mapDispatchToProps: DispatchProps = {};

const BookingSuccessPageComponent = ({ therapyCase, patient }: Props) => {
  const goToSchedule = useGoTo(SCHEDULE_PATH);
  return (
    <SuccessPageTemplate
      therapyCaseId={String(therapyCase?.id)}
      patientId={String(patient?.id)}
      onFooterClick={goToSchedule}
      footerLabel="Back to my schedule"
    >
      <Text
        marginTop={3}
        marginLeft={3}
        marginRight={3}
        fontSize="24pt"
        textAlign="center"
      >
        Appointment was Booked Successfully!
      </Text>
    </SuccessPageTemplate>
  );
};

export const BookingSuccessPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingSuccessPageComponent);
