import React, { useCallback } from 'react';
import { Flex, Text } from 'rebass';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { useGoBack } from 'utils/hooks/useGoBack';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { useUrlParam } from 'utils/hooks/useUrlParam';
import { CarePlanUrlParams } from 'routing/routeFunctions';
import { getPatient } from 'selectors/patients/getPatient';
import { useSelectorWithProps } from 'utils/hooks/useSelectorWithProps';
import { CareHeader } from 'components/CareHeader/CareHeader';
import { getPatientLead } from 'selectors/leads/getPatientLead';
import { getCaseForPatient } from 'selectors/cases/getCaseForPatient';
import { Separator } from 'components/Separator';
import { FutureCaseAppointments } from 'containers/FutureCaseAppointments/FutureCaseAppointments';
import { isFailure, isFetching } from 'utils/fetchStatus';
import { ACTION_HOOKS, CallAction } from 'utils/hooks/useCall/config';

export const CarePlanPage = () => {
  const goBack = useGoBack();
  const patientId = useUrlParam<CarePlanUrlParams>('patientId');
  const patient = useSelectorWithProps(getPatient, {
    patientId: patientId || '',
  });
  const lead = useSelectorWithProps(getPatientLead, {
    patientId: patientId || '',
  });
  const therapyCase = useSelectorWithProps(getCaseForPatient, {
    patientId: patientId || '',
  });

  const { call: dischargeCaseCall, fetchStatus } = ACTION_HOOKS[
    CallAction.Discharge
  ]({
    onSuccess: goBack,
  });

  const dischargeCase = useCallback(
    () =>
      dischargeCaseCall({
        caseId: therapyCase?.id || '',
      }),
    [dischargeCaseCall, therapyCase]
  );

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Patient Plan"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
      />
      <CareHeader patient={patient} lead={lead} therapyCase={therapyCase} />
      <Separator marginTop={0} marginBottom={3} />
      <ScrollBox flexDirection="column" flex={1}>
        <FutureCaseAppointments caseId={String(therapyCase?.id)} />
      </ScrollBox>
      {isFailure(fetchStatus) && (
        <Text color="red" alignSelf="center" margin={2}>
          Call failed
        </Text>
      )}
      <FooterButton
        label="Discharge"
        variant="secondary"
        onClick={dischargeCase}
        disabled={isFetching(fetchStatus)}
      />
    </Flex>
  );
};
