import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Appointment, FetchStatus } from 'types/types';
import { fetchFutureAppointments as fetchFutureAppointmentsAction } from 'modules/appointments/actions';
import { getScheduleFetchStatus } from 'selectors/schedule/getScheduleFetchStatus';
import { getFutureAppointmentsList } from 'selectors/cases/getFutureAppointmentsList';

export interface RenderProps {
  fetchStatus: FetchStatus;
  appointments: Appointment[];
}

interface OwnProps {
  render: (props: RenderProps) => React.ReactNode;
  caseId: string;
}

interface StateProps {
  futureAppointments: Appointment[];
  appointmentsFetchStatus: FetchStatus;
}

interface DispatchProps {
  fetchFutureAppointments: typeof fetchFutureAppointmentsAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  { caseId }: OwnProps
): StateProps => ({
  appointmentsFetchStatus: getScheduleFetchStatus(state),
  futureAppointments: getFutureAppointmentsList(state, { caseId }),
});

const mapDispatchToProps: DispatchProps = {
  fetchFutureAppointments: fetchFutureAppointmentsAction,
};

const FutureAppointmentsComponent = ({
  appointmentsFetchStatus,
  render,
  fetchFutureAppointments,
  caseId,
  futureAppointments,
}: Props) => {
  useEffect(() => {
    fetchFutureAppointments({ caseId });
  }, [fetchFutureAppointments, caseId]);

  return (
    <>
      {render({
        fetchStatus: appointmentsFetchStatus,
        appointments: futureAppointments,
      })}
    </>
  );
};

export const FutureAppointments = connect(
  mapStateToProps,
  mapDispatchToProps
)(FutureAppointmentsComponent);
