import React from 'react';
import { connect } from "react-redux";
import { GlobalState } from "reducers";
import { Appointment, Case, Patient } from "types/types";
import { getNextAppointment } from "utils/appointment/getNextAppointment";
import { BucketedAppointmentList } from "pages/schedule/BucketedAppointmentList";
import { getLastAppointmentId } from "selectors/schedule/getLastAppointment";

interface OwnProps {
  appointments: Appointment[];
  patients: Record<string, Patient>;
  cases: Record<string, Case>
  currentlySelectedDate?: string;
}

interface StateProps {
  lastAppointmentId: number | null;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (state: GlobalState): StateProps => ({
  lastAppointmentId: getLastAppointmentId(state),
});

const SmartAppointmentListComponent = ({
  appointments,
  patients,
  cases,
  currentlySelectedDate,
  lastAppointmentId,
}: Props) => {
  const initialAppointmentId = lastAppointmentId || getNextAppointment({
    appointments,
  })?.id;

  return (
    <BucketedAppointmentList
      appointments={appointments}
      patients={patients}
      cases={cases}
      currentlySelectedDate={currentlySelectedDate}
      initialAppointmentId={initialAppointmentId}
    />
  );
};

export const SmartAppointmentList = connect(mapStateToProps)(SmartAppointmentListComponent);
