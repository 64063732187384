import React from 'react';
import { Button, ButtonProps } from "rebass";
import { CssTypeFix } from "types/types";

export const OptionButton = ({children, ...props}: ButtonProps & CssTypeFix) => (
  <Button
    variant="secondary"
    marginBottom={3}
    {...props}
  >
    {children}
  </Button>
);
