import { Case } from "types/types";
import { getCaseProgression } from "utils/case/caseProgression/getCaseProgression";

interface Props {
  therapyCase: Case;
}

export const getCaseProgressionFraction = ({ therapyCase }: Props): string => {
  const { casesDone, casesLeft } = getCaseProgression({ therapyCase });
  return `${casesDone}/${casesLeft}`;
};
