import React from 'react';
import { CardBrand } from 'types/types';
import { Box, Flex } from 'rebass';
import { CreditCardIcon } from 'components/icons/CreditCardIcon';

interface Props {
  cardBrand?: CardBrand | null;
  cardLast4?: string | null;
}

const defaultCardBrand = CardBrand.Unknown;

export const CreditCardInfo = ({
  cardBrand = defaultCardBrand,
  cardLast4,
}: Props) => (
  <Flex alignItems="center">
    <Box marginRight={2}>
      <CreditCardIcon cardBrand={cardBrand || defaultCardBrand} size={30} />
    </Box>
    **** **** **** {cardLast4}
  </Flex>
);
