import React from 'react';
import { Avatar } from 'components/Avatar/Avatar';
import { DefaultUserAvatar } from 'components/icons/DefaultUserAvatar';
import { Flex, Text } from 'rebass';
import { Patient } from 'types/types';

interface Props {
  patient: Patient;
  children?: React.ReactNode;
}

export const PatientBasicInfo = ({ patient, children = null }: Props) => (
  <Flex flex={1}>
    <Avatar size={40}>
      <DefaultUserAvatar size={30} />
    </Avatar>
    <Flex flexDirection="column" paddingLeft={2}>
      <Text fontWeight="bold" paddingBottom={2}>
        {patient?.lastName}, {patient?.firstName}
      </Text>
      {children}
    </Flex>
  </Flex>
);
