import { AppFileType } from '../../utils/file/constants';

export const FILE_UPLOAD_HOOK_URL = 'webhooks/file_upload';
export const EVENT_HOOK_URL = 'webhooks/event_notice';

export const fileTypeToUploadType: Record<AppFileType, string | undefined> = {
  [AppFileType.InsuranceFront]: 'insurance',
  [AppFileType.InsuranceBack]: 'insurance',
  [AppFileType.Insurance2Front]: 'insurance2',
  [AppFileType.Insurance2Back]: 'insurance2',
  [AppFileType.Rx]: 'rx',
  [AppFileType.Payment]: 'payment',
  [AppFileType.FormSignature]: 'signature',
  [AppFileType.Initials]: 'initials',
  [AppFileType.IntakeForm]: 'intake',
  [AppFileType.AbnForm]: 'abn',
  [AppFileType.LienForm]: 'lien',
  [AppFileType.Other]: 'other',
};
