import { createSelector } from 'reselect';
import { getListOffers } from 'selectors/offers/getListOffers';
import { ListOffer, OfferStatus } from 'types/types';

export type BucketedOfferList = Record<OfferStatus, ListOffer[]>;

export const getBucketedOfferList = createSelector(getListOffers, offers =>
  Object.values(offers).reduce(
    (result, currentValue) => {
      result[currentValue.status].push(currentValue);
      return result;
    },
    {
      [OfferStatus.Ready]: [],
      [OfferStatus.Claimed]: [],
      [OfferStatus.Unclaimed]: [],
      [OfferStatus.Offered]: [],
      [OfferStatus.Scheduled]: [],
      [OfferStatus.PatientCancelled]: [],
    } as BucketedOfferList
  )
);
