import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { AppointmentPathParams, SCHEDULE_PATH } from 'routing/routePaths';
import { History } from 'history';
import { match } from 'react-router-dom';
import {
  Appointment,
  Case,
  Doctor,
  Insurance,
  Lead,
  Patient,
} from 'types/types';
import { fetchLead as fetchLeadAction } from 'modules/leads/actions';
import { getAppointment } from 'selectors/schedule/getAppointment';
import { getPatient } from 'selectors/patients/getPatient';
import { getCase } from 'selectors/cases/getCase';
import { getDoctor } from 'selectors/doctors/getDoctor';
import { getLead } from 'selectors/leads/getLead';
import { ScheduledAppointmentPage } from 'pages/appointment/ScheduledAppointmentPage';
import { SessionPage } from 'pages/session/SessionPage/SessionPage';
import { isAppointmentInProgress } from 'utils/appointment/isAppointmentInProgress';
import { isAppointmentFinished } from 'utils/appointment/isAppointmentFinished';
import { FinishedAppointmentPage } from 'pages/appointment/FinishedAppointmentPage';
import { isAppointmentFinalized } from 'utils/appointment/isAppointmentFinalized';
import { getPaymentPath, getPostSessionPath } from 'routing/routeFunctions';
import { Redirect } from 'react-router-dom';
import { setLastAppointment as setLastAppointmentAction } from 'modules/appointments/actions';
import { getIsPaymentEnabled } from 'selectors/features/getIsPaymentEnabled';
import { setAppointmentAsFinalized as setAppointmentAsPaidAction } from 'modules/appointments/actions';
import { isPaymentDue } from 'utils/appointment/isPaymentDue';
import { getPatientInsurance } from 'selectors/patients/getPatientInsurance';
import { getIsHomeHealthAlertEnabled } from 'selectors/features/getIsHomeHealthAlertEnabled';

interface OwnProps {
  match: match<AppointmentPathParams>;
  history: History;
}

interface StateProps {
  appointment?: Appointment;
  patient?: Patient;
  therapyCase?: Case;
  doctor?: Doctor;
  lead?: Lead;
  insurance?: Insurance;
  isHomeHealthAlertEnabled: boolean;
}

interface DispatchProps {
  fetchLead: typeof fetchLeadAction;
  setLastAppointment: typeof setLastAppointmentAction;
  setAppointmentAsPaid: typeof setAppointmentAsPaidAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  {
    match: {
      params: { appointmentId },
    },
  }: OwnProps
): StateProps => {
  const isHomeHealthAlertEnabled = getIsHomeHealthAlertEnabled(state);
  const appointment = getAppointment(state, {
    appointmentId: Number(appointmentId),
  });
  const patient = appointment
    ? getPatient(state, {
        patientId: appointment.patientId,
      })
    : undefined;
  const insurance = appointment
    ? getPatientInsurance(state, {
        patientId: appointment.patientId,
      })
    : undefined;
  const therapyCase = appointment
    ? getCase(state, {
        caseId: String(appointment.caseId),
      })
    : undefined;
  const doctor = therapyCase
    ? getDoctor(state, {
        doctorId: String(therapyCase.doctorId),
      })
    : undefined;
  const lead = therapyCase
    ? getLead(state, {
        leadId: String(therapyCase.leadId),
      })
    : undefined;
  return {
    appointment,
    therapyCase,
    patient,
    doctor,
    lead,
    insurance,
    isHomeHealthAlertEnabled,
  };
};

const mapDispatchToProps: DispatchProps = {
  fetchLead: fetchLeadAction,
  setLastAppointment: setLastAppointmentAction,
  setAppointmentAsPaid: setAppointmentAsPaidAction,
};

const AppointmentPageComponent = ({
  match,
  history,
  appointment,
  patient,
  therapyCase,
  doctor,
  fetchLead,
  lead,
  insurance,
  setLastAppointment,
  setAppointmentAsPaid,
  isHomeHealthAlertEnabled,
}: Props) => {
  const isPaymentEnabled = useSelector(getIsPaymentEnabled);

  useEffect(() => {
    if (therapyCase && therapyCase.leadId) {
      fetchLead({ leadId: String(therapyCase.leadId) });
    }
  }, [fetchLead, therapyCase]);

  // Set this as last visited appointment
  useEffect(() => {
    if (appointment) {
      setLastAppointment({
        id: appointment.id,
      });
    }
  });

  console.log(appointment?.appStatus); // TODO: remove

  if (appointment) {
    if (isAppointmentInProgress(appointment)) {
      return (
        <SessionPage
          match={match}
          history={history}
          appointment={appointment}
          patient={patient}
          therapyCase={therapyCase}
          doctor={doctor}
          lead={lead}
          insurance={insurance}
        />
      );
    }
    if (isAppointmentFinished(appointment) && therapyCase) {
      if (isPaymentEnabled) {
        if (isPaymentDue({ appointment })) {
          return (
            <Redirect to={getPaymentPath({ appointmentId: appointment.id })} />
          );
        }
        setAppointmentAsPaid({ id: appointment.id });
      }
      return (
        <Redirect to={getPostSessionPath({ appointmentId: appointment.id })} />
      );
    }
    if (isAppointmentFinalized(appointment)) {
      return (
        <FinishedAppointmentPage
          match={match}
          history={history}
          appointment={appointment}
          patient={patient}
          therapyCase={therapyCase}
          doctor={doctor}
          lead={lead}
          insurance={insurance}
        />
      );
    }
    return (
      <ScheduledAppointmentPage
        lead={lead}
        match={match}
        history={history}
        appointment={appointment}
        patient={patient}
        insurance={insurance}
        therapyCase={therapyCase}
      />
    );
  } else {
    if (isHomeHealthAlertEnabled) {
      return <Redirect to={SCHEDULE_PATH}></Redirect>;
    } else {
      return null;
    }
  }
};

export const AppointmentPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentPageComponent);
