import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/user/getUser';
import { TherapistInfo } from 'components/TherapistInfo/TherapistInfo';

interface Props {
  children?: React.ReactNode;
}

export const UserInfo = ({ children }: Props) => {
  const user = useSelector(getUser);

  return <TherapistInfo therapist={user}>{children}</TherapistInfo>;
};
