
export enum OnHoldReason {
  MedicalReasons = 'medical reasons',
  OutOfTown = 'out of town',
  UnsureOfSchedule = 'unsure of schedule',
  RefuseToScheduleAhead = 'refuse to schedule ahead',
  Treatment = 'treatment',
  SelfDischarge = 'self-discharge',
  MetGoal = 'met goal',
  Other = 'other',
}

interface OnHoldReasonOption {
  label: string,
  value: OnHoldReason,
}

export const onHoldReasonOptions: OnHoldReasonOption[] = [
  { label: 'On Hold for Medical Reasons', value: OnHoldReason.MedicalReasons },
  { label: 'Out of Town', value: OnHoldReason.OutOfTown },
  { label: 'Unsure of schedule (PT will Call Back)', value: OnHoldReason.UnsureOfSchedule },
  { label: 'Refuse to Schedule Ahead', value: OnHoldReason.RefuseToScheduleAhead },
  { label: 'Treatment', value: OnHoldReason.Treatment },
  { label: 'Self-Discharge', value: OnHoldReason.SelfDischarge },
  { label: 'Met Goal', value: OnHoldReason.MetGoal },
  { label: 'Other', value: OnHoldReason.Other },
];
