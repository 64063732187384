// from https://gist.github.com/wuchengwei/b7e1820d39445f431aeaa9c786753d8e
export const dataUrlToBlob = (dataUrl: string) => {
  const arr = dataUrl.split(',');
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const binaryString = atob(arr[1]);
  let n = binaryString.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = binaryString.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};
