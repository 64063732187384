import React, { useCallback } from 'react';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { UserInfo } from 'containers/UserInfo/UserInfo';
import { FooterButton } from 'components/FooterButton/FooterButton';
import { Field, FormikProps } from 'formik';
import { FormValues } from 'pages/more/EditUserDetailsPage/constants';
import { TherapistInfoDisplayer } from 'components/TherapistInfo/TherapistInfoDisplayer';
import { FormikTextField } from 'components/forms/FormikTextField';
import { FetchStatusContext } from 'context/FetchStatusContext/FetchStatusContext';
import { FetchStatus } from 'types/types';

type Props = FormikProps<FormValues>;

export const EditUserDetailsPageFormFields = ({ submitForm }: Props) => {
  const Consumer = useCallback(
    (value: FetchStatus) => {
      const isFetching = value === FetchStatus.Fetching;
      return (
        <>
          <ScrollBox flexDirection="column" flex={1}>
            <UserInfo>
              <TherapistInfoDisplayer label="Notes:">
                <Field name="notes" component={FormikTextField} />
              </TherapistInfoDisplayer>
            </UserInfo>
          </ScrollBox>
          <FooterButton
            label={isFetching ? 'Loading...' : 'Save'}
            onClick={submitForm}
            disabled={isFetching}
            variant="secondary"
          />
        </>
      );
    },
    [submitForm]
  );
  return <FetchStatusContext.Consumer>{Consumer}</FetchStatusContext.Consumer>;
};
