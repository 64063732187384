import { CustomEpic } from 'types/types';
import {
  UpdatePatientActionPayload,
  updatePatientFailure,
  updatePatientSuccess,
} from 'modules/patients/actions';
import toast from 'react-hot-toast';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';
import { getPatientUrl } from 'modules/patients/utils/getPatientUrl';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { translatePatient } from 'modules/patients/utils/translatePatient';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { UPDATE_PATIENT_START } from 'modules/patients/actionTypes';
import { convertCamelToSnakeCase } from 'utils/convertCamelToSnakeCase';

export const updatePatient$: CustomEpic<UpdatePatientActionPayload> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(UPDATE_PATIENT_START),
    switchMap(({ payload: { patientId, ...rest } }) => {
      let toastId: string = '';
      if (rest.paymentMethodId) {
        toastId = toast.loading('Updating Patient');
      }
      return postApi$({
        endpoint: getPatientUrl({ patientId }),
        headers: getAuthHeaders(state$.value),
        payload: convertCamelToSnakeCase(rest),
      }).pipe(
        handleAjaxSuccess(updatePatientSuccess, result => {
          if (toastId) {
            toast.dismiss(toastId);
            toast.success('Patient updated');
          }
          return translatePatient(result);
        }),
        handleAjaxError(updatePatientFailure, 'An error ocurred', toastId)
      );
    })
  );
