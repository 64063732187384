import { CustomEpic } from 'types/types';
import {
  fetchCasesSuccess,
  fetchCasesFailure,
  GetPatientCasesActionPayload,
} from '../actions';
import { ofType } from 'redux-observable';
import { FETCH_PATIENT_CASES } from '../actionTypes';
import { switchMap } from 'rxjs/operators';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { fetchCasesCallTranslator } from 'modules/cases/translators/translateFetchCases';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import { FETCH_CASES_URL } from 'modules/cases/constants';

export const fetchPatientCases$: CustomEpic<GetPatientCasesActionPayload> = (
  action$,
  state$,
  { getApi$ }
) =>
  action$.pipe(
    ofType(FETCH_PATIENT_CASES),
    switchMap(({ payload: { patientId, onlyOpen = true } }) =>
      getApi$({
        endpoint: FETCH_CASES_URL,
        payload: {
          open: onlyOpen ? 'only' : undefined,
          patientId,
        },
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(fetchCasesSuccess, fetchCasesCallTranslator),
        handleAjaxError(fetchCasesFailure)
      )
    )
  );
