import React from 'react';
import { Box, Flex } from 'rebass';
import { Appointment, AppointmentService, Case, Patient } from 'types/types';
import { getCaseProgressionStatement } from 'utils/case/caseProgression/getCaseProgressionStatement';
import { formatAppointmentTime } from 'utils/appointment/formatAppointmentTime';

interface Props {
  appointment: Appointment;
  patient?: Patient;
  therapyCase?: Case;
}

export const AppointmentListItem = ({
  appointment,
  patient,
  therapyCase,
}: Props) => {
  const { service } = appointment;
  const time = formatAppointmentTime({ appointment });

  if (service === AppointmentService.Blocked) {
    return (
      <Flex
        width="100%"
        height={40}
        padding={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          borderTop: '1px solid',
          borderColor: 'muted',
          color: 'grey',
        }}
      >
        {service}
        <Flex>{time}</Flex>
      </Flex>
    );
  }

  return (
    <Flex
      flexDirection="column"
      width="100%"
      padding={2}
      sx={{
        borderTop: '1px solid',
        borderColor: 'muted',
      }}
    >
      <Flex
        justifyContent="space-between"
        sx={{
          fontWeight: 'bold',
        }}
      >
        <Box>
          {patient?.lastName}, {patient?.firstName}
        </Box>
        <Box>
          {therapyCase &&
            getCaseProgressionStatement({ therapyCase, appointment })}
        </Box>
      </Flex>
      <Flex
        justifyContent="space-between"
        sx={{
          color: 'grey',
        }}
      >
        <Box>{service}</Box>
        <Box>{time}</Box>
      </Flex>
    </Flex>
  );
};
