import React, { useCallback } from 'react';
import {
  AppFileType,
  AppFileInputType,
  UploaderRenderer,
} from 'utils/file/constants';
import { getAcceptedAppFileExtensions } from 'utils/file/getAcceptedAppFileExtensions';
import { SignatureFormModal } from 'components/forms/SignatureForm/SignatureFormModal';
import { blobToFile } from 'utils/image/blobToFile';
import { dataUrlToBlob } from 'utils/image/dataUrlToBlob';
import { getSignatureFormConfigMapping } from 'utils/file/getSignatureFormConfigMapping';
import { SimpleFileInputWrapper } from '../../../components/FileInputWrapper/SimpleFileInputWrapper';
import { CompositeImageInputModal } from '../../../components/CompositeImageInputModal/CompositeImageInputModal';

interface Props {
  appFileType: AppFileType;
  inputType?: AppFileInputType;
  render: UploaderRenderer;
  onUpload: (file: File) => void;
}

export const FileUploader = ({
  appFileType,
  render,
  inputType = AppFileInputType.Upload,
  onUpload,
}: Props) => {
  const renderButton = useCallback(
    ({ openModal }) => render({ handleClick: openModal }),
    [render]
  );

  const submitStringAsFile = useCallback(
    (data: string) => {
      const file = blobToFile({
        blob: dataUrlToBlob(data),
        fileName: 'temp',
      });
      onUpload(file);
    },
    [onUpload]
  );

  if (inputType === AppFileInputType.FormSignature) {
    const { signatureFormType, terms } = getSignatureFormConfigMapping({
      appFileType,
    });
    return (
      <SignatureFormModal
        onSubmit={({ signature }: { signature: string }) => {
          submitStringAsFile(signature);
        }}
        renderButton={renderButton}
        terms={terms}
        signatureFormType={signatureFormType}
      />
    );
  }

  if (inputType === AppFileInputType.Composite) {
    return (
      <CompositeImageInputModal
        onSubmit={submitStringAsFile}
        renderButton={renderButton}
        accept={getAcceptedAppFileExtensions(appFileType)}
      />
    );
  }

  return (
    <SimpleFileInputWrapper
      render={render}
      onFileSubmit={onUpload}
      accept={getAcceptedAppFileExtensions(appFileType)}
    />
  );
};
