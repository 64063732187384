import { Appointment } from "types/types";
import { GlobalState } from "reducers";
import { getAppointment } from "selectors/appointments/getAppointment";
import { PostSessionPathParams } from "routing/routePaths";
import { match } from "react-router-dom";
import { connect } from "react-redux";
import { AppointmentNotFoundPage } from "pages/appointmentNotFound/AppointmentNotFoundPage";
import React from "react";
import { PostSession } from "pages/postSession/PostSession";

interface OwnProps {
  match: match<PostSessionPathParams>;
}

interface StateProps {
  appointment?: Appointment;
}

type Props = OwnProps & StateProps;

const mapStateToProps = (
  state: GlobalState,
  {
    match: {
      params: {
        appointmentId,
      },
    },
  }: OwnProps,
) =>  ({
  appointment: getAppointment(state, { appointmentId }),
});

const PostSessionPageComponent = ({ appointment }: Props) => {
  if (!appointment) {
    return (
      <AppointmentNotFoundPage />
    );
  }

  return (
    <PostSession
      appointment={appointment}
    />
  );
};

export const PostSessionPage = connect(mapStateToProps)(PostSessionPageComponent);
