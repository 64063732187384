interface Props {
  amount: number | string;
  omitCurrencySymbol?: boolean;
}

export const formatCurrency = ({
  amount,
  omitCurrencySymbol = false,
}: Props): string => {
  const prefix = !omitCurrencySymbol ? '$' : '';
  if (Number(amount) % 1 > 0) {
    return `${prefix}${Number(amount).toFixed(2)}`;
  }
  return `${prefix}${Number(amount)}`;
};
