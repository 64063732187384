import { createSelector } from "reselect";
import { getLeads } from "selectors/leads/getLeads";
import { GlobalState } from "reducers";
import _ from 'lodash';

export const getPatientLead = createSelector(
  getLeads,
  (_: GlobalState, { patientId }: { patientId: string }) => patientId,
  (leads, patientId) => _.find(Object.values(leads), lead => String(lead.patientId) === patientId),
);
