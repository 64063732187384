import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { PatientInfoFrame } from 'components/PatientInfoFrame/PatientInfoFrame';
import { Appointment, Case, Lead, Patient } from 'types/types';
import { getPatientFirstName } from 'utils/patient/getPatientFirstName';
import { getPatientLastName } from 'utils/patient/getPatientLastName';
import { getInjury } from 'utils/lead/getInjury';
import { InsuranceAndSessions } from 'components/InsuranceAndSessions/InsuranceAndSessions';

interface Props {
  patient?: Patient;
  lead?: Lead;
  appointment?: Appointment;
  therapyCase?: Case;
}

export const CareHeader = ({ patient, lead, therapyCase }: Props) => {
  return (
    <Flex margin={3} flexDirection="column">
      <PatientInfoFrame
        firstName={getPatientFirstName({ patient })}
        lastName={getPatientLastName({ patient })}
      >
        <Text color="grey">{lead && getInjury({ lead })}</Text>
      </PatientInfoFrame>
      {therapyCase && patient && (
        <Box marginTop={2} marginBottom={2}>
          <InsuranceAndSessions therapyCase={therapyCase} patient={patient} />
        </Box>
      )}
    </Flex>
  );
};
