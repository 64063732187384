import React from 'react';
import { FieldProps } from 'formik';
import { LabelledComponent } from 'components/forms/LabelledComponent';
import { Radio } from 'components/forms/Radio';

export const LabelledRadio = ({
  field,
  label,
  checkedValue,
  disabled = false,
}: {
  label: React.ReactNode;
  checkedValue: any;
  disabled?: boolean;
} & FieldProps) => (
  <LabelledComponent
    label={label}
    input={
      <Radio field={field} checkedValue={checkedValue} disabled={disabled} />
    }
  />
);
