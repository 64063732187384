import { createSelector } from 'reselect';
import { getCaseFiles } from './getCaseFiles';
import { GlobalState } from '../../reducers';
import { AppFile } from '../../types/types';
import { AppFileType } from '../../utils/file/constants';

export const getCaseFile = createSelector(
  getCaseFiles,
  (_: GlobalState, { appFileType }: { appFileType?: AppFileType }) =>
    appFileType,
  (caseFiles, appFileType): AppFile | undefined =>
    appFileType && caseFiles?.[appFileType]
);
