import { getUser } from 'selectors/user/getUser';
import { GlobalState } from 'reducers';
import { Cohort } from 'types/types';
import { isTherapistInCohort } from 'utils/features/isTherapistInCohort';
import { createSelector } from 'reselect';

export const getIsUserInCohort = createSelector(
  getUser,
  (_: GlobalState, { cohort }: { cohort: Cohort }) => cohort,
  (user, cohort) => isTherapistInCohort({ therapist: user, cohort })
);
