import { SignatureFormType } from 'components/forms/SignatureForm/constants';
import { SignatureForm } from 'components/forms/SignatureForm/SignatureForm';
import { DisclaimerSignatureForm } from 'components/forms/SignatureForm/DisclaimerSignatureForm';

interface Props {
  signatureFormType: SignatureFormType;
}

type SignatureFormComponent =
  | typeof SignatureForm
  | typeof DisclaimerSignatureForm;

const signatureFormMapping: Record<
  SignatureFormType,
  SignatureFormComponent
> = {
  [SignatureFormType.Default]: SignatureForm,
  [SignatureFormType.Disclaimer]: DisclaimerSignatureForm,
};

export const getSignatureFormComponent = ({
  signatureFormType,
}: Props): SignatureFormComponent => signatureFormMapping[signatureFormType];
