import { Case, FetchStatus } from 'types/types';
import { Action, handleActions } from 'redux-actions';
import {
  FETCH_CASE,
  FETCH_CASE_FAILURE,
  FETCH_CASE_SUCCESS,
  FETCH_THERAPIST_CASES,
  FETCH_CASES_FAILURE,
  FETCH_CASES_SUCCESS,
  UPDATE_CASE,
  UPDATE_CASE_FAILURE,
  UPDATE_CASE_SUCCESS,
  FETCH_PATIENT_CASES,
} from 'modules/cases/actionTypes';
import {
  FetchCaseSuccessActionPayload,
  GetCasesSuccessActionPayload,
  UpdateCaseSuccessActionPayload,
} from 'modules/cases/actions';
import { combineReducers } from 'redux';
import { GET_SCHEDULE_SUCCESS } from 'modules/schedule/actionTypes';
import { GetScheduleSuccessActionPayload } from 'modules/schedule/actions';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';

export interface CaseMap {
  [key: string]: Case;
}
export interface CasesState {
  normalized: CaseMap;
  fetchStatus: FetchStatus;
}

const initialNormalizedState: CaseMap = {};

const normalized = handleActions<CaseMap, any>(
  {
    [FETCH_CASES_SUCCESS]: (
      state,
      action: Action<GetCasesSuccessActionPayload>
    ) => ({
      ...state,
      ...action.payload,
    }),
    [FETCH_CASE_SUCCESS]: (
      state,
      action: Action<FetchCaseSuccessActionPayload>
    ) => ({
      ...state,
      ...(action.payload?.id
        ? {
            [action.payload.id]: action.payload,
          }
        : {}),
    }),
    [GET_SCHEDULE_SUCCESS]: (
      state,
      action: Action<GetScheduleSuccessActionPayload>
    ) => ({
      ...state,
      ...action.payload.cases,
    }),
    [UPDATE_CASE_SUCCESS]: (
      state,
      action: Action<UpdateCaseSuccessActionPayload>
    ) => ({
      ...state,
      ...(action.payload?.id
        ? {
            [action.payload.id]: action.payload,
          }
        : {}),
    }),
  },
  initialNormalizedState
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [
    FETCH_PATIENT_CASES,
    FETCH_THERAPIST_CASES,
    FETCH_CASE,
    UPDATE_CASE,
  ],
  successActions: [
    FETCH_CASES_SUCCESS,
    FETCH_CASE_SUCCESS,
    UPDATE_CASE_SUCCESS,
  ],
  failureActions: [
    FETCH_CASES_FAILURE,
    FETCH_CASE_FAILURE,
    UPDATE_CASE_FAILURE,
  ],
});

export default combineReducers<CasesState>({
  normalized,
  fetchStatus,
});
