interface Params {
  fileUrl: string;
}
export const fetchS3Image = ({ fileUrl }: Params): Promise<any> => {
  return fetch(fileUrl)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise(callback => {
          let reader = new FileReader();
          reader.onload = function() {
            callback(this.result);
          };
          reader.readAsDataURL(blob);
        })
    );
};
