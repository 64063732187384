import React from 'react';
import { OfferStatus } from 'types/types';
import { Label } from 'components/Label/Label';
import { formattedOfferStatus, offerStatusColors } from 'appConstants';
import { Text } from 'rebass';

interface Props {
  offerStatus: OfferStatus;
}

export const OfferStatusLabel = ({ offerStatus }: Props) => {
  return (
    <Label color={offerStatusColors[offerStatus].color}>
      <Text color={offerStatusColors[offerStatus].textColor} fontWeight="bold">
        {formattedOfferStatus[offerStatus]}
      </Text>
    </Label>
  );
};
