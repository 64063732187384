import { createSelector } from 'reselect';
import { GlobalState } from '../../reducers';
import { getFiles } from './getFiles';
import { AppFile } from '../../types/types';

export const getCaseFiles = createSelector(
  getFiles,
  (_: GlobalState, { caseId }: { caseId: string }) => caseId,
  (files, caseId): Record<string, AppFile> | undefined => files[caseId]
);
