import { catchError } from 'rxjs/operators';
import { ActionFunction1 } from 'redux-actions';
import { ErrorActionPayload } from 'types/types';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

export const handleAjaxError = (
  failActionCreator: ActionFunction1<any, any>,
  customErrorMessage?: string,
  toastId?: string
) =>
  catchError((err: AxiosError) => {
    const errorActionPayload: ErrorActionPayload = {
      message: err?.message,
      name: err?.name,
      status: err?.response?.status,
    };
    if (toastId) toast.dismiss(toastId);
    if (
      (!err.response || (err?.response && err?.response?.status !== 401)) &&
      (customErrorMessage || err?.message)
    ) {
      toast.error(customErrorMessage || err?.message);
    }
    return [failActionCreator(errorActionPayload)];
  });
