import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/user/getUser';
import { EmploymentType } from 'types/types';
import { Flex } from 'rebass';
import {
  StatsDisplayer,
  StatsColumns,
} from 'components/StatsDisplayer/StatsDisplayer';

export const FullTimeTherapistStatsCard = () => {
  const user = useSelector(getUser);
  if (user.employmentType !== EmploymentType.FullTime) {
    return null;
  }
  const config: StatsColumns = [
    [
      {
        boldText: user.active,
        postText: 'Active',
      },
      {
        boldText: user.visits,
        postText: 'Visits',
      },
      {
        boldText: user.ptPoints,
        postText: 'PT Points',
      },
      {
        boldText: user.cxlRate && `${user.cxlRate}%`,
        postText: 'Cancel Rate',
      },
    ],
    [
      {
        boldText: user.onHold,
        postText: 'Hold',
      },
      {
        boldText: user.freq,
        postText: 'Frequency',
      },
      {
        boldText: user.fillRate && `${user.fillRate}%`,
        postText: 'Fill Rate',
      },
    ],
  ];

  return (
    <Flex
      padding={3}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'muted',
      }}
    >
      <StatsDisplayer config={config} />
    </Flex>
  );
};
