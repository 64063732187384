import { combineReducers } from 'redux';
import { handleAction, handleActions } from 'redux-actions';
import {
  FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE,
  FETCH_GOOGLE_OAUTH2_TOKENS,
  FETCH_GOOGLE_OAUTH2_TOKENS_FAILURE,
  FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS,
  FETCH_GOOGLE_OAUTH2_TOKENS_UNAUTHORIZED,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
} from 'modules/auth/actionTypes';
import { FetchStatus, Role } from 'types/types';
import {
  FetchGoogleOauth2TokensSuccessPayload,
  LoginActionPayload,
  LoginSuccessPayload,
} from 'modules/auth/actions';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';

export interface AuthState {
  isAuthenticated: boolean;
  token: string;
  role: Role;
  fetchStatus: FetchStatus;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  googleOauth2Status: FetchStatus;
}

const isAuthenticated = handleActions(
  {
    [LOGIN_SUCCESS]: () => true,
    [LOGIN_FAILURE]: () => false,
    [FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS]: () => true,
    [FETCH_GOOGLE_OAUTH2_TOKENS_FAILURE]: () => false,
  },
  false
);

const token = handleAction<string, LoginActionPayload>(
  LOGIN,
  (state, action) => {
    const { username, password } = action.payload;
    return btoa(`${username}:${password}`);
  },
  ''
);

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [LOGIN],
  successActions: [LOGIN_SUCCESS],
  failureActions: [LOGIN_FAILURE],
});

const role = handleActions<Role, LoginSuccessPayload>(
  {
    [LOGIN_SUCCESS]: (state, action) => action?.payload?.role,
    [FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS]: (state, action) =>
      action?.payload?.role,
  },
  Role.Unknown
);

const accessToken = handleActions<
  string,
  FetchGoogleOauth2TokensSuccessPayload
>(
  {
    [FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS]: (state, action) =>
      action?.payload?.access_token,
    FAKE_ACCESS_TOKEN: () => 'fakeity fakeity fake fake fake',
  },
  ''
);

const refreshToken = handleAction<
  string,
  FetchGoogleOauth2TokensSuccessPayload
>(
  FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS,
  (state, action) =>
    action?.payload?.refresh_token ? action?.payload?.refresh_token : state,
  ''
);

const idToken = handleAction<string, FetchGoogleOauth2TokensSuccessPayload>(
  FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS,
  (state, action) => action?.payload?.id_token,
  ''
);

const googleOauth2Status = createFetchStatusReducer({
  fetchingActions: [
    FETCH_GOOGLE_OAUTH2_AUTHORIZATION_CODE,
    FETCH_GOOGLE_OAUTH2_TOKENS,
  ],
  successActions: [FETCH_GOOGLE_OAUTH2_TOKENS_SUCCESS],
  failureActions: [FETCH_GOOGLE_OAUTH2_TOKENS_FAILURE],
  unauthorizedActions: [FETCH_GOOGLE_OAUTH2_TOKENS_UNAUTHORIZED],
});

export default combineReducers<AuthState>({
  isAuthenticated,
  token,
  role,
  fetchStatus,
  accessToken,
  refreshToken,
  idToken,
  googleOauth2Status,
});
