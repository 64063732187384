import React from 'react';
import { Flex } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ScrollBox } from 'components/ScrollBox/ScrollBox';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { useGoBack } from 'utils/hooks/useGoBack';
import { useSelector } from 'react-redux';
import { getBucketedOfferList } from 'selectors/offers/getBucketedOfferList';
import { OfferStatus } from 'types/types';
import { OfferListSection } from 'components/OfferListSection/OfferListSection';

const OFFER_SECTION_ORDER: OfferStatus[] = [
  OfferStatus.Unclaimed,
  OfferStatus.Claimed,
  OfferStatus.Scheduled,
  OfferStatus.Offered,
];

export const OffersPage = () => {
  const goBack = useGoBack();
  const offers = useSelector(getBucketedOfferList);

  return (
    <Flex width="100%" flexDirection="column">
      <HeaderBar
        title="Offers"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
      />
      <ScrollBox flexDirection="column" marginTop={2}>
        {OFFER_SECTION_ORDER.map(offerStatus => (
          <OfferListSection
            key={offerStatus}
            offerStatus={offerStatus}
            offers={offers[offerStatus]}
          />
        ))}
      </ScrollBox>
    </Flex>
  );
};
