import React, { ButtonHTMLAttributes } from 'react';
import GoogleLogo from './googleLogo.svg';

import './LoginWithGoogleButton.css';

type LoginWithGoogleButtonProps = {
  onClick: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
};

export const LoginWithGoogleButton = ({
  onClick,
}: LoginWithGoogleButtonProps) => {
  return (
    <button
      onClick={onClick}
      data-content="Login with Google"
      className="login-google-button"
    >
      <img src={GoogleLogo} width={30} height={30} alt="google logo"></img>
      <span
        style={{
          position: 'absolute',
          left: '-100vw',
          visibility: 'hidden',
        }}
      >
        Log in with Google
      </span>
    </button>
  );
};
