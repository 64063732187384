import { ListOffer, OfferType } from 'types/types';
import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';

export const translateListOffer = (response: Object): ListOffer => {
  const camelResponse = convertSnakeToCamelCase(response);
  return {
    offerType: OfferType.ListOffer,
    ...camelResponse,
  };
};
