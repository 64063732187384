import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { CreditCardInput } from 'components/forms/CreditCardInput/CreditCardInput';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { PatientPaymentPathParams } from 'routing/routePaths';
import { helpMessage } from 'appConstants';
import { PaymentMethod } from '@stripe/stripe-js';
import { getPatient } from 'selectors/patients/getPatient';
import { FetchStatus, Patient } from 'types/types';
import { updatePatientPaymentMethod } from 'modules/patients/actions';
import { useGoBack } from 'utils/hooks/useGoBack';
import { StripeProvider } from 'components/StripeProvider/StripeProvider';
import { useFetchStatusHooks } from 'utils/hooks/useFetchStatusHooks';
import { getPatientsFetchStatus } from 'selectors/patients/getPatientsFetchStatus';

type OwnProps = RouteComponentProps<PatientPaymentPathParams>;

interface StateProps {
  appointmentId: number;
  patient?: Patient;
  fetchStatus: FetchStatus;
}

interface DispatchProps {
  submitPaymentMethod: typeof updatePatientPaymentMethod;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  {
    match: {
      params: { appointmentId },
    },
  }: OwnProps
): StateProps => {
  const appointment = getAppointment(state, { appointmentId });
  const patientId = appointment?.patientId;
  const patient =
    typeof patientId === 'undefined'
      ? undefined
      : getPatient(state, { patientId });
  return {
    appointmentId: Number(appointmentId),
    patient,
    fetchStatus: getPatientsFetchStatus(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  submitPaymentMethod: updatePatientPaymentMethod,
};

const CardInputPageComponent = ({
  appointmentId,
  patient,
  submitPaymentMethod,
  fetchStatus,
}: Props) => {
  const goBack = useGoBack();

  const { armHook } = useFetchStatusHooks({
    fetchStatus,
    onSuccess: goBack,
  });

  const handlePaymentFormSubmit = useCallback(
    (paymentMethod: PaymentMethod) => {
      if (!patient) {
        throw new Error('Patient not found for appointment');
      }

      armHook();
      submitPaymentMethod({
        patientId: String(patient.id),
        paymentMethod,
      });
    },
    [patient, submitPaymentMethod, armHook]
  );

  const Frame = useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <Flex flexDirection="column" width="100%">
        <HeaderBar
          title="Add Card"
          left={<ArrowBackIcon size={20} onClick={goBack} />}
        />
        {children}
      </Flex>
    ),
    [goBack]
  );

  if (!appointmentId) {
    return (
      <Frame>
        <Text>Appointment not found</Text>
      </Frame>
    );
  }

  if (!patient) {
    return (
      <Frame>
        <Text>Patient not found</Text>
      </Frame>
    );
  }

  return (
    <Frame>
      <CreditCardInput
        customerId={String(patient.id)}
        onSuccess={handlePaymentFormSubmit}
      />
      <Text textAlign="center" color="grey" marginBottom={3}>
        {helpMessage}
      </Text>
    </Frame>
  );
};

const CardInputPageStripeWrapper = (props: Props) => (
  <StripeProvider>
    <CardInputPageComponent {...props} />
  </StripeProvider>
);

export const CardInputPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CardInputPageStripeWrapper)
);
