import { CustomEpic, ErrorActionPayload } from 'types/types';
import {
  BookAppointmentActionPayload,
  bookAppointmentFailure,
  bookAppointmentSuccess,
} from 'modules/appointments/actions';
import { ActionsObservable, ofType } from 'redux-observable';
import { BOOK_APPOINTMENT } from 'modules/appointments/actionTypes';
import { catchError, map, switchMap } from 'rxjs/operators';
import { APPOINTMENTS_ENDPOINT } from 'modules/appointments/constants';
import { getId } from 'selectors/user/getId';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { bookAppointmentCallTranslator } from 'modules/appointments/translator';
import { of } from 'rxjs';
import { fetchPatientCases } from 'modules/cases/actions';
import { fetchPatientCases$ } from 'modules/cases/epics/fetchPatientCases$';
import { getCaseForPatient } from 'selectors/cases/getCaseForPatient';
import { FETCH_CASES_SUCCESS } from 'modules/cases/actionTypes';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

export const bookAppointment$: CustomEpic<BookAppointmentActionPayload> = (
  action$,
  state$,
  { postApi$, getApi$ }
) =>
  action$.pipe(
    ofType(BOOK_APPOINTMENT),
    switchMap(action => of(action)),
    // Fetch cases for patient if none can be found
    switchMap(action => {
      const {
        payload: { patientId, caseId },
      } = action;
      if (caseId) {
        return of(action);
      }
      const patientCase = getCaseForPatient(state$.value, { patientId });
      if (!patientCase) {
        return fetchPatientCases$(
          of(
            fetchPatientCases({
              patientId: String(patientId),
            })
          ) as ActionsObservable<any>,
          state$,
          { getApi$, postApi$ }
        ).pipe(
          map(({ type, payload }) => {
            const caseId = Object.keys(payload)?.[0];
            if (type === FETCH_CASES_SUCCESS && caseId) {
              // Update payload with correct case id
              action.payload.caseId = Number(caseId);
            }
            return action; // pass hopefully updated payload to next item in chain
          })
        );
      }
      return of(action);
    }),
    switchMap(
      ({
        payload: { dateTime, type, therapistId, patientId, duration, caseId },
      }) => {
        const userId = getId(state$.value);

        const patientCase = getCaseForPatient(state$.value, { patientId });
        if (!caseId && !patientCase) {
          toast.error('Could not find case ID matching patient');
          return of(
            bookAppointmentFailure({
              name: 'Error',
              message: 'Could not find case ID matching patient',
            })
          );
        }
        return postApi$({
          endpoint: APPOINTMENTS_ENDPOINT,
          payload: {
            app_datetime: dateTime,
            app_type: type,
            case_id: caseId || patientCase.id,
            duration,
            patient_id: patientId,
            therapist_id: therapistId || userId,
            user_id: userId,
          },
          headers: getAuthHeaders(state$.value),
        }).pipe(
          handleAjaxSuccess(
            bookAppointmentSuccess,
            bookAppointmentCallTranslator
          ),
          catchError((err: AxiosError) => {
            const message = (err?.response?.data as any).message;
            const errorActionPayload: ErrorActionPayload = {
              message: message,
              name: err?.name,
              status: err?.response?.status,
            };
            toast.error(message);
            return [bookAppointmentFailure(errorActionPayload)];
          })
        );
      }
    )
  );
