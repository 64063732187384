import { combineReducers } from 'redux';
import { FetchStatus } from 'types/types';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';
import {
  REFER_PATIENT,
  REFER_PATIENT_FAILURE,
  REFER_PATIENT_SUCCESS,
} from 'modules/patientReferral/actionTypes';

export interface ApiState {
  patientReferralStatus: FetchStatus;
}

const patientReferralStatus = createFetchStatusReducer({
  fetchingActions: [REFER_PATIENT],
  successActions: [REFER_PATIENT_SUCCESS],
  failureActions: [REFER_PATIENT_FAILURE],
});

export default combineReducers<ApiState>({
  patientReferralStatus,
});
