import React from 'react';
import { FetchStatus } from 'types/types';
import { Flex } from 'rebass';

interface Props {
  fetchStatus: FetchStatus;
}

export const CallError = ({ fetchStatus }: Props) => {
  if (fetchStatus !== FetchStatus.Failure) {
    return null;
  }
  return (
    <Flex
      alignItems="center"
      margin={1}
      sx={{
        color: 'red',
      }}
    >
      Call failed
    </Flex>
  );
};
