import React, { SyntheticEvent, useState } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { AutoComplete } from 'components/AutoComplete/AutoComplete';
import { Patient } from 'types/types';
import { getPatientsList } from 'selectors/patients/getPatientsList';
import {
  InputProps,
  RenderSuggestion,
  SuggestionsFetchRequestedParams,
  Theme,
} from 'react-autosuggest';
import { Box, Text } from 'rebass';
import { Input } from '@rebass/forms';
import { patientToString } from 'utils/patient/patientToString';

export interface OwnProps {
  value: string;
  onChange: (newValue: string) => void;
  onBlur?: (e: SyntheticEvent) => void;
  onFocus?: (e: SyntheticEvent) => void;
}

interface StateProps {
  patients: Patient[];
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const suggestPatients = ({
  currentInput,
  patients,
}: {
  currentInput: string;
  patients: Patient[];
}) => {
  const inputValue = currentInput.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength <= 0) {
    return [];
  }

  return patients.filter(
    patient =>
      (patient?.firstName &&
        patient.firstName.toLowerCase().slice(0, inputLength) === inputValue) ||
      (patient?.lastName &&
        patient.lastName.toLowerCase().slice(0, inputLength) === inputValue)
  );
};

const renderHighlightedString = ({
  highlightedPrefix,
  rest,
}: {
  highlightedPrefix: string;
  rest: string;
}) => (
  <Box
    sx={{
      display: 'inline',
    }}
  >
    <Text
      fontWeight="bold"
      sx={{
        display: 'inline',
      }}
    >
      {highlightedPrefix}
    </Text>
    {rest}
  </Box>
);

const renderInputComponent = (inputProps: any) => <Input {...inputProps} />;

const theme: Theme = {
  suggestionsContainer: {
    boxShadow: '0 0 12pt 0 black',
    borderRadius: '5px',
  },
  suggestionsList: {
    margin: 0,
    paddingTop: '5px',
    paddingLeft: 0,
  },
  suggestion: {
    display: 'block',
  },
};

const mapStateToProps = (state: GlobalState) => ({
  patients: getPatientsList(state),
});

const mapDispatchToProps = {};

const PatientAutoCompleteComponent = ({
  patients,
  value,
  onChange,
  onBlur,
  onFocus,
}: Props) => {
  const [suggestions, setSuggestions] = useState(patients);

  const getSuggestions = ({
    value,
    reason,
  }: SuggestionsFetchRequestedParams) => {
    setSuggestions(suggestPatients({ currentInput: value, patients }));
  };

  const clearSuggestions = () => {
    setSuggestions(patients);
  };

  const inputProps: InputProps<Patient> = {
    value,
    onChange: (event, { newValue }) => onChange(newValue),
    placeholder: 'Enter patient name',
    name: 'patientAutoComplete',
    style: {
      borderRadius: '5px',
    },
  };
  if (onBlur) {
    inputProps.onBlur = onBlur;
  }
  if (onFocus) {
    inputProps.onFocus = onFocus;
  }

  const renderSuggestion: RenderSuggestion<Patient> = (
    suggestedPatient,
    { query, isHighlighted }
  ) => {
    const parsedQuery = query.toLowerCase();
    const queryLength = parsedQuery.length;

    const renderHighlightedMatch = (string?: string) => {
      if (!string) {
        return null;
      }
      if (string.slice(0, queryLength).toLowerCase() === parsedQuery) {
        return renderHighlightedString({
          highlightedPrefix: string.slice(0, queryLength),
          rest: string.slice(queryLength),
        });
      }
      return string;
    };

    const firstName = renderHighlightedMatch(suggestedPatient?.firstName);
    const lastName = renderHighlightedMatch(suggestedPatient?.lastName);

    return (
      <Box
        sx={{
          paddingBottom: 1,
          paddingLeft: 2,
          ...(isHighlighted
            ? {
                backgroundColor: 'muted',
              }
            : {}),
        }}
      >
        {lastName}, {firstName}
      </Box>
    );
  };

  return (
    <AutoComplete
      suggestions={suggestions}
      getSuggestionValue={patientToString}
      inputProps={inputProps}
      onSuggestionsFetchRequested={getSuggestions}
      onSuggestionsClearRequested={clearSuggestions}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      theme={theme}
      focusInputOnSuggestionClick={false}
    />
  );
};

export const PatientAutoComplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientAutoCompleteComponent);
