import React from 'react';
import { Flex } from 'rebass';
import { Field } from 'formik';
import { FormikTextField } from 'components/forms/FormikTextField';

export const AddressInputFields = () => (
  <Flex width="100%" flexDirection="column">
    <Field
      name="streetAddress"
      component={FormikTextField}
      placeholder="Street Address"
      disableError={false}
      wrapperProps={{
        width: '100%',
      }}
    />
    <Field
      name="apt"
      component={FormikTextField}
      placeholder="Apt or Suite #"
      disableError={false}
      wrapperProps={{
        width: '100%',
      }}
    />
    <Flex>
      <Field
        name="city"
        component={FormikTextField}
        placeholder="City"
        disableError={false}
        wrapperProps={{
          flex: 1,
          marginRight: 1,
        }}
      />
      <Field
        name="zip"
        component={FormikTextField}
        placeholder="Zip"
        disableError={false}
        wrapperProps={{
          flex: 1,
          marginLeft: 1,
        }}
      />
    </Flex>
  </Flex>
);
