import React from 'react';
import moment, { Moment } from 'moment';
import _ from 'lodash';

interface Props {
  moment1: Moment;
  moment2: Moment;
}

const padZero = (num: number) => _.padStart(String(num), 2, '0');

export const TimeDuration = ({ moment1, moment2 }: Props) => {
  // @ts-ignore
  const { hours, minutes, seconds } = moment.preciseDiff(
    moment1,
    moment2,
    true
  );

  return (
    <div>
      {hours}:{padZero(minutes)}:{padZero(seconds)}
    </div>
  );
};
