import React, { useCallback } from 'react';
import { Flex } from 'rebass';
import { useGoBack } from 'utils/hooks/useGoBack';
import { HeaderBar } from 'components/HeaderBar';
import { ArrowBackIcon } from 'components/icons/ArrowBackIcon';
import { Formik } from 'formik';
import { AddressInputForm } from 'pages/addressInput/AddressInputForm';
import * as Yup from 'yup';

export interface FormValues {
  streetAddress: string;
  apt?: string;
  city: string;
  zip: string;
}

const initialValues: FormValues = {
  streetAddress: '',
  apt: '',
  city: '',
  zip: '',
};

const validationSchema = Yup.object().shape({
  streetAddress: Yup.string().required(),
  city: Yup.string().required(),
  zip: Yup.string().required(),
});

export const AddressInputPage = () => {
  const goBack = useGoBack();
  const onSubmit = useCallback((values: FormValues) => {
    console.log(values);
  }, []);

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Add Address"
        left={<ArrowBackIcon size={20} onClick={goBack} />}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {AddressInputForm}
      </Formik>
    </Flex>
  );
};
