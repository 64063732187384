import React from 'react';
import { Text } from 'rebass';
import { COLOR_CODE_PINK } from 'theme/constants';

interface Props {
  phoneNumber: string | null;
}

const formatPhoneNumber = (phoneNumber: string) => {
  const [, area, first3, last4] =
    phoneNumber?.match(/(\d{3})(\d{3})(\d{4})/) ?? [];
  return `(${area}) ${first3}-${last4}`;
};

export const FormattedPhoneNumber = ({ phoneNumber }: Props) => {
  if (!phoneNumber) {
    return null;
  }
  return <Text color={COLOR_CODE_PINK}>{formatPhoneNumber(phoneNumber)}</Text>;
};
