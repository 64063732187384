import { BoxProps } from 'rebass';
import { CssTypeFix } from 'types/types';

export const inputWrapperStyleProps: BoxProps & CssTypeFix = {
  padding: 2,
  sx: {
    border: 'border',
    borderRadius: 'borderRadius',
  },
};
