import { CustomEpic } from 'types/types';
import {
  HomeHealthAppointmentCancel,
  homeHealthCancelSuccess,
  homeHealthCancelFailure,
} from '../actions';
import { ofType } from 'redux-observable';
import { HOME_HEALTH_APPOINTMENT_CANCEL } from '../actionTypes';
import { switchMap } from 'rxjs/operators';
import { getAuthHeaders } from 'api/getAuthHeaders';
import { handleAjaxSuccess } from 'utils/epics/handleAjaxSuccess';
import { handleAjaxError } from 'utils/epics/handleAjaxError';
import toast from 'react-hot-toast';
import { getIsHomeHealthAlertEnabled } from 'selectors/features/getIsHomeHealthAlertEnabled';
import { of } from 'rxjs';

export const homeHealthCancel$: CustomEpic<HomeHealthAppointmentCancel> = (
  action$,
  state$,
  { postApi$ }
) =>
  action$.pipe(
    ofType(HOME_HEALTH_APPOINTMENT_CANCEL),
    switchMap(({ payload: { appointmentId } }) => {
      if (!getIsHomeHealthAlertEnabled(state$.value)) {
        return of({ type: 'FEATURE_GATE' });
      }
      const toastId = toast.loading('Cancelling session');
      return postApi$({
        endpoint: `/appointments/${appointmentId}/home_health_cancel`,
        headers: getAuthHeaders(state$.value),
      }).pipe(
        handleAjaxSuccess(() => {
          toast.dismiss(toastId);
          return homeHealthCancelSuccess({ appointmentId });
        }),
        handleAjaxError(homeHealthCancelFailure, undefined, toastId)
      );
    })
  );
