import _ from 'lodash';

// Recursively mutates all keys of an object
export const convertObject = ({
  mutator,
  param,
}: {
  mutator: (input: any) => any;
  param: any;
}): any => {
  if (_.isPlainObject(param)) {
    const result: { [key: string]: any } = {};
    _.toPairs(param).forEach(([key, value]) => {
      result[mutator(key)] = convertObject({ mutator, param: value });
    });
    return result;
  } else if (_.isArray(param)) {
    return param.map(value => convertObject({ mutator, param: value }));
  } else {
    return param;
  }
};
