import React from 'react';
import {
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { StripeCardNumberElementOptions } from '@stripe/stripe-js';
import { Box, Flex } from 'rebass';
import { inputWrapperStyleProps } from 'components/forms/CreditCardInput/constants';
import { Field } from 'formik';
import { FormikTextField } from 'components/forms/FormikTextField';

interface Props {}

// available options: https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options
const CARD_NUMBER_OPTIONS: StripeCardNumberElementOptions = {
  iconStyle: 'solid',
  showIcon: true,
};

export const CreditCardInputFormElements = (props: Props) => {
  return (
    <Flex flexDirection="column">
      <Box {...inputWrapperStyleProps} marginBottom={2}>
        <CardNumberElement options={CARD_NUMBER_OPTIONS} />
      </Box>
      <Flex>
        <Flex flex={1}>
          <Box {...inputWrapperStyleProps} width="100%" marginRight={2}>
            <CardExpiryElement />
          </Box>
        </Flex>
        <Flex flex={1}>
          <Box {...inputWrapperStyleProps} width="100%">
            <CardCvcElement />
          </Box>
        </Flex>
      </Flex>
      <Field
        name="zipCode"
        component={FormikTextField}
        placeholder="Zip Code"
        disableError={false}
      />
    </Flex>
  );
};
