import React from 'react';
import Select from 'react-select';
import { FieldProps } from 'formik';
import { Flex, Text } from 'rebass';

interface Option {
  label: string;
  value: string;
}

interface Props extends FieldProps<Option['value']> {
  options: Option[];
}

export const FormikSelectField = ({
  field,
  form: { touched, errors, setFieldValue },
  options,
  ...props
}: Props) => {
  const error = errors[field.name];
  const isTouched = touched[field.name];
  return (
    <Flex flexDirection="column" width="100%">
      <Select
        {...props}
        options={options}
        value={
          (options
            ? options.find(option => option.value === field.value)
            : '') as any
        }
        onChange={option => {
          setFieldValue(field.name, option ? option.value : '');
          const change = (props as any).onChangeField;
          change && change(field.name, option);
        }}
        width="100%"
      />
      {isTouched && error && (
        <Text variant="error" marginTop={1}>
          {error}
        </Text>
      )}
    </Flex>
  );
};
