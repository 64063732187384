import { CustomEpic } from "types/types";
import { FetchCaseActionPayload, fetchCaseFailure, fetchCaseSuccess } from "modules/cases/actions";
import { ofType } from "redux-observable";
import { FETCH_CASE } from "modules/cases/actionTypes";
import { switchMap } from "rxjs/operators";
import { getCaseUrl } from "modules/cases/utils/getCaseUrl";
import { getAuthHeaders } from "api/getAuthHeaders";
import { handleAjaxSuccess } from "utils/epics/handleAjaxSuccess";
import { handleAjaxError } from "utils/epics/handleAjaxError";
import { translateCase } from "modules/cases/translators/translateCase";

export const fetchCase$: CustomEpic<FetchCaseActionPayload> = (
  action$,
  state$,
  { getApi$ },
) => action$.pipe(
  ofType(FETCH_CASE),
  switchMap(
    ({
      payload: {
        caseId,
      },
    }) => getApi$({
      endpoint: getCaseUrl({ caseId }),
      headers: getAuthHeaders(state$.value),
    })
      .pipe(
        handleAjaxSuccess(fetchCaseSuccess, translateCase),
        handleAjaxError(fetchCaseFailure),
      )
  )
);
