import { GetCasesSuccessActionPayload } from 'modules/cases/actions';
import { FetchCasesResponseSuccess } from 'modules/cases/types';
import { convertSnakeToCamelCase } from 'utils/convertSnakeToCamelCase';
import { Case } from 'types/types';

export const fetchCasesCallTranslator = (
  response: Object[]
): GetCasesSuccessActionPayload => {
  const convertedResponse: FetchCasesResponseSuccess = convertSnakeToCamelCase(
    response
  );
  const result: Record<string, Case> = {};
  convertedResponse.forEach(therapyCase => {
    result[String(therapyCase.id)] = therapyCase;
  });
  return result;
};
