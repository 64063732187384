import React from 'react';
import { Flex } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { Centered } from 'components/Centered/Centered';

interface Props {
  children: React.ReactNode;
  title: React.ReactNode;
}

export const PageWrapper = ({ children, title }: Props) => (
  <Flex flexDirection="column" width="100%">
    <HeaderBar title={title} />
    <Centered
      sx={{
        textAlign: 'center',
      }}
    >
      {children}
    </Centered>
  </Flex>
);
