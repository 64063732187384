import React from 'react';
import { Therapist } from 'types/types';
import { Text } from 'rebass';

interface Props {
  therapist: Therapist;
}

export const TherapistEmail = ({
  therapist: { email, companyEmail },
}: Props) => {
  const therapistEmail = (companyEmail || email || '').toLowerCase();
  return (
    <Text color="grey" fontSize={14} marginBottom={1}>
      {therapistEmail}
    </Text>
  );
};
