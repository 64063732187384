import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Box, Flex, Text } from 'rebass';
import { HeaderBar } from 'components/HeaderBar';
import { History } from 'history';
import { match } from 'react-router-dom';
import { CloseIcon } from 'components/icons/CloseIcon';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CancellationForm } from 'pages/appointment/AppointmentCancellationPage/CancellationForm';
import { CancellationReason } from 'modules/appointments/constants';
import { cancelAppointment as cancelAppointmentAction } from 'modules/appointments/actions';
import { getScheduleFetchStatus } from 'selectors/schedule/getScheduleFetchStatus';
import { useFetchStatusHooks } from 'utils/hooks/useFetchStatusHooks';
import { FetchStatus } from 'types/types';
import { SCHEDULE_PATH } from 'routing/routePaths';

interface RouteParams {
  appointmentId: string;
}
interface OwnProps {
  history: History;
  match: match<RouteParams>;
}

interface StateProps {
  scheduleFetchStatus: FetchStatus;
}

interface DispatchProps {
  cancelAppointment: typeof cancelAppointmentAction;
}

export interface FormValues {
  reason?: CancellationReason;
}

const initialValues: FormValues = {};

const validationSchema = Yup.object().shape({
  reason: Yup.string().required(),
});

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: GlobalState): StateProps => ({
  scheduleFetchStatus: getScheduleFetchStatus(state),
});

const mapDispatchToProps: DispatchProps = {
  cancelAppointment: cancelAppointmentAction,
};

const AppointmentCancellationPageComponent = ({
  history,
  cancelAppointment,
  scheduleFetchStatus,
  match: {
    params: { appointmentId },
  },
}: Props) => {
  const goBack = React.useCallback(() => history.goBack(), [history]);

  const { armHook, isArmed } = useFetchStatusHooks({
    fetchStatus: scheduleFetchStatus,
    onSuccess: () => history.push(SCHEDULE_PATH),
  });

  const onSubmit = useCallback(
    ({ reason }: FormValues) => {
      if (reason) {
        cancelAppointment({
          id: Number(appointmentId),
          reason,
        });
        armHook();
      }
    },
    [appointmentId, cancelAppointment, armHook]
  );

  const renderCancellationForm = useCallback(
    props => (
      <CancellationForm
        {...props}
        disabled={isArmed && scheduleFetchStatus === FetchStatus.Fetching}
      />
    ),
    [isArmed, scheduleFetchStatus]
  );

  return (
    <Flex flexDirection="column" width="100%">
      <HeaderBar
        title="Cancel Appointment"
        right={<CloseIcon size={20} onClick={goBack} />}
      />
      {isArmed && scheduleFetchStatus === FetchStatus.Failure && (
        <Box paddingLeft={3} paddingTop={1}>
          <Text color="red">
            Cancellation request failed, please try again.
          </Text>
        </Box>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {renderCancellationForm}
      </Formik>
    </Flex>
  );
};

export const AppointmentCancellationPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentCancellationPageComponent);
