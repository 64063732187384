import React from 'react';
import {
  FooterButton,
  Props as FooterButtonProps,
} from 'components/FooterButton/FooterButton';
import moment, { Moment } from 'moment';
import { getAppointmentArrivalWindow } from 'utils/appointment/getAppointmentArrivalWindow';
import { isDevelopmentMode } from 'utils/isDevelopmentMode';

interface Props extends FooterButtonProps {
  startTime: Moment;
}

const getLabel = ({
  defaultLabel,
  earliest,
  latest,
  isArrivable,
}: {
  defaultLabel: React.ReactNode;
  earliest: Moment;
  latest: Moment;
  isArrivable: boolean;
}) => {
  if (isArrivable) {
    return defaultLabel;
  }
  if (moment().isBefore(earliest, 'day')) {
    return `Can't start session before ${earliest.format('MM/DD')}`;
  }
  if (moment().isAfter(latest, 'day')) {
    return `Can't start session after ${latest.format('MM/DD')}`;
  }
  if (moment().isBefore(earliest)) {
    return `Can't start session before ${earliest.format('hh:mm A')}`;
  }
  return `Can't start session after ${latest.format('hh:mm A')}`;
};

export const AppointmentArrivalButton = ({
  startTime,
  label,
  ...rest
}: Props) => {
  const { earliest, latest } = getAppointmentArrivalWindow({ startTime });

  const isArrivable = isDevelopmentMode || moment().isBetween(earliest, latest);
  const finalLabel = getLabel({
    defaultLabel: label,
    earliest,
    latest,
    isArrivable,
  });
  return <FooterButton label={finalLabel} {...rest} disabled={!isArrivable} />;
};
