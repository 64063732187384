import React from 'react';
import { Flex, Text } from 'rebass';

export interface Props {
  preText?: React.ReactNode;
  boldText: React.ReactNode;
  postText?: React.ReactNode;
}

export const BoldDisplayer = ({ preText, boldText, postText }: Props) => {
  return (
    <Flex>
      {preText && (
        <Text color="grey" paddingRight={1}>
          {preText}
        </Text>
      )}
      <Text fontWeight="bold">{boldText}</Text>
      {postText && (
        <Text color="grey" paddingLeft={1}>
          {postText}
        </Text>
      )}
    </Flex>
  );
};
