import { Appointment, Case } from 'types/types';

interface Props {
  therapyCase: Case;
  appointment: Appointment;
}

interface Output {
  casesDone: string;
  casesLeft: string;
}

export const getAppointmentCaseProgression = ({ therapyCase, appointment }: Props): Output => ({
  casesDone: String(appointment.visitNumber),
  casesLeft: therapyCase.totalPrescribedVisits,
});
