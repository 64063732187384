import { combineEpics } from 'redux-observable';
import { fetchTherapistCases$ } from 'modules/cases/epics/fetchTherapistCases$';
import { fetchCase$ } from 'modules/cases/epics/fetchCase$';
import { updateCase$ } from 'modules/cases/epics/updateCase$';
import { fetchPatientCases$ } from 'modules/cases/epics/fetchPatientCases$';
import { fetchCaseAppTypes$ } from './fetchCaseAppTypes$';

export default combineEpics(
  fetchPatientCases$,
  fetchTherapistCases$,
  fetchCase$,
  updateCase$,
  fetchCaseAppTypes$
);
