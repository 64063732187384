import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'selectors/user/getUser';
import { TherapistDetailsCard } from 'components/TherapistDetailsCard/TherapistDetailsCard';

interface Props {
  children?: React.ReactNode;
}

export const UserDetailsCard = ({ children }: Props) => {
  const user = useSelector(getUser);
  return (
    <TherapistDetailsCard therapist={user}>{children}</TherapistDetailsCard>
  );
};
