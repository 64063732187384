import React from 'react';
import { Flex } from 'rebass';
import { MainNav } from 'components/MainNav/MainNav';

export const FooterBar = () => (
  <Flex
    width="100%"
    sx={{
      borderTop: 'border',
      borderColor: 'muted',
      padding: 2,
      paddingTop: 3,
    }}
  >
    <MainNav />
  </Flex>
);
