import { createAction } from 'redux-actions';
import {
  REFER_PATIENT,
  REFER_PATIENT_FAILURE,
  REFER_PATIENT_SUCCESS,
} from 'modules/patientReferral/actionTypes';

export interface PatientReferralActionPayload {
  patientFirstName: string;
  patientLastName: string;
  patientEmail?: string;
  patientPhone?: string;
  referralName: string;
  referralEmail: string;
  referralPhone?: string;
}
export const referPatient = createAction<PatientReferralActionPayload>(
  REFER_PATIENT
);

export const referPatientSuccess = createAction(REFER_PATIENT_SUCCESS);

export const referPatientFailure = createAction(REFER_PATIENT_FAILURE);
