// https://developers.google.com/maps/documentation/urls/guide#directions-action

export interface GetGoogleMapsDirectionsUrlParams {
  lat?: number;
  lng?: number;
}
export const getGoogleMapsDirectionsUrl = ({
  lat,
  lng,
}: GetGoogleMapsDirectionsUrlParams) => {
  let baseUrl = 'https://www.google.com/maps/dir/?api=1';
  if (typeof lat !== 'undefined' && typeof lng !== 'undefined') {
    baseUrl = `${baseUrl}&destination=${lat},${lng}`;
  }
  return baseUrl;
};
