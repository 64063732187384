import React from 'react';
import { connect } from "react-redux";
import { GlobalState } from "reducers";
import { Flex } from "rebass";

interface OwnProps {
}

interface StateProps {
}

interface DispatchProps {
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: GlobalState): StateProps => ({});

const mapDispatchToProps: DispatchProps = ({});

const EndSessionConfirmationComponent = ({}: Props) => ( // eslint-disable-line
  <Flex>
    End Session Confirmation
  </Flex>
);

export const EndSessionConfirmation = connect(mapStateToProps, mapDispatchToProps)(EndSessionConfirmationComponent);
