import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { GlobalState } from 'reducers';
import { Box, Flex, Text } from 'rebass';
import { Appointment, FetchStatus, Patient } from 'types/types';
import { makePayment as makePaymentAction } from 'modules/payment/actions';
import { getAppointment } from 'selectors/appointments/getAppointment';
import { getPatient } from 'selectors/patients/getPatient';
import { CustomTextLink } from 'components/CustomLink/CustomTextLink';
import { useFetchStatusHooks } from 'utils/hooks/useFetchStatusHooks';
import { getPaymentFetchStatus } from 'selectors/payment/getPaymentFetchStatus';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { useGoBack } from 'utils/hooks/useGoBack';
import { getPaymentError } from 'selectors/payment/getPaymentError';
import { getPatientPaymentSourceId } from 'utils/patient/getPatientPaymentSourceId';

const TITLE = 'Payment in Progress';

interface OwnProps {
  appointmentId: string;
  onSuccess: () => void;
}

interface StateProps {
  appointment?: Appointment;
  patient?: Patient;
  fetchStatus: FetchStatus;
  error?: string;
}

interface DispatchProps {
  makePayment: typeof makePaymentAction;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: GlobalState,
  { appointmentId }: OwnProps
): StateProps => {
  const appointment = getAppointment(state, { appointmentId });
  return {
    appointment,
    patient:
      appointment && getPatient(state, { patientId: appointment.patientId }),
    fetchStatus: getPaymentFetchStatus(state),
    error: getPaymentError(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  makePayment: makePaymentAction,
};

const TriggerCardPaymentComponent = ({
  appointmentId,
  makePayment,
  patient,
  appointment,
  fetchStatus,
  onSuccess,
  error,
}: Props) => {
  const goBack = useGoBack();
  const { armHook, isArmed } = useFetchStatusHooks({
    fetchStatus,
    onSuccess,
  });

  const paymentMethodId = getPatientPaymentSourceId({ patient });
  useEffect(() => {
    if (paymentMethodId && appointment?.stripePiSecret) {
      makePayment({
        paymentMethodId,
        paymentIntentSecret: appointment?.stripePiSecret,
      });
      armHook();
    }
  }, [patient, appointment, makePayment, armHook, paymentMethodId]);

  const ErrorMessage = useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <PageWrapper title={TITLE}>
        <Flex flexDirection="column">
          <Box>{children}</Box>
          <CustomTextLink onClick={goBack} marginTop={3}>
            Try again
          </CustomTextLink>
        </Flex>
      </PageWrapper>
    ),
    [goBack]
  );

  if (!appointment) {
    return <ErrorMessage>Appointment {appointmentId} not found</ErrorMessage>;
  }

  if (!appointment?.stripePiSecret) {
    return (
      <ErrorMessage>
        Stripe payment info not found for appointment {appointmentId}
      </ErrorMessage>
    );
  }

  if (!patient) {
    return (
      <ErrorMessage>Patient {appointment.patientId} not found</ErrorMessage>
    );
  }

  if (!paymentMethodId) {
    return (
      <ErrorMessage>
        Payment info for patient {appointment.patientId} not found
      </ErrorMessage>
    );
  }

  if (isArmed && fetchStatus === FetchStatus.Failure) {
    return (
      <ErrorMessage>
        Payment failed
        {error && (
          <Text marginTop={2} color="red">
            {error}
          </Text>
        )}
      </ErrorMessage>
    );
  }

  return <PageWrapper title={TITLE}>Payment in progress...</PageWrapper>;
};

export const TriggerCardPayment = connect(
  mapStateToProps,
  mapDispatchToProps
)(TriggerCardPaymentComponent);
