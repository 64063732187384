import React from 'react';
import { useFormikContext } from "formik";
import { FormValues } from "pages/appointment/AppointmentReschedulePage/AppointmentReschedulePage";
import { Redirect } from "react-router-dom";

// Redirects to first page of flow if condition not met
interface Props {
  initialPath: string;
}

export const TriggerRestartReschedule = ({ initialPath }: Props) => {
  const { values: { reason } } = useFormikContext<FormValues>();

  if (!reason) {
    return <Redirect to={initialPath} />;
  }

  return null;
};
