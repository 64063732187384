import React from 'react';
import { Box, Flex, FlexProps } from 'rebass';
import { CssTypeFix } from 'types/types';

type Props = FlexProps &
  CssTypeFix & {
    children: React.ReactNode;
  };

export const Centered = ({ children, ...rest }: Props) => (
  <Flex
    width="100%"
    justifyContent="center"
    alignItems="center"
    flex={1}
    {...rest}
  >
    <Box>{children}</Box>
  </Flex>
);
