import React from 'react';
import { DetailedOffer } from 'types/types';
import { Flex, Text } from 'rebass';
import { Avatar } from 'components/Avatar/Avatar';
import { DefaultUserAvatar } from 'components/icons/DefaultUserAvatar';
import { OfferDetailsLabel } from 'components/OfferDetails/OfferDetailsLabel';
import { FormattedPhoneNumber } from 'components/OfferDetails/FormattedPhoneNumber';
import { Separator } from 'components/Separator';
import { OfferMessage } from 'components/OfferDetails/OfferMessage';

interface Props {
  offer: DetailedOffer | null;
}

export const OfferDetails = ({ offer }: Props) => {
  if (offer === null) {
    return null;
  }
  return (
    <Flex flexDirection="column">
      <OfferMessage offerStatus={offer.status} />
      <Flex alignItems="center">
        <Flex>
          <Avatar size={50}>
            <DefaultUserAvatar size={45} />
          </Avatar>
        </Flex>
        <Text marginLeft={2}>
          {offer.patientFirstName} {offer.patientLastName}
        </Text>
      </Flex>
      <OfferDetailsLabel label="Insurance">{offer.insurance}</OfferDetailsLabel>
      {/* Need treatment */}
      <OfferDetailsLabel label="Address">{offer.address}</OfferDetailsLabel>
      <OfferDetailsLabel label="Home">
        <FormattedPhoneNumber phoneNumber={offer.homePhone} />
      </OfferDetailsLabel>
      <OfferDetailsLabel label="Cell">
        <FormattedPhoneNumber phoneNumber={offer.mobilePhone} />
      </OfferDetailsLabel>
      <Separator />
      <OfferDetailsLabel label="Notes">{offer.notes}</OfferDetailsLabel>
    </Flex>
  );
};
