import React from 'react';
import { Box, Flex, Text } from 'rebass';
import { IconContext } from 'react-icons';
import { BUTTON_LEFT_COLOR } from 'theme/constants';
import { NavItemProps } from 'types/types';

export type Props = NavItemProps;

const getIconColor = ({
  isSelected,
  isDisabled,
}: {
  isSelected?: boolean;
  isDisabled?: boolean;
}) => {
  if (isDisabled) {
    return 'grey';
  }
  if (isSelected) {
    return BUTTON_LEFT_COLOR;
  }
};

export const NavItem = ({
  isSelected = false,
  icon,
  label,
  isDisabled = false,
}: Props) => (
  <Flex
    flexDirection="column"
    sx={{
      textAlign: 'center',
    }}
  >
    <Box>
      <IconContext.Provider
        value={{
          color: getIconColor({
            isSelected,
            isDisabled,
          }),
        }}
      >
        {icon}
      </IconContext.Provider>
    </Box>
    <Text
      sx={{
        fontSize: '9pt',
      }}
    >
      {label}
    </Text>
  </Flex>
);
