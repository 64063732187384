import { useDispatch } from "react-redux";
import { useCallback } from "react";

type InputFunction<Params extends Array<any>> = (...params: Params) => any;
type Output<Params extends Array<any>> = (...params: Params) => void;

export const useDispatchFunction = <Params extends Array<any>>(inputFunction: InputFunction<Params>): Output<Params> => {
  const dispatch = useDispatch();
  return useCallback(
    (...params: Params) => {
      dispatch(inputFunction(...params));
    },
    [dispatch, inputFunction],
  );
};
