import React from 'react';
import { Therapist } from 'types/types';
import { Flex, Text } from 'rebass';
import { DefaultTherapistAvatar } from 'components/DefaultTherapistAvatar/DefaultTherapistAvatar';
import { TherapistName } from 'components/TherapistName/TherapistName';

interface Props {
  therapist: Therapist;
  children?: React.ReactNode;
}

const Displayer = ({ children }: { children: React.ReactNode }) => (
  <Text color="grey" fontSize={14} marginTop={1}>
    {children}
  </Text>
);

export const TherapistDetailsCard = ({ therapist, children }: Props) => {
  const { email, city } = therapist;
  return (
    <Flex justifyContent="space-between" padding={3}>
      <Flex>
        <DefaultTherapistAvatar />
        <Flex flexDirection="column" marginLeft={2}>
          <TherapistName therapist={therapist} />
          <Displayer>{email}</Displayer>
          <Displayer>{city}</Displayer>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
};
