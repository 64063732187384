import React from 'react';
import { Box } from 'rebass';
import { EmploymentType } from 'types/types';
import { employmentTypeString } from 'components/TherapistEmploymentType/constants';

interface Props {
  employmentType: EmploymentType;
}

export const TherapistEmploymentType = ({ employmentType }: Props) => {
  return <Box>{employmentTypeString[employmentType]}</Box>;
};
