import { useCallback, useState } from "react";

export const useBoolean = (initialValue: boolean = false) => {
  const [value, setValue] = useState<boolean>(initialValue);
  const setTrue = useCallback(
    () => setValue(true),
    [setValue],
  );
  const setFalse = useCallback(
    () => setValue(false),
    [setValue],
  );
  return {
    value,
    setTrue,
    setFalse,
  };
};
