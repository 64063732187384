import { getFetchAppFileEndpoint } from '../constants';
import { baseURL } from 'api/api';

interface Params {
  caseId: string;
  fileId: string;
  headers: Record<string, string>;
}

type FileUrl = string;

export const fetchAppFile = async ({
  caseId,
  fileId,
  headers,
}: Params): Promise<FileUrl | undefined> => {
  const endpoint = getFetchAppFileEndpoint({ caseId, fileId });
  const result = await fetch(`${baseURL}/${endpoint}`, {
    headers: {
      ...headers,
    },
  });
  const resultData = await result.json();
  return resultData?.file_url;
};
