import { combineReducers } from 'redux';
import { Action, handleActions } from 'redux-actions';
import { BookedAppointment, ErrorAction, FetchStatus } from 'types/types';
import {
  GET_SCHEDULE,
  GET_SCHEDULE_FAILURE,
  GET_SCHEDULE_SUCCESS,
} from 'modules/schedule/actionTypes';
import { GetScheduleSuccessActionPayload } from 'modules/schedule/actions';
import {
  BOOK_APPOINTMENT,
  BOOK_APPOINTMENT_FAILURE,
  BOOK_APPOINTMENT_SUCCESS,
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILURE,
  FETCH_APPOINTMENTS_SUCCESS,
  HOME_HEALTH_APPOINTMENT_CANCEL_SUCCESS,
  SET_LAST_APPOINTMENT,
  UPDATE_APPOINTMENT,
  UPDATE_APPOINTMENT_FAILURE,
  UPDATE_APPOINTMENT_SUCCESS,
} from 'modules/appointments/actionTypes';
import {
  BookAppointmentSuccessActionPayload,
  SetLastAppointmentPayload,
} from 'modules/appointments/actions';
import { createFetchStatusReducer } from 'utils/createFetchStatusReducer';

export interface ScheduleState {
  appointments: number[];
  bookedAppointment: BookedAppointment | null;
  fetchStatus: FetchStatus;
  lastAppointmentId: number | null;
  error: null | string;
}

export const appointments = handleActions<
  number[],
  GetScheduleSuccessActionPayload
>(
  {
    [GET_SCHEDULE_SUCCESS]: (state, action) => action.payload.appointmentList,
    [HOME_HEALTH_APPOINTMENT_CANCEL_SUCCESS]: (state, action: any) => {
      const appoinmentIdIndex = state.indexOf(action.payload.appointmentId);
      const newState = [...state];
      newState.splice(appoinmentIdIndex, 1);
      return newState;
    },
  },
  []
);

const bookedAppointment = (
  state: ScheduleState['bookedAppointment'] = null,
  action: Action<BookAppointmentSuccessActionPayload>
) => {
  switch (action.type) {
    case BOOK_APPOINTMENT_SUCCESS:
      return action.payload;
    case BOOK_APPOINTMENT:
    case BOOK_APPOINTMENT_FAILURE:
    default:
      return state;
  }
};

const fetchStatus = createFetchStatusReducer({
  fetchingActions: [
    GET_SCHEDULE,
    BOOK_APPOINTMENT,
    FETCH_APPOINTMENTS,
    UPDATE_APPOINTMENT,
  ],
  successActions: [
    GET_SCHEDULE_SUCCESS,
    BOOK_APPOINTMENT_SUCCESS,
    FETCH_APPOINTMENTS_SUCCESS,
    UPDATE_APPOINTMENT_SUCCESS,
  ],
  failureActions: [
    GET_SCHEDULE_FAILURE,
    BOOK_APPOINTMENT_FAILURE,
    FETCH_APPOINTMENTS_FAILURE,
    UPDATE_APPOINTMENT_FAILURE,
  ],
});

const lastAppointmentId = handleActions<
  ScheduleState['lastAppointmentId'],
  any
>(
  {
    [SET_LAST_APPOINTMENT]: (
      state,
      action: Action<SetLastAppointmentPayload>
    ) => action.payload.id,
  },
  null
);

const error = handleActions<ScheduleState['error'], any>(
  {
    [BOOK_APPOINTMENT]: () => null,
    [BOOK_APPOINTMENT_FAILURE]: (state, action: ErrorAction) =>
      action.payload.message,
  },
  null
);

export default combineReducers<ScheduleState>({
  appointments,
  bookedAppointment,
  fetchStatus,
  lastAppointmentId,
  error,
});
