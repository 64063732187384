import React from 'react';
import { Patient } from 'types/types';
import { CreditCardInfo } from 'components/CreditCardInfo/CreditCardInfo';
import { getPatientCardInfo } from 'utils/patient/getPatientCardInfo';

interface Props {
  patient: Patient;
}

export const PatientCreditCardInfo = ({ patient }: Props) => {
  const { cardBrand, cardLast4 } = getPatientCardInfo({ patient });
  return <CreditCardInfo cardBrand={cardBrand} cardLast4={cardLast4} />;
};
