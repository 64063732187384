import { GlobalState } from 'reducers';
import { Feature } from 'types/types';
import { getUser } from 'selectors/user/getUser';
import { getIsUserInCohort } from 'selectors/features/getIsUserInCohort';

export const getIsFeatureEnabledForUser = (
  state: GlobalState,
  {
    feature,
  }: {
    feature: Feature;
  }
) => {
  const userId = String(getUser(state).id);
  const {
    isGloballyEnabled,
    whitelist = [],
    blacklist = [],
    cohort,
  } = state.features[feature];
  return (
    !blacklist.includes(userId) &&
    (isGloballyEnabled ||
      (cohort && getIsUserInCohort(state, { cohort })) ||
      whitelist.includes(String(userId)))
  );
};
