import React, { ReactNode } from 'react';
import { Box, Text } from 'rebass';
import { AppFile } from '../../types/types';
import { LazyS3ImageViewLink } from './LazyS3ImageViewLink';

interface Props {
  file: AppFile;
  caseId: string;
  uploader: ReactNode;
}

export const FileViewUpload = ({
  file: { fileName },
  caseId,
  uploader,
}: Props) => {
  return (
    <Box display="flex">
      <LazyS3ImageViewLink fileName={fileName} caseId={caseId}>
        View
      </LazyS3ImageViewLink>
      <Text marginRight="5px">,</Text>
      {uploader}
    </Box>
  );
};
