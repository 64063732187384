import { Moment } from 'moment';

export const getActiveMonths = ({
  activeDay,
  weeksToShow,
}: {
  activeDay: Moment;
  weeksToShow: number;
}) => {
  const start = activeDay.clone().startOf('week');
  const current = activeDay.clone();
  const end = activeDay
    .clone()
    .startOf('week')
    .add(7 * weeksToShow - 1, 'days');
  let result = current.format('MMMM');
  if (!start.isSame(current, 'month')) {
    result = `${start.format('MMMM')} / ${result}`;
  }
  if (!end.isSame(current, 'month')) {
    result = `${result} / ${end.format('MMMM')}`;
  }
  return result;
};
