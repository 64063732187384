export const getFetchAppFileEndpoint = ({
  caseId,
  fileId,
}: {
  caseId: string;
  fileId: string;
}) => `cases/${caseId}/attachments/${fileId}`;

export const getFetchAppFilesEndpoint = ({ caseId }: { caseId: string }) =>
  `cases/${caseId}/attachments`;

export const getUploadAppFileEndpoint = getFetchAppFilesEndpoint;
