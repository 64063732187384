import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { History } from "history";

export const useGoTo = (path: History.Path) => {
  const history = useHistory();
  return useCallback(
    () => history.push(path),
    [history, path],
  );
};
