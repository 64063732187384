import { AppFileType } from './constants';

const testWithString = ({
  baseString,
  appFileType,
}: {
  baseString: string;
  appFileType: AppFileType;
}) => {
  return baseString.slice(0, appFileType.length) === appFileType;
};

export const getAppFileType = (baseString: string): AppFileType | undefined => {
  for (const appFileType of Object.values(AppFileType)) {
    if (testWithString({ baseString, appFileType })) {
      return appFileType;
    }
  }
  return undefined;
};
