import { combineEpics } from 'redux-observable';
import { fetchOffers$ } from 'modules/offers/epics/fetchOffers$';
import { fetchOffer$ } from 'modules/offers/epics/fetchOffer$';
import { claimOffer$ } from 'modules/offers/epics/claimOffer$';
import { unclaimOffer$ } from 'modules/offers/epics/unclaimOffer$';

export default combineEpics(
  fetchOffers$,
  fetchOffer$,
  claimOffer$,
  unclaimOffer$
);
