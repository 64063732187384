import React from 'react';
import { Box, Text } from 'rebass';
import { COLOR_CODE_GREY } from 'theme/constants';

interface Props {
  label: React.ReactNode;
  children: React.ReactNode;
}

export const OfferDetailsLabel = ({ label, children }: Props) => {
  return (
    <Box marginTop={2}>
      <Text display="inline" color={COLOR_CODE_GREY}>
        {label}:
      </Text>
      <Text display="inline" marginLeft={1}>
        {children}
      </Text>
    </Box>
  );
};
