export const FETCH_PATIENT_CASES = 'FETCH_PATIENT_CASES';
export const FETCH_THERAPIST_CASES = 'FETCH_THERAPIST_CASES';
export const FETCH_CASES_SUCCESS = 'FETCH_CASES_SUCCESS';
export const FETCH_CASES_FAILURE = 'FETCH_CASES_FAILURE';
export const FETCH_CASE = 'FETCH_CASE';
export const FETCH_CASE_SUCCESS = 'FETCH_CASE_SUCCESS';
export const FETCH_CASE_FAILURE = 'FETCH_CASE_FAILURE';
export const UPDATE_CASE = 'UPDATE_CASE';
export const UPDATE_CASE_SUCCESS = 'UPDATE_CASE_SUCCESS';
export const UPDATE_CASE_FAILURE = 'UPDATE_CASE_FAILURE';
export const FETCH_CASE_APP_TYPES = 'FETCH_CASE_APP_TYPES';
export const FETCH_CASE_APP_TYPES_SUCCESS = 'FETCH_CASE_APP_TYPES_SUCCESS';
export const FETCH_CASE_APP_TYPES_FAILURE = 'FETCH_CASE_APP_TYPES_FAILURE';
