import React, { SyntheticEvent, useCallback } from 'react';
import {
  FileInputWrapper,
  Props as FileInputWrapperProps,
} from './FileInputWrapper';

interface Props extends FileInputWrapperProps {
  onFileSubmit: (f: File) => void;
}

export const SimpleFileInputWrapper = ({ onFileSubmit, ...props }: Props) => {
  const onChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      const file = e.currentTarget.files?.[0];
      if (file) {
        onFileSubmit(file);
        e.currentTarget.value = '';
      }
    },
    [onFileSubmit]
  );
  return <FileInputWrapper onChange={onChange} {...props} />;
};
