import { Moment } from 'moment';

interface Props {
  startTime: Moment;
}

interface Result {
  earliest: Moment;
  latest: Moment;
}

const LOOKBEHIND = [30, 'minutes'];
const LOOKAHEAD = [30, 'minutes'];

export const getAppointmentArrivalWindow = ({ startTime }: Props): Result => {
  return {
    earliest: startTime.clone().subtract(...LOOKBEHIND),
    latest: startTime.clone().add(...LOOKAHEAD),
  };
};
