import React from 'react';
import { Appointment, Case, Patient } from 'types/types';
import { withRouter, match } from 'react-router';
import { History, Location } from 'history';

import { AppointmentCard } from 'components/AppointmentCard/AppointmentCard';
import { getAppointmentPath } from 'routing/routeFunctions';
import { Box } from 'rebass';

interface Props {
  appointment: Appointment;
  history: History;
  location: Location;
  match: match;
  patient: Patient;
  therapyCase: Case;
}

const AppointmentCardLinkComponent = ({
  history,
  location,
  match,
  appointment,
  patient,
  ...restProps
}: Props) => (
  <Box
    onClick={React.useCallback(
      () => history.push(getAppointmentPath({ appointmentId: appointment.id })),
      [history, appointment]
    )}
  >
    <AppointmentCard
      appointment={appointment}
      patient={patient}
      {...restProps}
    />
  </Box>
);

export const AppointmentCardLink = withRouter<
  Props,
  typeof AppointmentCardLinkComponent
>(AppointmentCardLinkComponent);
